import {
	Component,
	OnInit,
	Input,
	EventEmitter,
	Output,
	HostListener,
	ViewChild,
	ElementRef,
} from "@angular/core";
import { BaseService } from "../../services/base.service";

@Component({
	selector: "app-shared-search-passengers",
	templateUrl: "./shared-search-passengers.component.html",
	styleUrls: ["./shared-search-passengers.component.scss"],
})
export class SharedSearchPassengersComponent implements OnInit {
	@Input() adultNumber: number;
	@Input() childNumber: number;
	@Input() infantNumber: number;
	@Input() infantSeatNumber: number;
	@Input() youthNumber: number;
	@Input() seniorNumber: number;
	@Input() searchSource: string;

	@Output() decrementEvent = new EventEmitter();
	@Output() incrementEvent = new EventEmitter();

	hideMorePaxes: boolean = true;
	show_dropdown: boolean = false;

	dropDownButton: string = "flight_search_select_travelers_button";
	dropDownButtonAria: string = "flight_search_select_travelers_button_aria";

	@ViewChild("dropdown_passenger") dropdown_passenger: ElementRef;
	@ViewChild("dropdown_passenger_button") dropdown_passenger_button: ElementRef;

	@HostListener("document:click", ["$event"]) clickoutDoc($event) {
		if (this.dropdown_passenger.nativeElement.contains($event.target)) {
			this.show_dropdown = true;
		} else {
			if (!this.dropdown_passenger_button.nativeElement.contains($event.target)) {
				this.show_dropdown = false;
			}
		}
	}

	constructor(private baseService: BaseService) {}

	ngOnInit() {}

	decrement(type: string) {
		this.decrementEvent.emit(type);
	}

	increment(type: string) {
		this.incrementEvent.emit(type);
	}

	updateHidePaxes() {
		this.hideMorePaxes = !this.hideMorePaxes;
	}

	openDPD() {
		// let elm1: HTMLElement = document.getElementById(this.dropDownButton) as HTMLElement;
		// elm1.focus();

		// let elm2: HTMLElement = document.getElementById(this.dropDownButtonAria) as HTMLElement;
		// elm2.classList.add('show');
		this.show_dropdown = !this.show_dropdown;
	}

	closeDPD() {
		let elm1: HTMLElement = document.getElementById(this.dropDownButton) as HTMLElement;
		elm1.blur();
		let elm2: HTMLElement = document.getElementById(this.dropDownButtonAria) as HTMLElement;
		elm2.classList.remove("show");
	}

	isArabic() {
		return this.baseService.isArabic();
	}
	formatNum(num: number): string {
		return num < 10 ? `0${num}` : `${num}`;
	}
}
