import { Component, OnInit, Input } from "@angular/core";
import { ShareVars } from "../../services/share.vars";
import { environment } from "environments/environment";

@Component({
	selector: "app-loader",
	templateUrl: "./loader.component.html",
	styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
	@Input() isBusy = false;
	shareVarsRef = ShareVars;
	clientName = "";

	constructor() {
		this.clientName = environment.clientDisplayName.toUpperCase();
	}

	ngOnInit() {}
}
