import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { ShareVars } from "./share.vars";
import { AGENCY_AGENCIES_B2B, AGENCY_PLATFORMS_B2B, CHARTER_CANCEL_BOOKING, CHARTER_CONFIRM_BOOKING, CHARTER_GET_BOOKINGS, FLIGHT_MANAGE_BOOKINGS_VALIDATING_CARRIERS_B2B_URL } from "./urls_b2b";
import { UrlBuilder } from "./urls_b2c";
import { charterManageBookingsSaves } from "./charter-manage-bookings-saves";

@Injectable()
export class CharterManageService {
	token;
	headers;
	currentBooking;
	searchSaves = new charterManageBookingsSaves();

	constructor(private baseService: BaseService) {}

	getValidatingCarriers() {
		return this.baseService.tokenGetRequest(FLIGHT_MANAGE_BOOKINGS_VALIDATING_CARRIERS_B2B_URL);
	}

	getAgenciesList(term?: string) {
		term = term ? "?term=" + term : "";
		return this.baseService.tokenGetRequest(AGENCY_AGENCIES_B2B + term);
	}

	getPlatformsList() {
		return this.baseService.tokenGetRequest(AGENCY_PLATFORMS_B2B + "?type=AIR");
	}

	getBookings(
		firstDay,
		lastDay,
		dateType,
		company,
		agencyID,
		platformID,
		customerName,
		per_page,
		page
	) {
		const urlBuilder = new UrlBuilder(CHARTER_GET_BOOKINGS)
			.addParam("firstDay", firstDay)
			.addParam("lastDay", lastDay)
			.addParam("date_type", dateType);

		if (company) {
			urlBuilder.addParam("airline", company);
		}

		if (agencyID) {
			urlBuilder.addParam("agency_id", agencyID);
		}

		if (platformID) {
			urlBuilder.addParam("platform_id", platformID);
		}

		if (customerName) {
			urlBuilder.addParam("customer", customerName);
		}

		if (per_page) {
			urlBuilder.addParam("per_page", per_page);
		}

		if (page) {
			urlBuilder.addParam("page", page);
		}
		
		return this.baseService.tokenGetRequest(urlBuilder.getUrl());
	}

	confirmBooking(id) {
		return this.baseService.tokenGetRequest(CHARTER_CONFIRM_BOOKING + "/" + id);
	}

	cancelBooking(id) {
		return this.baseService.tokenGetRequest(CHARTER_CANCEL_BOOKING + "/" + id);
	}
}
