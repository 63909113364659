export const callsigns = [
	{
		country: "Tunisie",
		code: "+216",
	},
	{
		country: "Afghanistan",
		code: "+93",
	},
	{
		country: "Afrique-du-Sud",
		code: "+27",
	},
	{
		country: "Albanie",
		code: "+355",
	},
	{
		country: "Algerie",
		code: "+213",
	},
	{
		country: "Allemagne",
		code: "+49",
	},
	{
		country: "Andorre",
		code: "+376",
	},
	{
		country: "Angola",
		code: "+244",
	},
	{
		country: "Anguilla",
		code: "+1264",
	},
	{
		country: "Antigua-et-Barbuda",
		code: "+1268",
	},
	{
		country: "Antarctique",
		code: "+672",
	},
	{
		country: "Arabie-saoudite",
		code: "+966",
	},
	{
		country: "Argentine",
		code: "+54",
	},
	{
		country: "Armenie",
		code: "+374",
	},
	{
		country: "Aruba",
		code: "+297",
	},
	{
		country: "Ascension",
		code: "+247",
	},
	{
		country: "Australie",
		code: "+61",
	},
	{
		country: "Autriche",
		code: "+43",
	},
	{
		country: "Azerba�djan",
		code: "+994",
	},
	{
		country: "Bahamas",
		code: "+1242",
	},
	{
		country: "Bahrein",
		code: "+973",
	},
	{
		country: "Bangladesh",
		code: "+880",
	},
	{
		country: "Barbade",
		code: "+1246",
	},
	{
		country: "Belgique",
		code: "+32",
	},
	{
		country: "Belize",
		code: "+501",
	},
	{
		country: "Benin",
		code: "+229",
	},
	{
		country: "Bermudes",
		code: "+1441",
	},
	{
		country: "Bhoutan",
		code: "+975",
	},
	{
		country: "Bielorussie",
		code: "+375",
	},
	{
		country: "Birmanie",
		code: "+95",
	},
	{
		country: "Bolivie",
		code: "+591",
	},
	{
		country: "Bosnie-Herz�govine",
		code: "+387",
	},
	{
		country: "Botswana",
		code: "+267",
	},
	{
		country: "Bresil",
		code: "+55",
	},
	{
		country: "Brunei",
		code: "+673",
	},
	{
		country: "Bulgarie",
		code: "+359",
	},
	{
		country: "Burkina-Faso",
		code: "+226",
	},
	{
		country: "Burundi",
		code: "+257",
	},
	{
		country: "Cambodge",
		code: "+855",
	},
	{
		country: "Cameroun",
		code: "+237",
	},
	{
		country: "Canada",
		code: "+1",
	},
	{
		country: "Cap-Vert",
		code: "+238",
	},
	{
		country: "Iles-Caimans",
		code: "+1345",
	},
	{
		country: "Rpublique-centrafricaine",
		code: "+236",
	},
	{
		country: "Chili",
		code: "+56",
	},
	{
		country: "Chine",
		code: "+86",
	},
	{
		country: "Chypre",
		code: "+357",
	},
	{
		country: "Colombie",
		code: "+57",
	},
	{
		country: "Comores",
		code: "+269",
	},
	{
		country: "Republique-d�mocratique-du-Congo",
		code: "+243",
	},
	{
		country: "Republique-du-Congo",
		code: "+242",
	},
	{
		country: "Iles-Cook",
		code: "+682",
	},
	{
		country: "Coree-du-Nord",
		code: "+850",
	},
	{
		country: "Coree-du-Sud",
		code: "+82",
	},
	{
		country: "Costa-Rica",
		code: "+506",
	},
	{
		country: "Cote-d'Ivoire",
		code: "+225",
	},
	{
		country: "Croatie",
		code: "+385",
	},
	{
		country: "Cuba",
		code: "+53",
	},
	{
		country: "Danemark",
		code: "+45",
	},
	{
		country: "Djibouti",
		code: "+253",
	},
	{
		country: "Egypte",
		code: "+20",
	},
	{
		country: "Emirats-arabes-unis",
		code: "+971",
	},
	{
		country: "Equateur",
		code: "+593",
	},
	{
		country: "Erythree",
		code: "+291",
	},
	{
		country: "Espagne",
		code: "+34",
	},
	{
		country: "Estonie",
		code: "+372",
	},
	{
		country: "Etats-Unis",
		code: "+1",
	},
	{
		country: "Ethiopie",
		code: "+251",
	},
	{
		country: "Finlande",
		code: "+358",
	},
	{
		country: "France",
		code: "+33",
	},
	{
		country: "Gabon",
		code: "+241",
	},
	{
		country: "Gambie",
		code: "+220",
	},
	{
		country: "Georgie",
		code: "+995",
	},
	{
		country: "Ghana",
		code: "+233",
	},
	{
		country: "Gibraltar",
		code: "+350",
	},
	{
		country: "Grece",
		code: "+30",
	},
	{
		country: "Grenade",
		code: "+1473",
	},
	{
		country: "Guadeloupe",
		code: "+590",
	},
	{
		country: "Guatemala",
		code: "+502",
	},
	{
		country: "Guinee",
		code: "+224",
	},
	{
		country: "Guinee-Equatoriale",
		code: "+240",
	},
	{
		country: "Guinee-Bissau",
		code: "+245",
	},
	{
		country: "Guyana",
		code: "+592",
	},
	{
		country: "Guyane",
		code: "+594",
	},
	{
		country: "Haiti",
		code: "+509",
	},
	{
		country: "Honduras",
		code: "+504",
	},
	{
		country: "Hong Kong",
		code: "+852",
	},
	{
		country: "Hongrie",
		code: "+36",
	},
	{
		country: "Inde",
		code: "+91",
	},
	{
		country: "Indonesie",
		code: "+62",
	},
	{
		country: "Irak",
		code: "+964",
	},
	{
		country: "Iran",
		code: "+98",
	},
	{
		country: "Irlande",
		code: "+353",
	},
	{
		country: "Islande",
		code: "+354",
	},
	{
		country: "Italie",
		code: "+39",
	},
	{
		country: "Jamaique",
		code: "+1876",
	},
	{
		country: "Japon",
		code: "+81",
	},
	{
		country: "Jordanie",
		code: "+962",
	},
	{
		country: "Kazakhstan",
		code: "+7",
	},
	{
		country: "Kenya",
		code: "+254",
	},
	{
		country: "Kirghizistan",
		code: "+996",
	},
	{
		country: "Kiribati",
		code: "+686",
	},
	{
		country: "Kosovo",
		code: "+383",
	},
	{
		country: "Koweit",
		code: "+965",
	},
	{
		country: "Laos",
		code: "+856",
	},
	{
		country: "Lesotho",
		code: "+266",
	},
	{
		country: "Lettonie",
		code: "+371",
	},
	{
		country: "Liban",
		code: "+961",
	},
	{
		country: "Liberia",
		code: "+231",
	},
	{
		country: "Libye",
		code: "+218",
	},
	{
		country: "Liechtenstein",
		code: "+423",
	},
	{
		country: "Lituanie",
		code: "+370",
	},
	{
		country: "Luxembourg",
		code: "+352",
	},
	{
		country: "Macao",
		code: "+853",
	},
	{
		country: "Macedoine du Nord",
		code: "+389",
	},
	{
		country: "Madagascar",
		code: "+261",
	},
	{
		country: "Malaisie",
		code: "+60",
	},
	{
		country: "Malawi",
		code: "+265",
	},
	{
		country: "Maldives",
		code: "+960",
	},
	{
		country: "Mali",
		code: "+223",
	},
	{
		country: "Malouines",
		code: "+500",
	},
	{
		country: "Malte",
		code: "+356",
	},
	{
		country: "Maroc",
		code: "+212",
	},
	{
		country: "Iles Marshall",
		code: "+692",
	},
	{
		country: "Martinique",
		code: "+596",
	},
	{
		country: "Maurice",
		code: "+230",
	},
	{
		country: "Mauritanie",
		code: "+222",
	},
	{
		country: "Mayotte",
		code: "+262",
	},
	{
		country: "Mexique",
		code: "+52",
	},
	{
		country: "Moldavie",
		code: "+373",
	},
	{
		country: "Monaco",
		code: "+377",
	},
	{
		country: "Mongolie",
		code: "+976",
	},
	{
		country: "Montenegro",
		code: "+382",
	},
	{
		country: "Mozambique",
		code: "+258",
	},
	{
		country: "Namibie",
		code: "+264",
	},
	{
		country: "Nepal",
		code: "+977",
	},
	{
		country: "Nicaragua",
		code: "+505",
	},
	{
		country: "Niger",
		code: "+227",
	},
	{
		country: "Nigeria",
		code: "+234",
	},
	{
		country: "Niue",
		code: "+683",
	},
	{
		country: "Norvege",
		code: "+47",
	},
	{
		country: "Nouvelle-Caledonie",
		code: "+687",
	},
	{
		country: "Nouvelle-Zelande",
		code: "+64",
	},
	{
		country: "Oman",
		code: "+968",
	},
	{
		country: "Ouganda",
		code: "+256",
	},
	{
		country: "Ouzbekistan",
		code: "+998",
	},
	{
		country: "Pakistan",
		code: "+92",
	},
	{
		country: "Palaos",
		code: "+680",
	},
	{
		country: "Palestine",
		code: "+970",
	},
	{
		country: "Panama",
		code: "+507",
	},
	{
		country: "Papouasie-Nouvelle-Guinee",
		code: "+675",
	},
	{
		country: "Paraguay",
		code: "+595",
	},
	{
		country: "Pays-Bas",
		code: "+31",
	},
	{
		country: "Perou",
		code: "+51",
	},
	{
		country: "Philippines",
		code: "+63",
	},
	{
		country: "Pologne",
		code: "+48",
	},
	{
		country: "Portugal",
		code: "+351",
	},
	{
		country: "Qatar",
		code: "+974",
	},
	{
		country: "La-Reunion",
		code: "+262",
	},
	{
		country: "Roumanie",
		code: "+40",
	},
	{
		country: "Royaume-Uni",
		code: "+44",
	},
	{
		country: "Russie",
		code: "+7",
	},
	{
		country: "Rwanda",
		code: "+250",
	},
	{
		country: "Iles-Salomon",
		code: "+677",
	},
	{
		country: "Salvador",
		code: "+503",
	},
	{
		country: "Senegal",
		code: "+221",
	},
	{
		country: "Serbie",
		code: "+381",
	},
	{
		country: "Seychelles",
		code: "+248",
	},
	{
		country: "Sierra-Leone",
		code: "+232",
	},
	{
		country: "Singapour",
		code: "+65",
	},
	{
		country: "Slovaquie",
		code: "+421",
	},
	{
		country: "Slovenie",
		code: "+386",
	},
	{
		country: "Somalie",
		code: "+252",
	},
	{
		country: "Soudan",
		code: "+249",
	},
	{
		country: "Soudan-du-Sud",
		code: "+211",
	},
	{
		country: "Sri-Lanka",
		code: "+94",
	},
	{
		country: "Suede",
		code: "+46",
	},
	{
		country: "Suisse",
		code: "+41",
	},
	{
		country: "Suriname",
		code: "+597",
	},
	{
		country: "Eswatini",
		code: "+268",
	},
	{
		country: "Syrie",
		code: "+963",
	},
	{
		country: "Tadjikistan",
		code: "+992",
	},
	{
		country: "Tanzanie",
		code: "+255",
	},
	{
		country: "Taiwan",
		code: "+886",
	},
	{
		country: "Tchad",
		code: "+235",
	},
	{
		country: "Tcheque",
		code: "+420",
	},
	{
		country: "Thailande",
		code: "+66",
	},
	{
		country: "Togo",
		code: "+228",
	},
	{
		country: "Tokelau",
		code: "+690",
	},
	{
		country: "Tonga",
		code: "+676",
	},
	{
		country: "Trinite-et-Tobago",
		code: "+1868",
	},
	{
		country: "Tunisie",
		code: "+216",
	},
	{
		country: "Turkmenistan",
		code: "+993",
	},
	{
		country: "Turquie",
		code: "+90",
	},
	{
		country: "Tuvalu",
		code: "+688",
	},
	{
		country: "Ukraine",
		code: "+380",
	},
	{
		country: "Uruguay",
		code: "+598",
	},
	{
		country: "Vanuatu",
		code: "+678",
	},
	{
		country: "Venezuela",
		code: "+58",
	},
	{
		country: "Vietnam",
		code: "+84",
	},
	{
		country: "Yemen",
		code: "+967",
	},
	{
		country: "Zambie",
		code: "+260",
	},
	{
		country: "Zimbabwe",
		code: "+263",
	},
];
