import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Router, ActivatedRoute } from "@angular/router";

const PLATFORM_WEB = "web";

// !important: every function in this service must declared with same declartion in mobile.service.ts even if it's not needed for mobile (declare it with empty)
@Injectable({
	providedIn: "root",
})
export class WebService {
	constructor(
		private cookieService: CookieService,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {}

	saveData(key, value, date?) {
		const path = "/";
		this.cookieService.set(key, value, date, path, "", false, "Lax"); //, date, path, window.location.protocol + window.location.host, true, 'Lax');
	}

	getData(key) {
		return this.cookieService.get(key);
	}

	deleteData(key) {
		const path = "/";
		this.cookieService.delete(key, path);
	}

	getPlatformName() {
		return PLATFORM_WEB;
	}

	navigate(route, params?) {
		if (params) {
			this.router.navigate(route, { queryParams: params });
		} else {
			this.activatedRoute.queryParams.subscribe((urlParams) => {
				Object.keys(urlParams).length > 0
					? this.router.navigate(route, { queryParams: urlParams })
					: this.router.navigate(route);
			});
		}
	}

	backToPreviousPage() {
		this.router.navigate([".."]);
	}

	queryParamsObservable() {
		return this.activatedRoute.queryParams;
	}

	getGPSPostion() {
		return new Promise((resolve) => {
			navigator.geolocation.getCurrentPosition((position) => resolve(position.coords));
		});
	}

	routerEventsObservable() {
		return this.router.events;
	}
	getRoutePath() {
		return this.router.url;
	}
}
