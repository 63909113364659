import { Component, OnInit, Input } from "@angular/core";
import { DateService } from "../../services/date.service";
import { BaseService } from "../../services/base.service";
import { HotelService } from "../../services/hotel.service";
import { NavigationService } from "../../services/navigation.service";
import { ShareVars } from "../../services/share.vars";
import { ERROR_MESSAGE_GETTING_DATA } from "../../services/display.messages";
import { environment } from "environments/environment";

declare var QRCode: any;

@Component({
	selector: "app-hotel-voucher-shared",
	templateUrl: "./hotel-voucher-shared.component.html",
	styleUrls: ["./hotel-voucher-shared.component.scss"],
})
export class HotelVoucherSharedComponent implements OnInit {
	@Input() confirmationData: any;
	@Input() userData = null;
	@Input() confirmationPage = true;
	@Input() udSource = false;
	hotelNights: number;
	hotel;
	paxes;

	showModalEmail: boolean = false;
	printEmail: string = null;
	isB2BUser: boolean = false;
	staticURL = "";
	booking = null;
	agency = null;
	remarkChanged = false;
	qrcodedata = null;
	token = null;
	assetClientName;
	imageLogo = "LOGO_LARGE.png";

	constructor(
		private baseService: BaseService,
		private hotelService: HotelService,
		private navigationService: NavigationService
	) {}

	ngOnInit() {
		this.isB2BUser = this.baseService.b2bUserType == "B2B";
		this.hotelNights = this.setHotelNights(this.confirmationData);
		this.hotel = this.hotelService.getHotelBookingHotelItem();
		this.paxes = this.confirmationData.result.booking.hotel.paxes;
		this.staticURL = environment.staticApi;
		this.assetClientName = environment.assets;
		if (this.assetClientName == "triponline") {
			this.imageLogo = "logo-tpo-large-vertical.png";
		}
		this.booking = this.confirmationData.result.booking;
		this.trimComments();
		if (this.booking.hotel.name) {
			this.booking.hotel.name = this.booking.hotel.name.replace(/\u00A0/g, " ");
		}
		this.agency = this.confirmationData.result.agency;
		this.token = this.baseService.getToken();
		this.qrcodedata = this.baseService.getPrintBookingUrl(
			this.booking.internal_reference,
			this.token,
			"B2B",
			"HOTEL"
		);
		setTimeout(() => {
			let doc = document.getElementById("qrcode");
			new QRCode(doc, this.qrcodedata);
		}, 1000);
	}

	setHotelNights(confirmationData) {
		let checkInDate;

		if (!this.udSource) {
			checkInDate = DateService.toObjectDate(
				checkInDate,
				confirmationData["result"]["booking"]["hotel"]["checkIn"]
			);
		} else {
			checkInDate = DateService.toObjectDate(checkInDate, confirmationData["checkin"]);
		}

		checkInDate = DateService.toNewDate(checkInDate);

		let checkOutDate;

		if (!this.udSource) {
			checkOutDate = DateService.toObjectDate(
				checkOutDate,
				confirmationData["result"]["booking"]["hotel"]["checkOut"]
			);
		} else {
			checkOutDate = DateService.toObjectDate(checkOutDate, confirmationData["checkout"]);
		}

		checkOutDate = DateService.toNewDate(checkOutDate);

		let outd = checkOutDate.getTime();
		let ind = checkInDate.getTime();

		return Math.ceil((outd - ind) / (1000 * 3600 * 24));
	}

	formatPrice(price: number) {
		return this.baseService.formatPrice(price);
	}

	getTotalPrice(rooms) {
		let total: number = rooms.reduce(
			(acc, valeur) => acc.rates[0].net.total + valeur.rates[0].net.total
		);
		return this.formatPrice(total) + " " + rooms[0].rates[0].net.currency;
	}

	printVoucher(ref) {
		this.baseService.printBooking(ref, this.token, environment.mode, "HOTEL");
	}

	goToHomePage() {
		this.navigationService.goToHomePage();
	}

	openEmailModal() {
		this.showModalEmail = true;
	}

	sendHotelVoucherByEmail(voucherEmailModal) {
		ShareVars.isBusy = true;
		voucherEmailModal.closeModal();

		this.baseService
			.sendBookingByEmail(
				this.confirmationData.result.booking.internal_reference,
				this.printEmail,
				environment.mode,
				"HOTEL"
			)
			.subscribe(
				(data) => {
					ShareVars.isBusy = false;
					if (data["status"] != 200 && !data["ok"]) {
						ShareVars.showError = true;
						ShareVars.errorMessage = ERROR_MESSAGE_GETTING_DATA;
					}
				},
				(error) => {
					ShareVars.isBusy = false;
					ShareVars.showError = true;
					ShareVars.errorMessage = ERROR_MESSAGE_GETTING_DATA;
				}
			);
	}

	goToHotelManageBookingsPage(ref) {
		ShareVars.isBusy = true;
		this.navigationService.goToB2BHotelVoucher(ref);
	}

	trimComments() {
		if (this.booking.hotel.comments) {
			this.booking.hotel.comments = this.booking.hotel.comments
				.replace(/(<\/*br\/*>\s*){2,}/gi, "<br>")
				.replace(/^\s*<\/*br\/*>/gi, "");
			this.booking.hotel.comments = this.booking.hotel.comments.replace(
				"<strong><b></b></strong><br>",
				""
			);
		}
	}

	onInputEvent($event) {
		this.remarkChanged = true;
	}

	getNights() {
		const oneDay = 24 * 60 * 60 * 1000;
		const firstDate: any = new Date(this.booking.hotel.checkIn);
		const secondDate: any = new Date(this.booking.hotel.checkOut);
		return Math.round(Math.abs((firstDate - secondDate) / oneDay));
	}
}
