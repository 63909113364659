import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { LoginService } from "@app/shared/services/login.service";
import { FlightService } from "../../../shared/services/flight.service";
import { LocalizationService } from "../../../shared/services/localization.service";
import { ShareVars } from "../../../shared/services/share.vars";
import { BaseService } from "../../../shared/services/base.service";
import { NavigationService } from "../../../shared/services/navigation.service";
import { NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import {
	CustomDatepickerI18nService,
	I18n,
} from "@app/shared/services/custom-datepicker-i18n.service";
import { FlightPassengerCommonModel } from "@app/flight-booking/components/flight-passenger-models/flight.passenger.common.model";
import { FlightPassengerInfantModel } from "@app/flight-booking/components/flight-passenger-models/flight.passenger.infant.model";
import { CharterSearchService } from "@app/shared/services/charter-search.service";
import { DateService } from "@app/shared/services/date.service";

const INFANT_MAX_AGE = 2;
const INFANT_MIN_AGE = 0;
const ADULT_MAX_AGE = 130;
const ADULT_MIN_DATE = 12;
const CHILD_MIN_AGE = 2;
const CHILD_MAX_AGE = 12;
const MINOR_AGE = 18;

const YOUTH_MIN_DATE = 12;
const YOUTH_MAX_DATE = 24;
const SENIOR_MIN_DATE = 60;

@Component({
	selector: "app-charter-passenger",
	templateUrl: "./charter-passenger.component.html",
	styleUrls: ["./charter-passenger.component.scss"],
	providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }],
})
export class CharterPassengerComponent implements OnInit, OnDestroy {
	@Input() object = {};
	@Input() adultNumber: number;
	@Input() childrenNumber: number;
	@Input() infantNumber: number;

	// @Input() infantSeatNumber: number;
	// @Input() youthNumber: number;
	// @Input() seniorNumber: number;
	maxDateAdultBD;
	minDateAdultBD;
	maxDateChildBD;
	minDateChildBD;
	maxDateInfantBD;
	minDateInfantBD;

	clientInfos = {
		clientEmail: "",
		clientVerifyEmail: "",
		clientPhoneNumber: "",
		clientAddress: "",
	};

	user = {
		email: "",
	};

	userType = "B2B";

	phonePattern: string = "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$";
	emailPattern: string = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$";
	noPattern: string = "";

	clientCountryCodeDropdown = {
		mainButtonID: "flight_passenger_pax_client_countrycode_class_dropdown",
		mainButtonText: "+213 Algerie",
		dropdownAriaID: "flight_passenger_pax_client_countrycode_class_dropdown_aria",
		ariaButtonsID: [],
		ariaButtonsText: ["+213 Algerie"],
		dropdownTypeString: true,
	};

	clientCitiesDropdown = {
		mainButtonID: "flight_passenger_pax_client_city_class_dropdown",
		dropdownAriaID: "flight_passenger_pax_client_city_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		dropdownTypeString: false,
	};

	clientTownsDropdown = {
		mainButtonID: "flight_passenger_pax_client_town_class_dropdown",
		dropdownAriaID: "flight_passenger_pax_client_town_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		dropdownTypeString: false,
	};

	clientCountriesDropdown = {
		mainButtonID: "flight_passenger_pax_client_country_class_dropdown",
		dropdownAriaID: "flight_passenger_pax_client_country_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: ["Algérie", "Liban"],
		dropdownTypeString: false,
	};

	adultCivilityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "Mr.",
		ariaButtonsID: [],
		ariaButtonsText: ["Mr.", "Mrs.", "Ms."],
		dropdownTypeString: false,
	};

	adultNationalityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		ariaButtonsTextTranslation: {},
		dropdownTypeString: false,
	};

	adultCardPassportDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "ID_CARD",
		ariaButtonsID: [],
		ariaButtonsText: ["ID_CARD", "PASSPORT"],
		dropdownTypeString: true,
	};

	infantCivilityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "Mr.",
		ariaButtonsID: [],
		ariaButtonsText: ["Mr.", "Mrs.", "Ms."],
		dropdownTypeString: false,
	};

	infantNationalityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		ariaButtonsTextTranslation: {},
		dropdownTypeString: false,
	};

	childCivilityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "Mr.",
		ariaButtonsID: [],
		ariaButtonsText: ["Mr.", "Mrs.", "Ms."],
		dropdownTypeString: false,
	};

	childNationalityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		ariaButtonsTextTranslation: {},
		dropdownTypeString: false,
	};

	childCardPassportDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "ID_CARD",
		ariaButtonsID: [],
		ariaButtonsText: ["ID_CARD", "PASSPORT"],
		dropdownTypeString: true,
	};

	shareVarsRef = ShareVars;

	extraFields = new Array();
	travellerProfiles = new Array();
	travellerProfilesNames = new Array();
	travellerProfilesDictionnary = {};
	countryCodesDictionnary = {};
	corporate = false;

	adultsInfosArray = new Array();
	childrenInfosArray = new Array();
	infantsInfosArray = new Array();

	validNamesAdult = [true];
	validNamesInfant = [true];
	validNamesChild = [true];

	constructor(
		private navigationService: NavigationService,
		private charterService: CharterSearchService,
		private localizationService: LocalizationService,
		private baseService: BaseService
	) {
		this.charterService.charters.length === 0 ? this.navigationService.goToCharterPage() : null;
	}

	ngOnInit() {
		if (this.adultNumber > 0) {
			this.validNamesAdult = new Array(this.adultNumber).fill(false);
			this.adultsInfosArray = new Array(this.adultNumber)
				.fill(0)
				.map((e) => new FlightPassengerCommonModel());
		}

		if (this.childrenNumber > 0) {
			this.validNamesChild = new Array(this.childrenNumber).fill(false);
			this.childrenInfosArray = new Array(this.childrenNumber)
				.fill(0)
				.map((e) => new FlightPassengerCommonModel());
		}

		if (this.infantNumber > 0) {
			this.validNamesInfant = new Array(this.infantNumber).fill(false);
			this.infantsInfosArray = new Array(this.infantNumber)
				.fill(0)
				.map((e) => new FlightPassengerInfantModel());
		}

		if (this.shareVarsRef.countryCodes.length > 0) {
			this.initCountriesDpd();
		} else {
			this.localizationService.getCountries().subscribe((data) => {
				this.shareVarsRef.setCountriesInfo(data);

				this.initCountriesDpd();
			});
		}

		let flightDeparture = "1900-01-01";
		if (
			this.charterService.charterItem &&
			this.charterService.charterItem.departure_date_time !== undefined
		) {
			flightDeparture = this.charterService.charterItem.departure_date_time.substring(0, 10);
		} else {
			this.navigationService.goToCharterPage();
		}

		if (this.adultNumber > 0) {
			this.validNamesAdult = new Array(this.adultNumber).fill(false);
			this.adultsInfosArray = new Array(this.adultNumber)
				.fill(0)
				.map((e) => new FlightPassengerCommonModel());
			this.maxDateAdultBD = DateService.convertDateInPastYear(
				ADULT_MAX_AGE,
				flightDeparture,
				true
			);
			this.minDateAdultBD = DateService.convertDateInPastYear(
				ADULT_MIN_DATE,
				flightDeparture,
				false
			);
		}

		if (this.childrenNumber > 0) {
			this.validNamesChild = new Array(this.childrenNumber).fill(false);
			this.childrenInfosArray = new Array(this.childrenNumber)
				.fill(0)
				.map((e) => new FlightPassengerCommonModel());
			this.maxDateChildBD = DateService.convertDateInPastYear(
				CHILD_MAX_AGE,
				flightDeparture,
				true
			);
			this.minDateChildBD = DateService.convertDateInPastYear(
				CHILD_MIN_AGE,
				flightDeparture,
				false
			);
		}

		if (this.infantNumber > 0) {
			this.validNamesInfant = new Array(this.infantNumber).fill(false);
			this.infantsInfosArray = new Array(this.infantNumber)
				.fill(0)
				.map((e) => new FlightPassengerInfantModel());
			this.maxDateInfantBD = DateService.convertDateInPastYear(
				INFANT_MAX_AGE,
				flightDeparture,
				true
			);
			this.minDateInfantBD = DateService.convertDateInPastYear(
				INFANT_MIN_AGE,
				flightDeparture,
				false
			);
		}
	}

	initCountriesDpd() {
		this.adultNationalityDropdown.ariaButtonsText = this.shareVarsRef.countryCodes;
		this.adultNationalityDropdown.ariaButtonsTextTranslation =
			this.shareVarsRef.countryNameDictionnary;
		this.childNationalityDropdown.ariaButtonsText = this.shareVarsRef.countryCodes;
		this.childNationalityDropdown.ariaButtonsTextTranslation =
			this.shareVarsRef.countryNameDictionnary;
		this.infantNationalityDropdown.ariaButtonsText = this.shareVarsRef.countryCodes;
		this.infantNationalityDropdown.ariaButtonsTextTranslation =
			this.shareVarsRef.countryNameDictionnary;
		this.clientCountriesDropdown.ariaButtonsText = this.shareVarsRef.countryNames;
	}

	canBook() {
		return true;
	}

	ngOnDestroy(): void {}

	getAdultNatAriaBtnID(adultIndex) {
		for (let i = 0; i < this.adultNationalityDropdown.ariaButtonsText.length; i++) {
			this.adultNationalityDropdown.ariaButtonsID[i] =
				"flight_passenger_pax_adult_nationality_" + adultIndex;
		}

		return this.adultNationalityDropdown.ariaButtonsID;
	}

	getInfantNatAriaBtnID(adultIndex) {
		for (let i = 0; i < this.infantNationalityDropdown.ariaButtonsText.length; i++) {
			this.infantNationalityDropdown.ariaButtonsID[i] =
				"flight_passenger_pax_infant_nationality_" + adultIndex;
		}

		return this.infantNationalityDropdown.ariaButtonsID;
	}

	getChildNatAriaBtnID(childIndex) {
		for (let i = 0; i < this.childNationalityDropdown.ariaButtonsText.length; i++) {
			this.childNationalityDropdown.ariaButtonsID[i] =
				"flight_passenger_pax_child_nationality_" + childIndex;
		}

		return this.childNationalityDropdown.ariaButtonsID;
	}

	testTrim(a: string): boolean {
		if (a != null) {
			if (a.trim().length < 2) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	openBillingTab() {
		this.charterService.setClientInfos(this.clientInfos);
		this.charterService.setAdultsInfos(this.adultsInfosArray);
		this.charterService.setInfantsInfos(this.infantsInfosArray);
		this.charterService.setChildrenInfos(this.childrenInfosArray);
		this.setBillingTab();
	}

	setBillingTab() {
		const elm: HTMLElement = document.getElementById("pax_billing_info_tab") as HTMLElement;
		ShareVars.enableBillingInfoTab = true;
		elm.classList.remove("disbaled-tab");
		elm.click();
		this.scrollToTop();
	}

	scrollToTop() {
		let elm: HTMLElement = document.getElementById("nav_pax_tabs") as HTMLElement;
		setTimeout(() => {
			elm.scrollIntoView({ behavior: "smooth" });
		}, 500);
	}

	setNationality(passenger, nationality) {
		passenger["nationality"] = nationality;
	}

	setCivility(passenger, civility) {
		passenger["civility"] = civility;
	}

	infantToggle(e, adultIndex) {
		if (this.infantNumber > adultIndex) {
			this.infantsInfosArray[adultIndex].fill_after = e;
		}
	}
}
