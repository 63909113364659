import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
	HOTEL_AUTOCOMPLETE_API_URL,
	URL_PARAMS,
	UrlBuilder,
	HOTEL_AVAILABILITY_API_URL,
	HOTEL_CHECK_RATES_API_URL,
	HOTEL_CHECK_BOOKING_WITH_REF,
	HOTEL_TOP_DESTINATIONS_URL,
	HOTEL_OFFERS_B2C_URL,
} from "./urls_b2c";
import {
	HOTEL_AUTOCOMPLETE_B2B_URL,
	HOTEL_AVAILABILITY_B2B_URL,
	HOTEL_CHECK_RATES_B2B_URL,
	HOTEL_OFFERS_B2B_URL,
	HOTEL_UPDATE_REMARK_B2B,
	HOTEL_TOP_DESTINATIONS_B2B_URL,
	HOTEL_EXCLUDE_B2B_URL,
	HOTEL_INCLUDE_B2B_URL,
} from "./urls_b2b";
import { BaseService } from "./base.service";
import { cloneDeep } from "lodash";
import { SharedRoomModel } from "../models/sharedRoomModel";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
const HOTEL_SEARCH_PARAMS = "searchHotelsParms";
const SELECTED_HOTEL_KEY = "selectedHotelKey";
const SELECTED_HOTEL_PROVIDER = "selectedHoteProvider";

@Injectable()
export class HotelService {
	hotelItem = null;
	hotelBookingHotelCode = null;
	hotelBookingHotelCodeCrypted = null;
	hotelBookingHotelRates = new Array();
	hotelBookingCancellationPolicyCombinedRooms = new Array();
	hotelBookingHotelTotalPrice = null;
	hotelBookingHotelPriceCurrency = null;
	hotelProviders = null;
	selectedProvider = null;
	selectedComment = null;
	supplementsArray = new Array();

	//to create array of rooms
	hotelBookingHotelSearchParams = null;

	hotelConfirmationRequest = null;

	hotelBookingAvailabilityResults = null;

	clientInfos = null;
	guestsInfosArray = null;
	hotelBookingHotelCheckRateRooms = null;
	recommendedHotelsSubject: BehaviorSubject<any> = new BehaviorSubject([]);

	constructor(private baseService: BaseService, private httpClient: HttpClient) {}

	removeHotelPaxData() {
		this.setHotelBookingHotelRates(null);
		// this.setHotelBookingHotelItem(null);
		//this.setHotelBookingHotelProviders(null);
		this.setHotelConfirmationRequest(null);
	}

	removeAllData() {
		this.hotelItem = null;
		this.hotelBookingHotelRates = new Array();
		this.hotelBookingHotelTotalPrice = null;
		this.hotelBookingHotelPriceCurrency = null;
		this.hotelProviders = null;
		this.selectedComment = null;
		this.hotelBookingAvailabilityResults = null;
		this.hotelBookingHotelSearchParams = null;
		this.selectedProvider = null;
		this.hotelBookingHotelCode = null;
		this.hotelBookingHotelCodeCrypted = null;
		this.clientInfos = null;
		this.guestsInfosArray = null;
		this.hotelBookingHotelCheckRateRooms = null;
		this.hotelBookingCancellationPolicyCombinedRooms = new Array();
	}

	getHotelBookingHotelItem() {
		return this.hotelItem;
	}

	setHotelBookingHotelItem(hotelItem) {
		if (hotelItem == null) {
			this.hotelItem = null;
		} else {
			this.hotelItem = cloneDeep(hotelItem);
		}
	}

	getHotelBookingHotelProviders() {
		return this.hotelProviders;
	}

	setHotelBookingHotelProviders(providers) {
		this.hotelProviders = cloneDeep(providers);
	}

	getHotelBookingHotelCode() {
		return this.hotelBookingHotelCode;
	}

	setHotelBookingHotelCode(code) {
		this.hotelBookingHotelCode = code;
	}

	setHotelBookingHotelCodeCrypted(code) {
		this.hotelBookingHotelCodeCrypted = code;
	}

	getHotelBookingHotelCodeCrypted() {
		return this.hotelBookingHotelCodeCrypted;
	}

	getHotelBookingHotelTotalPrice() {
		return this.hotelBookingHotelTotalPrice;
	}

	setHotelBookingHotelTotalPrice(price) {
		this.hotelBookingHotelTotalPrice = price;
	}

	getHotelBookingHotelPriceCurrency() {
		return this.hotelBookingHotelPriceCurrency;
	}

	setHotelBookingHotelPriceCurrency(currency) {
		this.hotelBookingHotelPriceCurrency = currency;
	}

	getHotelBookingHotelSelectedProvider() {
		return this.selectedProvider;
	}

	setHotelBookingHotelSelectedProvider(provider) {
		this.selectedProvider = provider;
	}

	getHotelBookingHotelSelectedComment() {
		return this.selectedComment;
	}

	setHotelBookingHotelSelectedComment(comment) {
		this.selectedComment = comment;
	}

	getHotelBookingHotelSearchParams() {
		return this.hotelBookingHotelSearchParams;
	}

	setHotelBookingHotelSearchParams(queryParams) {
		localStorage.setItem("taskParams", queryParams ? JSON.stringify(queryParams) : "")
		if (queryParams == null) {
			this.hotelBookingHotelSearchParams = null;
		} else {
			this.hotelBookingHotelSearchParams = cloneDeep(queryParams);
		}
	}

	getHotelBookingCancellationPolicyCombinedRooms() {
		return this.hotelBookingCancellationPolicyCombinedRooms;
	}

	setHotelBookingCancellationPolicyCombinedRooms(policy) {
		this.hotelBookingCancellationPolicyCombinedRooms = policy;
	}

	getHotelBookingHotelRates() {
		return this.hotelBookingHotelRates;
	}

	setHotelBookingHotelRates(keys) {
		if (keys == null) {
			this.hotelBookingHotelRates.length = 0;
		} else {
			this.hotelBookingHotelRates = cloneDeep(keys);
		}
	}

	getHotelBookingAvailabilityResults() {
		return this.hotelBookingAvailabilityResults;
	}

	setHotelBookingAvailabilityResults(results) {
		this.hotelBookingAvailabilityResults = cloneDeep(results);
	}

	getAutocompleteHotel(term) {
		if (term === "") {
			return of([]);
		}

		let correctUrl;
		this.baseService.b2bUserType == "B2B"
			? (correctUrl = HOTEL_AUTOCOMPLETE_B2B_URL)
			: (correctUrl = HOTEL_AUTOCOMPLETE_API_URL);

		const urlBuilder = new UrlBuilder(correctUrl).addParam(URL_PARAMS.TERM, term);
		return this.baseService.tokenGetRequest(urlBuilder.getUrl());
	}

	getHotelsListFromHotelParams(provider, hotelsParms, hotelCode?, hotelCodeCrypted?) {
		let hotelNights: number,
			hotelRooms: number,
			hotelDeparture: string,
			hotelDepartureID: string,
			childTotal: number,
			adultTotal: number,
			customer_nationality: string;

		const hotelCheckInDate = hotelsParms["checkIn"];
		const hotelCheckOutDate = hotelsParms["checkOut"];

		hotelDeparture = hotelsParms["hotelDeparture"];
		hotelDepartureID = hotelsParms["hotelDepartureID"];
		hotelNights = hotelsParms["nights"];
		hotelRooms = hotelsParms["rooms"];
		childTotal = hotelsParms["child_total"];
		adultTotal = hotelsParms["adult_total"];
		customer_nationality = hotelsParms["customer_nationality"];
		if (hotelsParms["code"] && hotelsParms["code"] != undefined) {
			hotelCode = hotelsParms["code"];
		}
		// set rooms in array
		const hotelRoomsToBook = new Array();

		for (let i = 0; i < hotelRooms; i++) {
			const room = new SharedRoomModel();

			hotelRoomsToBook.push(room);

			hotelRoomsToBook[i].adult_nbr = hotelsParms["adults_nbr_" + (i + 1)];
			hotelRoomsToBook[i].child_nbr = hotelsParms["child_nbr_" + (i + 1)];

			if (hotelRoomsToBook[i].child_nbr > 0) {
				for (let j = 0; j < hotelRoomsToBook[i].child_nbr; j++) {
					hotelRoomsToBook[i].child_age[j] =
						hotelsParms["child_age_" + (i + 1) + "_" + (j + 1)];
				}
			}
		}

		return this.getHotelsList(
			hotelCheckInDate,
			hotelCheckOutDate,
			hotelDeparture,
			hotelDepartureID,
			hotelNights,
			hotelRooms,
			hotelRoomsToBook,
			childTotal,
			adultTotal,
			provider,
			customer_nationality,
			hotelCode,
			hotelCodeCrypted
		);
	}

	getHotelsList(
		hotelCheckInDate,
		hotelCheckOutDate,
		hotelDeparture,
		hotelDepartureID,
		hotelNights,
		hotelRooms,
		hotelRoomsToBook,
		childTotal,
		adultTotal,
		provider,
		customer_nationality,
		hotelCode?,
		hotelCodeCrypted?
	) {
		const request = {};

		request["checkInDate"] = hotelCheckInDate;
		request["checkOutDate"] = hotelCheckOutDate;

		request["destination"] = hotelDeparture.slice(0, hotelDeparture.indexOf("-")).trim();

		request["destinationCity"] = hotelDepartureID;
		request["destinationCityValue"] = hotelDeparture;

		request["nights"] = hotelNights;
		request["room"] = hotelRooms;

		request["language"] = this.baseService.getLanguage();
		request["customer_nationality"] = customer_nationality.split("-")[1];

		request["adults"] = adultTotal;
		request["children"] = childTotal;
		request["persons"] = adultTotal + childTotal;

		for (let i = 0; i < hotelRooms; i++) {
			request["adults_" + (i + 1)] = hotelRoomsToBook[i]["adult_nbr"];
			request["children_" + (i + 1)] = hotelRoomsToBook[i]["child_nbr"];

			if (hotelRoomsToBook[i]["child_nbr"] > 0) {
				for (let j = 0; j < hotelRoomsToBook[i]["child_nbr"]; j++) {
					request["child_age_" + (i + 1) + "_" + (j + 1)] =
						hotelRoomsToBook[i].child_age[j];
				}
			}
		}
		
		request["provider"] = provider.code;
		request["provider_id"] = provider.id;

		if (hotelCode) {
			request["code"] = hotelCode;
			request["provider"] = provider;
		}

		if (hotelCodeCrypted) {
			request["hotelCodeCrypted"] = hotelCodeCrypted;
		}

		let correctUrl;
		this.baseService.b2bUserType == "B2B"
			? (correctUrl = HOTEL_AVAILABILITY_B2B_URL)
			: (correctUrl = HOTEL_AVAILABILITY_API_URL);
		return this.baseService.tokenPostRequest(correctUrl, request);
	}

	excludeHotel(request) {
		return this.baseService.tokenPostRequest(HOTEL_EXCLUDE_B2B_URL, request);
	}

	includeHotel(id) {
		return this.baseService.tokenDeleteRequest(HOTEL_INCLUDE_B2B_URL + id);
	}

	checkHotelCheckRates(request) {
		let correctUrl;
		this.baseService.b2bUserType == "B2B"
			? (correctUrl = HOTEL_CHECK_RATES_B2B_URL)
			: (correctUrl = HOTEL_CHECK_RATES_API_URL);
		return this.baseService.tokenPostRequest(correctUrl, request);
	}

	checkHotelWithRef(request) {
		return this.baseService.tokenPostRequest(HOTEL_CHECK_BOOKING_WITH_REF, request);
	}

	getHotelConfirmationRequest() {
		return this.hotelConfirmationRequest;
	}

	setHotelConfirmationRequest(request) {
		return (this.hotelConfirmationRequest = request);
	}

	saveHotelSearchParams(params) {
		this.deleteHotelSearchParams();
		this.baseService.saveData(HOTEL_SEARCH_PARAMS, params);
	}

	getHotelSearchParams() {
		return this.baseService.getData(HOTEL_SEARCH_PARAMS);
	}

	deleteHotelSearchParams() {
		this.baseService.deleteData(HOTEL_SEARCH_PARAMS);
	}

	saveSelectedHotelKey(key) {
		this.baseService.saveData(SELECTED_HOTEL_KEY, key);
	}

	getSelectedHotelKey() {
		return this.baseService.getData(SELECTED_HOTEL_KEY);
	}

	deleteSelectedHotelKey() {
		this.baseService.deleteData(SELECTED_HOTEL_KEY);
	}

	saveSelectedHotelProvider(key) {
		this.baseService.saveData(SELECTED_HOTEL_PROVIDER, key);
	}

	getSelectedHotelProvider() {
		return this.baseService.getData(SELECTED_HOTEL_PROVIDER);
	}

	deleteSelectedHotelProvider() {
		this.baseService.deleteData(SELECTED_HOTEL_PROVIDER);
	}

	getStars(name: string): number {
		switch (name) {
			case "one":
				return 1;
			case "two":
				return 2;
			case "three":
				return 3;
			case "four":
				return 4;
			case "five":
				return 5;
			default:
				return 0;
		}
	}

	getHotelOffers() {
		let correctUrl = environment.mode == "B2B" ? HOTEL_OFFERS_B2B_URL : HOTEL_OFFERS_B2C_URL;
		return this.baseService.tokenGetRequest(correctUrl);
	}

	getHotelTopDestinations() {
		let correctUrl =
			environment.mode == "B2B" ? HOTEL_TOP_DESTINATIONS_B2B_URL : HOTEL_TOP_DESTINATIONS_URL;
		return this.baseService.tokenGetRequest(correctUrl);
	}

	getRecommendedHotels() {
		return this.recommendedHotelsSubject;
	}
	setRecommendedHotels(hotels) {
		this.recommendedHotelsSubject.next(hotels);
	}

	saveRemark(data) {
		return this.baseService.tokenPostRequest(HOTEL_UPDATE_REMARK_B2B, data);
	}
}
