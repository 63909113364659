// import { CityService } from './services/city.service';
import { LocalizationService } from "./services/localization.service";
import { DestinationsService } from "./services/destinations.service";
import { PaxService } from "./services/pax.service";
import { FlightConfirmationService } from "./services/flight.confirmation.service";
import { RangePipe } from "./pipes/range.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { ModalComponent } from "./components/modal/modal.component";
import { FooterComponent } from "./components/footer/footer.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { HotelRatingComponent } from "./components/hotel-rating/hotel-rating.component";
import { VoucherComponent } from "./components/voucher/voucher.component";
import { PaxComponent } from "./components/pax/pax.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { LoginService } from "./services/login.service";
import { NavigationService } from "./services/navigation.service";
import { FlightDetailsComponent } from "./components/flight-details/flight-details.component";
import { FareDetailsComponent } from "./components/fare-details/fare-details.component";
import { PackageBookingConfirmationService } from "./services/package-booking-confirmation.service";
import { LoginComponent } from "./components/login/login.component";
import { SignupComponent } from "./components/signup/signup.component";
import { SignUpService } from "./services/signup.service";
import { FlightPassengerComponent } from "../flight-booking/components/flight-passenger/flight-passenger.component";
import { FlightPassengerInfoComponent } from "../flight-booking/components/flight-passenger-info/flight-passenger-info.component";
import { AirValidateService } from "./services/air.validate.service";
import { ErrorModalComponent } from "./components/error-modal/error-modal.component";
import { LoaderChildComponent } from "./components/loader-child/loader-child.component";
import { HotelGuestComponent } from "../hotel-booking/components/hotel-guest/hotel-guest.component";
import { HotelConfirmationService } from "./services/hotel.confirmation.service";
import { PackageTravellerComponent } from "../package-booking/components/package-traveller/package-traveller.component";
import { PackagePaxReviewBookingComponent } from "./components/package-pax-review-booking/package-pax-review-booking.component";
import { PackageDetailsComponent } from "./components/package-details/package-details.component";
import { FarePackageComponent } from "./components/fare-package/fare-package.component";
import { HotelPaxReviewBookingComponent } from "./components/hotel-pax-review-booking/hotel-pax-review-booking.component";
import { FareHotelComponent } from "./components/fare-hotel/fare-hotel.component";
import { VisaApplicationService } from "./services/visa.application.service";
import { VisaPassengerComponent } from "../visa/components/visa-passenger/visa-passenger.component";
import { VisaPassengerInformationsComponent } from "../visa/components/visa-passenger-informations/visa-passenger-informations.component";
import { NavbarMenuComponent } from "./components/navbar-menu/navbar-menu.component";
import { HotelSearchSharedComponent } from "./components/hotel-search-shared/hotel-search-shared.component";
import { SearchRoomsSharedComponent } from "./components/search-rooms-shared/search-rooms-shared.component";
import { HotelVoucherSharedComponent } from "./components/hotel-voucher-shared/hotel-voucher-shared.component";
import { FlightVoucherSharedComponent } from "./components/flight-voucher-shared/flight-voucher-shared.component";
import { FlightHotelSearchSharedComponent } from "./components/flight-hotel-search-shared/flight-hotel-search-shared.component";
import { AgencyPanelComponent } from "./components/agency-panel/agency-panel.component";
import { SharedSearchPassengersComponent } from "./components/shared-search-passengers/shared-search-passengers.component";
import { SharedCommunDropdownComponent } from "./components/shared-commun-dropdown/shared-commun-dropdown.component";
import { FlightSearchSharedComponent } from "./components/flight-search-shared/flight-search-shared.component";
import { FlightSearchFiledsComponent } from "../main/components/flight-search-fileds/flight-search-fileds.component";
import { CardPaymentComponent } from "./components/card-payment/card-payment.component";
import { FlightOffersComponent } from "./components/flight-offers/flight-offers.component";
import { HotelOffersComponent } from "./components/hotel-offers/hotel-offers.component";
import { HotelTopDestinationsComponent } from "./components/hotel-top-destinations/hotel-top-destinations.component";
import { HotelRecommendationsComponent } from "./components/hotel-recommendations/hotel-recommendations.component";
import { EPaymentService } from "./services/e_payment.service";
//import { NavBarB2bComponent } from './components/nav-bar-b2b/nav-bar-b2b.component';
import { FlightManageBookingsService } from "./services/flight-manage-bookings.service";
import { HotelManageBookingsService } from "./services/hotel-manage-bookings.service";
import { ExcelService } from "./services/CSV-export";
import { AccountHistoryService } from "./services/account-history.service";
import { AgentService } from "./services/agent.service";
import { FlightAmadeusMarkupService } from "./services/flight-amadeus-markup.service";
import { MarkupHistoryService } from "./services/markup-history.service";
import { AgencyDashboardService } from "./services/agency-dashboard.service";
import { PackageManageBookingsService } from "./services/package-manage-bookings.service";
import { EnableB2CGuard } from "./services/enable-b2c-guard.service";
import { FlightB2bReviewComponent } from "../b2b/components/flight-b2b-review/flight-b2b-review.component";
import { HotelB2bReviewComponent } from "../b2b/components/hotel-b2b-review/hotel-b2b-review.component";
import { PackageB2bReviewComponent } from "../b2b/components/package-b2b-review/package-b2b-review.component";
import { SearchFilterPipe } from "./pipes/searchFilter";
import { HowItWorksComponent } from "@app/main/components/sections/how-it-works/how-it-works.component";
import { MapSharedComponent } from "./components/map-shared/map-shared.component";
import { B2cAccountConfirmationPageComponent } from "./components/b2c-account-confirmation-page/b2c-account-confirmation-page.component";
import { ForgetPasswordComponent } from "@app/b2b/components/forget-password/forget-password.component";
import { CommunicationComponent } from "@app/main/components/communication/communication.component";
import { B2cResetPasswordPageComponent } from "./components/b2c-reset-password-page/b2c-reset-password-page.component";
import { FareVisaComponent } from "./components/fare-visa/fare-visa.component";
import { VisaPaxReviewBookingComponent } from "./components/visa-pax-review-booking/visa-pax-review-booking.component";
import { VisaB2bReviewComponent } from "@app/b2b/components/visa-b2b-review/visa-b2b-review.component";
import { CharterManageService } from "./services/charter-manage.service";
import { CharterSearchService } from "./services/charter-search.service";
import { CharterDetailsComponent } from "./components/charter-details/charter-details.component";
import { CharterPassengerComponent } from "@app/charter-booking/components/charter-passenger/charter-passenger.component";
import { CharterPassengerUpdateComponent } from "@app/charter-booking/components/charter-passenger-update/charter-passenger-update.component";
import { CharterPassengerReviewComponent } from "./components/charter-passenger-review/charter-passenger-review.component";
import { AssuranceManageComponent } from "@app/main/components/assurance-manage/assurance-manage.component";
import { ColumnDisplayComponent } from "@app/main/components/column-display/column-display.component";
import { FareCharterComponent } from "@app/charter-booking/components/fare-charter/fare-charter.component";
import { ExtraServiceComponent } from "./components/extra-service/extra-service.component";
import { TripadvisorRatingComponent } from "./components/tripadvisor-rating/tripadvisor-rating.component";
import { InvoiceComponent } from "@app/main/components/invoice/invoice.component";
import { InvoiceDetailsComponent } from "@app/main/components/invoice-details/invoice-details.component";
import { InvoiceService } from "./services/invoice.service";
import { FlightEditTicketComponent } from "@app/main/components/flight-edit-ticket/flight-edit-ticket.component";
import { PackageDetailsComponent as PackageBookingDetailsComponent } from "@app/package-booking/components/package-details/package-details.component";
import { PackageItemComponent } from "@app/package-booking/components/package-item/package-item.component";
import { FlightCalendarComponent } from "./components/flight-calendar/flight-calendar.component";
import { AnalyticsService } from './services/analytics.service';
import { VerticalAd } from "./components/ads/vertical-ad/vertical-ad.component";
import { TaskActivityComponent } from "./components/multitask/task-activity/task-activity.component";
import { HotelTaskViewComponent } from "./components/multitask/hotel-task-view/hotel-task-view.component"
import { DrawerComponent } from "./components/drawer/drawer.component";
import { MimirService } from "./services/mimir.service";

export const componentDeclarations: any[] = [
	RangePipe,
	SafePipe,
	NavbarComponent,
	// NavBarB2bComponent,
	FooterComponent,
	LoaderComponent,
	ModalComponent,
	HotelRatingComponent,
	TripadvisorRatingComponent,
	VoucherComponent,
	PaxComponent,
	PageNotFoundComponent,
	FlightDetailsComponent,
	FareDetailsComponent,
	LoginComponent,
	SignupComponent,
	FlightPassengerComponent,
	FlightPassengerInfoComponent,
	ErrorModalComponent,
	ExtraServiceComponent,
	LoaderChildComponent,
	HotelGuestComponent,
	PackageTravellerComponent,
	PackagePaxReviewBookingComponent,
	PackageDetailsComponent,
	PackageItemComponent,
	PackageBookingDetailsComponent,
	FarePackageComponent,
	HotelPaxReviewBookingComponent,
	VisaPaxReviewBookingComponent,
	FareHotelComponent,
	FareVisaComponent,
	FareCharterComponent,
	VisaPassengerComponent,
	VisaPassengerInformationsComponent,
	NavbarMenuComponent,
	HotelSearchSharedComponent,
	SearchRoomsSharedComponent,
	HotelVoucherSharedComponent,
	FlightVoucherSharedComponent,
	FlightHotelSearchSharedComponent,
	AgencyPanelComponent,
	SharedSearchPassengersComponent,
	SharedCommunDropdownComponent,
	FlightSearchSharedComponent,
	FlightSearchFiledsComponent,
	CardPaymentComponent,
	FlightOffersComponent,
	HotelOffersComponent,
	HotelTopDestinationsComponent,
	HotelRecommendationsComponent,
	FlightB2bReviewComponent,
	PackageB2bReviewComponent,
	HotelB2bReviewComponent,
	SearchFilterPipe,
	MapSharedComponent,
	HowItWorksComponent,
	B2cAccountConfirmationPageComponent,
	B2cResetPasswordPageComponent,
	ForgetPasswordComponent,
	CommunicationComponent,
	VisaB2bReviewComponent,
	// AssuranceManageComponent,
	CharterDetailsComponent,
	CharterPassengerComponent,
	CharterPassengerUpdateComponent,
	CharterPassengerReviewComponent,
	ColumnDisplayComponent,
	InvoiceComponent,
	InvoiceDetailsComponent,
	FlightEditTicketComponent,
	FlightCalendarComponent,
	VerticalAd,
	TaskActivityComponent,
	HotelTaskViewComponent,
	DrawerComponent
];

export const providerDeclarations: any[] = [
	// CityService,
	LocalizationService,
	// FlightConfirmationService,
	DestinationsService,
	PaxService,
	LoginService,
	NavigationService,
	SignUpService,
	AirValidateService,
	HotelConfirmationService,
	PackageBookingConfirmationService,
	EPaymentService,
	FlightManageBookingsService,
	HotelManageBookingsService,
	AnalyticsService,
	ExcelService,
	AccountHistoryService,
	AgentService,
	FlightAmadeusMarkupService,
	MarkupHistoryService,
	AgencyDashboardService,
	EnableB2CGuard,
	PackageManageBookingsService,
	InvoiceService,
	MimirService
	//CharterManageService,
	//CharterSearchService
];
