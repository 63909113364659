import { Component, OnInit } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { VisaApplicationService } from "@app/shared/services/visa.application.service";
import { Observable } from "rxjs";

@Component({
	selector: "app-fare-visa",
	templateUrl: "./fare-visa.component.html",
	styleUrls: ["./fare-visa.component.scss"],
})
export class FareVisaComponent implements OnInit {
	visa;

	constructor(
		private visaService: VisaApplicationService,
		private baseService: BaseService
	) {
		this.visaService._visa.subscribe((visa) => {
			this.visa = visa;
		});
	}

	ngOnInit() {}

	formatPrice(price) {
		return this.baseService.formatPrice(price);
	}

	isArabic() {
		return this.baseService.isArabic();
	}
}
