import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { NavigationService } from "@app/shared/services/navigation.service";
import { BaseService } from "@app/shared/services/base.service";
import { PackagesService } from "../../../shared/services/packages.service";
import { IMAGES_URL } from "../../../shared/services/urls_b2b";

@Component({
	selector: "app-package-details",
	templateUrl: "./package-details.component.html",
	styleUrls: ["./package-details.component.scss"],
})
export class PackageDetailsComponent implements OnInit, OnDestroy {
	@Input() showModal;
	@Input() packageObj: any;
	@Input() totalRooms: number = 0;

	selectedRooms;
	roomsToBook = new Array();
	intervalID;

	reqRoomsHeight: number = 41.5;
	requestedRooms = [];
	imagesURL = IMAGES_URL;

	constructor(
		private navigation: NavigationService,
		private packagesService: PackagesService,
		private baseService: BaseService
	) {}

	ngOnInit(): void {
		this.waitingForPackageObj();
	}

	ngOnDestroy(): void {
		if (this.intervalID) {
			clearInterval(this.intervalID);
		}
	}

	// get rooms list coz it is not a table
	private getRoomsList(rooms): any {
		this.reqRoomsHeight = this.reqRoomsHeight * rooms.length;
		return rooms;
	}

	private selectRoom(
		departureID,
		packageID,
		reqsRoomNumber,
		totalPersonsSelectedRoom,
		rateID,
		rooms,
		subPackID,
		stepIndex
	): void {
		if (
			this.selectedRooms[stepIndex][reqsRoomNumber].isRoomSelected &&
			this.selectedRooms[stepIndex][reqsRoomNumber].selectedRateID === rateID
		) {
			// to delete selected room
			this.selectedRooms[stepIndex][reqsRoomNumber].isRoomSelected = false;
			this.selectedRooms[stepIndex][reqsRoomNumber].selectedRateID = -1;

			this.roomsToBook[stepIndex][reqsRoomNumber] = null;
		} else {
			// add selected room
			this.selectedRooms[stepIndex][reqsRoomNumber].isRoomSelected = true;
			this.selectedRooms[stepIndex][reqsRoomNumber].selectedRateID = rateID;

			const room = {
				departureID: departureID,
				packageID: packageID,
				reqsRoomNumber: reqsRoomNumber,
				totalPersonsSelectedRoom: totalPersonsSelectedRoom,
				rateID: rateID,
				rooms: rooms,
				subPackID: subPackID,
				stepIndex: stepIndex,
			};

			this.roomsToBook[stepIndex][reqsRoomNumber] = room;

			// console.log('room clicked: ' + (reqsRoomNumber + 1) + '\n' +
			//   'departureID: ' + departureID + '\n' +
			//   'packageID: ' + packageID + '\n' +
			//   'reqsRoomNumber: ' + reqsRoomNumber + '\n' +
			//   'totalPersonsSelectedRoom: ' + totalPersonsSelectedRoom + '\n' +
			//   'rateID: ' + rateID + '\n' +
			//   'rooms: ' + rooms + '\n' +
			//   'subPackID: ' + subPackID + '\n');
		}
	}

	enableBookButton(): boolean {
		let enable = 0;

		for (let i = 0; i < this.roomsToBook.length; i++) {
			for (let j = 0; j < this.roomsToBook[i].length; j++) {
				if (this.roomsToBook[i][j]) {
					// if rooms exists
					enable++;
				}
			}
		}
		return enable < this.totalRooms || enable == 0;
	}

	goToTravellerPage(): void {
		this.roomsToBook.forEach((e, i) => {
			if (e[0] == null) {
				this.roomsToBook.splice(i, 1);
			}
		});

		//they all have the same data
		this.packagesService.setPackageBookingPackageRoomsToBook(this.roomsToBook);
		this.packagesService.setPackageBookingPackageDepartureID(
			this.roomsToBook[0][0].departureID
		);
		this.packagesService.setPackageBookingPackageID(this.roomsToBook[0][0].packageID);
		this.packagesService.setPackageBookingPackageRoomsNumber(this.roomsToBook[0][0].rooms);
		this.packagesService.setPackageBookingSubPackageID(this.roomsToBook[0][0].subPackID);

		this.packagesService.setPackageBookingPackageObject(this.packageObj);
		this.setTotalAdutChild();

		this.baseService.setPaxType("package");

		this.navigation.goToPackageTravellerPage();
	}

	setTotalAdutChild() {
		let adultNumber = 0;
		let childNumber = 0;

		let packageSearchParams = { ...JSON.parse(this.packagesService.getPackageSearchParams()) };

		for (let i = 0; i < packageSearchParams["room"]; i++) {
			//adults in room x
			adultNumber += packageSearchParams["adults_" + (i + 1)];
			//child in room x
			childNumber += packageSearchParams["children_" + (i + 1)];
		}

		this.packagesService.setPackageBookingAdultTotalNumber(adultNumber);
		this.packagesService.setPackageBookingChildTotalNumber(childNumber);
	}

	// initialize all selected room and rooms to book
	initializeAllRoomsSelection() {
		// create array for selected rooms
		this.selectedRooms = new Array();
		this.requestedRooms = new Array();

		for (let i = 0; i < this.packageObj.steps.length; i++) {
			const step = this.packageObj.steps[i];

			if (step.type == "HOTEL") {
				let stepInfos = {};
				stepInfos["rooms"] = step.hotel.rooms;
				stepInfos["stepIndex"] = i;

				this.requestedRooms.push(stepInfos);
				this.selectedRooms[i] = new Array();

				for (let j = 0; j < step.hotel.rooms.length; j++) {
					const stateRoom = {
						isRoomSelected: false,
						selectedRateID: -1,
						stepIndex: i + 1,
					};

					this.selectedRooms[i].push(stateRoom);
				}

				// create array for booking rooms
				this.roomsToBook[i] = new Array(this.selectedRooms[i].length);
			} else {
				this.roomsToBook[i] = [null];
			}
		}
	}

	waitingForPackageObj(): void {
		this.intervalID = setInterval(() => {
			if (this.packageObj) {
				this.initializeAllRoomsSelection();
				clearInterval(this.intervalID);
				this.intervalID = null;
			}
		}, 3000);
	}

	// show details modal
	showModalPackageDetails() {
		if (this.packageObj) {
			this.initializeAllRoomsSelection();
		}

		this.showModal = true;
	}

	hideModalPackageDetails() {
		this.showModal = false;
	}

	formatPrice(price: number) {
		return this.baseService.formatPrice(price);
	}

	isArabic() {
		return this.baseService.isArabic();
	}

	isEnglish() {
		return this.baseService.isEnglish();
	}
}
