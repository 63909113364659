import { Component, OnInit, Input } from "@angular/core";
import { BaseService } from "../../services/base.service";
import { FlightService } from "../../services/flight.service";

@Component({
	selector: "app-fare-details",
	templateUrl: "./fare-details.component.html",
	styleUrls: ["./fare-details.component.scss"],
})
export class FareDetailsComponent implements OnInit {
	@Input() entry = {};
	@Input() extras = [];
	serviceFeePerPax = 0;
	isB2BUser = false;

	constructor(
		private baseService: BaseService,
		private flightService: FlightService
	) {
		this.isB2BUser = this.baseService.b2bUserType == "B2B";
	}

	ngOnInit() {
		this.serviceFeePerPax =
			this.entry["price"]["detailed_price"]["agency_fees_per_pax"] +
			this.entry["price"]["detailed_price"]["platform_fees_per_pax"]; //+
		//this.entry["price"]["detailed_price"]["provider_fees_per_pax"];
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}

	formatFlightPrice(price: number): string {
		return this.baseService.formatPrice(price);
	}

	getExtraServicesTotal() {
		let sum = 0;
		for (let traveller of this.linearise(this.extras)) {
			for (let extra of traveller) {
				for (let option of extra.options) {
					if (option.amount) {
						sum = sum + option.amount;
					}
				}
			}
		}
		return sum;
	}

	linearise(dict) {
		let result = [];
		for (let key in dict) {
			result.push(dict[key]);
		}
		//console.log(result);
		return result;
	}
}
