import { Injectable } from "@angular/core";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { BaseService } from "./base.service";

const I18N_VALUES = {
	es: {
		weekdays: ["L", "M", "M", "J", "V", "S", "D"],
		months: [
			"Ene",
			"Feb",
			"Mar",
			"Abr",
			"May",
			"Jun",
			"Jul",
			"Ago",
			"Sep",
			"Oct",
			"Nov",
			"Dic",
		],
	},
	en: {
		weekdays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
		months: [
			"Jan",
			"Feb",
			"Mar",
			"Avr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		],
	},
	fr: {
		weekdays: ["Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"],
		months: [
			"Jan",
			"Fev",
			"Mar",
			"Avr",
			"Mai",
			"Jun",
			"Jul",
			"Aou",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		],
	},
	ar: {
		weekdays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
		months: [
			"Jan",
			"Feb",
			"Mar",
			"Avr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		],
	},
	// other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
	language = "es";
}

@Injectable()
export class CustomDatepickerI18nService extends NgbDatepickerI18n {
	constructor(
		private _i18n: I18n,
		private baseService: BaseService
	) {
		super();
		this._i18n.language = this.baseService.getLanguage();
	}

	getWeekdayShortName(weekday: number): string {
		return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
	}
	getMonthShortName(month: number): string {
		return I18N_VALUES[this._i18n.language].months[month - 1];
	}
	getMonthFullName(month: number): string {
		return this.getMonthShortName(month);
	}

	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
}
