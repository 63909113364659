import { SharedRoomModel } from "../../../shared/models/sharedRoomModel";

export class HotelGuestRoom {
	guestInfos;
	guestRoom: SharedRoomModel;

	constructor() {
		this.guestInfos = new Array();
		this.guestRoom = new SharedRoomModel();
	}
}
