export class FlightSearchFiledsModel {
	flightDepartureSpinner: boolean;
	flightArrivalSpinner: boolean;

	departureItem;
	returnItem;

	flightDepartureName;
	flightArrivalName;

	modelDeparture;
	modelReturn;

	cabinClass_out_bound: string;
	cabinClassName_out_bound: string;

	cabinClass_in_bound: string;
	cabinClassName_in_bound: string;

	constructor() {
		this.flightDepartureSpinner = false;
		this.flightArrivalSpinner = false;

		this.departureItem = null;
		this.returnItem = null;

		this.flightDepartureName = null;
		this.flightArrivalName = null;

		this.modelDeparture = null;
		this.modelReturn = null;
		this.cabinClass_out_bound = "M";
		this.cabinClassName_out_bound = "ECONOMY_STANDARD";

		this.cabinClass_in_bound = "M";
		this.cabinClassName_in_bound = "ECONOMY_STANDARD";
	}
}
