import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { DateUtils } from "@app/shared/services/utils";
import { interval } from "rxjs";
import { BaseService } from "@app/shared/services/base.service";
import { HotelService } from "@app/shared/services/hotel.service";

export interface remainData {
	days: Number;
	hours: Number;
	minutes: Number;
	seconds: Number;
}
@Component({
	selector: "app-column-display",
	templateUrl: "./column-display.component.html",
	styleUrls: ["./column-display.component.scss"],
})
export class ColumnDisplayComponent implements OnInit, OnDestroy {
	@Input() image = "assets/images/offer1.jpg";
	@Input() title = "Title";
	@Input() subTitle = "Sub Title";
	@Input() description = "";
	@Input() date = "";
	@Input() stars = "";
	@Input() freeDescription = false;
	@Input() price = null;
	@Input() currency = "";
	@Input() promotion = 0;
	@Input() previousPrice = 0;
	@Input() buttonText = "Button";
	@Input() counter = "";
	@Input() icons = [];
	@Input() places = { initial: 0, available: 0 };
	@Input() buttonDisabled: boolean = false;
	@Input() hideBook: boolean = false;
	// [
	//   {
	//     icon: 'fa-comments',
	//     text: '20',
	//     style: ''
	//   },
	//   {
	//     icon:'fa-share-alt',
	//     text: '20',
	//     style: 'font-size: 13px;'
	//   }
	// ];
	@Output() buttonClick = new EventEmitter();

	counterDate;
	remains;
	showRemains = false;
	remainsMessage;
	source;
	stars_array = [];

	constructor(
		private baseService: BaseService,
		private hotelService: HotelService
	) {}

	ngOnInit() {
		if (this.counter != "") {
			this.source = interval(1000);
			this.remainsMessage = this.source.subscribe((val) => this.getRemains());
		}
		let num = this.hotelService.getStars(this.stars);
		for (let i = 0; i < num; ++i) {
			this.stars_array.push(0);
		}
	}
	formatPrice(price: number): string {
		return this.baseService.formatPrice(price);
	}

	calculateOldPrice(price) {
		return Math.trunc(price * (1 + Number(this.promotion) / 100));
	}

	ngOnDestroy() {
		clearInterval(this.source);
	}

	getRemains() {
		if (this.counter != "") {
			let now = new Date();
			this.counterDate = new Date(this.counter);
			if (this.counterDate.valueOf() > now.valueOf()) {
				this.remains = this.deltaDate(this.counterDate);
				this.showRemains = true;
			}
		}
	}

	deltaDate(dateFuture): remainData {
		let dateNow = new Date();

		var delta = Math.abs(dateFuture.valueOf() - dateNow.valueOf()) / 1000;
		var days = Math.floor(delta / 86400);
		delta -= days * 86400;

		var hours = Math.floor(delta / 3600) % 24;
		delta -= hours * 3600;

		var minutes = Math.floor(delta / 60) % 60;
		delta -= minutes * 60;

		var seconds = Math.trunc(delta % 60);

		return {
			days: days,
			hours: hours,
			minutes: minutes,
			seconds: seconds,
		};
	}
}
