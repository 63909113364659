import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { FlightService } from "../../services/flight.service";
import { NavigationService } from "../../services/navigation.service";
import { BaseService } from "../../services/base.service";
import { HotelService } from "../../services/hotel.service";
import { PackagesService } from "../../services/packages.service";
import { ShareVars } from "../../services/share.vars";
import { FlightConfirmationService } from "../../services/flight.confirmation.service";
import { PackageBookingConfirmationService } from "../../services/package-booking-confirmation.service";
import { HotelConfirmationService } from "../../services/hotel.confirmation.service";
import {
	CONFIRMATION_BOOKING_WAITING_MESSAGE,
	ERROR_CONFIRMATION_BOOKING_MESSAGE,
	CANCELED_CONFIRMATION_BOOKING_MESSAGE,
	SOLD_NOT_ENOUGH_CONFIRMATION_BOOKING_MESSAGE,
	PRICE_DISCREPANCY_BOOKING_MESSAGE,
	BOOKING_CONFIRMED_WITH_ERRORS,
	PAYMENT_NOT_AVAILABLE_B2C,
	HOTEL_BOOKING_REDIRECT_MESSAGE,
	DELEVERY_BOOKING_MESSAGE,
	ERROR_ACTION_FUNDS_TITLE
} from "../../services/display.messages";
import { ActivatedRoute } from "@angular/router";
import { VisaApplicationService } from "@app/shared/services/visa.application.service";
import {
	FLIGHT_PAX,
	PACKAGE_PAX,
	HOTEL_PAX,
	VISA_PAX,
	CHARTER_PAX,
} from "../../services/routes.pages";
import { CharterSearchService } from "@app/shared/services/charter-search.service";
import { CharterManageService } from "@app/shared/services/charter-manage.service";
import { environment } from "environments/environment";
import { LoginService } from "@app/shared/services/login.service";
import { MimirService } from "@app/shared/services/mimir.service";

const FLIGHT = "flight";
const PACKAGE = "package";
const HOTEL = "hotel";
const VISA = "visa";
const CHARTER = "charter";

@Component({
	selector: "app-pax",
	templateUrl: "./pax.component.html",
	styleUrls: ["./pax.component.scss"],
})
export class PaxComponent implements OnInit, OnDestroy {
	user = null;
	errorMessage: string = "";
	loaderChildMessage: string = "";
	provider: any;

	env = environment;

	/* flight data */
	flightObject = {};
	showFlightModal: boolean = false;
	showFlightAirValidateErrorModal: boolean = false;
	flightModalElement;
	savedExtrasServices = [];

	@Input() travelers = {};
	canValidate = false;
	captchaValidate = false;
	/* end flight data */

	activeTab = "fare";
	chekingLoaderData: boolean = false;

	paxType: string = null;

	/* package data */
	packageObject = null;
	/* end package data */

	/* hotel data */
	hotelItem = null;
	/* end hotel data */

	/* charter data */
	charterItem = null;
	/* end charter data */

	shareVarsRef;

	CharterSuccessModal = false;

	initPaxView: boolean = false;
	totalPrice;
	priceCurrency;
	hideEPayment: boolean = false;
	hideAgencyPayment: boolean = true;

	isB2BUser: boolean = false;
	bookConfirmationModal: boolean = false;
	isZoneB: boolean = false;
	showBaggageWarning: boolean = false;
	selectedPaymentMethod = 0;
	paymentMethodsInfos = [
		{
			images: [
				"/assets/algerie-booking/123fly/EDAHABIA.png",
				"/assets/algerie-booking/123fly/cib.png",
			],
			text: [
				"Cliquez sur le bouton « Payer » pour continuer, vous allez être redirigé vers la page de paieent d'Algérie poste, une fois les informations de paiement saisies, vous devez utiliser le mot de passe à usage unique reçu par SMS au numéro de téléphone lié à la carte.",
				"Une fois l'opération de paiement terminée, vous serez redirigé encore une fois usr notre site, et vous recevrez une confirmation de réservation et votre billet par email.",
				"Le plafond de paiement de la carte Edahabia est fixé à 200 000 DA par jour, avant de procéder au paiement, veuillez vous assurer que le prix de votre billet est en dessous de ce montant.",
				"Avant de procéder au paiement de votre billet d’avion, assurez-vous que le plafond de paiement journalier de votre carte CIB vous le permet.",
				"Pour toute aide our support, prière de nous contacter au numéro affiché à droite",
			],
			imgClass: false,
		},
		{
			images: ["/assets/algerie-booking/123fly/delivery.jpeg"],
			text: [
				"Afin de confirmer votre billet d'avion, le paiement et la livraison de ce billet doivent s’effectuer avant minuit.",
				"Pour faire livrer votre billet d’avion dans les plus brefs délais, veuillez sélectionner votre ville et inscrire l’adresse de livraison. Nous vous contacterons dans quelques instants pour convenir de cette livraison.  (Nous vous prions de bien vouloir préparer le montant à payer avant l'arrivée de notre livreur).",
				"Le service de livraison est disponible dans les wilayas suivantes : Alger, Blida et Constantine.",
			],
			imgClass: true,
		},
	];
	showFlightBookingDeliveryModal = false;
	showFlightBookingDelivery = false;
	
	extrasServicesPayload = [];
	allow_epayment = false;
	isOnDemand = false;

	showHotelBookingRequestModal = false;
	showHotelBookingRequest = false;
	bookingHotelResultB2C = null;
	showModalNewPrice = false;
	newPriceModalData = null;
	//TODO: use country and localization.service to add cities and countries

	fundsWarningModal = false
	isRefreshBalance = false

	mimirData: Partial<MimirEvent> = {}

	constructor(
		private flightService: FlightService,
		private navigationService: NavigationService,
		public baseService: BaseService,
		private hotelService: HotelService,
		private packagesService: PackagesService,
		private loginService: LoginService,
		private hotelConfirmationService: HotelConfirmationService,
		private visaService: VisaApplicationService,
		private flightConfirmationService: FlightConfirmationService,
		private packageBookingConfirmationService: PackageBookingConfirmationService,
		private charterService: CharterSearchService,
		private charterManageBookingsService: CharterManageService,
		private activatedRoute: ActivatedRoute,
		private mimirService: MimirService
	) {
		this.baseService.b2bUserType == "B2B" ? (this.isB2BUser = true) : (this.isB2BUser = false);
		if ((this.env.assets === "volz") || (this.env.assets === "123fly")) {
			this.activeTab = "pax";
		}		
	}

	ngOnInit() {
		this.shareVarsRef = ShareVars;
		this.scrollToTop();
		this.checkPaxType();
		this.mimirData.event = "CHECKRATE"
		this.setMimirData()
		this.mimirService.log(this.mimirData)
		this.allow_epayment = this.baseService.allow_epayment;
		if (!this.baseService.hasEnoughBalance(this.totalPrice) && this.paxType == HOTEL) {
			this.toggleFundsWarningModal()
		}
	}

	toggleFundsWarningModal() {
		this.fundsWarningModal = !this.fundsWarningModal
	}

	resolved(captchaResponse: string) {
		//console.log(captchaResponse);
		if (captchaResponse) {
			this.captchaValidate = true;
		}
	}

	private setMimirData() {
		switch (this.paxType) {
			case HOTEL:
				this.mimirData.type = "HOTEL"
				this.mimirData.param1 = this.hotelService.getHotelSearchParams()
				this.mimirData.param2 = JSON.stringify(this.hotelService.getHotelBookingHotelRates())
				break;
			case FLIGHT:
				this.mimirData.type = "FLIGHT"
				this.mimirData.param1 = JSON.stringify(this.flightService.flightVars)
				this.mimirData.param2 = JSON.stringify(this.flightService.recommendation)
			default:
				break;
		}
	}

	private checkPaxType() {
		if (this.baseService.isEmptyPax()) {
			this.initPaxView = false;

			this.activatedRoute.url.subscribe((data) => {
				const tempRoute = "/" + data[0]["path"];

				switch (tempRoute) {
					case FLIGHT_PAX:
						this.baseService.setPaxType(FLIGHT);
						this.paxType = FLIGHT;
						this.initFlightPax();
						return;

					case PACKAGE_PAX:
						this.baseService.setPaxType(PACKAGE);
						this.initPackagePax();
						this.paxType = PACKAGE;
						return;

					case HOTEL_PAX:
						this.baseService.setPaxType(HOTEL);
						this.initHotelPax();
						this.paxType = HOTEL;
						if (environment.mode == "B2C") {
							this.selectedPaymentMethod = 1;
						}
						return;

					case VISA_PAX:
						this.baseService.setPaxType(VISA);
						this.initVisaPax();
						this.paxType = VISA;
						return;

					case CHARTER_PAX:
						this.baseService.setPaxType(CHARTER);
						this.initCharterPax();
						this.paxType = CHARTER;
						return;

					default:
						this.navigationService.goToHomePage();
						return;
				}
			});
		}

		if (this.baseService.isFlightPax()) {
			this.paxType = FLIGHT;
			this.activeTab = "pax";
			this.initFlightPax();
			return;
		}

		if (this.baseService.isHotelPax()) {
			if (environment.mode == "B2C") {
				this.showPaymentSection("booking_reservation");
			}
			this.paxType = HOTEL;
			this.initHotelPax();
			return;
		}

		if (this.baseService.isPackagePax()) {
			this.paxType = PACKAGE;
			this.initPackagePax();
			return;
		}

		if (this.baseService.isVisaPax()) {
			this.paxType = VISA;
			this.initVisaPax();
			return;
		}

		if (this.baseService.isCharterPax()) {
			this.paxType = CHARTER;
			this.initCharterPax();
			return;
		}
	}

	saveExtraServices(e) {
		this.savedExtrasServices = e;
		this.flightService.extraServicesPayload = this.savedExtrasServices;
	}

	private initFlightPax() {
		if (
			this.flightService.getRecommendation() == null ||
			this.flightService.getDeparture() == null ||
			this.flightService.getAirportDictionnary() == null ||
			this.flightService.getAirlineDictionary() == null ||
			this.flightService.flightVars.nrOfAdult == null ||
			this.flightService.flightVars.nrOfChild == null ||
			this.flightService.flightVars.nrOfInfant == null
		) {
			this.initPaxView = false;
			this.navigationService.goToFlightAvailabilityPage();
		} else {
			if (
				this.flightService.flightVars.tripType == "rt" &&
				this.flightService.getArrival() == null
			) {
				this.initPaxView = false;
				this.navigationService.goToFlightAvailabilityPage();
			} else {
				this.initPaxView = true;

				this.flightObject["recommendation"] = this.flightService.getRecommendation();
				this.provider = this.flightObject["recommendation"]["provider"];

				if (this.flightService.flightVars.tripType == "rt") {
					this.flightObject["arrival"] = this.flightService.getArrival();
				}

				this.flightObject["departure"] = this.flightService.getDeparture();
				this.flightObject["airportDictionary"] = this.flightService.getAirportDictionnary();
				this.flightObject["airlineDictionary"] = this.flightService.getAirlineDictionary();
				this.flightObject["needExchangeDocuments"] =
					this.flightService.getFlightNeedExchangeDocuments(this.provider["id"]);

				this.isZoneB = this.flightService.isZoneB(this.provider);

				this.totalPrice =
					this.flightObject["recommendation"]["price"]["detailed_price"]["total"];
				this.priceCurrency =
					this.flightObject["recommendation"]["price"]["detailed_price"]["currency"];
				if (
					this.provider.code !== "TRAVEL_FUSION" &&
					(!this.flightObject["recommendation"]["baggage"] ||
						!this.flightObject["recommendation"]["baggage"]["withBaggage"] ||
						this.flightObject["recommendation"]["baggage"]["withBaggage"] == false)
				) {
					this.showBaggageWarning = true;
				}

				if (this.provider.code == "TRAVEL_FUSION" && !this.extraservicesHasLuggageInfos()) {
					//console.log(this.extraservicesHasLuggageInfos());
					this.showBaggageWarning = true;
				}
			}
		}
	}

	private extraservicesHasLuggageInfos() {
		let extraServices = this.flightService.extraServices;
		let hasLuggage = false;
		extraServices.PER_PAX.forEach((extra) => {
			if (extra.Name.toLowerCase().includes("ancillary")) {
				hasLuggage = true;
			}
			if (extra.Name.toLowerCase().includes("luggage")) {
				hasLuggage = true;
			}
		});
		return hasLuggage;
	}

	private initPackagePax() {
		if (
			this.packagesService.getPackageBookingPackageRoomsToBook() == null ||
			this.packagesService.getPackageBookingPackageRoomsNumber() == null ||
			!this.packagesService.getPackageSearchParams() ||
			this.packagesService.getPackageBookingPackageDepartureID() == null ||
			this.packagesService.getPackageBookingPackageID() == null ||
			this.packagesService.getPackageBookingSubPackageID() == null ||
			this.packagesService.getPackageBookingPackageObject() == null
		) {
			this.initPaxView = false;
			this.navigationService.goToPackageAvailabilityPage();
		} else {
			this.initPaxView = true;
			this.packageObject = { ...this.packagesService.getPackageBookingPackageObject() };

			this.totalPrice = this.packageObject["price"]["total"];
			this.priceCurrency = this.packageObject["price"]["currency"];
		}
	}

	private initHotelPax() {
		if (
			this.hotelService.getHotelBookingHotelCode() == null ||
			this.hotelService.getHotelBookingHotelRates() == null ||
			this.hotelService.getHotelBookingHotelSearchParams() == null ||
			this.hotelService.getHotelBookingHotelItem() == null
		) {
			this.initPaxView = false;
			this.navigationService.goToHotelAvailabilityPage();
		} else {
			this.initPaxView = true;
			this.hotelItem = this.hotelService.getHotelBookingHotelItem();
			this.totalPrice = this.hotelService.getHotelBookingHotelTotalPrice();
			this.priceCurrency = this.hotelService.getHotelBookingHotelPriceCurrency();
			this.hotelService.getHotelBookingHotelRates().forEach((rate) => {
				if (rate.rateObject.ondemand == 1) {
					this.isOnDemand = true;
				}
			});
		}
	}

	private initVisaPax() {
		console.log(this.visaService.getVisaApplicationParams);
		this.initPaxView = true;
	}

	private initCharterPax() {
		this.charterItem = this.charterService.charterItem;
		this.initPaxView = true;
	}

	private openFlightModal($event, flightPaxDetailsModal) {
		this.flightModalElement = $event;
		this.showFlightModal = true;
		flightPaxDetailsModal.showModal = true;
	}

	private timeDifference(fd1, time1, fd2, time2) {
		fd1 = fd1.split("-");
		fd2 = fd2.split("-");
		time1 = time1.split(":");
		time2 = time2.split(":");

		fd1 = fd1.map((el) => Number(el));
		fd2 = fd2.map((el) => Number(el));

		let diff: any;
		const date1 = new Date(fd1[2], fd1[1] - 1, fd1[0], time1[0], time1[1]).getTime();
		const date2 = new Date(fd2[2], fd2[1] - 1, fd2[0], time2[0], time2[1]).getTime();
		diff = date2 - date1;
		let msec = Math.abs(diff);
		const hh = Math.floor(msec / 1000 / 60 / 60);
		msec -= hh * 1000 * 60 * 60;
		const mm = Math.floor(msec / 1000 / 60);
		msec -= mm * 1000 * 60;
		const ss = Math.floor(msec / 1000);
		msec -= ss * 1000;
		let newTime = {};
		newTime["hours"] = hh;
		newTime["minutes"] = mm;
		return newTime;
	}

	isUserAGuest() {
		return this.user && this.user.first_name === "Guest";
	}

	private nextPage(page) {
		this.activeTab = page;
	}

	tabClickAction(tabName: string) {
		switch (tabName) {
			case "bill":
				if (this.shareVarsRef.enableBillingInfoTab) {
					this.activeTab = tabName;
					this.hideEPayment = false;
					this.hideAgencyPayment = true;
				}
				if (environment.mode == "B2C" && !this.allow_epayment) {
					this.showPaymentSection("agencyPayment");
				}
				break;

			case "pax":
				this.activeTab = tabName;
				this.shareVarsRef.enableBillingInfoTab = false;
				this.hideEPayment = false;
				this.hideAgencyPayment = false;
				break;

			case "fare":
				this.activeTab = tabName;
				this.shareVarsRef.enableBillingInfoTab = false;
				this.hideEPayment = false;
				this.hideAgencyPayment = false;
				break;
		}
	}

	private deleteHoteleData() {
		this.hotelService.removeHotelPaxData();
	}

	private deleteFlightData() {
		this.flightService.removeFlightPaxData();
	}

	private deletePackageData() {
		this.packagesService.removeHotelPaxData();
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}

	ngOnDestroy() {
		this.shareVarsRef.enableBillingInfoTab = false;

		switch (this.paxType) {
			case FLIGHT:
				this.deleteFlightData();
				break;

			case HOTEL:
				this.deleteHoteleData();
				break;

			case PACKAGE:
				this.deletePackageData();
				break;
		}
	}

	showPaymentSection(paymentType) {
		switch (paymentType) {
			case "agencyPayment":
				this.hideEPayment = true;
				this.hideAgencyPayment = false;
				this.selectedPaymentMethod = 1;
				break;

			case "ePayment":
				this.hideEPayment = false;
				this.hideAgencyPayment = true;
				this.selectedPaymentMethod = 0;
				break;

			case "booking_reservation":
				this.hideEPayment = true;
				this.hideAgencyPayment = true;
				this.selectedPaymentMethod = 2;
		}
	}

	showPaymentB2B(i) {
		this.selectedPaymentMethod = i;
	}

	scrollToTop() {
		let elm: HTMLElement = document.getElementById("nav_pax_tabs") as HTMLElement;
		setTimeout(() => {
			elm.scrollIntoView({ behavior: "smooth" });
		}, 500);
	}

	disableBookButton(): boolean {
		//only tests agency payment, update it when epayment is working
		if (!this.baseService.hasEnoughBalance(this.totalPrice)) return true
		if (this.selectedPaymentMethod == 2) {
			return false;
		}
		if (!this.hideAgencyPayment && !this.hideEPayment) {
			return true;
		} else {
			return this.getBookingRequest()["point_of_sale_id"] == null || this.hideAgencyPayment;
		}
	}

	confirmBooking() {
		this.closeBookConfirmationModal();

		switch (this.paxType) {
			case FLIGHT:
				this.confirmFlightBooking();
				break;

			case HOTEL:
				this.confirmHotelBooking();
				break;

			case PACKAGE:
				this.confirmPackageBooking();
				break;

			case CHARTER:
				this.confirmCharterBooking();
				break;
		}
	}

	confirmCharterBooking() {
		let request = this.charterService.getBookingRequest();

		this.charterService.confirmCharterBooking(request).subscribe(
			(data) => {
				console.log(data);
				if (data["success"]) {
					this.CharterSuccessModal = true;
					//console.log(this.charterManageBookingsService.currentBooking);
					this.charterManageBookingsService.currentBooking = data.booking;
					console.log(this.charterManageBookingsService.currentBooking);
					//console.log(this.charterManageBookingsService.currentBooking);
					this.navigationService.goToEticketCharterPage();
				}
			},
			(error) => {
				console.error(error);
			}
		);
	}

	closeCharterConfirmation() {
		this.navigationService.goToHomePage();
	}

	confirmFlightBooking(fop?) {
		ShareVars.showChildLoader = true;
		ShareVars.childLoaderMessage = CONFIRMATION_BOOKING_WAITING_MESSAGE;

		let confirmationData = {
			booking: null,
		};

		let request = this.getBookingRequest();

		if (fop) {
			request["fop"] = "CIB";
			request["language"] = this.baseService.getLanguage();
			delete request["point_of_sale_id"];
		}

		this.mimirData.event = "CONFIRMATION"
		this.flightConfirmationService.confirmFlightBooking(request).subscribe((data) => {
			ShareVars.showChildLoader = false;
			ShareVars.childLoaderMessage = null;
			this.checkFlightConfirmationData(data, confirmationData, request);
		});
	}

	checkFlightConfirmationData(results, confirmationData, request?) {
		if (results["success"] && results["redirect"]) {
			window.location.href = results["redirect_to"];
			return;
		}
		if (results["result"]["IBE_Errors"]) {
			if (results["result"]["IBE_Errors"].includes("PRICE_DISCREPANCY")) {
				
				if (!results.result.price && results.price){
					results.result.price = results.price;

				}
				this.showModalNewPrice = true;
				this.newPriceModalData = {
					result: results,
					request: request,
				};
				
				return;
			}
			if (environment.mode == "B2C") {
				if (results["result"]["IBE_Errors"].includes("PAYMENT_ERROR")) {
					ShareVars.showError = true;
					ShareVars.errorMessage = PAYMENT_NOT_AVAILABLE_B2C;
					this.navigationService.goToFlightAvailabilityPage();
				}
			}
			this.mimirData.param1 = "FAILED"
			this.mimirData.param2 = JSON.stringify(results["result"])
			this.mimirService.log(this.mimirData)
			return;
		}
		if (results["result"] && results["result"]["status"] == "CANCELED") {
			ShareVars.showError = true;
			ShareVars.errorMessage = CANCELED_CONFIRMATION_BOOKING_MESSAGE;
			this.mimirData.param1 = "CANCELED"
			this.mimirData.param2 = JSON.stringify(results["result"])
			this.mimirService.log(this.mimirData, this.navigationService.goToFlightAvailabilityPage)
			return;
		}
		confirmationData["booking"] = results["result"];

		if (this.isB2BUser) {
			ShareVars.b2bLocalDepositBalance =
				confirmationData["booking"]["ticket"]["agency"]["local_deposit_balance"];
			ShareVars.b2bForeignDepositBalance =
				confirmationData["booking"]["ticket"]["agency"]["foreign_deposit_balance"];
			this.mimirData.param1 = "SUCCESS"
			this.mimirData.param2 = JSON.stringify(results["result"])
			this.mimirService.log(this.mimirData, () => {
				this.flightConfirmationService.confirmationModalElement = confirmationData
				this.navigationService.goToFlightConfirmationPage()
			})
		}
	}

	confirmFlightBookingNewPrice() {
		this.closeNewPriceModal();
		ShareVars.showChildLoader = true;
		ShareVars.childLoaderMessage = CONFIRMATION_BOOKING_WAITING_MESSAGE;
		let confirmationData = {
			booking: null,
		};

		this.newPriceModalData.request["ignore_price_discrepancy"] = "true";
		

		this.flightConfirmationService
			.confirmFlightBooking(this.newPriceModalData.request)
			.subscribe((data) => {
				ShareVars.showChildLoader = false;
				ShareVars.childLoaderMessage = null;
				this.checkFlightConfirmationData(data, confirmationData);
			});
	}

	confirmHotelBooking(fop?) {
		ShareVars.showChildLoader = true;
		ShareVars.childLoaderMessage = CONFIRMATION_BOOKING_WAITING_MESSAGE;

		let confirmationRequest = this.getBookingRequest();

		if (fop && !this.isB2BUser) {
			confirmationRequest["fop"] = "CIB";
			confirmationRequest["language"] = this.baseService.getLanguage();
			delete confirmationRequest["point_of_sale_id"];
		}

		if (this.hotelService.getHotelBookingHotelCodeCrypted()) {
			confirmationRequest["hotelCodeCrypted"] =
				this.hotelService.getHotelBookingHotelCodeCrypted();
		}

		this.mimirData.type = "HOTEL"
		this.hotelConfirmationService.confirmHotelBooking(confirmationRequest).subscribe(
			(data) => {
				if (environment.mode == "B2C") {
					this.showHotelBookingRequestModal = true;
					ShareVars.showChildLoader = false;
					if (data.success && data["result"] && !data["result"]["IBE_Errors"]) {
						this.showHotelBookingRequest = true;
					}
					this.bookingHotelResultB2C = data.result;
					return;
				}

				if (data["success"] && data["redirect"]) {
					this.mimirData.event = "CONFIRMATION";
					this.mimirData.param1 = "SUCCESS"
					this.mimirData.param2 = JSON.stringify(data["result"]["booking"]);
					this.mimirService.log(this.mimirData, () => {
						window.location.href = data["redirect_to"]
					});
				} else {
					if (
						!data["result"]["booking"] ||
						data["result"]["booking"]["status"] == "CANCELED"
					) {
						ShareVars.showChildLoader = false;
						ShareVars.childLoaderMessage = null;
						ShareVars.showError = true;
						this.mimirData.event = "CONFIRMATION";
						this.mimirData.param1 = "CANCELED"
						this.mimirData.param2 = JSON.stringify(data["result"]["booking"]);
						this.mimirService.log(this.mimirData);
						if (data["result"]["IBE_Errors"]) {
							if (data["result"]["IBE_Errors"].indexOf("PRICE_DISCREPANCY") != -1) {
								this.navigationService.goToHotelAvailabilityPage();
								ShareVars.errorMessage = PRICE_DISCREPANCY_BOOKING_MESSAGE;
							} else if (
								data["result"]["IBE_Errors"].indexOf("BOOKNG_NOT_CONFIRMED") !=
									-1 ||
								data["result"]["booking"]["status"] == "CANCELED"
							) {
								ShareVars.errorMessage = ERROR_CONFIRMATION_BOOKING_MESSAGE;
								this.hotelConfirmationService.setHotelConfirmationModalElement(
									data
								);
								if (this.isB2BUser && data["result"]["agency"]) {
									ShareVars.b2bLocalDepositBalance =
										data["result"]["agency"]["local_deposit_balance"];
									ShareVars.b2bForeignDepositBalance =
										data["result"]["agency"]["foreign_deposit_balance"];
								}
								this.navigationService.goToHotelAvailabilityPage();
							}
						} else {
							ShareVars.errorMessage = CANCELED_CONFIRMATION_BOOKING_MESSAGE;
							this.hotelConfirmationService.setHotelConfirmationModalElement(data);
							if (this.isB2BUser) {
								ShareVars.b2bLocalDepositBalance =
									data["result"]["agency"]["local_deposit_balance"];
								ShareVars.b2bForeignDepositBalance =
									data["result"]["agency"]["foreign_deposit_balance"];
							}
							this.navigationService.goToHotelAvailabilityPage();
						}
					} else {
						ShareVars.showChildLoader = false;
						ShareVars.childLoaderMessage = null;

						if (
							data["result"]["IBE_Errors"] &&
							data["result"]["IBE_Errors"].length > 0
						) {
							ShareVars.showError = true;
							ShareVars.errorMessage = BOOKING_CONFIRMED_WITH_ERRORS;
							this.mimirData.event = "CONFIRMATION";
							this.mimirData.param1 = "FAILED"
							this.mimirData.param2 = JSON.stringify(data["result"]["booking"]);
						} //else{

						this.hotelConfirmationService.setHotelConfirmationModalElement(data);
						if (this.isB2BUser) {
							ShareVars.b2bLocalDepositBalance =
								data["result"]["agency"]["local_deposit_balance"];
							ShareVars.b2bForeignDepositBalance =
								data["result"]["agency"]["foreign_deposit_balance"];
						}
						this.navigationService.goToHotelConfirmationPage();

						//}
					}
				}
			},
			(error) => {
				console.error(error);
				ShareVars.showChildLoader = false;
				ShareVars.childLoaderMessage = null;
				this.navigationService.goToHotelAvailabilityPage();
				ShareVars.showError = true;
				ShareVars.errorTitle = "";
				ShareVars.errorMessage = HOTEL_BOOKING_REDIRECT_MESSAGE;
			}
		);
	}

	confirmPackageBooking(fop?) {
		ShareVars.showChildLoader = true;
		ShareVars.childLoaderMessage = CONFIRMATION_BOOKING_WAITING_MESSAGE;

		let request = this.getBookingRequest();
		if (fop && !this.isB2BUser) {
			request["fop"] = "CIB";
			request["language"] = this.baseService.getLanguage();
			delete request["point_of_sale_id"];
		}
		this.packageBookingConfirmationService.confirmPackageBooking(request).subscribe((data) => {
			if (data["success"] && data["redirect"]) {
				window.location.href = data["redirect_to"];
			} else {
				ShareVars.showChildLoader = false;
				ShareVars.childLoaderMessage = null;
				this.packageBookingConfirmationService.setPackageBookingConfirmationData(data);
				if (this.isB2BUser) {
					ShareVars.b2bLocalDepositBalance =
						data["booking"]["agency"]["local_deposit_balance"];
					ShareVars.b2bForeignDepositBalance =
						data["booking"]["agency"]["foreign_deposit_balance"];
				}
				this.navigationService.goToPackageConfirmationPage();
			}
		});
	}

	getBookingRequest() {
		switch (this.paxType) {
			case FLIGHT:
				return this.flightService.getConfirmationRequest();

			case HOTEL:
				return this.hotelService.getHotelConfirmationRequest();

			case PACKAGE:
				return this.packagesService.getPackageConfirmArray(); //getPackageBookingConfirmationRequest();
		}
	}

	formatPrice(price: number): string {
		return this.baseService.formatPrice(price);
	}

	sendFlightDeliveryRequest() {
		ShareVars.showChildLoader = true;
		ShareVars.childLoaderMessage = CONFIRMATION_BOOKING_WAITING_MESSAGE;
		let data = this.flightService.getConfirmationRequest();
		this.flightService.confirmFlightDeliveryBooking(data).subscribe(
			(res) => {
				this.showFlightBookingDeliveryModal = true;
				ShareVars.showChildLoader = false;
				this.showFlightBookingDelivery = res.success;
			},
			(error) => {
				this.showFlightBookingDeliveryModal = true;
				ShareVars.showChildLoader = false;
				this.showFlightBookingDelivery = false;
				console.log("error", error);
			}
		);
	}

	eConfirmBooking() {
		if (this.env.delivery && this.selectedPaymentMethod == 1 && this.paxType === FLIGHT) {
			this.sendFlightDeliveryRequest();
			return;
		}

		switch (this.paxType) {
			case FLIGHT:
				this.confirmFlightBooking("fop");
				break;

			case HOTEL:
				this.confirmHotelBooking("fop");
				break;

			case PACKAGE:
				this.confirmPackageBooking("fop");
				break;
		}
	}

	/* mon voyage */

	goToPax() {
		this.shareVarsRef.enableBillingInfoTab = false;
		this.hideEPayment = false;
		this.hideAgencyPayment = false;
		this.scrollToTop();
	}

	disableBookButtonM(): boolean {
		//only tests agency payment, update it when epayment is working
		if (!this.hideAgencyPayment && !this.hideEPayment) {
			return true;
		} else {
			return (
				this.getBookingRequest()["card_type"] == null ||
				this.getBookingRequest()["card_number"] == null ||
				this.getBookingRequest()["card_exp_month"] == null ||
				this.getBookingRequest()["card_exp_year"] == null ||
				this.hideAgencyPayment
			);
		}
	}

	openBookConfirmationModal() {
		this.bookConfirmationModal = true;
	}

	closeBookConfirmationModal() {
		this.bookConfirmationModal = false;
	}
	closeBaggageWarningModalModal() {
		this.showBaggageWarning = false;
		this.navigationService.goToFlightAvailabilityPage();
	}

	closeFlightBookingDeliveryModal() {
		this.showFlightBookingDeliveryModal = false;
		this.goToFlightAvailability();
	}

	closeHotelBookingRequestModal() {
		this.showHotelBookingRequestModal = false;
		this.goToHotelAvailabilityPage();
	}

	displayPax() {
		this.showBaggageWarning = false;
	}

	goToFlightAvailability() {
		this.navigationService.goToFlightAvailabilityPage();
	}

	goToHotelAvailabilityPage() {
		this.navigationService.goToHotelAvailabilityPage();
	}

	goToPackagePage() {
		this.navigationService.goToPackagePage();
	}

	goToVisaPassengerPage() {
		this.navigationService.goToVisaPassengerPage();
	}

	goToCharterPage() {
		this.navigationService.goToCharterPage();
	}

	goToVisaPage() {
		this.navigationService.goToVisaPage();
	}

	goToHomePage() {
		this.navigationService.goToHomePage();
	}

	goBack() {
		switch (this.paxType) {
			case "flight":
				this.goToFlightAvailability();
				break;
			case "hotel":
				this.goToHotelAvailabilityPage();
				break;
			case "package":
				this.goToPackagePage();
				break;
			case "charter":
				this.goToCharterPage();
				break;
			case "visa":
				this.goToVisaPage();
				break;
			default:
				break;
		}
	}

	inverseValidate() {
		this.canValidate = !this.canValidate;
	}

	closeNewPriceModal() {
		this.showModalNewPrice = false;
	}

	refreshBalance() {
		if (this.isRefreshBalance) return
		this.isRefreshBalance = true
		this.loginService.verifyToken().subscribe(
			(data) => {
				ShareVars.currentAgency = data;
				ShareVars.b2bLocalDepositBalance = ShareVars.currentAgency["local_deposit_balance"];
				ShareVars.b2bForeignDepositBalance = ShareVars.currentAgency["foreign_deposit_balance"];
				ShareVars.b2bOverdraw = ShareVars.currentAgency["local_authorized_overdraw"];
				if (this.baseService.hasEnoughBalance(this.totalPrice)) {
					this.isRefreshBalance = false
					return
				}
				setTimeout(() => {
					this.isRefreshBalance = false
				}, 2000)
			},
			(error) => {
				console.log(error);
			}
		);
	}
}
