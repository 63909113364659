import { Component, OnInit } from "@angular/core";
import { SignUpService } from "@app/shared/services/signup.service";
import { BaseService } from "@app/shared/services/base.service";
import { ShareVars } from "../../services/share.vars";
import { SIGNUP_ERROR_MESSAGE } from ".././../services/display.messages";

@Component({
	selector: "app-signup",
	templateUrl: "./signup.component.html",
	styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
	watingServerResponse: boolean = false;
	shareVarsRef = ShareVars;
	tempPhoneNumber: number;
	tempCountryCode: string;
	passwordVerification: string;
	errorMessage: string;
	countryCodeList = new Array();

	constructor(
		private signupService: SignUpService,
		public baseService: BaseService
	) {}

	ngOnInit() {
		this.shareVarsRef.initSignUpData();
		this.countryCodeList.push("+213");
		this.tempCountryCode = this.countryCodeList[0];
	}

	setCountryCode(countryCode) {
		this.tempCountryCode = countryCode;
	}

	validate(signup_from) {
		this.shareVarsRef.signUpUser["phone_number"] = this.tempCountryCode + this.tempPhoneNumber;
		this.shareVarsRef.signup_from = signup_from;
		signup_from.disable();
		this.watingServerResponse = true;

		this.signupService.register(this.shareVarsRef.signUpUser).subscribe(
			(data) => {
				if (data["error"]) {
					this.shareVarsRef.signUpFailed = true;
					this.shareVarsRef.signUpSucceeded = false;
					this.errorMessage = data["error_type"];
				} else {
					this.shareVarsRef.signUpFailed = false;
					this.shareVarsRef.signUpSucceeded = true;
					this.errorMessage = null;
					this.tempPhoneNumber = null;
					this.passwordVerification = null;
				}
			},

			(error) => {
				this.shareVarsRef.signUpFailed = true;
				this.shareVarsRef.signUpSucceeded = false;
				this.errorMessage = SIGNUP_ERROR_MESSAGE;
			},

			() => {
				signup_from.enable();
				this.watingServerResponse = false;
			}
		);
	}

	isArabic() {
		return this.baseService.isArabic();
	}
}
