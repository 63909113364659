import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
	selector: "app-visa-passenger-informations",
	templateUrl: "./visa-passenger-informations.component.html",
	styleUrls: ["./visa-passenger-informations.component.scss"],
})
export class VisaPassengerInformationsComponent implements OnInit {
	@Input() passengerIndex: any;
	@Input() passenger: any;
	@Output() getPassengerFile = new EventEmitter<any>();
	documents;
	files = [];
	maxDate;

	constructor() {}

	ngOnInit() {
		let dtToday = new Date();

		let month: any = dtToday.getMonth() + 1;
		let day: any = dtToday.getDate();
		const year: any = dtToday.getFullYear();

		if (month < 10) {
			month = "0" + month.toString();
		}
		if (day < 10) {
			day = "0" + day.toString();
		}

		this.maxDate = year + "-" + month + "-" + day;
	}

	emitFiles() {
		this.getPassengerFile.emit({
			files: this.files,
			index: this.passengerIndex,
		});
	}

	getImgData(event, index) {
		let self = this;
		const files = event.target.files;
		if (files) {
			for (const file of files) {
				let id = Math.floor(Math.random() * 10000 + 1);
				file["id"] = id;
				const fileReader = new FileReader();
				fileReader.readAsDataURL(file);
				fileReader.addEventListener("load", function () {
					file.result = this.result;
					self.files.push(file);
				});
			}
			this.emitFiles();
		}
	}

	removeImage(id) {
		this.files = this.files.filter((file) => file.id !== id);
		document.getElementById(id).remove();
		this.emitFiles();
	}
}
