export class HotelGuestClientModel {
	clientEmail: string;
	clientVerifyEmail: string;
	clientAddress: string;
	clientCountryCode: string;
	clientPhoneNumber: string;
	clientWilaya: string;
	clientTown: string;
	clientCountry: string;
	remark: string;

	constructor() {
		this.clientEmail = null;
		this.clientVerifyEmail = null;
		this.clientAddress = "";
		this.clientCountryCode = "213";
		this.clientPhoneNumber = null;
		this.clientWilaya = "Adrar";
		this.clientTown = null;
		this.clientCountry = "Algerie";
		this.remark = null;
	}
}
