import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { MARKUP_HISTORY_B2B_URL } from "./urls_b2b";
import { UrlBuilder } from "./urls_b2c";

@Injectable()
export class MarkupHistoryService {
	fromDate;
	toDate;
	accountID;

	constructor(private baseService: BaseService) {}

	getMarkupHistory(agencyID, from, to, accountId, type) {
		const urlBuilder = new UrlBuilder(MARKUP_HISTORY_B2B_URL + agencyID)
			.addParam("accountId", accountId)
			.addParam("from", from)
			.addParam("to", to)
			.addParam("type", type);

		return this.baseService.tokenGetRequest(urlBuilder.getUrl());
	}
}
