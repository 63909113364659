import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { ShareVars } from "../../services/share.vars";
import { ERROR_MESSAGE_GETTING_DATA } from "../../services/display.messages";
import { NavigationService } from "../../services/navigation.service";
import { IMAGES_URL } from "../../../shared/services/urls_b2b";
import { environment } from "environments/environment";
declare var QRCode: any;

@Component({
	selector: "app-voucher",
	templateUrl: "./voucher.component.html",
	styleUrls: ["./voucher.component.scss"],
})
export class VoucherComponent implements OnInit {
	@Input() confirmationData: any;

	@Output() printBookinEvent = new EventEmitter<any>();
	@Output() sendBookingEmailEvent = new EventEmitter<any>();
	isB2BUser: boolean = false;
	packageObj = null;

	imagesURL = IMAGES_URL;

	imageLogo = "LOGO_LARGE.png";
	assetClientName = null;
	qrcodedata: string = "";
	token = null;

	constructor(
		private baseService: BaseService,
		private navigationService: NavigationService
	) {}

	ngOnInit() {
		this.assetClientName = environment.assets;
		if (this.assetClientName == "triponline") {
			this.imageLogo = "logo-tpo-large-vertical.png";
		}

		this.token = this.baseService.getToken();
		this.qrcodedata = this.baseService.getPrintBookingUrl(
			this.confirmationData.booking.id,
			this.token,
			"B2B",
			"PACKAGE"
		);
		setTimeout(() => {
			let doc = document.getElementById("qrcode");
			new QRCode(doc, this.qrcodedata);
		}, 1000);
		this.isB2BUser = this.baseService.b2bUserType == "B2B";
		this.mapPackageObj();
	}

	printVoucher() {
		this.printBookinEvent.emit();
	}

	openEmailModal() {
		this.sendBookingEmailEvent.emit();
	}

	goToHomePage() {
		this.navigationService.goToHomePage();
	}

	goToPackageManageBookingsPage() {
		ShareVars.isBusy = true;
		this.navigationService.goToB2BPackageVoucher(this.confirmationData.booking.id);
	}

	mapPackageObj() {
		this.packageObj = {};
		this.packageObj["package"] = {};
		this.packageObj["package"]["name"] =
			this.confirmationData.booking["sub_package"]["package"]["name"];
		this.packageObj["package"]["picture"] =
			this.confirmationData.booking["sub_package"]["package"]["picture"];
		this.packageObj["package"]["departure"] = {};
		this.packageObj["package"]["departure"]["start_date"] =
			this.confirmationData.booking["start_date"];
		this.packageObj["package"]["departure"]["end_date"] =
			this.confirmationData.booking["end_date"];
	}

	formatPrice(price: number) {
		return this.baseService.formatPrice(price);
	}

	isArabic() {
		return this.baseService.isArabic();
	}
}
