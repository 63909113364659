export const doc_type = {
	UMP: "Passeport ordinaire",
	KMB: "Carte d'identité",
	DPP: "Passeport diplomatique",
	HZP: "Passeport de Service",
	HSP: "Passeport spécial",
	YMP: "Passeport Pour Etrangers",
	SYB: "Document de voyage",
	VTP: "Passeport Nansen",
	DGR: "Autres",
};
