import { Component, OnInit, Input } from "@angular/core";
import { FlightService } from "../../../shared/services/flight.service";
import { DateService } from "../../../shared/services/date.service";
import { ShareVars } from "../../../shared/services/share.vars";
import { BaseService } from "../../../shared/services/base.service";
import { NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import {
	CustomDatepickerI18nService,
	I18n,
} from "@app/shared/services/custom-datepicker-i18n.service";
import { ControlContainer, NgForm } from "@angular/forms";
import { environment } from "../../../../environments/environment";

const INFANT_MAX_AGE = 2;
const INFANT_MIN_AGE = 0;

@Component({
	selector: "app-flight-passenger-info",
	templateUrl: "./flight-passenger-info.component.html",
	styleUrls: ["./flight-passenger-info.component.scss"],
	providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FlightPassengerInfoComponent implements OnInit {
	@Input() infosArray = [];
	@Input() type = "";
	@Input() maxDatePersonBD = 0;
	@Input() minDatePersonBD = 0;
	@Input() validNames = [];
	@Input() infantsInfosArray = [];
	@Input() validNamesInfant = [];
	@Input() ageSpan = "";
	@Input() nationalityDropdown = null;

	userType;
	env = environment;

	minDate;
	flightVars;
	isDomesticFlight: boolean = false;

	paxNameCharacterLimit: number;
	paxNameCharacterLimitINF: number;

	civilityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "Mr.",
		ariaButtonsID: [],
		ariaButtonsText: ["Mr.", "Mrs.", "Ms."],
		dropdownTypeString: false,
	};

	passportDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "ID_CARD",
		ariaButtonsID: [],
		ariaButtonsText: ["ID_CARD", "PASSPORT"],
		dropdownTypeString: true,
	};

	shareVarsRef = ShareVars;

	travellerProfiles = new Array();
	travellerProfilesNames = new Array();
	travellerProfilesDictionnary = {};

	minDateInfantBD
	maxDateInfantBD

	constructor(private flightService: FlightService, private baseService: BaseService) {}

	ngOnInit() {
		let flightDeparture = this.flightService.getSearchData().departureDate_1;
		this.maxDateInfantBD = DateService.convertDateInPastYear(
			INFANT_MAX_AGE,
			flightDeparture,
			true
		);
		this.minDateInfantBD = DateService.convertDateInPastYear(
			INFANT_MIN_AGE,
			flightDeparture,
			false
		);
		this.userType = this.env.mode;
		this.flightVars = this.flightService.flightVars;
		this.isDomesticFlight = this.flightService.getDomesticFlight();
		this.paxNameCharacterLimit = this.flightService.paxNameCharacterLimit
			? this.flightService.paxNameCharacterLimit
			: 33;
		this.paxNameCharacterLimitINF = this.flightService.paxNameCharacterLimitINF
			? this.flightService.paxNameCharacterLimitINF
			: 22;
		this.minDate = DateService.getTodayDate();
		this.travellerProfiles = this.flightService.getTravellerProfilesArray();
		if (this.travellerProfiles !== undefined && this.travellerProfiles !== null) {
			for (let index = 0; index < this.travellerProfiles.length; index++) {
				let travellerProfile = this.travellerProfiles[index];
				let travellerName =
					travellerProfile.firstname +
					" " +
					travellerProfile.middlename +
					" " +
					travellerProfile.name +
					" (" +
					travellerProfile.genInternalEmployeenumber +
					")";
				this.travellerProfilesNames.push(travellerName);
				this.travellerProfilesDictionnary[travellerName] = travellerProfile;
			}
		}
	}

	setIDType(person, $event) {
		person.idType = $event;
	}

	setCivility(passenger, civility) {
		passenger["civility"] = civility;
	}

	setNationality(passenger, nationality) {
		passenger["nationality"] = nationality;
	}

	setSpecialServices(passenger, specialServices) {
		passenger["specialServices"] = specialServices;
	}

	prefillTravellerInformation(passenger, passengerIndex, name, minBirthDate, maxBirthDate) {
		let profile = this.travellerProfilesDictionnary[name];
		passenger["firstName"] = profile.firstname + " " + profile.middlename;
		passenger["lastName"] = profile.name;
		passenger["passportNumber"] = profile.passports0number;
		let passportExpDate = this.correctProfilesDateFormat(profile.passports0expiration);
		let birthDate = this.correctProfilesDateFormat(profile.birthdate);
		if (passportExpDate > new Date(DateService.toNewDate(this.minDate))) {
			passenger["passportExpDate"] = DateService.setDate(
				passenger["passportExpDate"],
				passportExpDate
			);
		} else {
			passenger["passportExpDate"] = null;
		}

		if (
			birthDate < new Date(DateService.toNewDate(maxBirthDate)) &&
			birthDate > new Date(DateService.toNewDate(minBirthDate))
		) {
			passenger["birthDate"] = DateService.setDate(passenger["birthDate"], birthDate);
		} else {
			passenger["birthDate"] = null;
		}
		passenger["employeeID"] = profile.genInternalEmployeenumber;
		this.setNationality(
			passenger,
			this.shareVarsRef.countryCodesDictionnary[profile.nationality]
		);
		this.setCivility(passenger, profile.gender);
	}

	correctProfilesDateFormat(profileDate: string) {
		let dateArray = profileDate.split(".");
		return new Date(dateArray[1] + "-" + dateArray[0] + "-" + dateArray[2]);
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}

	testTrim(a: string): boolean {
		return !(a == null || a.trim().length >= 2);
	}

	showInputError(adult_fname_field, adultFname) {
		return (
			!(adult_fname_field.invalid && adult_fname_field.touched) ||
			adultFname == null ||
			!this.testTrim(adultFname)
		);
	}

	onNameChange(pIndex, p, limit, isInf?) {
		let length = 0;
		if (p.firstName) {
			length += p.firstName.length;
		}
		if (p.lastName) {
			length += p.lastName.length;
		}
		if (isInf) {
			this.validNamesInfant[pIndex] = length < limit + 1;
			return;
		}
		this.validNames[pIndex] = length < limit + 1;
	}

	calculatePaxId(index) {
		let id = 0;
		let n = this.flightVars.nrOfInfant;
		if (this.type == "ADULT") {
			id = index == 0 ? 0 : index <= n ? 2 * index + 1 : n + 2;
		}

		if (this.type == "CHILD") {
			id = this.flightVars.nrOfAdult + this.flightVars.nrOfInfant + index + 1;
		}

		if (this.type == "INFANT") {
			id =
				this.flightVars.nrOfAdult +
				this.flightVars.nrOfInfant +
				this.flightVars.nrOfChild +
				index +
				1;
		}

		if (this.type == "YOUTH") {
			id =
				this.flightVars.nrOfAdult +
				this.flightVars.nrOfInfant +
				this.flightVars.nrOfChild +
				this.flightVars.nrOfInfantWithSeat +
				index +
				1;
		}

		if (this.type == "SENIOR") {
			id =
				this.flightVars.nrOfAdult +
				this.flightVars.nrOfInfant +
				this.flightVars.nrOfChild +
				this.flightVars.nrOfInfantWithSeat +
				this.flightVars.nrOfSenior +
				index +
				1;
		}
		//console.log(type, n, index, id)
		return id;
	}

	updateFrequentFlyerInput(event, person) {
		person[`frequentFlyerId`] = event.target.value
	}
}
