import { Component, Input, OnInit } from "@angular/core";
import { BaseService } from "../../services/base.service";
import { HOTEL_BOOKING_ROUTE, AVAILABILITY_CHILD_ROUTE } from "../../services/routes.pages";
import { HotelService } from "../../services/hotel.service";
import { NavigationService } from "../../services/navigation.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "environments/environment";
@Component({
	selector: "app-hotel-top-destinations",
	templateUrl: "./hotel-top-destinations.component.html",
	styleUrls: ["./hotel-top-destinations.component.scss"],
})
export class HotelTopDestinationsComponent implements OnInit {
	topDestinations: any = [];
	staticURL = "";

	constructor(
		private hotelService: HotelService,
		private baseService: BaseService
	) {}

	ngOnInit() {
		this.staticURL = environment.staticApi;
		this.hotelService.getHotelTopDestinations().subscribe(
			(data) => (this.topDestinations = data),
			(error) => console.log(error)
		);
	}

	gotoDestination(topd) {
		console.log(topd);
	}

	isArabic() {
		return this.baseService.isArabic();
	}
}
