import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from "@angular/core";
import { LocalizationService } from "../../../shared/services/localization.service";
import { ShareVars } from "../../../shared/services/share.vars";
import { BaseService } from "../../../shared/services/base.service";
import { NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import {
	CustomDatepickerI18nService,
	I18n,
} from "@app/shared/services/custom-datepicker-i18n.service";
import { FlightPassengerCommonModel } from "@app/flight-booking/components/flight-passenger-models/flight.passenger.common.model";
import { FlightPassengerInfantModel } from "@app/flight-booking/components/flight-passenger-models/flight.passenger.infant.model";
import { CharterSearchService } from "@app/shared/services/charter-search.service";
import { DateService } from "@app/shared/services/date.service";
import { CHARTER_UPDATE_B2B_URL } from "@app/shared/services/urls_b2b";
import { CONFIRMATION_BOOKING_WAITING_MESSAGE } from "@app/shared/services/display.messages";

const INFANT_MAX_AGE = 2;
const INFANT_MIN_AGE = 0;
const ADULT_MAX_AGE = 130;
const ADULT_MIN_DATE = 12;
const CHILD_MIN_AGE = 2;
const CHILD_MAX_AGE = 12;

@Component({
	selector: "app-charter-passenger-update",
	templateUrl: "./charter-passenger-update.component.html",
	styleUrls: ["./charter-passenger-update.component.scss"],
	providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }],
})
export class CharterPassengerUpdateComponent implements OnInit, OnDestroy {
	@Input() passengers;
	@Input() charterId;
	@Input() flightDeparture;
	@Output() updatedData = new EventEmitter();

	adultNumber: number = 0;
	childrenNumber: number = 0;
	infantNumber: number = 0;

	saveMode: boolean = false;

	adultPassengers;
	childPassengers;
	infantPassengers;

	maxDateAdultBD;
	minDateAdultBD;
	maxDateChildBD;
	minDateChildBD;
	maxDateInfantBD;
	minDateInfantBD;

	minDate = DateService.toStringDate(DateService.getTodayDate());

	clientInfos = {
		clientEmail: "",
		clientVerifyEmail: "",
		clientPhoneNumber: "",
		clientAddress: "",
	};

	user = {
		email: "",
	};

	userType = "B2B";

	phonePattern: string = "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$";
	emailPattern: string = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$";
	noPattern: string = "";

	clientCountryCodeDropdown = {
		mainButtonID: "flight_passenger_pax_client_countrycode_class_dropdown",
		mainButtonText: "+213 Algerie",
		dropdownAriaID: "flight_passenger_pax_client_countrycode_class_dropdown_aria",
		ariaButtonsID: [],
		ariaButtonsText: ["+213 Algerie"],
		dropdownTypeString: true,
	};

	clientCitiesDropdown = {
		mainButtonID: "flight_passenger_pax_client_city_class_dropdown",
		dropdownAriaID: "flight_passenger_pax_client_city_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		dropdownTypeString: false,
	};

	clientTownsDropdown = {
		mainButtonID: "flight_passenger_pax_client_town_class_dropdown",
		dropdownAriaID: "flight_passenger_pax_client_town_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		dropdownTypeString: false,
	};

	clientCountriesDropdown = {
		mainButtonID: "flight_passenger_pax_client_country_class_dropdown",
		dropdownAriaID: "flight_passenger_pax_client_country_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: ["Algérie", "Liban"],
		dropdownTypeString: false,
	};

	adultCivilityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "Mr.",
		ariaButtonsID: [],
		ariaButtonsText: ["Mr.", "Mrs.", "Ms."],
		dropdownTypeString: false,
	};

	adultNationalityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		ariaButtonsTextTranslation: {},
		dropdownTypeString: false,
	};

	adultCardPassportDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "ID_CARD",
		ariaButtonsID: [],
		ariaButtonsText: ["ID_CARD", "PASSPORT"],
		dropdownTypeString: true,
	};

	infantCivilityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "Mr.",
		ariaButtonsID: [],
		ariaButtonsText: ["Mr.", "Mrs.", "Ms."],
		dropdownTypeString: false,
	};

	infantNationalityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		ariaButtonsTextTranslation: {},
		dropdownTypeString: false,
	};

	childCivilityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "Mr.",
		ariaButtonsID: [],
		ariaButtonsText: ["Mr.", "Mrs.", "Ms."],
		dropdownTypeString: false,
	};

	childNationalityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		ariaButtonsTextTranslation: {},
		dropdownTypeString: false,
	};

	childCardPassportDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "ID_CARD",
		ariaButtonsID: [],
		ariaButtonsText: ["ID_CARD", "PASSPORT"],
		dropdownTypeString: true,
	};

	shareVarsRef = ShareVars;

	extraFields = new Array();
	travellerProfiles = new Array();
	travellerProfilesNames = new Array();
	travellerProfilesDictionnary = {};
	countryCodesDictionnary = {};

	adultsInfosArray = new Array();
	childrenInfosArray = new Array();
	infantsInfosArray = new Array();

	validNamesAdult = [true];
	validNamesInfant = [true];
	validNamesChild = [true];

	constructor(
		private charterService: CharterSearchService,
		private localizationService: LocalizationService,
		private baseService: BaseService
	) {}

	ngOnInit() {
		this.adultPassengers = this.passengers.filter((passenger) => passenger.pax.type === "ADT");
		this.childPassengers = this.passengers.filter((passenger) => passenger.pax.type === "CHD");
		this.infantPassengers = this.passengers.filter((passenger) => passenger.pax.type === "INF");

		this.adultNumber = this.adultPassengers.length;
		this.childrenNumber = this.childPassengers.length;
		this.infantNumber = this.infantPassengers.length;

		this.localizationService.getCountries().subscribe((data) => {
			let countryCodes = data.map((item, i) => item.alpha3Code + "-" + item.alpha2Code);
			let countryNames = data.map((item, i) => item.name);
			let callingCodes = data.map((item, i) => "+" + item.callingCodes[0] + " " + item.name);
			let countryNameDictionnary = {};
			for (let i = 0; i < data.length; i++) {
				let country = data[i];
				let countryFullCode = country.alpha3Code + "-" + country.alpha2Code;
				countryNameDictionnary[countryFullCode] = country.name;
				this.countryCodesDictionnary[country.alpha2Code] = countryFullCode;
			}
			this.adultNationalityDropdown.ariaButtonsText = countryCodes;
			this.adultNationalityDropdown.ariaButtonsTextTranslation = countryNameDictionnary;
			this.childNationalityDropdown.ariaButtonsText = countryCodes;
			this.childNationalityDropdown.ariaButtonsTextTranslation = countryNameDictionnary;
			this.infantNationalityDropdown.ariaButtonsText = countryCodes;
			this.infantNationalityDropdown.ariaButtonsTextTranslation = countryNameDictionnary;

			this.clientCountriesDropdown.ariaButtonsText = countryNames;
		});

		if (this.adultNumber > 0) {
			this.validNamesAdult = new Array(this.adultNumber).fill(false);
			this.adultsInfosArray = new Array(this.adultNumber)
				.fill(0)
				.map((e) => new FlightPassengerCommonModel());
			this.maxDateAdultBD = DateService.toStringDate(
				DateService.convertDateInPastYear(ADULT_MAX_AGE, this.flightDeparture, true)
			);
			this.minDateAdultBD = DateService.toStringDate(
				DateService.convertDateInPastYear(ADULT_MIN_DATE, this.flightDeparture, false)
			);
			console.log({ minADT: this.maxDateAdultBD, maxADT: this.minDateAdultBD });
		}

		if (this.childrenNumber > 0) {
			this.validNamesChild = new Array(this.childrenNumber).fill(false);
			this.childrenInfosArray = new Array(this.childrenNumber)
				.fill(0)
				.map((e) => new FlightPassengerCommonModel());
			this.maxDateChildBD = DateService.toStringDate(
				DateService.convertDateInPastYear(CHILD_MAX_AGE, this.flightDeparture, true)
			);
			this.minDateChildBD = DateService.toStringDate(
				DateService.convertDateInPastYear(CHILD_MIN_AGE, this.flightDeparture, false)
			);
		}

		if (this.infantNumber > 0) {
			this.validNamesInfant = new Array(this.infantNumber).fill(false);
			this.infantsInfosArray = new Array(this.infantNumber)
				.fill(0)
				.map((e) => new FlightPassengerInfantModel());
			this.maxDateInfantBD = DateService.toStringDate(
				DateService.convertDateInPastYear(INFANT_MAX_AGE, this.flightDeparture, true)
			);
			this.minDateInfantBD = DateService.toStringDate(
				DateService.convertDateInPastYear(INFANT_MIN_AGE, this.flightDeparture, false)
			);
		}
	}

	getAdultNatAriaBtnID(adultIndex) {
		for (let i = 0; i < this.adultNationalityDropdown.ariaButtonsText.length; i++) {
			this.adultNationalityDropdown.ariaButtonsID[i] =
				"flight_passenger_pax_adult_nationality_" + adultIndex;
		}

		return this.adultNationalityDropdown.ariaButtonsID;
	}

	getInfantNatAriaBtnID(adultIndex) {
		for (let i = 0; i < this.infantNationalityDropdown.ariaButtonsText.length; i++) {
			this.infantNationalityDropdown.ariaButtonsID[i] =
				"flight_passenger_pax_infant_nationality_" + adultIndex;
		}

		return this.infantNationalityDropdown.ariaButtonsID;
	}

	getChildNatAriaBtnID(childIndex) {
		for (let i = 0; i < this.childNationalityDropdown.ariaButtonsText.length; i++) {
			this.childNationalityDropdown.ariaButtonsID[i] =
				"flight_passenger_pax_child_nationality_" + childIndex;
		}

		return this.childNationalityDropdown.ariaButtonsID;
	}

	testTrim(a: string): boolean {
		if (a != null) {
			if (a.trim().length < 2) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	save() {
		const request = {
			adults: this.adultPassengers,
			children: this.childPassengers,
			infants: this.infantPassengers,
			charter_flight_booking_id: this.charterId,
		};

		this.saveMode = true;

		this.updateCharterBooking(request).subscribe(
			(data) => {
				this.updatedData.emit(data.booking);
				this.saveMode = false;
			},
			(error) => {
				this.saveMode = false;
			}
		);
	}

	updateCharterBooking(request) {
		return this.baseService.tokenPostRequest(CHARTER_UPDATE_B2B_URL, request);
	}

	scrollToTop() {
		let elm: HTMLElement = document.getElementById("nav_pax_tabs") as HTMLElement;
		setTimeout(() => {
			elm.scrollIntoView({ behavior: "smooth" });
		}, 500);
	}

	ngOnDestroy(): void {}

	setCivility(passenger, civility) {
		passenger["pax"]["title"] = civility;
	}

	setNationality(passenger, nationality) {
		passenger["nationality"] = nationality;
	}
}
