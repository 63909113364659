import { AppComponent } from "./app.component";
import { ColumnDisplayComponent } from "./main/components/column-display/column-display.component";
import { TreeSearchComponent } from "./main/components/tree-search/tree-search.component";
import { CharterSearchService } from "./shared/services/charter-search.service";

export const componentDeclarations: any[] = [
	// ColumnDisplayComponent,
	TreeSearchComponent,
	AppComponent,
];

export const providerDeclarations: any[] = [CharterSearchService];
