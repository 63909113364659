import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { NotyfModule } from "ng-notyf";
import { CookieService } from "ngx-cookie-service";
import { AppRoutingModule } from "./app-routing.module";
import { componentDeclarations, providerDeclarations } from "./app.common";
import { AppComponent } from "./app.component";
import { HttpLoaderFactory } from "./HttpLoaderFactory";
import { SharedModule } from "./shared/shared.module";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";

@NgModule({
	declarations: [...componentDeclarations],
	imports: [
		BrowserModule,
		FormsModule,
		AppRoutingModule,
		HttpClientModule,
		ReactiveFormsModule,
		NotyfModule,
		LeafletModule,
		SharedModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	providers: [CookieService, ...providerDeclarations],
	exports: [...componentDeclarations],
	bootstrap: [AppComponent],
})
export class AppModule {}
