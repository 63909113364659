import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { BaseService } from "@app/shared/services/base.service";
import { InvoiceService } from "@app/shared/services/invoice.service";
import { ShareVars } from "@app/shared/services/share.vars";
import { Subscription } from "rxjs";
@Component({
	selector: "app-invoice-details",
	templateUrl: "./invoice-details.component.html",
	styleUrls: ["./invoice-details.component.scss"],
})
export class InvoiceDetailsComponent implements OnInit, OnDestroy {
	private selectedInvoiceSubscription: Subscription;
	public data;

	constructor(
		private invoiceService: InvoiceService,
		private baseService: BaseService,
		private activatedRoute: ActivatedRoute
	) {
		ShareVars.isBusy = false;
	}

	ngOnDestroy(): void {
		this.selectedInvoiceSubscription.unsubscribe();
	}

	ngOnInit() {
		this.selectedInvoiceSubscription =
			this.invoiceService.selectedInvoiceSubscription.subscribe((data) => {
				this.data = data;
			});

		this.activatedRoute.params.subscribe((data) => {
			this.invoiceService.getInvoiceByNumber(+data["id"]);
		});
	}

	formatEntry(val: number, option: string) {
		return this.baseService.formatPrice(val);
	}
}
