import { Component, OnInit } from "@angular/core";
import { VisaPassengerModel } from "../visa-application-passenger-model/visa.passenger.model";
import { VisaApplicationService } from "../../../shared/services/visa.application.service";
import { ShareVars } from "../../../shared/services/share.vars";
import { DateService } from "../../../shared/services/date.service";
import { isEmail, isNumeric } from "validator";
import { filter } from "rxjs/operators";
import { cloneDeep } from "lodash";
import { BaseService } from "@app/shared/services/base.service";

@Component({
	selector: "app-visa-passenger",
	templateUrl: "./visa-passenger.component.html",
	styleUrls: ["./visa-passenger.component.scss"],
})
export class VisaPassengerComponent implements OnInit {
	passengersData: VisaPassengerModel[] = [];
	selectedVisaTotalPersons: number;

	errors = [];
	visa;
	showDescription = true;

	constructor(
		private visaApplicationService: VisaApplicationService,
		private baseService: BaseService
	) {}

	ngOnInit() {
		this.visaApplicationService.visa$.pipe(filter((data) => !!data)).subscribe((visa) => {
			this.visa = visa;
			this.selectedVisaTotalPersons = this.visa["totalPersons"];
			this.initForm();
		});
	}

	initForm() {
		this.passengersData = [];
		for (let i = 0; i < this.selectedVisaTotalPersons; i++) {
			const passenger = new VisaPassengerModel();
			this.passengersData.push(passenger);
		}
	}

	validateFormData(): boolean {
		let checkData = false;
		this.errors = [];
		const required: Boolean[] = [];
		for (let i = 0; i < this.passengersData.length; i++) {
			if (this.passengersData[i].email && !isEmail(`${this.passengersData[i].email}`)) {
				this.errors.push(`Email of passenger ${i + 1} is not valid`);
			}
			if (
				this.passengersData[i].passport_number &&
				!isNumeric(`${this.passengersData[i].passport_number}`)
			) {
				this.errors.push(`Passport number of passenger ${i + 1} is not valid`);
			}

			required[i] =
				!!this.passengersData[i].first_name &&
				!!this.passengersData[i].last_name &&
				!!this.passengersData[i].birth_place &&
				!!this.passengersData[i].birth_date &&
				!!this.passengersData[i].passport_number &&
				!!this.passengersData[i].passport_date &&
				!!this.passengersData[i].passport_exp_date &&
				!!this.passengersData[i].documents;
		}

		this.errors.length > 0 || !required.every((r) => r == true)
			? (checkData = true)
			: (checkData = false);
		return checkData;
	}

	readPassengerFile(event) {
		const { index, files } = event;
		this.passengersData[index].documents = files;
	}

	goToVisaConfirmation() {
		this.visaApplicationService._passengers.next(this.passengersData);

		const elm: HTMLElement = document.getElementById("pax_billing_info_tab") as HTMLElement;
		ShareVars.enableBillingInfoTab = true;
		elm.classList.remove("disbaled-tab");
		elm.click();
		this.scrollToTop();
	}

	scrollToTop() {
		const elm: HTMLElement = document.getElementById("nav_pax_tabs") as HTMLElement;
		setTimeout(() => {
			elm.scrollIntoView({ behavior: "smooth" });
		}, 500);
	}
}
