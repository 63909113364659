export class HotelGuestModel {
	civility: string;
	firstName: string;
	lastName: string;
	gender: string;
	guestIndex: number;
	guestType: string;
	guestTypeName: string;

	constructor() {
		this.civility = "Mr";
		this.firstName = null;
		this.lastName = null;
		this.gender = "M";
		this.guestIndex = 0;
		this.guestType = "";
		this.guestTypeName = "";
	}
}
