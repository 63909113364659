import { Component, OnInit, Input } from "@angular/core";
import { HotelService } from "../../services/hotel.service";

@Component({
	selector: "app-tripadvisor-rating",
	templateUrl: "./tripadvisor-rating.component.html",
	styleUrls: ["./tripadvisor-rating.component.scss"],
})

// FIXME: change the component name to hotel-category, rating is something else we will do later
export class TripadvisorRatingComponent implements OnInit {
	@Input() rating = 10;
	@Input() reviews = 0;
	@Input() sm = false;
	@Input() link = "#";
	@Input() task = false

	// tripadvisor = 35;
	ctripadvisor = 0;
	otripadvisor = 0;
	htripadvisor = 0;
	ctripadvisorArray: any[];
	otripadvisorArray: any[];
	htripadvisorArray: any[];

	constructor(private hotelService: HotelService) {}

	ngOnInit() {
		this.ctripadvisor = Math.floor(this.rating / 10);
		this.htripadvisor = (this.rating / 5) % 2;
		this.otripadvisor = 5 - this.ctripadvisor - this.htripadvisor;
		this.ctripadvisorArray = new Array(this.ctripadvisor);
		this.htripadvisorArray = new Array(this.htripadvisor);
		this.otripadvisorArray = new Array(this.otripadvisor);
	}
}
