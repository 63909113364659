import { Component, OnInit } from "@angular/core";
import { PackageTravellerClientModel } from "../package-traveller-models/package.client.model";
import { PackageTravellersRoom } from "../package-traveller-models/package.travellers.room";
import { PackageTravellerModel } from "../package-traveller-models/package.traveller.model";
import { LoginService } from "@app/shared/services/login.service";
import { COMMUNES } from "@app/shared/services/misc/mock-communes";
import { LocalizationService } from "../../../shared/services/localization.service";
import { NavigationService } from "../../../shared/services/navigation.service";
import { PackagesService } from "../../../shared/services/packages.service";
import { ShareVars } from "../../../shared/services/share.vars";
import { DateService } from "../../../shared/services/date.service";
import { BaseService } from "../../../shared/services/base.service";
import { ERROR_MESSAGE_GETTING_DATA } from "../../../shared/services/display.messages";
import { cloneDeep } from "lodash";

@Component({
	selector: "app-package-traveller",
	templateUrl: "./package-traveller.component.html",
	styleUrls: ["./package-traveller.component.scss"],
})
export class PackageTravellerComponent implements OnInit {
	tokenVerificationRequest$ = null;
	departements;
	towns;

	user = null;
	userType = null;
	packageSearchParams;
	minDate;

	travellersInfosArray = new Array();

	roomsNBR: number = 0;

	clientInfos = new PackageTravellerClientModel();

	clientCountryCodeDropdown = {
		mainButtonID: "package_passenger_pax_client_countrycode_class_dropdown",
		mainButtonText: "+213 Algerie",
		dropdownAriaID: "package_passenger_pax_client_countrycode_class_dropdown_aria",
		ariaButtonsID: [],
		ariaButtonsText: ["+213 Algerie"],
		dropdownTypeString: true,
	};

	clientCitiesDropdown = {
		mainButtonID: "package_passenger_pax_client_city_class_dropdown",
		dropdownAriaID: "package_passenger_pax_client_city_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		dropdownTypeString: false,
	};

	clientTownsDropdown = {
		mainButtonID: "package_passenger_pax_client_town_class_dropdown",
		dropdownAriaID: "package_passenger_pax_client_town_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		dropdownTypeString: false,
	};

	clientCountriesDropdown = {
		mainButtonID: "package_passenger_pax_client_country_class_dropdown",
		dropdownAriaID: "package_passenger_pax_client_country_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: ["Algérie"],
		dropdownTypeString: false,
	};

	travelerCivilityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: "Mr.",
		ariaButtonsID: [],
		ariaButtonsText: ["Mr.", "Mrs.", "Ms."],
		dropdownTypeString: false,
	};

	constructor(
		private localizationService: LocalizationService,
		private loginService: LoginService,
		private navigationService: NavigationService,
		private packagesService: PackagesService,
		private baseService: BaseService
	) {
		for (let i = 0; i < this.clientCountryCodeDropdown.ariaButtonsText.length; i++) {
			this.clientCountryCodeDropdown.ariaButtonsID[i] =
				"package_passeneger_pax_client_countrycode_" + i;
		}

		for (let i = 0; i < this.clientCountriesDropdown.ariaButtonsText.length; i++) {
			this.clientCountriesDropdown.ariaButtonsID[i] =
				"package_passenger_pax_client_country_" + i;
		}
	}

	ngOnInit() {
		this.minDate = DateService.getTodayDate();
		this.packageSearchParams = { ...JSON.parse(this.packagesService.getPackageSearchParams()) };

		if (Object.keys(this.packageSearchParams).length > 0) {
			if (ShareVars.departements.length > 0) {
				this.departements = ShareVars.departements;
				this.initDepartementsDpd();
			} else {
				this.localizationService.getDepartements().subscribe((data) => {
					this.departements = data;
					ShareVars.departements = data;
					this.initDepartementsDpd();
				});
			}

			switch (this.baseService.b2bUserType) {
				case "B2C":
					this.userType = "B2C";
					this.tokenVerificationRequest$ = this.loginService.verifyToken();
					this.tokenVerificationRequest$.subscribe(
						(user) => this.initClientInfos(user),
						(error) => this.checkUserError()
					);
					break;

				case "B2B":
					this.userType = "B2B";
					this.initClientInfos(new PackageTravellerClientModel());
					break;
			}
		}

		this.initTravellersArrays(this.packageSearchParams, this.travellersInfosArray);
	}

	initDepartementsDpd() {
		for (let i = 0; i < this.departements.length; i++) {
			this.clientCitiesDropdown.ariaButtonsID[i] = "package_passenger_pax_client_city_" + i;
			this.clientCitiesDropdown.ariaButtonsText[i] = this.departements[i]["name"];
		}

		this.towns = COMMUNES["Adrar"];
		this.clientInfos.clientTown = COMMUNES["Adrar"][0]["name"];

		this.clientTownsDropdown.mainButtonText = this.towns[0]["name"];
		this.clientCountriesDropdown.mainButtonText = "Algérie";

		for (let i = 0; i < this.towns.length; i++) {
			this.clientTownsDropdown.ariaButtonsID[i] = "package_passenger_pax_client_town_" + i;
			this.clientTownsDropdown.ariaButtonsText[i] = this.towns[i]["name"];
		}
	}

	checkUserError() {
		ShareVars.showError = true;
		ShareVars.errorMessage = ERROR_MESSAGE_GETTING_DATA;
		ShareVars.errorStatus = null;
		this.navigationService.goToPackageAvailabilityPage();
	}

	initClientInfos(user) {
		this.user = user;

		if (this.user.address) {
			this.clientInfos.clientAddress = this.user.address;
		}

		if (this.user.phone_number) {
			this.clientInfos.clientPhoneNumber = this.user.phone_number.slice(-9);
		}

		if (this.user.town) {
			this.clientInfos.clientTown = this.user.town;
			this.clientTownsDropdown.mainButtonText = this.clientInfos.clientTown;
		}

		if (this.user.email) {
			this.clientInfos.clientEmail = this.user.email;
		}

		if (this.user["country"]) {
			this.clientCountriesDropdown.mainButtonText = this.user["country"];
		}

		this.clientCitiesDropdown.mainButtonText = this.clientInfos.clientWilaya;
	}

	setCountryCode(code) {
		this.clientInfos.clientCountryCode = code.slice(0, code.indexOf(" "));
	}

	setWilaya(wilayaName) {
		this.towns = COMMUNES[wilayaName];
		this.clientInfos.clientTown = this.towns[0]["name"];
		this.clientInfos.clientWilaya = wilayaName;

		this.clientTownsDropdown.mainButtonText = this.towns[0]["name"];
		this.clientTownsDropdown.ariaButtonsText = [];
		for (let i = 0; i < this.towns.length; i++) {
			this.clientTownsDropdown.ariaButtonsText[i] = this.towns[i]["name"];
		}
	}

	setTown(townName) {
		this.clientInfos.clientTown = townName;
	}

	setCountry(countryName) {
		this.clientInfos.clientCountry = countryName;
	}

	setCivility(traveller, civility) {
		traveller["civility"] = civility;
		traveller.gender = traveller.civility == "Mr" ? "M" : "F";
	}

	initTravellersArrays(packageSearchParams, travellersInfosArray) {
		this.roomsNBR = packageSearchParams["room"];

		for (let i = 0; i < this.roomsNBR; i++) {
			//room x
			const packageTravellerRoom = new PackageTravellersRoom();

			//adults in room x
			packageTravellerRoom.travellerRoom.adult_nbr = packageSearchParams["adults_" + (i + 1)];
			for (let k = 0; k < packageTravellerRoom.travellerRoom.adult_nbr; k++) {
				//crate to each adult his infos
				const adultInfos = new PackageTravellerModel();
				adultInfos.travellerTypeName = "ADULT";
				adultInfos.travellerType = "ADT";
				adultInfos.travellerIndex = k;
				packageTravellerRoom.travellerInfos.push(adultInfos);
			}

			//child in room x
			packageTravellerRoom.travellerRoom.child_nbr =
				packageSearchParams["children_" + (i + 1)];

			if (packageTravellerRoom.travellerRoom.child_nbr > 0) {
				//create child age array in room x
				packageTravellerRoom.travellerRoom.child_age = new Array();

				for (let j = 0; j < packageTravellerRoom.travellerRoom.child_nbr; j++) {
					//create to each child his infos
					const childInfos = new PackageTravellerModel();
					childInfos.travellerTypeName = "CHILD";
					childInfos.travellerType = "CHD";
					childInfos.travellerIndex = j;
					packageTravellerRoom.travellerInfos.push(childInfos);
					//add to each child his age
					packageTravellerRoom.travellerRoom.child_age.push(
						packageSearchParams["child_age_" + (i + 1) + "_" + (j + 1)]
					);
				}
			}

			//add room x to rooms array
			travellersInfosArray.push(packageTravellerRoom);
		}
	}

	savePaxData() {
		this.packagesService.clientInfos = cloneDeep(this.clientInfos);
		this.packagesService.travellersInfosArray = cloneDeep(this.travellersInfosArray);
	}

	goToBillingTab() {
		this.savePaxData();
		this.packagesService.setPackageBookingConfirmationRequest(this.getConfirmationRequest());

		const elm: HTMLElement = document.getElementById("pax_billing_info_tab") as HTMLElement;
		ShareVars.enableBillingInfoTab = true;
		elm.classList.remove("disbaled-tab");
		elm.click();
	}

	setBillingInofs() {
		this.savePaxData();
		this.packagesService.setPackageBookingConfirmationRequest(this.getConfirmationRequest());
		ShareVars.enableBillingInfoTab = true;
	}

	private getConfirmationRequest(): any {
		let request = {};

		request["customer_address"] = this.clientInfos.clientAddress;
		request["customer_city"] = this.clientInfos.clientWilaya;
		request["customer_country"] = this.clientInfos.clientCountry;
		request["customer_email"] = this.clientInfos.clientEmail;
		request["customer_phoneNumber"] = this.clientInfos.clientPhoneNumber;
		request["customer_phone_countrycode"] = this.clientInfos.clientCountryCode;
		request["reference"] = "note";

		request["departure_id"] = this.packagesService.getPackageBookingPackageDepartureID();
		request["package_id"] = this.packagesService.getPackageBookingPackageID();
		request["rooms"] = this.packagesService.getPackageBookingPackageRoomsNumber();
		request["sub_package_id"] = this.packagesService.getPackageBookingSubPackageID();
		request["point_of_sale_id"] = null;

		request = this.setTravelersInfos(this.travellersInfosArray, request);

		let sortedRequest = {};

		Object.keys(request)
			.sort()
			.forEach(function (key) {
				sortedRequest[key] = request[key];
			});

		return sortedRequest;
	}

	setTravelersInfos(tab, req) {
		const roomsTobook = this.packagesService.getPackageBookingPackageRoomsToBook();
		console.log(roomsTobook);
		const roomRates = this.packagesService.getPackageRoomRate();
		console.log(roomRates);
		console.log(tab.length);
		console.log(this.travellersInfosArray);

		let confirmArray = null;
		let paxArray = [];
		let packageObject = this.packagesService.getPackageBookingPackageObject();
		console.log(packageObject);

		confirmArray = {
			customer_address: this.clientInfos.clientAddress,
			customer_city: this.clientInfos.clientTown,
			customer_country: this.clientInfos.clientCountryCode,
			customer_email: this.clientInfos.clientEmail,
			customer_phoneNumber: this.clientInfos.clientPhoneNumber,
			customer_phone_countrycode: this.clientInfos.clientCountry,
			departure_id: packageObject.package.departure.id,
			package_id: packageObject.package_id,
			point_of_sale_id: null,
			reference: "note",
			sub_package_id: packageObject.steps[0].sub_package_id,
			price_id: this.packagesService.getPackageSelectedPriceID(),
		};

		let idPax = 1;
		this.travellersInfosArray.forEach((room) => {
			room.travellerInfos.forEach((pax) => {
				paxArray.push({
					id: idPax,
					gender: pax.gender,
					firstname: pax.firstName,
					lastname: pax.lastName,
					type: pax.travellerType,
					age:
						pax.travellerType == "CHD"
							? room.travellerRoom.child_age[pax.travellerIndex]
							: 30,
				});
				idPax++;
			});
		});
		confirmArray["paxes"] = paxArray;
		console.log(confirmArray);
		let totalPersons = 0;
		confirmArray["steps"] = [];
		packageObject.steps.forEach((step) => {
			if (step.type === "EXCURSION") {
				confirmArray.steps.push({
					type: "EXCURSION",
					id: step.id,
				});
			}
			if (step.type === "FLIGHT") {
				confirmArray.steps.push({
					type: "FLIGHT",
					id: step.id,
				});
			}
			if (step.type === "TRANSFER") {
				confirmArray.steps.push({
					type: "TRANSFER",
					id: step.id,
				});
			}
			if (step.type === "HOTEL") {
				if (step.hotel.simple_hotel) {
					confirmArray.steps.push({
						type: "HOTEL",
						id: step.id,
					});
				} else {
					let RoomsArray = [];
					let hotel: any;
					for (let key in roomRates) {
						let value = roomRates[key];
						if (value.hotel_id === step.hotel_id) {
							hotel = value;
						}
					}
					let roomIndex = 0;
					hotel.rooms.forEach((room) => {
						let paxes = [];
						totalPersons = totalPersons + room.rate.adults + room.rate.children_number;
						for (let i = 0; i < room.rate.adults + room.rate.children_number; i++) {
							roomIndex++;
							paxes.push(roomIndex);
						}
						RoomsArray.push({
							id: room.rate.room_id,
							rate_id: room.rate.id,
							paxes_ids: paxes,
						});
					});
					console.log("HOTEL");
					console.log(hotel);
					confirmArray.steps.push({
						type: "HOTEL",
						id: step.id,
						rooms: RoomsArray,
					});
				}
			}
		});

		this.packagesService.setPackageConfirmArray(confirmArray);

		req["pax_number"] = totalPersons;

		return req;
	}

	goToPackageAvailability() {
		this.navigationService.goToPackageAvailabilityPage();
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}

	testTrim(a): boolean {
		//check if there is unuseful white space before or after the string

		if (a != null) {
			if (a.trim().length < 2) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}
}
