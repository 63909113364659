import { Injectable } from "@angular/core";
import { CONFIRM_ORDER_PAYMENT_API_URL } from "./urls_b2c";
import { BaseService } from "./base.service";

@Injectable()
export class EPaymentService {
	constructor(private baseService: BaseService) {}

	getConfirmation(orderNumber, language) {
		return this.baseService.tokenGetRequest(
			CONFIRM_ORDER_PAYMENT_API_URL + "?orderNumber=" + orderNumber + "&language=" + language
		);
	}
}
