export const environment = {
    production: true,
    CLIENTNAME: 'algerie-booking',
    assets: 'triponline',
    clientDisplayName: 'TripOnline',
    staticApi: 'https://static-api.algebratec.com/',
    serverURL: 'https://test-api.algebratec.com',
    key: 'h1p4ICUYcgnRDX2ZMJQK',
    delivery:false,
    phone: [],
    proAccessUrl: '', 
    mode: 'B2B'
};
