import { Component, OnInit, Input } from "@angular/core";
import { PackagesService } from "../../services/packages.service";
import { BaseService } from "../../services/base.service";
import { IMAGES_URL } from "../../../shared/services/urls_b2b";

@Component({
	selector: "app-package-pax-review-booking",
	templateUrl: "./package-pax-review-booking.component.html",
	styleUrls: ["./package-pax-review-booking.component.scss"],
})
export class PackagePaxReviewBookingComponent implements OnInit {
	@Input() packageObject = null;
	@Input() isConfimation: boolean = false;
	@Input() source: string = null;
	showModal: boolean = false;

	roomsNumber: number = null;
	adultNumber: number = null;
	childNumber: number = null;
	roomsToBook = null;

	imagesURL = IMAGES_URL;

	constructor(
		private packagesService: PackagesService,
		private baseService: BaseService
	) {}

	ngOnInit() {
		this.roomsNumber = this.packagesService.getPackageBookingPackageRoomsNumber();
		this.adultNumber = this.packagesService.getPackageBookingAdultTotalNumber();
		this.childNumber = this.packagesService.getPackageBookingChildTotalNumber();
		this.roomsToBook = this.packagesService.getPackageBookingPackageRoomsToBook();
	}

	showPackageDetails(packageDetailsPax) {
		this.showModal = true;
		packageDetailsPax.showModalPackageDetails();
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}
}
