import { Component, OnInit } from "@angular/core";
import { FlightService } from "@app/shared/services/flight.service";
import { BaseService } from "../../services/base.service";
import { FLIGHT_BOOKING_ROUTE, AVAILABILITY_CHILD_ROUTE } from "../../services/routes.pages";
import { cloneDeep } from "lodash";

@Component({
	selector: "app-flight-offers",
	templateUrl: "./flight-offers.component.html",
	styleUrls: ["./flight-offers.component.scss"],
})
export class FlightOffersComponent implements OnInit {
	flightOffers;
	flightAvailabilityRoute: string = FLIGHT_BOOKING_ROUTE + AVAILABILITY_CHILD_ROUTE;
	flightAvailabilityRouteParams = {};

	constructor(
		private flightService: FlightService,
		private baseService: BaseService
	) {}

	ngOnInit() {
		this.flightService.getFlightOffers().subscribe((data) => (this.flightOffers = data));
	}

	formatPrice(price: number): string {
		return this.baseService.formatPrice(price);
	}

	getDestinations(offer) {
		switch (offer.search_criteria.tripType) {
			case "rt":
				return [
					[
						offer.search_criteria.flightDepartureCityCountryName_1.slice(
							0,
							offer.search_criteria.flightDepartureCityCountryName_1.indexOf(",")
						),
						offer.search_criteria.flightArrivalCityCountryName_1.slice(
							0,
							offer.search_criteria.flightArrivalCityCountryName_1.indexOf(",")
						),
					],
				];

			case "ow":
				return [
					[
						offer.search_criteria.flightDepartureCityCountryName_1.slice(
							0,
							offer.search_criteria.flightDepartureCityCountryName_1.indexOf(",")
						),
						offer.search_criteria.flightArrivalCityCountryName_1.slice(
							0,
							offer.search_criteria.flightArrivalCityCountryName_1.indexOf(",")
						),
					],
				];

			case "md":
				let destinationsArray = [];
				for (let i = 0; i < offer.search_criteria.bounds; i++) {
					destinationsArray[i] = [];
					destinationsArray[i][0] = offer.search_criteria[
						"flightDepartureCityCountryName_" + (i + 1)
					].slice(
						0,
						offer.search_criteria["flightDepartureCityCountryName_" + (i + 1)].indexOf(
							","
						)
					);
					destinationsArray[i][1] = offer.search_criteria[
						"flightArrivalCityCountryName_" + (i + 1)
					].slice(
						0,
						offer.search_criteria["flightArrivalCityCountryName_" + (i + 1)].indexOf(
							","
						)
					);
				}
				return destinationsArray;
		}
	}

	getDates(offer) {
		switch (offer.search_criteria.tripType) {
			case "rt":
				return [
					offer.search_criteria.departureDate_1,
					offer.search_criteria.departureDate_2,
				];

			case "ow":
				return [offer.search_criteria.departureDate_1];

			case "md":
				let datesArray = [];
				for (let i = 0; i < offer.search_criteria.bounds; i++) {
					datesArray[i] = offer.search_criteria["departureDate_" + (i + 1)];
				}
				return datesArray;
		}
	}

	goToFlightAvailability(fOffer) {
		this.flightAvailabilityRouteParams = cloneDeep(fOffer.search_criteria);
	}

	isArabic() {
		return this.baseService.isArabic();
	}
}
