import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ANALYTICS_B2B_URL } from "./urls_b2b";

@Injectable()
export class AnalyticsService {
    constructor(private baseService: BaseService) {
    }
    getFlightAnalytics(firstDay = undefined, lastDay = undefined) {
        let url = ANALYTICS_B2B_URL + 'flight-analytics';

        if(firstDay != undefined && lastDay != undefined){
            url = url + `?first_day=${firstDay}&last_day=${lastDay}`
        }
        
        return this.baseService.tokenGetRequest(url);
    }
    getHotelAnalytics(firstDay = undefined, lastDay = undefined) {
        let url = ANALYTICS_B2B_URL + 'hotel-analytics';

        if(firstDay != undefined && lastDay != undefined){
            url = url + `?first_day=${firstDay}&last_day=${lastDay}`
        }
        
        return this.baseService.tokenGetRequest(url);
    }
    
    getVisaAnalytics(firstDay = undefined, lastDay = undefined) {
        let url = ANALYTICS_B2B_URL + 'visa-analytics';

        if(firstDay != undefined && lastDay != undefined){
            url = url + `?first_day=${firstDay}&last_day=${lastDay}`
        }

        return this.baseService.tokenGetRequest(url);
    }
    
    getInsuranceAnalytics(firstDay = undefined, lastDay = undefined) {
        let url = ANALYTICS_B2B_URL + 'insurance-analytics';

        if(firstDay != undefined && lastDay != undefined){
            url = url + `?first_day=${firstDay}&last_day=${lastDay}`
        }

        return this.baseService.tokenGetRequest(url);
    }
}