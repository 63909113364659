import { Component, OnDestroy, OnInit } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { ShareVars } from "@app/shared/services/share.vars";

import { NavigationService } from "@app/shared/services/navigation.service";
import { InvoiceService } from "@app/shared/services/invoice.service";
import { Subscription } from "rxjs";
@Component({
	selector: "app-invoice",
	templateUrl: "./invoice.component.html",
	styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent implements OnInit, OnDestroy {
	page = 1;
	perPage = 10;
	pagesCount = 1;
	total = 0;
	numbers = [];
	invoices = [];

	invoiceSubscription: Subscription;

	constructor(
		private baseService: BaseService,
		private navigationService: NavigationService,
		private invoiceService: InvoiceService
	) {
		ShareVars.isBusy = false;
	}

	ngOnDestroy(): void {
		this.invoiceSubscription.unsubscribe();
	}

	ngOnInit() {
		this.invoiceSubscription = this.invoiceService.dataSubscription.subscribe((invoices) => {
			this.numbers = [];
			this.invoices = invoices.invoices.data;
			this.total = invoices.invoices.total;

			let x = this.total;
			let y = this.perPage;
			this.pagesCount = (x - (x % y)) / y;
			if (this.pagesCount == 0) {
				this.pagesCount = 1;
			}
			for (let i = 0; i < this.pagesCount; i++) {
				this.numbers.push(i);
			}
		});

		this.getAllInvoices();
	}

	getAllInvoices() {
		this.invoiceService.getAll(this.page, this.perPage);
	}

	formatPrice(val: number): string {
		return this.baseService.formatPrice(val);
	}
	formatDate(val: string): string {
		return this.baseService.formatDates(val);
	}

	openInvoice(invoice) {
		this.invoiceService.readInvoice(invoice.id);
		this.navigationService.goToInvoiceDetailsPage(invoice.invoice_number);
	}
}
