export class VisaPassengerModel {
	first_name: string;
	last_name: string;
	birth_date: Date | string;
	birth_place: string;
	passport_number: number;
	passport_date: Date | string;
	passport_exp_date: Date | string;
	email?: string;
	phone?: string;
	documents;

	constructor() {
		this.first_name = null;
		this.last_name = null;
		this.birth_date = null;
		this.birth_place = null;
		this.passport_number = null;
		this.passport_date = null;
		this.passport_exp_date = null;
		this.email = null;
		this.phone = null;
		this.documents = null;
	}
}
