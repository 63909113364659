import { environment } from "../../../environments/environment";

export const BASE_URL = environment.serverURL;
export const API_URL = BASE_URL + "/api";
export const DEPARTEMENTS_API_URL = "/common/statics/department";
export const TOWNS_API_URL = API_URL + "/common/statics/town";
export const COUNTRIES_API_URL = "api/localization/country";
export const POINT_OF_SALE_API_URL = API_URL + "/b2c/point-of-sale";
export const DEPARTMENTS_OF_SALE_API_URL = API_URL + "/b2c/departments-of-sale";
export const POINT_OF_SALE_IN_DEPARTMENT_API_URL = API_URL + "/b2c/point-of-sale-in-department";
export const DISTINATIONS_API_URL = API_URL + "/common/statics/package-destinations";
export const PACKAGES_AVAILABILITY_API_URL = API_URL + "/b2c/package/booking/availability";
export const AIRPORT_AUTOCOMPLETE_API_URL = API_URL + "/common/statics/airportAutoComplete";

export const HOTEL_TOP_DESTINATIONS_URL = API_URL + "/b2c/hotel/topDestinations";
export const HOTEL_OFFERS_B2C_URL = API_URL + "/b2c/hotel/offer";

export const CURRENT_PLATFORM_API_URL = API_URL + "/common/statics/platform";

export const HOTEL_AUTOCOMPLETE_API_URL = API_URL + "/common/statics/cityAutocomplete";
export const HOTEL_AVAILABILITY_API_URL = API_URL + "/b2c/hotel/availability";
export const HOTEL_CHECK_RATES_API_URL = API_URL + "/b2c/hotel/checkRates";
export const HOTEL_CONFIRMATION_API_URL = API_URL + "/b2c/hotel/confirmation";
export const HOTEL_CHECK_BOOKING_WITH_REF = API_URL + "/b2c/hotel/check";
export const LOGIN_API_URL = API_URL + "/b2c/user/login";
export const USER_API_URL = API_URL + "/b2c/user";
export const SIGNUP_API_URL = USER_API_URL;
export const PACKAGE_BOOKING_CONFIRMATION_API_URL = API_URL + "/b2c/package/booking/confirmation";
export const FLIGHT_AVAILABILITY_API_URL = API_URL + "/b2c/flight/availability";
export const FLIGHT_CALENDAR_URL = API_URL + "/b2c/flight/calendar";
export const FLIGHT_CONFIRMATION_API_URL = API_URL + "/b2c/flight/confirmation";
export const FLIGHT_DELIVERY_CONFIRMATION_API_URL = API_URL + "/b2c/flight/delivery-confirmation";
export const FLIGHT_AIR_VALIDATE_API_URL = API_URL + "/b2c/flight/airvalidate";
export const FLIGHT_FARE_CONDITIONS_API_URL = API_URL + "/b2c/flight/fare";
export const FLIGHT_ETICKET_MINI_RULES_API_URL = API_URL + "/b2c/flight/getMiniRulesFromEticket";
export const FLIGHT_ETICKET_PNR_URL = API_URL + "/b2c/flight/getTicket";
export const USER_BOOKINGS_API_URL = API_URL + "/b2c/user/booking";
export const USER_UPDATE_PW_API_URL = API_URL + "/b2c/user/change-password";
export const CONFIRM_B2C_ACCOUNT = API_URL + "/b2c/user/confirm";
export const CONFIRM_ORDER_PAYMENT_API_URL = API_URL + "/common/payment/confirmOrder";
export const B2C_FORGET_PASSWORD = API_URL + "/b2c/user/generateResetLink";
export const B2C_RESET_PASSWORD = API_URL + "/b2c/user/resetPassword";
export const UPDATE_USER_API_URL = API_URL + "/b2c/user";
export const UPDATE_USER_PASSWORD_API_URL = API_URL + "/b2c/user/change-password";

export const STATIC_FILES_URL = "https://static.algebratec.com/";
export const STATIC_API_URL = "https://static-api.algebratec.com/";

export const HOTEL_DEFAULT_IMG = "/assets/common/images/hotel_default_image.jpg";

export const AIRLINE_LOGO_URL = "http://static.algebratec.com/assets/logos/airline/";
export const FLIGHT_PRINT_BOOKING_B2C = API_URL + "/b2c/flight/printTickets";
export const HOTEL_PRINT_BOOKING_B2C = API_URL + "/b2c/hotel/printVoucher";
export const PACKAGE_PRINT_BOOKING_B2C = API_URL + "/b2c/package/booking/printVoucher";
export const FLIGHT_SEND_BOOKING_BYEMAIL_B2C_URL = API_URL + "/b2c/flight/sendByEmail";
export const HOTEL_SEND_BOOKING_BYEMAIL_B2C_URL = API_URL + "/b2c/hotel/sendByEmail";
export const PACKAGE_SEND_BOOKING_BYEMAIL_B2C_URL = API_URL + "/b2c/package/booking/sendByEmail";

export const URL_PARAMS = {
	USERNAME: "username",
	PASSWORD: "password",
	ACTIVATION_CODE: "authentication_code",
	REMEMBER_ME: "remember_me",
	LASTNAME: "lastname",
	FIRSTNAME: "firstname",
	PHONE_NUMBER: "phone_number",
	ADDRESS: "address",
	EMAIL: "email",
	TERM: "term",
	OLD_PWD: "old_password",
	NEW_PWD: "new_password",
};

export class UrlBuilder {
	private url;

	constructor(baseUrl) {
		this.url = baseUrl;
	}

	addParam(paramName, paramValue): UrlBuilder {
		let temp = this.url;
		temp = temp + (temp.indexOf("?") === -1 ? "?" : "&") + paramName + "=" + paramValue;
		this.url = temp;
		return this;
	}

	getUrl() {
		return this.url;
	}
}
