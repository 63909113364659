import { Component, OnInit } from "@angular/core";
import { BaseService } from "../../services/base.service";
import { HotelService } from "../../services/hotel.service";
import { NavigationService } from "../../services/navigation.service";
import { environment } from "environments/environment";
import { Subscription } from "rxjs";
@Component({
	selector: "app-hotel-recommendations",
	templateUrl: "./hotel-recommendations.component.html",
	styleUrls: ["./hotel-recommendations.component.scss"],
})
export class HotelRecommendationsComponent implements OnInit {
	recommendedSubscription: Subscription;
	recommendations: any;
	staticURL = "";
	env;

	constructor(
		private navigationService: NavigationService,
		private hotelService: HotelService,
		private baseService: BaseService
	) {
		this.env = environment;
	}

	ngOnInit() {
		this.staticURL = environment.staticApi;
		this.recommendedSubscription = this.hotelService
			.getRecommendedHotels()
			.subscribe((data) => {
				this.recommendations = data;
			});
	}
	ngOnDestroy() {
		if (this.recommendedSubscription) {
			this.recommendedSubscription.unsubscribe();
		}
	}

	isArabic() {
		return this.baseService.isArabic();
	}
	getStars(stars) {
		let stars_num = this.hotelService.getStars(stars);
		let stars_arr = [];
		for (let i = 0; i < stars_num; i++) {
			stars_arr.push(i);
		}
		return stars_arr;
	}

	getStarsCount(stars) {
		return this.hotelService.getStars(stars)
	}

	formatPrice(price: number) {
		return this.baseService.formatPrice(price)
	}

	goToOffer(recommendation_id: number | string) {
		const rec = this.recommendations.find((r) => r.id == recommendation_id)
		if (!rec) return

		const params = rec['search_criteria']

		let a_nbr = 0
		let c_nbr = 0

		const queryParams = {}

		params.rooms.forEach((room, i) => {
			let a = parseInt(room['number_of_adults'])
			let c = parseInt(room['number_of_children'])
			a_nbr += a
			c_nbr += c
			queryParams["adults_nbr_" + (i + 1)] = a;
			queryParams["child_nbr_" + (i + 1)] = c;
		});

		queryParams["checkIn"] = params['checkin'].split(' ')[0]
		queryParams["checkOut"] = params['checkout'].split(' ')[0]

		queryParams["hotelObj"] = {
			id: parseInt(params.location_id),
			description: rec.location
		}

		queryParams["hotelDeparture"] = rec.location;
		queryParams["hotelDepartureID"] = parseInt(params.location_id);

		queryParams["nights"] = params['nights']
		queryParams["rooms"] = params['rooms'].length
		queryParams["adult_total"] = a_nbr;
		queryParams["child_total"] = c_nbr;
		queryParams["totalProviders"] = params.providers.length;
		queryParams["customer_nationality"] = 'DZA-DZ'
		for (let i = 0; i < params.providers.length; i++) {
			queryParams["providers_" + (i + 1)] = {code: params.providers[i]};
		}

		this.hotelService.removeAllData();

		this.hotelService.setHotelBookingHotelSearchParams(queryParams)
		this.hotelService.saveHotelSearchParams(JSON.stringify(queryParams));

		this.navigationService.goToHotelAvailabilityPage()
	}
}
