export class FlightPassengerInfantModel {
	civility: string;
	firstName: string;
	middleName: string;
	lastName: string;
	nationality: string;
	birthDate: string;
	passportNumber: number;
	passportExpDate: string;

	constructor() {
		this.civility = "Mr";
		this.firstName = null;
		this.middleName = null;
		this.lastName = null;
		this.nationality = "DZA-DZ";
		this.birthDate = null;
		this.passportNumber = null;
		this.passportExpDate = null;
	}
}
