import { Component, OnInit, Input } from "@angular/core";
import { HotelService } from "../../services/hotel.service";
import { BaseService } from "../../services/base.service";

@Component({
	selector: "app-fare-hotel",
	templateUrl: "./fare-hotel.component.html",
	styleUrls: ["./fare-hotel.component.scss"],
})
export class FareHotelComponent implements OnInit {
	@Input() hotel;

	selectedRoom = new Array();
	selectedBoard = new Array();
	selectedPrice = new Array();
	selectedRates = new Array();
	selectedPersons = new Array();
	supplements = new Array();
	selectedSupplement = null;
	totalPrice = null;
	totalPriceCurrency = null;
	cancelPolicy;

	constructor(
		private hotelService: HotelService,
		private baseService: BaseService
	) {}

	ngOnInit() {
		if (this.hotel) {
			this.selectedRates = this.hotelService.getHotelBookingHotelRates();

			this.hotelService.supplementsArray = [];
			this.totalPrice = this.hotelService.getHotelBookingHotelTotalPrice();
			this.totalPriceCurrency = this.hotelService.getHotelBookingHotelPriceCurrency();
			this.cancelPolicy = this.hotelService.getHotelBookingCancellationPolicyCombinedRooms();
			if (this.hotel.combinedRooms == false) {
				let rateIndex = 1;
				if (this.selectedRates.length > 0) {
					this.selectedRates.forEach((rate) => {
						if (rate["rateObject"]["supplements"]) {
							rate["rateObject"]["supplements"].forEach((supplement) => {
								if (supplement["SuppIsMandatory"]) {
									this.hotelService.supplementsArray.push({
										supplement: supplement,
										rateIndex: rateIndex,
									});
								}
							});
						}
						rateIndex++;
					});
				}
			}

			// this.selectedRatesObject[0]['supplements'].push({
			//   Price: "30.00",
			//   SuppChargeType: "Addition",
			//   SuppID: 2,
			//   SuppIsMandatory: false,
			//   SuppName: "pool",
			//   Type: "PerStaySupplement",
			// });
		}
	}

	showSupplements(): boolean {
		this.selectedRates.forEach((rate) => {
			if (rate["rateObject"]["supplements"]) {
				if (rate["rateObject"]["supplements"].length === 0) {
					// console.log(rate["rateObject"]['supplements'].length);
					return false;
				}
			} else {
				return false;
			}
		});

		return this.selectedRates.length > 0;
	}

	showCancelPolicy() {
		return true;
	}

	isArabic() {
		return this.baseService.isArabic();
	}

	formatPrice(price: number): string {
		return this.baseService.formatPrice(price);
	}

	checkSupplement(element, supplement, rateIndex): boolean {
		return (
			element["supplement"]["SuppID"] === supplement["SuppID"] &&
			element["rateIndex"] === rateIndex
		);
	}

	addSupplement(event, supplement, rateIndex): void {
		if (event.target.checked) {
			this.hotelService.supplementsArray.push({
				supplement: supplement,
				rateIndex: rateIndex + 1,
			});
			if (!supplement["SuppIsMandatory"] && supplement["SuppChargeType"] === "Addition") {
				this.totalPrice = this.totalPrice + Number(supplement["Price"]);
			}
		} else {
			this.hotelService.supplementsArray = this.hotelService.supplementsArray.filter(
				(el) => !this.checkSupplement(el, supplement, rateIndex + 1)
			);
			if (!supplement["SuppIsMandatory"] && supplement["SuppChargeType"] === "Addition") {
				this.totalPrice = this.totalPrice - Number(supplement["Price"]);
			}
		}
	}
}
