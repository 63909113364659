import { Component, OnInit, Input } from "@angular/core";
import { FlightService } from "../../../shared/services/flight.service";
import { BaseService } from "../../../shared/services/base.service";
import { cloneDeep } from "lodash";
import { DateService } from "../../../shared/services/date.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-flight-b2b-review",
	templateUrl: "./flight-b2b-review.component.html",
	styleUrls: ["./flight-b2b-review.component.scss"],
})
export class FlightB2bReviewComponent implements OnInit {
	@Input() object;

	clientInfos;
	adultsInfosArray;
	infantsInfosArray;
	childrenInfosArray;
	youthInfosArray;
	seniorInfosArray;
	infantSeatInfosArray;
	onholdbooking;
	corporate;
	userType;
	infantNumber;
	extraFields;

	constructor(
		private flightService: FlightService,
		private baseService: BaseService,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		this.userType = this.baseService.b2bUserType;
		this.infantNumber = this.flightService.flightVars.nrOfInfant;
		this.getPaxData();
		this.extraFields = this.flightService.getExtraFieldsArray();
	}

	getPaxData() {
		this.clientInfos = cloneDeep(this.flightService.clientInfos);
		this.adultsInfosArray = cloneDeep(this.flightService.adultsInfosArray);
		this.infantsInfosArray = cloneDeep(this.flightService.infantsInfosArray);
		this.childrenInfosArray = cloneDeep(this.flightService.childrenInfosArray);
		this.youthInfosArray = cloneDeep(this.flightService.youthsInfoArray);
		this.seniorInfosArray = cloneDeep(this.flightService.seniorInfoArray);
		this.infantSeatInfosArray = cloneDeep(this.flightService.infantsSeatInfoArray);

		this.onholdbooking = this.flightService.onholdbooking;
		this.corporate = this.flightService.corporate;
	}

	getDate(dateObj): string {
		return dateObj ? DateService.toStringDate(dateObj) : "";
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}
}
