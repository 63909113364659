import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import {
	AGENT_B2B_URL,
	DELETE_AGENT_B2B_URL,
	ADD_AGENT_B2B_URL,
	UPDATE_AGENT_B2B_URL,
} from "./urls_b2b";
import { UrlBuilder } from "./urls_b2c";

@Injectable()
export class AgentService {
	constructor(private baseService: BaseService) {}

	getAgents() {
		return this.baseService.tokenGetRequest(AGENT_B2B_URL);
	}

	addAgent(agent) {
		return this.baseService.tokenPostRequest(ADD_AGENT_B2B_URL, agent);
	}

	updateAgent(agent) {
		return this.baseService.tokenPutRequest(UPDATE_AGENT_B2B_URL, agent);
	}

	deleteAgent(agent) {
		let urlBuilder = new UrlBuilder(DELETE_AGENT_B2B_URL + agent["username"])
			.addParam("active", agent["active"])
			.addParam("activity_status", agent["activity_status"])
			.addParam("agency_id", agent["agency_id"])
			.addParam("agency_name", agent["agency_name"])
			.addParam("auth_to_login_from_outside", agent["auth_to_login_from_outside"])
			.addParam("avatar", agent["avatar"])
			.addParam("first_name", agent["first_name"])
			.addParam("last_name", agent["last_name"])
			.addParam("name", agent["name"])
			.addParam("password_reset", agent["password_reset"])
			.addParam("phone_number", agent["phone_number"])
			.addParam("preferred_lang", agent["preferred_lang"])
			.addParam("role_id", agent["role_id"])
			.addParam("type", agent["type"]);

		for (let i = 0; i < agent["authorizations"].length; i++) {
			urlBuilder.addParam("authorizations", agent["authorizations"][i]);
		}

		for (let i = 0; i < agent["product_authorizations"].length; i++) {
			urlBuilder.addParam("product_authorizations", agent["product_authorizations"][i]);
		}

		return this.baseService.tokenDeleteRequest(urlBuilder.getUrl());
	}
}
