import { Component, OnInit, Input } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { CharterSearchService } from "@app/shared/services/charter-search.service";

@Component({
	selector: "app-fare-charter",
	templateUrl: "./fare-charter.component.html",
	styleUrls: ["./fare-charter.component.scss"],
})
export class FareCharterComponent implements OnInit {
	@Input() charter;

	constructor(
		private baseService: BaseService,
		private charterService: CharterSearchService
	) {}

	ngOnInit() {
		if (this.charter) {
			console.log(this.charter);
		}
	}

	isArabic() {
		return this.baseService.isArabic();
	}

	formatPrice(price: number): string {
		return this.baseService.formatPrice(price);
	}
}
