import { Component, OnInit } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { HotelService } from "@app/shared/services/hotel.service";
import { ShareVars } from "@app/shared/services/share.vars";
import { environment } from "environments/environment";

@Component({
	selector: "app-tree-search",
	templateUrl: "./tree-search.component.html",
	styleUrls: ["./tree-search.component.scss"],
})
export class TreeSearchComponent implements OnInit {
	countries = [];
	cities = [];
	provinces = [];
	city = "";
	country = "";
	province = "";
	citiesStatus = "closed";
	countriesStatus = "closed";
	zonesStatus = "closed";
	provincesStatus = "closed";
	continent = "";
	continentsCode = [
		{
			continent: "africa",
			code: "JPC000003",
		},
		{
			continent: "asia",
			code: "JPC000001",
		},
		{
			continent: "europe",
			code: "JPC000002",
		},
		{
			continent: "namerica",
			code: "JPC000005",
		},
		{
			continent: "samerica",
			code: "JPC000006",
		},
		{
			continent: "oceania",
			code: "JPC000004",
		},
	];
	zone = "";
	zones = [];
	headers;
	token;
	noSubCities = false;
	results = [];
	url = "";
	isbusy = true;
	isbusyContinent = true;

	constructor(
		private baseService: BaseService,
		private hotelService: HotelService
	) {
		this.token = this.baseService.getToken();
		this.headers = this.baseService.createRequestHeaders(this.token);
		this.url = environment.serverURL + "/api/common/statics/getLocations";
	}

	ngOnInit() {
		this.getZone("").subscribe((data) => {
			this.continentsCode = data["locations"];
			this.isbusyContinent = false;
			this.isbusy = false;
		});
	}

	getZone(code) {
		this.isbusy = true;
		return this.baseService.getRequestCode(this.url + "?code=" + code, this.headers);
	}

	selectCountry(country) {
		this.country = country;
		let code = this.country["code"];
		this.getZone(code).subscribe((data) => {
			this.provinces = data["locations"];
			if (data.length == 0) {
				this.selectDeparture(this.country);
				this.getProviders(country["name"]);
			}
			this.isbusy = false;
			this.countriesStatus = "open";
			this.changeCountriesStatus();
		});
	}

	getProviders(zone) {
		this.hotelService.getAutocompleteHotel(zone).subscribe(
			(data: any) => {
				console.log(zone);
				console.log(data["providers"]);
				ShareVars.treeProviders = data["providers"];
				this.closeTreeSearch();
			},
			(error) => {
				console.error(error);
			}
		);
	}

	selectProvince(province) {
		console.log(province);
		this.province = province;
		let code = this.province["code"];
		this.getZone(code).subscribe((data) => {
			this.cities = data["locations"];
			if (data.length == 0) {
				this.selectDeparture(this.province);
				this.getProviders(province["name"]);
				//this.closeTreeSearch();
			}
			this.isbusy = false;
			this.countriesStatus = "open";
			this.changeCitiesStatus();
		});
	}

	selectCity(city) {
		console.log(city);
		this.city = city;
		let code = this.city["code"];
		this.getZone(code).subscribe((data) => {
			this.zones = data["locations"];
			if (data.length == 0) {
				this.selectDeparture(this.city);
				this.getProviders(city["name"]);
				//this.closeTreeSearch();
			}
			this.isbusy = false;
			this.zonesStatus = "closed";
			this.changeZonesStatus();
		});
	}

	selectZone(zone) {
		console.log(zone);
		this.zone = zone;
		let code = this.zone["code"];
		this.selectDeparture(this.zone);
		this.getProviders(zone["name"]);
		//this.closeTreeSearch();
	}

	selectDeparture(e) {
		console.log("22222", e);
		let element = {
			description: e["name"] + " - " + this.country["name"],
			descriptionEn: e["name"] + " - " + this.country["name"],
			id: Number(e["id"]),
		};
		console.log(element);
		ShareVars.treeSearchDeparture = element;
	}

	changeCitiesStatus() {
		this.citiesStatus = this.citiesStatus == "closed" ? "open" : "closed";
		this.provincesStatus = this.citiesStatus == "open" ? "closed" : "open";
		this.countriesStatus = "closed";
		this.zonesStatus = "closed";
	}

	changeCountriesStatus() {
		this.countriesStatus = this.countriesStatus == "closed" ? "open" : "closed";
		this.provincesStatus = this.countriesStatus == "open" ? "closed" : "open";
		this.citiesStatus = "closed";
		this.zonesStatus = "closed";
	}

	changeProvincesStatus() {
		this.provincesStatus = this.provincesStatus == "closed" ? "open" : "closed";
		this.countriesStatus = this.provincesStatus == "open" ? "closed" : "open";
		this.citiesStatus = "closed";
		this.zonesStatus = "closed";
	}

	changeZonesStatus() {
		this.zonesStatus = this.zonesStatus == "closed" ? "open" : "closed";
		this.citiesStatus = this.zonesStatus == "open" ? "closed" : "open";
		this.countriesStatus = "closed";
		this.provincesStatus = "closed";
	}

	chooseContinent(continent) {
		this.continent = continent;
		let code = this.continentsCode.filter((c) => {
			if (c["name"] == continent) return c;
		})[0]["code"];

		this.getZone(code).subscribe((data) => {
			this.isbusy = false;
			this.countries = data["locations"];
			this.countriesStatus = "open";
			this.citiesStatus = "closed";
			this.zonesStatus = "closed";
		});
	}

	closeTreeSearch() {
		ShareVars.usingTreeSearch = true;
		ShareVars.showTreeSearch = false;
	}
}
