import { Component, OnInit, Input, Output, EventEmitter, HostListener } from "@angular/core";
import { BaseService } from "../../services/base.service";

@Component({
	selector: "app-shared-commun-dropdown",
	templateUrl: "./shared-commun-dropdown.component.html",
	styleUrls: ["./shared-commun-dropdown.component.scss"],
})
//TODO: the dropdown should be generic, we should not put specific logic inside, to be refactored
export class SharedCommunDropdownComponent implements OnInit {
	@Input() mainButtonID: string;
	@Input() mainButtonText: string;
	@Input() dropdownAriaID: string;
	@Input() ariaButtonsID: [];
	@Input() ariaButtonsText: [];
	@Input() ariaButtonsTextTranslation: [];
	@Input() dropdownTypeString: boolean = false;
	@Input() useShowDropdownMenu: boolean = false;
	@Input() isDisabled: boolean = false;
	@Input() searchSource: string;
	@Input() addSearch: boolean = false;

	@Output() clickEvent = new EventEmitter();
	@Output() keepOpened = new EventEmitter();
	@Input() backendFilter: boolean = false;
	@Output() backendFilterEvent = new EventEmitter();

	@Output() initDropdown = new EventEmitter();

	showDropdownMenu = false;
	mainWrapperID: string = "main-wrapper-dpd";
	searchData: string = null;

	constructor(private baseService: BaseService) {}

	ngOnInit() {
		this.mainWrapperID += this.mainButtonID;

		if (this.useShowDropdownMenu) {
			this.showDropdownMenu = false;
		}

		this.initDropdown.emit(this.mainButtonID);
	}

	sendEvent(value, index) {
		this.mainButtonText = value;

		switch (this.searchSource) {
			case "flight":
			case "flight-b2b":
			case "flight_availability":
				//TODO this mapping should be done using a dictionnary
				switch (value) {
					//TODO find an other way more generic to emit a value of the dropdown
					case "ECONOMY_STANDARD":
						this.clickEvent.emit(0);
						break;

					case "ECONOMY":
						this.clickEvent.emit(1);
						break;

					case "ECONOMY_PREMIUM":
						this.clickEvent.emit(2);
						break;

					case "BUSINESS":
						this.clickEvent.emit(3);
						break;

					case "FIRST":
						this.clickEvent.emit(4);
						break;

					case "MULTIPLE":
						this.clickEvent.emit(5);
						break;
				}
				break;

			case "agency-panel":
			case "hotel_availability_rooms":
			case "use_index":
				this.clickEvent.emit(index);
				break;

			default:
				this.clickEvent.emit(value);
				break;
		}

		this.showDropdownMenu = false;
	}

	openDropdown() {
		this.keepOpened.emit();

		if (this.useShowDropdownMenu) {
			this.showDropdownMenu = true;
		}

		let elm0: HTMLElement = document.getElementById(this.mainWrapperID) as HTMLElement;
		elm0.classList.add("show");
		elm0.classList.add("dropdown");

		let elm1: HTMLElement = document.getElementById(this.mainButtonID) as HTMLElement;
		elm1.focus();

		if (this.useShowDropdownMenu) {
			setTimeout(() => {
				let elm2: HTMLElement = document.getElementById(this.dropdownAriaID) as HTMLElement;
				if (elm2) {
					elm2.classList.add("show");
				}
			}, 10);
		} else {
			let elm2: HTMLElement = document.getElementById(this.dropdownAriaID) as HTMLElement;
			elm2.classList.add("show");
		}
	}

	closeDropdown() {
		setTimeout(() => {
			this.closeDPD();
		}, 300);
	}

	closeDPD() {
		let elm1: HTMLElement = document.getElementById(this.mainButtonID) as HTMLElement;
		if (elm1) {
			elm1.blur();
		}

		let elm2: HTMLElement = document.getElementById(this.dropdownAriaID) as HTMLElement;

		if (elm2) {
			elm2.classList.remove("show");
		}

		if (this.useShowDropdownMenu) {
			setTimeout(() => {
				this.showDropdownMenu = false;
			}, 300);
		}
	}

	openDPDNew() {
		if (!this.isDisabled) {
			this.showDropdownMenu = !this.showDropdownMenu;
		}
	}

	isArabic() {
		return this.baseService.isArabic();
	}

	search(term: string): void {
		this.backendFilterEvent.emit(term);
	}
}
