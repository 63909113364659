export class FlightPassengerCommonModel {
	civility: string;
	firstName: string;
	middleName: string;
	lastName: string;
	nationality: string;
	specialServices: string;
	passportNumber: number;
	fill_after: boolean;
	passportExpDate: string;
	idCardNumber: number;
	idCardExpDate: string;
	idType: string;
	birthDate: string;
	frequentFlyerId: string

	constructor() {
		this.civility = "Mr";
		this.firstName = null;
		this.middleName = null;
		this.lastName = null;
		this.nationality = "DZA-DZ";
		this.fill_after = false;
		this.specialServices = null;
		this.passportNumber = null;
		this.passportExpDate = null;
		this.idCardNumber = null;
		this.idCardExpDate = null;
		this.idType = "ID_CARD";
		this.birthDate = null;
		this.frequentFlyerId = ""
	}
}
