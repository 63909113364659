import { Component, OnInit } from "@angular/core";
import { ShareVars } from "../../services/share.vars";

@Component({
	selector: "app-page-not-found",
	templateUrl: "./page-not-found.component.html",
	styleUrls: ["./page-not-found.component.scss"],
})
export class PageNotFoundComponent implements OnInit {
	constructor() {}

	ngOnInit() {
		ShareVars.isBusy = false;
	}
}
