export const COMMUNES = {
	Guelma: [
		{
			name: "Guelma",
			gps: ["7.45", "36.46666667"],
		},
		{
			name: "Aïn Ben Beida",
			gps: ["7.6952791", "36.6177322"],
		},
		{
			name: "Aïn Larbi",
			gps: ["7.397747", "36.2657293"],
		},
		{
			name: "Aïn Makhlouf",
			gps: ["7.2507191", "36.2434425"],
		},
		{
			name: "Aïn Reggada",
			gps: ["7.073889", "36.259444"],
		},
		{
			name: "Aïn Sandel",
			gps: ["7.5124598", "36.2446193"],
		},
		{
			name: "Belkheir",
			gps: ["7.479287", "36.460847"],
		},
		{
			name: "Ben Djerrah",
			gps: ["7.3685646", "36.432125"],
		},
		{
			name: "Beni Mezline",
			gps: ["7.603097", "36.4809322"],
		},
		{
			name: "Bordj Sabath",
			gps: ["7.04557", "36.40302"],
		},
		{
			name: "Bouhachana",
			gps: ["7.50832", "36.30626"],
		},
		{
			name: "Bouhamdane",
			gps: ["7.1111584", "36.4628488"],
		},
		{
			name: "Bouati Mahmoud",
			gps: ["7.3280954", "36.5922557"],
		},
		{
			name: "Bouchegouf",
			gps: ["7.72976", "36.47172"],
		},
		{
			name: "Boumahra Ahmed",
			gps: ["7.5149488", "36.4600531"],
		},
		{
			name: "Dahouara",
			gps: ["7.7327442", "36.3515639"],
		},
		{
			name: "Djeballah Khemissi",
			gps: ["7.56891", "36.46451"],
		},
		{
			name: "El Fedjoudj",
			gps: ["7.3996353", "36.503013"],
		},
		{
			name: "Guellat Bou Sbaa",
			gps: ["7.4751663", "36.5456994"],
		},
		{
			name: "Guelma",
			gps: ["7.433333", "36.466667"],
		},
		{
			name: "Hammam Debagh",
			gps: ["7.4411774", "36.504393"],
		},
		{
			name: "Hammam N'Bail",
			gps: ["7.64361", "36.32572"],
		},
		{
			name: "Héliopolis",
			gps: ["7.45", "36.5"],
		},
		{
			name: "Houari Boumédiène",
			gps: ["7.2862359", "36.4161972"],
		},
		{
			name: "Khezarra",
			gps: ["7.5293", "36.3694"],
		},
		{
			name: "Medjez Amar",
			gps: ["7.3105431", "36.4451755"],
		},
		{
			name: "Medjez Sfa",
			gps: ["7.7832127", "36.4338859"],
		},
		{
			name: "Nechmaya",
			gps: ["7.51331", "36.61149"],
		},
		{
			name: "Oued Cheham",
			gps: ["7.7644157", "36.3788645"],
		},
		{
			name: "Oued Fragha",
			gps: ["7.7130032", "36.5559126"],
		},
		{
			name: "Oued Zenati",
			gps: ["7.1639442", "36.3154018"],
		},
		{
			name: "Ras El Agba",
			gps: ["7.2232533", "36.3731288"],
		},
		{
			name: "Roknia",
			gps: ["7.22963", "36.5481"],
		},
		{
			name: "Sellaoua Announa",
			gps: ["7.2505689", "36.3869665"],
		},
		{
			name: "Tamlouka",
			gps: ["7.133333", "36.15"],
		},
	],
	Blida: [
		{
			name: "Blida",
			gps: ["2.833333", "36.483333"],
		},
		{
			name: "Chebli",
			gps: ["3.016667", "36.583333"],
		},
		{
			name: "Bouinan",
			gps: ["2.99194", "36.53167"],
		},
		{
			name: "Oued Alleug",
			gps: ["2.790914", "36.552982"],
		},
		{
			name: "Ouled Yaïch",
			gps: ["2.86214", "36.50393"],
		},
		{
			name: "Chréa",
			gps: ["2.87667", "36.42556"],
		},
		{
			name: "El Affroun",
			gps: ["2.621956", "36.473755"],
		},
		{
			name: "Chiffa",
			gps: ["2.75", "36.466667"],
		},
		{
			name: "Hammam Melouane",
			gps: ["3.05", "36.483333"],
		},
		{
			name: "Benkhelil",
			gps: ["2.87639", "36.62024"],
		},
		{
			name: "Soumaa",
			gps: ["2.90528", "36.51833"],
		},
		{
			name: "Mouzaia",
			gps: ["2.683333", "36.466667"],
		},
		{
			name: "Souhane",
			gps: ["3.19909", "36.51376"],
		},
		{
			name: "Meftah",
			gps: ["3.233333", "36.616667"],
		},
		{
			name: "Ouled Slama",
			gps: ["3.11006", "36.54828"],
		},
		{
			name: "Boufarik",
			gps: ["2.916667", "36.566667"],
		},
		{
			name: "Larbaa",
			gps: ["3.153934", "36.56522"],
		},
		{
			name: "Oued Djer",
			gps: ["2.55", "36.416667"],
		},
		{
			name: "Beni Tamou",
			gps: ["2.815976", "36.536467"],
		},
		{
			name: "Bouarfa",
			gps: ["2.8163194", "36.4668528"],
		},
		{
			name: "Beni Mered",
			gps: ["2.866667", "36.516667"],
		},
		{
			name: "Bougara",
			gps: ["3.0833", "36.5333"],
		},
		{
			name: "Guerouaou",
			gps: ["2.883333", "36.516667"],
		},
		{
			name: "Aïn Romana",
			gps: ["2.71165", "36.42066"],
		},
		{
			name: "Djebabra",
			gps: ["3.2705382", "36.5841631"],
		},
	],
	Saïda: [
		{
			name: "Saïda",
			gps: ["0.15", "34.83"],
		},
		{
			name: "Saïda",
			gps: ["0.15", "34.833333"],
		},
		{
			name: "Sidi Ahmed",
			gps: ["0.016667", "35.066667"],
		},
		{
			name: "Sidi Amar ",
			gps: ["0.1072", "35.02523"],
		},
		{
			name: "Sidi Boubekeur",
			gps: ["0.0569916", "35.0289421"],
		},
		{
			name: "Tircine",
			gps: ["0.5546379", "34.9013485"],
		},
		{
			name: "Youb",
			gps: ["-0.216667", "34.916667"],
		},
	],
	"Tizi Ouzou": [
		{
			name: "Tizi Ouzou",
			gps: ["4.05", "36.716667"],
		},
		{
			name: "Ain El Hammam",
			gps: ["4.309731", "36.571251"],
		},
		{
			name: "Akbil",
			gps: ["4.306949", "36.523135"],
		},
		{
			name: "Freha",
			gps: ["4.316254", "36.761991"],
		},
		{
			name: "Souamaâ",
			gps: ["0", "0"],
		},
		{
			name: "Mechtras",
			gps: ["4.004935", "36.544829"],
		},
		{
			name: "Irdjen",
			gps: ["4.149642", "36.661297"],
		},
		{
			name: "Timizart",
			gps: ["4.26667", "36.8"],
		},
		{
			name: "Makouda",
			gps: ["4.062422", "36.791973"],
		},
		{
			name: "Draâ El Mizan",
			gps: ["3.834167", "36.535556"],
		},
		{
			name: "Tizi Gheniff",
			gps: ["3.767796", "36.589068"],
		},
		{
			name: "Bounouh",
			gps: ["3.93333", "36.5"],
		},
		{
			name: "Aït Chafâa",
			gps: ["4.53358", "36.81702"],
		},
		{
			name: "Frikat",
			gps: ["3.883152", "36.518535"],
		},
		{
			name: "Beni Aïssi",
			gps: ["4.08009", "36.66205"],
		},
		{
			name: "Aït Zmenzer",
			gps: ["4.04202", "36.64775"],
		},
		{
			name: "Iferhounène",
			gps: ["4.37012", "36.533754"],
		},
		{
			name: "Azazga",
			gps: ["4.371111", "36.745278"],
		},
		{
			name: "Illoula Oumalou",
			gps: ["4.41667", "36.58333"],
		},
		{
			name: "Yakouren",
			gps: ["4.438648", "36.734757"],
		},
		{
			name: "Larbaâ Nath Irathen",
			gps: ["4.206709", "36.6366137"],
		},
		{
			name: "Tizi Rached",
			gps: ["4.19176", "36.67176"],
		},
		{
			name: "Zekri",
			gps: ["4.553146", "36.776292"],
		},
		{
			name: "Ouaguenoun",
			gps: ["4.174654", "36.769965"],
		},
		{
			name: "Aïn Zaouia",
			gps: ["3.89416", "36.54828"],
		},
		{
			name: "Imkiren",
			gps: ["3.792488", "36.625249"],
		},
		{
			name: "Aït Yahia",
			gps: ["4.3302536", "36.5811332"],
		},
		{
			name: "Aït Mahmoud",
			gps: ["3.993187", "36.508394"],
		},
		{
			name: "Mâatkas",
			gps: ["3.98778", "36.612083"],
		},
		{
			name: "Aït Boumahdi",
			gps: ["4.200032", "36.500943"],
		},
		{
			name: "Abi Youcef",
			gps: ["4.33342", "36.551293"],
		},
		{
			name: "Beni Douala",
			gps: ["4.066667", "36.616667"],
		},
		{
			name: "Illilten",
			gps: ["4.40429", "36.50796"],
		},
		{
			name: "Bouzeguène",
			gps: ["4.4798498", "36.6167048"],
		},
		{
			name: "Aït Aggouacha",
			gps: ["4.232488", "36.6178136"],
		},
		{
			name: "Ouadhia",
			gps: ["4.083333", "36.55"],
		},
		{
			name: "Azeffoun",
			gps: ["4.420366", "36.896096"],
		},
		{
			name: "Tigzirt",
			gps: ["4.12255", "36.89298"],
		},
		{
			name: "Aït Aïssa Mimoun",
			gps: ["4.136482", "36.7602263"],
		},
		{
			name: "Boghni",
			gps: ["3.9556355", "36.541133"],
		},
		{
			name: "Ifigha",
			gps: ["4.410152", "36.670484"],
		},
		{
			name: "Aït Oumalou",
			gps: ["4.2219826", "36.6516241"],
		},
		{
			name: "Tirmitine",
			gps: ["3.984776", "36.661809"],
		},
		{
			name: "Akerrou",
			gps: ["4.42056", "36.79917"],
		},
		{
			name: "Yatafen",
			gps: ["4.256086", "36.552224"],
		},
		{
			name: "Ath Zikki",
			gps: ["4.50535", "36.56493"],
		},
		{
			name: "Draâ Ben Khedda",
			gps: ["3.955593", "36.734895"],
		},
		{
			name: "Aït Ouacif",
			gps: ["4.21667", "36.53333"],
		},
		{
			name: "Idjeur",
			gps: ["4.5186453", "36.6664284"],
		},
		{
			name: "Mekla",
			gps: ["4.2681185", "36.6876391"],
		},
		{
			name: "Tizi N'Tleta",
			gps: ["4.05712", "36.54569"],
		},
		{
			name: "Aït Yenni",
			gps: ["4.2076442", "36.5751916"],
		},
		{
			name: "Aghribs",
			gps: ["4.322691", "36.802206"],
		},
		{
			name: "Iflissen",
			gps: ["4.25", "36.86667"],
		},
		{
			name: "Boudjima",
			gps: ["4.1587979", "36.8139801"],
		},
		{
			name: "Aït Yahia Moussa",
			gps: ["3.888431", "36.641151"],
		},
		{
			name: "Souk El Thenine",
			gps: ["4.008551", "36.594099"],
		},
		{
			name: "Aït Khellili",
			gps: ["4.3083865", "36.6579226"],
		},
		{
			name: "Sidi Namane",
			gps: ["3.9839728", "36.7581485"],
		},
		{
			name: "Iboudraren",
			gps: ["4.239542", "36.518311"],
		},
		{
			name: "Agouni Gueghrane",
			gps: ["4.1366694444444", "36.492402777778"],
		},
		{
			name: "Mizrana",
			gps: ["4.096183", "36.845126"],
		},
		{
			name: "Imsouhel",
			gps: ["4.38786", "36.56926"],
		},
		{
			name: "Tadmaït",
			gps: ["3.901863", "36.742736"],
		},
		{
			name: "Aït Bouaddou",
			gps: ["4.016667", "36.5"],
		},
		{
			name: "Assi Youcef",
			gps: ["4.01855", "36.507084"],
		},
		{
			name: "Aït Toudert",
			gps: ["4.185106", "36.520053"],
		},
		{
			name: "Tizi Ouzou",
			gps: ["4.05", "36.716667"],
		},
		{
			name: "Tizi Rached",
			gps: ["4.19176", "36.67176"],
		},
		{
			name: "Yakouren",
			gps: ["4.438648", "36.734757"],
		},
		{
			name: "Yatafen",
			gps: ["4.256086", "36.552224"],
		},
		{
			name: "Zekri",
			gps: ["4.553146", "36.776292"],
		},
	],
	Bouira: [
		{
			name: "Bouira",
			gps: ["3.88333", "36.36666"],
		},
		{
			name: "Chorfa",
			gps: ["4.3307", "36.3617"],
		},
		{
			name: "Dechmia",
			gps: ["3.57656", "36.13002"],
		},
		{
			name: "Dirrah",
			gps: ["3.7533546", "36.0003161"],
		},
		{
			name: "Djebahia",
			gps: ["3.7578392", "36.4763773"],
		},
		{
			name: "El Hakimia",
			gps: ["3.7805", "36.09114"],
		},
		{
			name: "El Hachimia",
			gps: ["3.833333", "36.233333"],
		},
		{
			name: "El Adjiba",
			gps: ["4.150278", "36.325833"],
		},
		{
			name: "El Khabouzia",
			gps: ["3.60095", "36.31634"],
		},
		{
			name: "El Mokrani",
			gps: ["3.61185", "36.42501"],
		},
		{
			name: "El Asnam",
			gps: ["4.07493", "36.31216"],
		},
		{
			name: "Guerrouma",
			gps: ["3.4272194", "36.4333853"],
		},
		{
			name: "Haizer",
			gps: ["3.99917", "36.39702"],
		},
		{
			name: "Hadjera Zerga",
			gps: ["3.84937", "35.95765"],
		},
		{
			name: "Kadiria",
			gps: ["3.683333", "36.533333"],
		},
		{
			name: "Lakhdaria",
			gps: ["3.583333", "36.616667"],
		},
		{
			name: "M'Chedallah",
			gps: ["4.270935", "36.365181"],
		},
		{
			name: "Mezdour",
			gps: ["4.07058", "36.07987"],
		},
		{
			name: "Maala",
			gps: ["3.87112", "36.44261"],
		},
		{
			name: "Maamora",
			gps: ["3.62017", "35.95826"],
		},
		{
			name: "Oued El Berdi",
			gps: ["3.9189", "36.289"],
		},
		{
			name: "Ouled Rached",
			gps: ["4.11046", "36.2119"],
		},
		{
			name: "Raouraoua",
			gps: ["3.64704", "36.23546"],
		},
		{
			name: "Ridane",
			gps: ["3.46208", "36.07389"],
		},
		{
			name: "Saharidj",
			gps: ["4.24858", "36.39707"],
		},
		{
			name: "Sour El Ghouzlane",
			gps: ["3.690556", "36.147222"],
		},
		{
			name: "Souk El Khemis",
			gps: ["3.63495", "36.38806"],
		},
		{
			name: "Taguedit",
			gps: ["3.99462", "36.01759"],
		},
		{
			name: "Taghzout",
			gps: ["3.95937", "36.40849"],
		},
		{
			name: "Zbarbar",
			gps: ["3.52588", "36.48473"],
		},
	],
	Tamanrasset: [
		{
			name: "Tamanrasset",
			gps: ["5.527222", "22.786944"],
		},
		{
			name: "Abalessa",
			gps: ["4.85", "22.8833"],
		},
		{
			name: "In Ghar",
			gps: ["1.904645", "27.103927"],
		},
		{
			name: "In Guezzam",
			gps: ["5.787964", "19.849394"],
		},
		{
			name: "Idles",
			gps: ["5.93333", "23.8167"],
		},
		{
			name: "Tazrouk",
			gps: ["6.264", "23.416"],
		},
		{
			name: "Tin Zaouatine",
			gps: ["2.970061", "19.95368"],
		},
		{
			name: "In Salah",
			gps: ["2.52", "27.25"],
		},
		{
			name: "In Amguel",
			gps: ["5.149315", "23.690897"],
		},
		{
			name: "Foggaret Ezzaouia",
			gps: ["2.8476477", "27.3615151"],
		},
	],
	Alger: [
		{
			name: "Alger",
			gps: ["3.05997", "36.776234"],
		},
		{
			name: "Alger",
			gps: ["3.05997", "36.776234"],
		},
		{
			name: "Aïn Benian",
			gps: ["2.921698", "36.803104"],
		},
		{
			name: "Aïn Taya",
			gps: ["3.233333", "36.783333"],
		},
		{
			name: "Alger-Centre",
			gps: ["3.05", "36.766667"],
		},
		{
			name: "Baba Hassen",
			gps: ["2.9738", "36.695"],
		},
		{
			name: "Bab El Oued",
			gps: ["3.049731", "36.790703"],
		},
		{
			name: "Bab Ezzouar",
			gps: ["3.183333", "36.716667"],
		},
		{
			name: "Bachdjerrah",
			gps: ["3.118229", "36.721721"],
		},
		{
			name: "Baraki",
			gps: ["3.091621", "36.666233"],
		},
		{
			name: "Belouizdad",
			gps: ["3.062278", "36.753542"],
		},
		{
			name: "Ben Aknoun",
			gps: ["3.009267", "36.759499"],
		},
		{
			name: "Beni Messous",
			gps: ["2.9753936", "36.7796161"],
		},
		{
			name: "Birkhadem",
			gps: ["3.066667", "36.716667"],
		},
		{
			name: "Bir Mourad Raïs",
			gps: ["3.05", "36.75"],
		},
		{
			name: "Birtouta",
			gps: ["2.998753", "36.649691"],
		},
		{
			name: "Bologhine",
			gps: ["3.042741", "36.805265"],
		},
		{
			name: "Bordj El Bahri",
			gps: ["3.233333", "36.783333"],
		},
		{
			name: "Bordj El Kiffan",
			gps: ["3.183333", "36.75"],
		},
		{
			name: "Bourouba",
			gps: ["3.106384", "36.714669"],
		},
		{
			name: "Bouzareah",
			gps: ["3.01772", "36.78988"],
		},
		{
			name: "Casbah",
			gps: ["3.060036", "36.783442"],
		},
		{
			name: "Chéraga",
			gps: ["2.95", "36.766667"],
		},
		{
			name: "Dar El Beïda",
			gps: ["3.212385", "36.714256"],
		},
		{
			name: "Dely Ibrahim",
			gps: ["2.983333", "36.75"],
		},
		{
			name: "Djasr Kasentina",
			gps: ["3.095398", "36.696916"],
		},
		{
			name: "Douera",
			gps: ["2.9448509", "36.6702433"],
		},
		{
			name: "Draria",
			gps: ["2.99774", "36.7139"],
		},
		{
			name: "El Achour",
			gps: ["2.992", "36.7338"],
		},
		{
			name: "El Biar",
			gps: ["3.029684", "36.769069"],
		},
		{
			name: "El Hammamet",
			gps: ["2.99072", "36.81322"],
		},
		{
			name: "El Harrach",
			gps: ["3.137412", "36.721239"],
		},
		{
			name: "El Madania",
			gps: ["3.065078", "36.742873"],
		},
		{
			name: "El Marsa",
			gps: ["3.239508", "36.804715"],
		},
		{
			name: "El Mouradia",
			gps: ["3.051034", "36.74792"],
		},
		{
			name: "El Magharia",
			gps: ["3.10883", "36.731438"],
		},
		{
			name: "H'raoua",
			gps: ["3.316667", "36.766667"],
		},
		{
			name: "Hussein Dey",
			gps: ["3.092008", "36.744318"],
		},
		{
			name: "Hydra",
			gps: ["3.042955", "36.744318"],
		},
		{
			name: "Khraïssia",
			gps: ["2.981019", "36.6770577"],
		},
		{
			name: "Kouba",
			gps: ["3.086474", "36.73374"],
		},
		{
			name: "Les Eucalyptus",
			gps: ["3.1450081", "36.663875"],
		},
		{
			name: "Mahelma",
			gps: ["2.8783321", "36.6828751"],
		},
		{
			name: "Mohammadia",
			gps: ["3.133333", "36.733333"],
		},
		{
			name: "Oued Koriche",
			gps: ["3.042698", "36.780692"],
		},
		{
			name: "Oued Smar",
			gps: ["3.172281", "36.703677"],
		},
		{
			name: "Ouled Chebel",
			gps: ["2.9943752", "36.5984059"],
		},
		{
			name: "Ouled Fayet",
			gps: ["2.9493141", "36.7362016"],
		},
		{
			name: "Rahmania",
			gps: ["2.9064308", "36.6807626"],
		},
		{
			name: "Raïs Hamidou",
			gps: ["3.011498", "36.81747"],
		},
		{
			name: "Réghaïa",
			gps: ["3.35", "36.733333"],
		},
		{
			name: "Rouïba",
			gps: ["3.283333", "36.733333"],
		},
		{
			name: "Saoula",
			gps: ["3.016667", "36.7"],
		},
		{
			name: "Sidi M'Hamed",
			gps: ["3.058501", "36.75753"],
		},
		{
			name: "Sidi Moussa",
			gps: ["3.083333", "36.6"],
		},
		{
			name: "Souidania",
			gps: ["2.9125592", "36.7079748"],
		},
		{
			name: "Staoueli",
			gps: ["2.890177", "36.755803"],
		},
		{
			name: "Tessala El Merdja",
			gps: ["2.947083", "36.635228"],
		},
		{
			name: "Zéralda",
			gps: ["2.85018", "36.718109"],
		},
	],
	Tlemcen: [
		{
			name: "Tlemcen",
			gps: ["-1.32", "34.89"],
		},
		{
			name: "Beni Mester",
			gps: ["-1.416667", "34.866667"],
		},
		{
			name: "Aïn Tallout",
			gps: ["-0.96667", "34.91667"],
		},
		{
			name: "Remchi",
			gps: ["-1.43333", "35.05"],
		},
		{
			name: "El Fehoul ",
			gps: ["-1.33417", "35.11778"],
		},
		{
			name: "Sabra",
			gps: ["-1.52826", "34.82738"],
		},
		{
			name: "Ghazaouet ",
			gps: ["-1.86038", "35.093858"],
		},
		{
			name: "Souani",
			gps: ["-1.9162718", "34.9212181"],
		},
		{
			name: "Djebala",
			gps: ["-1.795063", "34.976423"],
		},
		{
			name: "El Gor",
			gps: ["-1.15", "34.633333"],
		},
		{
			name: "Oued Lakhdar",
			gps: ["-1.132128", "34.876021"],
		},
		{
			name: "Aïn Fezza",
			gps: ["-1.235189", "34.877182"],
		},
		{
			name: "Ouled Mimoun",
			gps: ["-1.034689", "34.904415"],
		},
		{
			name: "Amieur",
			gps: ["-1.240082", "35.035197"],
		},
		{
			name: "Aïn Youcef",
			gps: ["-1.366667", "35.05"],
		},
		{
			name: "Zenata",
			gps: ["-1.458435", "34.98437"],
		},
		{
			name: "Beni Snous",
			gps: ["-1.56145", "34.64299"],
		},
		{
			name: "Bab El Assa",
			gps: ["-2.0317999", "34.9661057"],
		},
		{
			name: "Dar Yaghmouracene",
			gps: ["-1.800889", "35.100433"],
		},
		{
			name: "Fellaoucene",
			gps: ["-1.605892", "35.035057"],
		},
		{
			name: "Azails",
			gps: ["-1.47294", "34.680017"],
		},
		{
			name: "Sebaa Chioukh",
			gps: ["-1.355717", "35.156135"],
		},
		{
			name: "Terny Beni Hdiel",
			gps: ["-1.35812", "34.79583"],
		},
		{
			name: "Bensekrane ",
			gps: ["-1.216667", "35.066667"],
		},
		{
			name: "Aïn Nehala",
			gps: ["-0.932293", "35.027326"],
		},
		{
			name: "Hennaya",
			gps: ["-1.366667", "34.95"],
		},
		{
			name: "Maghnia",
			gps: ["-1.730547", "34.861708"],
		},
		{
			name: "Hammam Boughrara",
			gps: ["-1.639409", "34.893675"],
		},
		{
			name: "Souahlia",
			gps: ["-1.883333", "35.033333"],
		},
		{
			name: "MSirda Fouaga",
			gps: ["-2.0649491", "35.0196062"],
		},
		{
			name: "Aïn Fetah",
			gps: ["-1.638508", "34.965522"],
		},
		{
			name: "El Aricha",
			gps: ["-1.255703", "34.222661"],
		},
		{
			name: "Souk Tlata",
			gps: ["-2.000113", "35.023934"],
		},
		{
			name: "Sidi Abdelli ",
			gps: ["-1.133333", "35.066667"],
		},
		{
			name: "Sebdou",
			gps: ["-1.326944", "34.639444"],
		},
		{
			name: "Beni Ouarsous",
			gps: ["-1.557312", "35.0833235"],
		},
		{
			name: "Sidi Medjahed",
			gps: ["-1.633333", "34.783333"],
		},
		{
			name: "Beni Boussaid",
			gps: ["-1.7529879", "34.6480251"],
		},
		{
			name: "Marsa Ben M'Hidi",
			gps: ["-2.20449", "35.083394"],
		},
		{
			name: "Nedroma",
			gps: ["-1.747534", "35.013057"],
		},
		{
			name: "Sidi Djillali",
			gps: ["-1.583333", "34.466667"],
		},
		{
			name: "Beni Bahdel",
			gps: ["-1.516667", "34.716667"],
		},
		{
			name: "El Bouihi",
			gps: ["-1.63438", "34.23926"],
		},
		{
			name: "Honaïne",
			gps: ["-1.65503", "35.176483"],
		},
		{
			name: "Tienet ",
			gps: ["-1.838837", "35.049462"],
		},
		{
			name: "Ouled Riyah ",
			gps: ["-1.49727", "34.96248"],
		},
		{
			name: "Bouhlou",
			gps: ["-1.607891", "34.824994"],
		},
		{
			name: "Beni Khellad",
			gps: ["-1.557226", "35.172581"],
		},
		{
			name: "Aïn Ghoraba ",
			gps: ["-1.3891", "34.7138"],
		},
		{
			name: "Chetouane",
			gps: ["-1.289767", "34.920308"],
		},
		{
			name: "Mansourah",
			gps: ["-1.339087", "34.87099"],
		},
		{
			name: "Beni Semiel",
			gps: ["-1.02665", "34.81678"],
		},
		{
			name: "Aïn Kebira",
			gps: ["-1.675758", "35.030418"],
		},
	],
	"Oum El Bouaghi": [
		{
			name: "Oum el Bouaghi",
			gps: ["7.113611", "35.8775"],
		},
		{
			name: "Aïn Beïda",
			gps: ["7.3928", "35.7964"],
		},
		{
			name: "Aïn M'lila",
			gps: ["6.583333", "36.033333"],
		},
		{
			name: "Behir Chergui",
			gps: ["7.717896", "35.790804"],
		},
		{
			name: "El Amiria",
			gps: ["6.8822913", "36.1117033"],
		},
		{
			name: "Sigus",
			gps: ["6.783333", "36.116667"],
		},
		{
			name: "El Belala",
			gps: ["7.760468", "35.670685"],
		},
		{
			name: "Aïn Babouche",
			gps: ["7.1855736", "35.9411847"],
		},
		{
			name: "Berriche",
			gps: ["7.3768044", "35.9164425"],
		},
		{
			name: "Ouled Hamla",
			gps: ["6.466667", "36.083333"],
		},
		{
			name: "Dhalaa",
			gps: ["7.515678", "35.544798"],
		},
		{
			name: "Aïn Kercha",
			gps: ["6.699328", "35.921169"],
		},
		{
			name: "Hanchir Toumghani",
			gps: ["6.696167", "36.021891"],
		},
		{
			name: "El Djazia",
			gps: ["7.507954", "35.663154"],
		},
		{
			name: "Aïn Diss",
			gps: ["7.117209", "36.092147"],
		},
		{
			name: "Fkirina",
			gps: ["7.2986984", "35.6637817"],
		},
		{
			name: "Souk Naamane",
			gps: ["6.3895798", "35.8957253"],
		},
		{
			name: "Zorg",
			gps: ["7.4666667", "35.85"],
		},
		{
			name: "El Fedjoudj Boughrara Saoudi",
			gps: ["6.817017", "35.70805"],
		},
		{
			name: "Ouled Zouaï",
			gps: ["6.506653", "35.836185"],
		},
		{
			name: "Bir Chouhada",
			gps: ["6.2899303", "35.8943346"],
		},
		{
			name: "Ksar Sbahi",
			gps: ["7.25", "36.083333"],
		},
		{
			name: "Oued Nini",
			gps: ["7.3", "35.666667"],
		},
		{
			name: "Meskiana",
			gps: ["7.6669121", "35.6277214"],
		},
		{
			name: "Aïn Fakroun",
			gps: ["6.866667", "35.966667"],
		},
		{
			name: "Rahia",
			gps: ["7.6451111", "35.7635077"],
		},
		{
			name: "Aïn Zitoun",
			gps: ["6.935449", "35.68435"],
		},
		{
			name: "Ouled Gacem",
			gps: ["6.66578", "36.03406"],
		},
		{
			name: "El Harmilia",
			gps: ["6.619263", "35.923532"],
		},
	],
	"Sidi Bel AbbÃ¨s": [
		{
			name: "Sidi Bel Abbès",
			gps: ["-0.63333333", "35.2"],
		},
		{
			name: "Aïn Adden",
			gps: ["-0.26128", "35.32938"],
		},
		{
			name: "Aïn El Berd",
			gps: ["-0.5128384", "35.3657108"],
		},
		{
			name: "Aïn Kada",
			gps: ["-0.8558715", "35.1369491"],
		},
		{
			name: "Aïn Thrid",
			gps: ["-0.675745", "35.2845835"],
		},
		{
			name: "Aïn Tindamine",
			gps: ["-0.7204199", "34.6896511"],
		},
		{
			name: "Amarnas",
			gps: ["-0.622787", "35.135422"],
		},
		{
			name: "Badredine El Mokrani",
			gps: ["-0.850153", "35.008909"],
		},
		{
			name: "Belarbi",
			gps: ["-0.4567909", "35.1514948"],
		},
		{
			name: "Ben Badis",
			gps: ["-0.9139252", "34.9516641"],
		},
		{
			name: "Benachiba Chelia",
			gps: ["-0.6144619", "34.9634117"],
		},
		{
			name: "Bir El Hammam",
			gps: ["-0.49923", "34.41902"],
		},
		{
			name: "Boudjebaa El Bordj",
			gps: ["-0.324633", "35.351256"],
		},
		{
			name: "Boukhanafis",
			gps: ["-0.723381", "35.0661136"],
		},
		{
			name: "Chettouane Belaila",
			gps: ["-0.8363342", "34.9499053"],
		},
		{
			name: "Dhaya",
			gps: ["-0.6207275", "34.6753586"],
		},
		{
			name: "El Haçaiba",
			gps: ["-0.7609749", "34.6997072"],
		},
		{
			name: "Hassi Dahou",
			gps: ["-0.54545", "35.07205"],
		},
		{
			name: "Hassi Zehana",
			gps: ["-0.890225", "35.025858"],
		},
		{
			name: "Lamtar",
			gps: ["-0.79814", "35.070627"],
		},
		{
			name: "Makedra",
			gps: ["-0.431385", "35.4409528"],
		},
		{
			name: "Marhoum",
			gps: ["-0.1950244", "34.4460042"],
		},
		{
			name: "M'Cid",
			gps: ["-0.246087", "35.138283"],
		},
		{
			name: "Merine",
			gps: ["-0.4510403", "34.7807472"],
		},
		{
			name: "Mezaourou",
			gps: ["-0.6233189", "34.8173267"],
		},
		{
			name: "Mostefa Ben Brahim",
			gps: ["-0.3582573", "35.1924684"],
		},
		{
			name: "Moulay Slissen",
			gps: ["-0.7616615", "34.8218363"],
		},
		{
			name: "Oued Sebaa",
			gps: ["-0.824097", "34.554455"],
		},
		{
			name: "Oued Sefioun",
			gps: ["-0.0933838", "34.9961131"],
		},
		{
			name: "Oued Taourira",
			gps: ["-0.3279161", "34.8027039"],
		},
		{
			name: "Ras El Ma",
			gps: ["-0.8195114", "34.4973612"],
		},
		{
			name: "Redjem Demouche",
			gps: ["-0.8097742", "34.4271075"],
		},
		{
			name: "Sehala Thaoura",
			gps: ["-0.831699", "35.201166"],
		},
		{
			name: "Sfisef",
			gps: ["-0.2434158", "35.2344028"],
		},
		{
			name: "Sidi Ali Benyoub",
			gps: ["-0.7194328", "34.9456138"],
		},
		{
			name: "Sidi Ali Boussidi",
			gps: ["-0.8320427", "35.1017234"],
		},
		{
			name: "Sidi Bel Abbes",
			gps: ["-0.641389", "35.193889"],
		},
		{
			name: "Sidi Brahim",
			gps: ["-0.5674668", "35.2606778"],
		},
		{
			name: "Sidi Chaib",
			gps: ["-0.54865", "34.59335"],
		},
		{
			name: "Sidi Daho des Zairs",
			gps: ["-0.9096336", "35.1160472"],
		},
		{
			name: "Sidi Hamadouche",
			gps: ["-0.5487557", "35.2990745"],
		},
		{
			name: "Sidi Khaled",
			gps: ["-0.7195402", "35.1130281"],
		},
		{
			name: "Sidi Lahcene",
			gps: ["-0.6959152", "35.1632838"],
		},
		{
			name: "Sidi Yacoub",
			gps: ["-0.7860374", "35.1358436"],
		},
		{
			name: "Tabia",
			gps: ["-0.7338524", "35.0178366"],
		},
		{
			name: "Tafissour",
			gps: ["-0.20271", "34.69277"],
		},
		{
			name: "Taoudmout",
			gps: ["-0.1101637", "34.5876442"],
		},
		{
			name: "Teghalimet",
			gps: ["-0.5600023", "34.882833"],
		},
		{
			name: "Telagh",
			gps: ["-0.5731773", "34.7849064"],
		},
		{
			name: "Tenira",
			gps: ["-0.5320644", "35.0196642"],
		},
		{
			name: "Tessala",
			gps: ["-0.773163", "35.242955"],
		},
		{
			name: "Tilmouni",
			gps: ["-0.54848", "35.17407"],
		},
		{
			name: "Zerouala",
			gps: ["-0.5221939", "35.2423245"],
		},
	],
	Bejaïa: [
		{
			name: "Béjaïa",
			gps: ["5.066667", "36.75"],
		},
		{
			name: "Amizour",
			gps: ["4.90561", "36.644147"],
		},
		{
			name: "Ferraoun",
			gps: ["4.866471", "36.556912"],
		},
		{
			name: "Taourirt Ighil",
			gps: ["4.7", "36.7"],
		},
		{
			name: "Chellata",
			gps: ["4.4975", "36.51944"],
		},
		{
			name: "Tamokra",
			gps: ["4.66446", "36.39322"],
		},
		{
			name: "Timezrit",
			gps: ["4.76667", "36.65"],
		},
		{
			name: "Souk El Ténine",
			gps: ["5.336067", "36.625163"],
		},
		{
			name: "M'cisna",
			gps: ["4.71262", "36.564082"],
		},
		{
			name: "Tinabdher",
			gps: ["4.68101", "36.62525"],
		},
		{
			name: "Tichy",
			gps: ["5.160085", "36.667483"],
		},
		{
			name: "Semaoun",
			gps: ["4.820938", "36.607811"],
		},
		{
			name: "Kendira",
			gps: ["5.062809", "36.532735"],
		},
		{
			name: "Tifra",
			gps: ["4.69722", "36.66639"],
		},
		{
			name: "Ighram",
			gps: ["4.50532", "36.46295"],
		},
		{
			name: "Amalou",
			gps: ["4.65", "36.48333"],
		},
		{
			name: "Ighil Ali",
			gps: ["4.46667", "36.33333"],
		},
		{
			name: "Fenaïa Ilmaten",
			gps: ["4.790618", "36.671801"],
		},
		{
			name: "Toudja",
			gps: ["4.893293", "36.758622"],
		},
		{
			name: "Darguina",
			gps: ["5.305173", "36.564737"],
		},
		{
			name: "Sidi-Ayad",
			gps: ["4.75", "36.61667"],
		},
		{
			name: "Aokas",
			gps: ["5.25", "36.633333"],
		},
		{
			name: "Ait Djellil",
			gps: ["4.93333", "36.58333"],
		},
		{
			name: "Adekar",
			gps: ["4.6726227", "36.6923733"],
		},
		{
			name: "Akbou",
			gps: ["4.55", "36.45"],
		},
		{
			name: "Seddouk",
			gps: ["4.68611", "36.54722"],
		},
		{
			name: "Tazmalt",
			gps: ["4.399273", "36.384393"],
		},
		{
			name: "Aït-R'zine",
			gps: ["4.487", "36.3716"],
		},
		{
			name: "Chemini",
			gps: ["4.61667", "36.6"],
		},
		{
			name: "Souk-Oufella",
			gps: ["4.63902", "36.606132"],
		},
		{
			name: "Tibane",
			gps: ["4.64746", "36.6089"],
		},
		{
			name: "Tala Hamza",
			gps: ["5.037553", "36.705294"],
		},
		{
			name: "Barbacha",
			gps: ["4.96667", "36.56667"],
		},
		{
			name: "Aït Ksila",
			gps: ["4.661915", "36.882504"],
		},
		{
			name: "Ouzellaguen",
			gps: ["4.61291", "36.54203"],
		},
		{
			name: "Bouhamza",
			gps: ["4.606769", "36.489714"],
		},
		{
			name: "Taskriout",
			gps: ["5.2733517", "36.5475654"],
		},
		{
			name: "Aït Mellikeche",
			gps: ["4.4", "36.433333"],
		},
		{
			name: "Sidi-Aïch",
			gps: ["4.688298", "36.612944"],
		},
		{
			name: "El Kseur",
			gps: ["4.852095", "36.684389"],
		},
		{
			name: "Melbou",
			gps: ["5.360759", "36.639645"],
		},
		{
			name: "Akfadou",
			gps: ["4.55449", "36.2942"],
		},
		{
			name: "Leflaye",
			gps: ["4.66364", "36.60214"],
		},
		{
			name: "Kherrata",
			gps: ["5.27759", "36.492724"],
		},
		{
			name: "Draâ El-Kaïd",
			gps: ["5.2245", "36.42054"],
		},
		{
			name: "Tamridjet",
			gps: ["5.3738", "36.572"],
		},
		{
			name: "Aït-Smail",
			gps: ["5.229664", "36.54626"],
		},
		{
			name: "Boukhelifa",
			gps: ["5.087201", "36.614391"],
		},
		{
			name: "Tizi N'Berber",
			gps: ["5.066667", "36.75"],
		},
		{
			name: "Aït Maouche",
			gps: ["4.638441", "36.478051"],
		},
		{
			name: "Oued Ghir",
			gps: ["4.97731", "36.71044"],
		},
		{
			name: "Boudjellil",
			gps: ["4.3587", "36.334"],
		},
	],
	Tebessa: [
		{
			name: "Tébessa",
			gps: ["8.116322", "35.405282"],
		},
		{
			name: "Bir el-Ater",
			gps: ["8.0579567", "34.7485241"],
		},
		{
			name: "Cheria",
			gps: ["7.7519703", "35.2703595"],
		},
		{
			name: "Stah Guentis",
			gps: ["7.30829", "34.9984"],
		},
		{
			name: "El Aouinet",
			gps: ["7.8876686", "35.8655434"],
		},
		{
			name: "El Houidjbet",
			gps: ["8.29347", "35.2972"],
		},
		{
			name: "Safsaf El Ouesra",
			gps: ["8.20773", "34.95664"],
		},
		{
			name: "Hammamet",
			gps: ["7.9530716", "35.4483648"],
		},
		{
			name: "Negrine",
			gps: ["7.5205279", "34.4856887"],
		},
		{
			name: "Bir Mokkadem",
			gps: ["7.8074", "35.37245"],
		},
		{
			name: "El Kouif",
			gps: ["8.321836", "35.498273"],
		},
		{
			name: "Morsott",
			gps: ["8.007222", "35.668333"],
		},
		{
			name: "El Ogla",
			gps: ["7.4688148", "35.1886105"],
		},
		{
			name: "Bir Dheb",
			gps: ["7.9384", "35.5248"],
		},
		{
			name: "Ogla Melha",
			gps: ["7.951", "35.12186"],
		},
		{
			name: "Guorriguer",
			gps: ["7.59333", "35.42278"],
		},
		{
			name: "Bekkaria",
			gps: ["8.2421494", "35.3721148"],
		},
		{
			name: "Boukhadra",
			gps: ["8.032379", "35.744562"],
		},
		{
			name: "Ouenza",
			gps: ["8.083333", "35.916667"],
		},
		{
			name: "El Ma Labiodh",
			gps: ["8.1700516", "35.2056541"],
		},
		{
			name: "Oum Ali",
			gps: ["8.3009434", "35.0107367"],
		},
		{
			name: "Tlidjene",
			gps: ["7.767334", "35.1175918"],
		},
		{
			name: "Aïn Zerga",
			gps: ["8.267155", "35.636651"],
		},
		{
			name: "El Meridj",
			gps: ["8.22951", "35.79308"],
		},
		{
			name: "Boulhaf Dir",
			gps: ["8.0717", "35.4885"],
		},
		{
			name: "Bedjene",
			gps: ["7.4716902", "35.3488408"],
		},
		{
			name: "El Mezeraa",
			gps: ["7.580337", "35.094069"],
		},
		{
			name: "Ferkane",
			gps: ["7.4122524", "34.5540735"],
		},
	],
	Annaba: [
		{
			name: "Annaba",
			gps: ["7.76667", "36.9"],
		},
		{
			name: "Annaba",
			gps: ["7.751944", "36.904167"],
		},
		{
			name: "Berrahal",
			gps: ["7.45", "36.833333"],
		},
		{
			name: "El Hadjar",
			gps: ["7.733333", "36.8"],
		},
		{
			name: "Eulma",
			gps: ["7.46163", "36.73875"],
		},
		{
			name: "El Bouni",
			gps: ["7.663889", "36.838889"],
		},
		{
			name: "Oued El Aneb",
			gps: ["7.483333", "36.883333"],
		},
		{
			name: "Cheurfa",
			gps: ["7.55407", "36.72057"],
		},
		{
			name: "Seraïdi",
			gps: ["7.666667", "36.916667"],
		},
		{
			name: "Aïn Berda",
			gps: ["7.58333", "36.65"],
		},
		{
			name: "Chetaïbi",
			gps: ["7.3803", "37.0661"],
		},
		{
			name: "Sidi Amar",
			gps: ["7.71793", "36.81766"],
		},
		{
			name: "Treat",
			gps: ["7.42802", "36.89747"],
		},
	],
	Constantine: [
		{
			name: "Constantine",
			gps: ["6.616667", "36.283333"],
		},
		{
			name: "Constantine",
			gps: ["6.6", "36.35"],
		},
		{
			name: "Aïn Abid",
			gps: ["6.944046", "36.2325044"],
		},
		{
			name: "Aïn Smara",
			gps: ["6.50135", "36.2674"],
		},
		{
			name: "Beni Hamiden",
			gps: ["6.54972", "36.50556"],
		},
		{
			name: "Constantine",
			gps: ["6.616667", "36.283333"],
		},
		{
			name: "Didouche Mourad",
			gps: ["6.6339397", "36.4483515"],
		},
		{
			name: "El Khroub",
			gps: ["6.683333", "36.266667"],
		},
		{
			name: "Hamma Bouziane",
			gps: ["6.59603", "36.41205"],
		},
		{
			name: "Ibn Badis",
			gps: ["6.8319511", "36.3173382"],
		},
		{
			name: "Ibn Ziad",
			gps: ["6.47202", "36.37925"],
		},
		{
			name: "Messaoud Boudjriou",
			gps: ["6.4725", "36.42444"],
		},
		{
			name: "Ouled Rahmoune",
			gps: ["6.705142", "36.182783"],
		},
		{
			name: "Zighoud Youcef",
			gps: ["6.71238", "36.53307"],
		},
		{
			name: "Ali Mendjeli",
			gps: ["6.5671", "36.2459"],
		},
	],
	Batna: [
		{
			name: "Batna",
			gps: ["6.17", "35.55"],
		},
		{
			name: "Ghassira",
			gps: ["6.20608", "35.08161"],
		},
		{
			name: "Maafa",
			gps: ["5.9", "35.266667"],
		},
		{
			name: "Merouana",
			gps: ["5.9117", "35.6286"],
		},
		{
			name: "Seriana",
			gps: ["6.18333333", "35.68333333"],
		},
		{
			name: "Menaa",
			gps: ["6.006775", "35.182508"],
		},
		{
			name: "El Madher",
			gps: ["6.369152", "35.63121"],
		},
		{
			name: "Tazoult",
			gps: ["6.254311", "35.489188"],
		},
		{
			name: "N'Gaous",
			gps: ["5.610924", "35.561836"],
		},
		{
			name: "Guigba",
			gps: ["5.59088", "35.73415"],
		},
		{
			name: "Inoughissen",
			gps: ["6.542", "35.28538"],
		},
		{
			name: "Ouyoun El Assafir",
			gps: ["6.37968", "35.52106"],
		},
		{
			name: "Djerma",
			gps: ["6.3097241", "35.6623355"],
		},
		{
			name: "Bitam",
			gps: ["5.3", "35.3"],
		},
		{
			name: "Abdelkader Azil",
			gps: ["5.183333", "35.366667"],
		},
		{
			name: "Arris",
			gps: ["6.17", "35.55"],
		},
		{
			name: "Kimmel",
			gps: ["6.52422", "34.98869"],
		},
		{
			name: "Tilatou",
			gps: ["5.783443", "35.320168"],
		},
		{
			name: "Aïn Djasser",
			gps: ["6.0012", "35.8611"],
		},
		{
			name: "Ouled Sellam",
			gps: ["5.88226", "35.82563"],
		},
		{
			name: "Tigherghar",
			gps: ["5.96984", "35.16029"],
		},
		{
			name: "Aïn Yagout",
			gps: ["6.416667", "35.783333"],
		},
		{
			name: "Sefiane",
			gps: ["5.557966", "35.441477"],
		},
		{
			name: "Fesdis",
			gps: ["6.247401", "35.617744"],
		},
		{
			name: "Rahbat",
			gps: ["5.65515", "35.71231"],
		},
		{
			name: "Tighanimine",
			gps: ["6.312675", "35.215472"],
		},
		{
			name: "Lemsane",
			gps: ["5.79668", "35.65622"],
		},
		{
			name: "Ksar Bellezma",
			gps: ["5.90322", "35.67553"],
		},
		{
			name: "Seggana",
			gps: ["5.5719001", "35.3641602"],
		},
		{
			name: "Ichmoul",
			gps: ["6.4833333", "35.3"],
		},
		{
			name: "Foum Toub",
			gps: ["6.54961", "35.40506"],
		},
		{
			name: "Ben Foudhala El Hakania",
			gps: ["6.073723", "35.378134"],
		},
		{
			name: "Oued El Ma",
			gps: ["6", "35.65"],
		},
		{
			name: "Talkhamt",
			gps: ["5.7889", "35.6669"],
		},
		{
			name: "Bouzina",
			gps: ["6.116667", "35.283333"],
		},
		{
			name: "Chemora",
			gps: ["6.65", "35.666667"],
		},
		{
			name: "Oued Chaaba",
			gps: ["6.07784", "35.5048"],
		},
		{
			name: "Taxlent",
			gps: ["5.816667", "35.616667"],
		},
		{
			name: "Gosbat",
			gps: ["5.516667", "35.666667"],
		},
		{
			name: "Ouled Aouf",
			gps: ["5.768337", "35.467101"],
		},
		{
			name: "Boumagueur",
			gps: ["5.5525", "35.5052"],
		},
		{
			name: "Barika",
			gps: ["5.365791", "35.397166"],
		},
		{
			name: "Djezar",
			gps: ["5.26679", "35.50956"],
		},
		{
			name: "T'Kout",
			gps: ["6.30861", "35.1392"],
		},
		{
			name: "Aïn Touta",
			gps: ["5.894213", "35.3752089"],
		},
		{
			name: "Hidoussa",
			gps: ["5.9", "35.5"],
		},
		{
			name: "Teniet El Abed",
			gps: ["6.19062", "35.24695"],
		},
		{
			name: "Oued Taga",
			gps: ["6.23", "35.33"],
		},
		{
			name: "Ouled Fadel",
			gps: ["6.62465", "35.4843"],
		},
		{
			name: "Timgad",
			gps: ["6.467235", "35.49581"],
		},
		{
			name: "Ras El Aioun",
			gps: ["5.6533", "35.6749"],
		},
		{
			name: "Chir",
			gps: ["6.09805", "35.21283"],
		},
		{
			name: "Ouled Si Slimane",
			gps: ["5.63275", "35.61089"],
		},
		{
			name: "Zanat El Beida",
			gps: ["6.08512", "35.77902"],
		},
		{
			name: "M'doukel",
			gps: ["5.17194", "35.12972"],
		},
		{
			name: "Ouled Ammar",
			gps: ["1.65", "36.483333"],
		},
		{
			name: "El Hassi",
			gps: ["5.9061111", "35.7227778"],
		},
		{
			name: "Lazrou",
			gps: ["6.21661", "35.84293"],
		},
		{
			name: "Boumia (Batna)",
			gps: ["6.45779", "35.68657"],
		},
		{
			name: "Boulhilat",
			gps: ["6.66242", "35.72442"],
		},
		{
			name: "Larbaâ",
			gps: ["6.04", "35.18"],
		},
	],
	Biskra: [
		{
			name: "Biskra",
			gps: ["5.75", "34.8667"],
		},
		{
			name: "Aïn Naga",
			gps: ["6.0885286", "34.6883807"],
		},
		{
			name: "Aïn Zaatout",
			gps: ["5.81666667", "35.13333333"],
		},
		{
			name: "Besbes",
			gps: ["4.9907112", "34.1500989"],
		},
		{
			name: "Biskra",
			gps: ["5.733333", "34.85"],
		},
		{
			name: "Bordj Ben Azzouz",
			gps: ["5.3628", "34.6972"],
		},
		{
			name: "Bouchagroune",
			gps: ["5.46555", "34.72161"],
		},
		{
			name: "Branis",
			gps: ["5.766667", "34.966667"],
		},
		{
			name: "Chetma",
			gps: ["5.809621", "34.8498574"],
		},
		{
			name: "Djemorah",
			gps: ["5.8442116", "35.0695559"],
		},
		{
			name: "Doucen",
			gps: ["5.016667", "34.1"],
		},
		{
			name: "Ech Chaïba",
			gps: ["4.92218", "34.84079"],
		},
		{
			name: "El Feidh",
			gps: ["6.52232", "34.52144"],
		},
		{
			name: "El Ghrous",
			gps: ["5.28516", "34.70524"],
		},
		{
			name: "El Hadjeb",
			gps: ["5.59683", "34.7904"],
		},
		{
			name: "El Haouch",
			gps: ["6.05126", "34.5619"],
		},
		{
			name: "El Kantara",
			gps: ["5.7104", "35.2166"],
		},
		{
			name: "El Mizaraa",
			gps: ["6.29278", "34.72156"],
		},
		{
			name: "El Outaya",
			gps: ["5.6", "35.033333"],
		},
		{
			name: "Foughala",
			gps: ["5.316667", "34.716667"],
		},
		{
			name: "Khenguet Sidi Nadji",
			gps: ["6.7069817", "34.8057696"],
		},
		{
			name: "Lichana",
			gps: ["5.433333", "34.716667"],
		},
		{
			name: "Lioua",
			gps: ["5.416667", "34.633333"],
		},
		{
			name: "M'Chouneche",
			gps: ["6", "34.95"],
		},
		{
			name: "Mekhadma",
			gps: ["5.483333", "34.65"],
		},
		{
			name: "M'Lili",
			gps: ["6.7069817", "34.8057696"],
		},
		{
			name: "Ouled Djellal",
			gps: ["5.0642", "34.4289"],
		},
		{
			name: "Oumache",
			gps: ["5.700531", "34.6944852"],
		},
		{
			name: "Ourlal",
			gps: ["5.51136", "34.65542"],
		},
		{
			name: "Ras El Miaad",
			gps: ["4.44955", "34.18463"],
		},
		{
			name: "Sidi Khaled",
			gps: ["4.983333", "34.383333"],
		},
		{
			name: "Sidi Okba",
			gps: ["5.9", "34.75"],
		},
		{
			name: "Tolga",
			gps: ["5.36666667", "34.71666667"],
		},
		{
			name: "Zeribet El Oued",
			gps: ["6.5022755", "34.6831227"],
		},
	],
	Tiaret: [
		{
			name: "Tiaret",
			gps: ["1.33333333", "35.38333333"],
		},
		{
			name: "Aïn Bouchekif",
			gps: ["1.5106201", "35.3557362"],
		},
		{
			name: "Aïn Deheb",
			gps: ["1.5492439", "34.8445216"],
		},
		{
			name: "Aïn El Hadid",
			gps: ["0.8833694", "35.0579641"],
		},
		{
			name: "Aïn Kermes",
			gps: ["1.1080742", "34.9081763"],
		},
		{
			name: "Aïn Dzarit",
			gps: ["1.6688919", "35.3526561"],
		},
		{
			name: "Bougara",
			gps: ["1.9672823", "35.5533525"],
		},
		{
			name: "Chehaima",
			gps: ["1.306601", "34.899061"],
		},
		{
			name: "Dahmouni",
			gps: ["1.4762879", "35.4166842"],
		},
		{
			name: "Djebilet Rosfa",
			gps: ["0.835004", "34.864208"],
		},
		{
			name: "Djillali Ben Amar",
			gps: ["0.8496809", "35.4444491"],
		},
		{
			name: "Faidja",
			gps: ["1.58111", "35.1925"],
		},
		{
			name: "Frenda",
			gps: ["1.05", "35.06666667"],
		},
		{
			name: "Guertoufa",
			gps: ["1.2560892", "35.3930383"],
		},
		{
			name: "Hamadia",
			gps: ["1.8747997", "35.4594116"],
		},
		{
			name: "Ksar Chellala",
			gps: ["2.316667", "35.216667"],
		},
		{
			name: "Madna",
			gps: ["0.98281", "34.75303"],
		},
		{
			name: "Mahdia",
			gps: ["1.7595291", "35.4314421"],
		},
		{
			name: "Mechraa Safa",
			gps: ["1.0534", "35.3839"],
		},
		{
			name: "Medrissa",
			gps: ["1.2399101", "34.8958576"],
		},
		{
			name: "Medroussa",
			gps: ["0.9993696", "35.1916968"],
		},
		{
			name: "Meghila",
			gps: ["1.4137157", "35.5964786"],
		},
		{
			name: "Mellakou",
			gps: ["1.234374", "35.253084"],
		},
		{
			name: "Nadorah",
			gps: ["1.8905", "35.2882"],
		},
		{
			name: "Naima",
			gps: ["1.477371", "35.090759"],
		},
		{
			name: "Oued Lilli",
			gps: ["1.2713242", "35.5119677"],
		},
		{
			name: "Rahouia",
			gps: ["1.0221577", "35.5302705"],
		},
		{
			name: "Rechaiga",
			gps: ["1.9734192", "35.4080802"],
		},
		{
			name: "Sebaine",
			gps: ["1.60349", "35.45623"],
		},
		{
			name: "Sebt",
			gps: ["1.32013", "35.62733"],
		},
		{
			name: "Serghine",
			gps: ["2.50768", "35.27075"],
		},
		{
			name: "Si Abdelghani",
			gps: ["1.63861", "35.22806"],
		},
		{
			name: "Sidi Abderahmane",
			gps: ["1.1302185", "34.7987218"],
		},
		{
			name: "Sidi Ali Mellal",
			gps: ["1.2256622", "35.5634422"],
		},
		{
			name: "Sidi Bakhti",
			gps: ["0.978276", "35.241168"],
		},
		{
			name: "Sidi Hosni",
			gps: ["1.5198898", "35.4711558"],
		},
		{
			name: "Sougueur",
			gps: ["1.4958572", "35.1834896"],
		},
		{
			name: "Tagdemt",
			gps: ["1.22727", "35.33543"],
		},
		{
			name: "Takhemaret",
			gps: ["0.6832981", "35.107165"],
		},
		{
			name: "Tiaret",
			gps: ["1.322823", "35.38793"],
		},
		{
			name: "Tidda",
			gps: ["1.266239", "35.582414"],
		},
		{
			name: "Tousnina",
			gps: ["1.303961", "34.898621"],
		},
		{
			name: "Zmalet El Emir Abdelkader",
			gps: ["2.30998", "34.89336"],
		},
	],
	Adrar: [
		{
			name: "Adrar",
			gps: ["-0.283333", "27.866667"],
		},
		{
			name: "Tamest",
			gps: ["-0.26024", "27.45394"],
		},
		{
			name: "Charouine",
			gps: ["-0.266667", "29.0167"],
		},
		{
			name: "Reggane",
			gps: ["0.171146", "26.719892"],
		},
		{
			name: "In Zghmir",
			gps: ["-0.1206", "27.09384"],
		},
		{
			name: "Tit",
			gps: ["1.483669", "26.950841"],
		},
		{
			name: "Ksar Kaddour",
			gps: ["0.3739", "29.57739"],
		},
		{
			name: "Tsabit",
			gps: ["-0.25", "28.366667"],
		},
		{
			name: "Timimoun",
			gps: ["0.25", "29.25"],
		},
		{
			name: "Ouled Saïd",
			gps: ["0.23876", "29.42054"],
		},
		{
			name: "Zaouiet Kounta",
			gps: ["-0.2", "27.216667"],
		},
		{
			name: "Aoulef",
			gps: ["1.08333", "26.9667"],
		},
		{
			name: "Tamekten",
			gps: ["1.026944", "27.018889"],
		},
		{
			name: "Tamantit",
			gps: ["-0.266667", "27.7667"],
		},
		{
			name: "Fenoughil",
			gps: ["0.3", "27.6667"],
		},
		{
			name: "Tinerkouk",
			gps: ["0.5", "29.75"],
		},
		{
			name: "Deldoul",
			gps: ["-0.0167", "28.8333"],
		},
		{
			name: "Sali",
			gps: ["-0.033333", "26.983333"],
		},
		{
			name: "Akabli",
			gps: ["1.36666667", "26.7"],
		},
		{
			name: "Metarfa",
			gps: ["-0.15", "28.5833"],
		},
		{
			name: "Ouled Ahmed Tammi",
			gps: ["-0.28543", "27.79632"],
		},
		{
			name: "Bouda",
			gps: ["-0.50254", "28.0142"],
		},
		{
			name: "Aougrout",
			gps: ["0.25", "28.75"],
		},
		{
			name: "Talmine",
			gps: ["-0.49746", "29.3291"],
		},
		{
			name: "Bordj Badji Mokhtar",
			gps: ["0.946", "21.325"],
		},
		{
			name: "Sebaa",
			gps: ["-0.1748782", "28.2118058"],
		},
		{
			name: "Ouled Aïssa",
			gps: ["-0.08756", "29.41836"],
		},
		{
			name: "Timiaouine",
			gps: ["1.79861", "20.44472"],
		},
	],
	Djelfa: [
		{
			name: "Djelfa",
			gps: ["3.25833", "34.675"],
		},
		{
			name: "Aïn Chouhada",
			gps: ["2.52186", "34.24108"],
		},
		{
			name: "Aïn El Ibel",
			gps: ["3.2226849", "34.3547742"],
		},
		{
			name: "Aïn Feka",
			gps: ["3.5756207", "35.4258471"],
		},
		{
			name: "Aïn Maabed",
			gps: ["3.1293869", "34.8048182"],
		},
		{
			name: "Aïn Oussara",
			gps: ["2.90444", "35.4489"],
		},
		{
			name: "Amourah",
			gps: ["3.87086", "34.35431"],
		},
		{
			name: "Benhar",
			gps: ["3.00984", "35.48473"],
		},
		{
			name: "Beni Yagoub",
			gps: ["2.7850771", "34.4664432"],
		},
		{
			name: "Birine",
			gps: ["3.216667", "35.633333"],
		},
		{
			name: "Bouira Lahdab",
			gps: ["3.1422615", "35.2439368"],
		},
		{
			name: "Charef",
			gps: ["2.8010416", "34.6180228"],
		},
		{
			name: "Dar Chioukh",
			gps: ["3.4904766", "34.8934641"],
		},
		{
			name: "Deldoul",
			gps: ["3.25311", "34.20519"],
		},
		{
			name: "Djelfa",
			gps: ["3.250065", "34.666676"],
		},
		{
			name: "Douis",
			gps: ["2.7021646", "34.3665712"],
		},
		{
			name: "El Guedid",
			gps: ["2.6149", "34.64705"],
		},
		{
			name: "El Idrissia",
			gps: ["2.5289154", "34.4481842"],
		},
		{
			name: "El Khemis",
			gps: ["2.59508", "35.28795"],
		},
		{
			name: "Faidh El Botma",
			gps: ["3.7818718", "34.5277728"],
		},
		{
			name: "Guernini",
			gps: ["2.68203", "35.19992"],
		},
		{
			name: "Guettara",
			gps: ["4.6852", "33.1586"],
		},
		{
			name: "Had-Sahary",
			gps: ["3.36089", "35.35168"],
		},
		{
			name: "Hassi Bahbah",
			gps: ["3.0270767", "35.0758078"],
		},
		{
			name: "Hassi El Euch",
			gps: ["3.24831", "35.15316"],
		},
		{
			name: "Hassi Fedoul",
			gps: ["2.2115564", "35.437806"],
		},
		{
			name: "Messaad",
			gps: ["3.5", "34.16666667"],
		},
		{
			name: "M'Liliha",
			gps: ["3.48333", "34.75"],
		},
		{
			name: "Moudjebara",
			gps: ["3.4704351", "34.503692"],
		},
		{
			name: "Oum Laadham",
			gps: ["4.5305", "33.7202"],
		},
		{
			name: "Sed Rahal",
			gps: ["3.2238", "33.94822"],
		},
		{
			name: "Selmana",
			gps: ["3.6010265", "34.1765892"],
		},
		{
			name: "Sidi Baizid",
			gps: ["2.383333", "35.75"],
		},
		{
			name: "Sidi Ladjel",
			gps: ["2.5", "35.433333"],
		},
		{
			name: "Tadmit",
			gps: ["2.9885387", "34.2866517"],
		},
		{
			name: "Zaafrane",
			gps: ["2.85705", "34.85076"],
		},
		{
			name: "Zaccar",
			gps: ["3.3273125", "34.430806"],
		},
	],
	Jijel: [
		{
			name: "Jijel",
			gps: ["5.766667", "36.816667"],
		},
		{
			name: "Eraguene",
			gps: ["5.580647", "36.5861566"],
		},
		{
			name: "El Aouana",
			gps: ["5.6099603", "36.7727264"],
		},
		{
			name: "Ziama Mansouriah",
			gps: ["5.4810994", "36.6737296"],
		},
		{
			name: "Taher",
			gps: ["5.8982105", "36.7720039"],
		},
		{
			name: "Emir Abdelkader",
			gps: ["5.849848", "36.753465"],
		},
		{
			name: "Chekfa",
			gps: ["5.9595251", "36.771308"],
		},
		{
			name: "Chahna",
			gps: ["5.9572034", "36.6793738"],
		},
		{
			name: "El Milia",
			gps: ["36", "5"],
		},
		{
			name: "Sidi Maarouf",
			gps: ["6.272507", "36.647487"],
		},
		{
			name: "Settara",
			gps: ["6.3355064", "36.7195539"],
		},
		{
			name: "El Ancer",
			gps: ["6.1569786", "36.7998696"],
		},
		{
			name: "Sidi Abdelaziz",
			gps: ["6.05", "36.85"],
		},
		{
			name: "Kaous",
			gps: ["5.8136168", "36.7701723"],
		},
		{
			name: "Ghebala",
			gps: ["6.3896927", "36.6275064"],
		},
		{
			name: "Bouraoui Belhadef",
			gps: ["6.1020255", "36.6988253"],
		},
		{
			name: "Djimla",
			gps: ["5.8849506", "36.5814326"],
		},
		{
			name: "Selma Benziada",
			gps: ["5.648346", "36.626481"],
		},
		{
			name: "Boucif Ouled Askeur",
			gps: ["6.0191886", "36.6427037"],
		},
		{
			name: "El Kennar Nouchfi",
			gps: ["5.96209", "36.8241692"],
		},
		{
			name: "Ouled Yahia Khedrouche",
			gps: ["6.201439", "36.717146"],
		},
		{
			name: "Boudriaa Ben Yadjis",
			gps: ["5.8071714", "36.5992076"],
		},
		{
			name: "Kheïri Oued Adjoul",
			gps: ["6.141703", "36.859460395735"],
		},
		{
			name: "Texenna",
			gps: ["5.7909778", "36.6605708"],
		},
		{
			name: "Djemaa Beni Habibi",
			gps: ["6.1265", "36.8065"],
		},
		{
			name: "Bordj Tahar",
			gps: ["6.0342794", "36.7469405"],
		},
		{
			name: "Ouled Rabah",
			gps: ["6.17054", "36.598716"],
		},
		{
			name: "Ouadjana",
			gps: ["5.8948904", "36.7073159"],
		},
	],
	Skikda: [
		{
			name: "Skikda",
			gps: ["6.9", "36.86666667"],
		},
		{
			name: "Aïn Bouziane",
			gps: ["6.751442", "36.5980269"],
		},
		{
			name: "Aïn Charchar",
			gps: ["7.2197342", "36.7311114"],
		},
		{
			name: "Aïn Kechra",
			gps: ["6.43237", "36.74839"],
		},
		{
			name: "Aïn Zouit",
			gps: ["6.7854738", "36.8901585"],
		},
		{
			name: "Azzaba",
			gps: ["7.1", "36.763333"],
		},
		{
			name: "Bekkouche Lakhdar",
			gps: ["7.3062515", "36.7010446"],
		},
		{
			name: "Bin El Ouiden",
			gps: ["6.5656185", "36.807979"],
		},
		{
			name: "Ben Azzouz",
			gps: ["7.29254", "36.86111"],
		},
		{
			name: "Beni Bechir",
			gps: ["6.933333", "36.783333"],
		},
		{
			name: "Beni Oulbane",
			gps: ["6.63888", "36.62683"],
		},
		{
			name: "Beni Zid",
			gps: ["6.5056229", "36.807979"],
		},
		{
			name: "Bouchtata",
			gps: ["6.7971897", "36.792481"],
		},
		{
			name: "Cheraia",
			gps: ["6.5128756", "37.0018329"],
		},
		{
			name: "Collo",
			gps: ["6.5609", "37.0065"],
		},
		{
			name: "Djendel Saadi Mohamed",
			gps: ["7.170725", "36.780555"],
		},
		{
			name: "El Ghedir",
			gps: ["6.9778", "36.68835"],
		},
		{
			name: "El Hadaiek",
			gps: ["6.8882561", "36.8256381"],
		},
		{
			name: "El Harrouch",
			gps: ["6.83638889", "36.65305556"],
		},
		{
			name: "El Marsa",
			gps: ["7.2531705", "37.029893"],
		},
		{
			name: "Emdjez Edchich",
			gps: ["6.8563271", "36.7036596"],
		},
		{
			name: "Es Sebt",
			gps: ["7.0774269", "36.6623603"],
		},
		{
			name: "Filfila",
			gps: ["7.0525", "36.8985"],
		},
		{
			name: "Hamadi Krouma",
			gps: ["6.9019032", "36.8453537"],
		},
		{
			name: "Kanoua",
			gps: ["6.4", "37.05"],
		},
		{
			name: "Kerkera",
			gps: ["6.5828705", "36.9297229"],
		},
		{
			name: "Kheneg Mayoum",
			gps: ["6.28421", "37.00845"],
		},
		{
			name: "Oued Zehour",
			gps: ["6.3155508", "36.927047"],
		},
		{
			name: "Ouldja Boulballout",
			gps: ["6.37312", "36.78617"],
		},
		{
			name: "Ouled Attia",
			gps: ["6.3413", "36.9944296"],
		},
		{
			name: "Ouled Hbaba",
			gps: ["6.95742", "36.50346"],
		},
		{
			name: "Oum Toub",
			gps: ["6.576667", "36.690833"],
		},
		{
			name: "Ramdane Djamel",
			gps: ["6.8927193", "36.7552525"],
		},
		{
			name: "Salah Bouchaour",
			gps: ["6.85", "36.7"],
		},
		{
			name: "Sidi Mezghiche",
			gps: ["6.716667", "36.683333"],
		},
		{
			name: "Skikda",
			gps: ["6.9", "36.866666666667"],
		},
		{
			name: "Tamalous",
			gps: ["6.641667", "36.836111"],
		},
		{
			name: "Zerdaza",
			gps: ["6.8632793", "36.6288227"],
		},
		{
			name: "Zitouna",
			gps: ["6.4582443", "36.9888424"],
		},
	],
	Laghouat: [
		{
			name: "Laghouat",
			gps: ["2.88333", "33.8"],
		},
		{
			name: "Laghouat",
			gps: ["2.882195", "33.806538"],
		},
		{
			name: "Ksar El Hirane",
			gps: ["3.1407166", "33.7885639"],
		},
		{
			name: "Bennasser Benchohra",
			gps: ["3.00287", "33.75284"],
		},
		{
			name: "Sidi Makhlouf",
			gps: ["3.016667", "34.133333"],
		},
		{
			name: "Hassi Delaa",
			gps: ["3.55021", "33.41742"],
		},
		{
			name: "Hassi R'Mel",
			gps: ["3.271", "32.928"],
		},
		{
			name: "Aïn Madhi",
			gps: ["2.3011", "33.793875"],
		},
		{
			name: "Tadjemout",
			gps: ["2.5234222", "33.8795726"],
		},
		{
			name: "Kheneg",
			gps: ["2.7941322", "33.7446112"],
		},
		{
			name: "Gueltat Sidi Saad",
			gps: ["1.94664", "34.2970048"],
		},
		{
			name: "Aïn Sidi Ali",
			gps: ["1.54179", "34.15691"],
		},
		{
			name: "Beidha",
			gps: ["2.17408", "34.47475"],
		},
		{
			name: "Brida",
			gps: ["1.733333", "33.966667"],
		},
		{
			name: "El Ghicha",
			gps: ["2.15", "33.933333"],
		},
		{
			name: "Hadj Mechri",
			gps: ["1.59879", "33.95715"],
		},
		{
			name: "Sebgag",
			gps: ["1.92798", "34.02958"],
		},
		{
			name: "Taouiala",
			gps: ["1.8605947", "33.8688833"],
		},
		{
			name: "Tadjrouna",
			gps: ["2.1019077", "33.5040434"],
		},
		{
			name: "Aflou",
			gps: ["2.097262", "34.113883"],
		},
		{
			name: "El Assafia",
			gps: ["2.983333", "33.833333"],
		},
		{
			name: "Oued Morra",
			gps: ["2.316667", "34.166667"],
		},
		{
			name: "Oued M'Zi",
			gps: ["2.43695", "33.92422"],
		},
		{
			name: "El Houaita",
			gps: ["2.44596", "33.6452779"],
		},
		{
			name: "Sidi Bouzid",
			gps: ["2.2615", "34.3429"],
		},
	],
	Setif: [
		{
			name: "Sétif",
			gps: ["5.4", "36.18333333"],
		},
		{
			name: "Aïn Abessa",
			gps: ["5.2949381", "36.2999085"],
		},
		{
			name: "Aïn Arnat",
			gps: ["5.316667", "36.183333"],
		},
		{
			name: "Aïn Azel",
			gps: ["5.522003", "35.843421"],
		},
		{
			name: "Aïn El Kebira",
			gps: ["5.501997", "36.364801"],
		},
		{
			name: "Aïn Lahdjar",
			gps: ["5.5420876", "35.9371544"],
		},
		{
			name: "Aïn Legradj",
			gps: ["4.891", "36.4096"],
		},
		{
			name: "Aïn Oulmene",
			gps: ["5.283333", "35.9"],
		},
		{
			name: "Aïn Roua",
			gps: ["5.1805687", "36.3343492"],
		},
		{
			name: "Aïn Sebt",
			gps: ["5.7112", "36.4818"],
		},
		{
			name: "Aït Naoual Mezada",
			gps: ["5.09004", "36.54217"],
		},
		{
			name: "Aït Tizi",
			gps: ["5.127153", "36.558877"],
		},
		{
			name: "Beni Ouartilene",
			gps: ["4.9", "36.4333"],
		},
		{
			name: "Amoucha",
			gps: ["5.4107666", "36"],
		},
		{
			name: "Babor",
			gps: ["5.5399", "36.4904"],
		},
		{
			name: "Bazer Sakhra",
			gps: ["5.7302284", "36.0701923"],
		},
		{
			name: "Beidha Bordj",
			gps: ["5.6637096", "35.8940565"],
		},
		{
			name: "Belaa",
			gps: ["5.853653", "36.20259"],
		},
		{
			name: "Beni Aziz",
			gps: ["5.65", "36.466667"],
		},
		{
			name: "Beni Chebana",
			gps: ["4.87613", "36.46508"],
		},
		{
			name: "Beni Fouda",
			gps: ["5.6071472", "36.2860726"],
		},
		{
			name: "Beni Hocine",
			gps: ["5.12743", "36.27229"],
		},
		{
			name: "Beni Mouhli",
			gps: ["4.91503", "36.50739"],
		},
		{
			name: "Bir El Arch",
			gps: ["5.833333", "36.133333"],
		},
		{
			name: "Bir Haddada",
			gps: ["5.430851", "35.9628629"],
		},
		{
			name: "Bouandas",
			gps: ["5.1019478", "36.494699"],
		},
		{
			name: "Bougaa",
			gps: ["5.0885153", "36.3324132"],
		},
		{
			name: "Bousselam",
			gps: ["5.04359", "36.49419"],
		},
		{
			name: "Boutaleb",
			gps: ["5.32103", "35.66024"],
		},
		{
			name: "Dehamcha",
			gps: ["5.5953", "36.3821"],
		},
		{
			name: "Djemila",
			gps: ["5.735207", "36.317062"],
		},
		{
			name: "Draa Kebila",
			gps: ["4.99551", "36.43637"],
		},
		{
			name: "El Eulma",
			gps: ["5.685081", "36.156449"],
		},
		{
			name: "El Ouldja",
			gps: ["5.95369", "36.06371"],
		},
		{
			name: "El Ouricia",
			gps: ["5.409565", "36.283651"],
		},
		{
			name: "Guellal",
			gps: ["5.328", "36.0451"],
		},
		{
			name: "Guelta Zerka",
			gps: ["5.6877", "36.2088"],
		},
		{
			name: "Guenzet",
			gps: ["4.833333", "36.316667"],
		},
		{
			name: "Guidjel",
			gps: ["5.5299854", "36.1184636"],
		},
		{
			name: "Hamma",
			gps: ["5.3726578", "35.6804462"],
		},
		{
			name: "Hammam Guergour",
			gps: ["5.066667", "36.316667"],
		},
		{
			name: "Hammam Soukhna",
			gps: ["5.8089352", "35.9768254"],
		},
		{
			name: "Harbil",
			gps: ["4.92641", "36.32488"],
		},
		{
			name: "Ksar El Abtal",
			gps: ["5.2881", "35.9742"],
		},
		{
			name: "Maaouia",
			gps: ["5.70995", "36.38899"],
		},
		{
			name: "Maoklane",
			gps: ["5.0753", "36.3971"],
		},
		{
			name: "Mezloug",
			gps: ["5.3369093", "36.1078201"],
		},
		{
			name: "Oued El Barad",
			gps: ["5.40143", "36.47672"],
		},
		{
			name: "Ouled Addouane",
			gps: ["5.4745", "36.3401"],
		},
		{
			name: "Ouled Sabor",
			gps: ["5.5620432", "36.1627902"],
		},
		{
			name: "Ouled Si Ahmed",
			gps: ["5.19173", "35.90101"],
		},
		{
			name: "Ouled Tebben",
			gps: ["5.10152", "35.81291"],
		},
		{
			name: "Rasfa",
			gps: ["5.26536", "35.81021"],
		},
		{
			name: "Salah Bey",
			gps: ["5.2916336", "35.8541353"],
		},
		{
			name: "Serdj El Ghoul",
			gps: ["5.5771", "36.478"],
		},
		{
			name: "Sétif",
			gps: ["5.433333", "36.15"],
		},
		{
			name: "Tachouda",
			gps: ["5.7130623", "36.2623728"],
		},
		{
			name: "Talaifacene",
			gps: ["4.9909258", "36.3810067"],
		},
		{
			name: "Taya",
			gps: ["5.95639", "35.97139"],
		},
		{
			name: "Tella",
			gps: ["5.7179", "36.0069"],
		},
		{
			name: "Tizi N'Bechar",
			gps: ["5.36", "36.4311"],
		},
	],
	Chlef: [
		{
			name: "Chlef",
			gps: ["1.336555", "36.173911"],
		},
		{
			name: "Dahra",
			gps: ["0.85174", "36.25533"],
		},
		{
			name: "El Hadjadj",
			gps: ["1.37837", "36.01592"],
		},
		{
			name: "El Karimia",
			gps: ["1.55", "36.116667"],
		},
		{
			name: "El Marsa",
			gps: ["0.916586", "36.401579"],
		},
		{
			name: "Harchoun",
			gps: ["1.55", "36.116667"],
		},
		{
			name: "Harenfa",
			gps: ["1.0457", "36.24521"],
		},
		{
			name: "Labiod Medjadja",
			gps: ["1.4", "36.25"],
		},
		{
			name: "Moussadek",
			gps: ["1.016667", "36.35"],
		},
		{
			name: "Oued Fodda",
			gps: ["1.533333", "36.183333"],
		},
		{
			name: "Oued Goussine",
			gps: ["1.306171", "36.506842"],
		},
		{
			name: "Oued Sly",
			gps: ["1.2", "36.1"],
		},
		{
			name: "Ouled Abbes",
			gps: ["1.483333", "36.216667"],
		},
		{
			name: "Ouled Ben Abdelkader",
			gps: ["1.27641", "36.025744"],
		},
		{
			name: "Ouled Fares",
			gps: ["0", "0"],
		},
		{
			name: "Oum Drou",
			gps: ["1.383333", "36.2"],
		},
		{
			name: "Sendjas",
			gps: ["1.4", "36.066667"],
		},
		{
			name: "Sidi Abderrahmane",
			gps: ["1.1", "36.5"],
		},
		{
			name: "Sidi Akkacha",
			gps: ["1.3", "36.466667"],
		},
		{
			name: "Sobha",
			gps: ["1.109722", "36.109167"],
		},
		{
			name: "Tadjena",
			gps: ["1.133333", "36.316667"],
		},
		{
			name: "Talassa",
			gps: ["1.09108", "36.424321"],
		},
		{
			name: "Taougrite",
			gps: ["0.922852", "36.244273"],
		},
		{
			name: "Ténès",
			gps: ["1.304444", "36.512222"],
		},
		{
			name: "Zeboudja",
			gps: ["1.430283", "36.350527"],
		},
	],
	Bechar: [
		{
			name: "Béchar",
			gps: ["-2.2166", "31.6166"],
		},
		{
			name: "Erg Ferradj",
			gps: ["-2.980042", "31.33839"],
		},
		{
			name: "Ouled Khoudir",
			gps: ["-1.05748", "29.25452"],
		},
		{
			name: "Meridja",
			gps: ["0", "0"],
		},
		{
			name: "Timoudi",
			gps: ["-1.1300468", "29.3211282"],
		},
		{
			name: "Lahmar",
			gps: ["-2.2597075", "31.9333347"],
		},
		{
			name: "Béni Abbès",
			gps: ["-2.1", "30.08"],
		},
		{
			name: "Beni Ikhlef",
			gps: ["-1.6091537", "29.575062"],
		},
		{
			name: "Mechraa Houari Boumedienne",
			gps: ["-2.737988", "30.933345"],
		},
		{
			name: "Kenadsa",
			gps: ["-2.423429", "31.55901"],
		},
		{
			name: "Igli",
			gps: ["-2.291417", "30.453194"],
		},
		{
			name: "Tabelbala",
			gps: ["-3.2592487", "29.40603"],
		},
		{
			name: "Taghit",
			gps: ["-2.0304108", "30.917542"],
		},
		{
			name: "El Ouata",
			gps: ["-1.82373", "29.880541"],
		},
		{
			name: "Boukais",
			gps: ["-2.4642849", "31.9233548"],
		},
		{
			name: "Mougheul",
			gps: ["-1.8676758", "31.9171989"],
		},
		{
			name: "Abadla",
			gps: ["-2.733333", "31.016667"],
		},
		{
			name: "Kerzaz",
			gps: ["-1.4178801", "29.4534623"],
		},
		{
			name: "Ksabi",
			gps: ["-0.97992", "29.09774"],
		},
		{
			name: "Tamtert",
			gps: ["-1.8891764", "29.9101566"],
		},
		{
			name: "Beni Ounif",
			gps: ["-1.25", "32.05"],
		},
	],
	Tindouf: [
		{
			name: "Tindouf",
			gps: ["-8.15", "27.666667"],
		},
	],
	"El Tarf": [
		{
			name: "El Tarf",
			gps: ["8.31666667", "36.76666667"],
		},
		{
			name: "Aïn El Assel",
			gps: ["8.3823967", "36.7863291"],
		},
		{
			name: "Aïn Kerma",
			gps: ["8.2010365", "36.5938924"],
		},
		{
			name: "Asfour",
			gps: ["7.9764605", "36.6740296"],
		},
		{
			name: "Ben Mehidi",
			gps: ["7.9053497", "36.7720299"],
		},
		{
			name: "Berrihane",
			gps: ["8.12385", "36.83725"],
		},
		{
			name: "Besbes",
			gps: ["7.84722", "36.70222"],
		},
		{
			name: "Bougous",
			gps: ["8.36935", "36.659469"],
		},
		{
			name: "Bouhadjar",
			gps: ["8.1051636", "36.5030131"],
		},
		{
			name: "Bouteldja",
			gps: ["8.1048203", "36.5028751"],
		},
		{
			name: "Chebaita Mokhtar",
			gps: ["7.741542", "36.755768"],
		},
		{
			name: "Chefia",
			gps: ["8.038881", "36.611015"],
		},
		{
			name: "Chihani",
			gps: ["7.77566", "36.646936"],
		},
		{
			name: "Dréan",
			gps: ["7.75", "36.68333"],
		},
		{
			name: "Echatt",
			gps: ["7.875137", "36.830172"],
		},
		{
			name: "El Aioun",
			gps: ["8.596344", "36.870832"],
		},
		{
			name: "El Kala",
			gps: ["8.44333", "36.89556"],
		},
		{
			name: "El Tarf",
			gps: ["8.31377", "36.7672"],
		},
		{
			name: "Hammam Beni Salah",
			gps: ["8.0341387", "36.5198456"],
		},
		{
			name: "Lac des Oiseaux",
			gps: ["8.1179523", "36.775605"],
		},
		{
			name: "Oued Zitoun",
			gps: ["8.05744", "36.46615"],
		},
		{
			name: "Raml Souk",
			gps: ["8.53556", "36.78611"],
		},
		{
			name: "Souarekh",
			gps: ["8.5642", "36.88199"],
		},
		{
			name: "Zerizer",
			gps: ["7.8946", "36.7273"],
		},
		{
			name: "Zitouna",
			gps: ["8.2345963", "36.6681435"],
		},
	],
	"NaÃ¢ma": [
		{
			name: "Naâma",
			gps: ["0.317", "33.267"],
		},
		{
			name: "Naâma",
			gps: ["-0.314444", "33.2622"],
		},
		{
			name: "Mecheria",
			gps: ["-0.283333", "33.55"],
		},
		{
			name: "Aïn Sefra",
			gps: ["-0.5857086", "32.7556643"],
		},
		{
			name: "Tiout",
			gps: ["-0.4202271", "32.7711099"],
		},
		{
			name: "Sfissifa",
			gps: ["-0.8690143", "32.7307759"],
		},
		{
			name: "Moghrar",
			gps: ["-0.5803871", "32.517868"],
		},
		{
			name: "Assela",
			gps: ["-0.0781059", "33.0044168"],
		},
		{
			name: "Djeniene Bourezg",
			gps: ["-0.8034611", "32.3724227"],
		},
		{
			name: "Aïn Ben Khelil",
			gps: ["-0.7640648", "33.2902163"],
		},
		{
			name: "Makman Ben Amer",
			gps: ["-0.7268143", "33.7166295"],
		},
		{
			name: "Kasdir",
			gps: ["-1.3589573", "33.7096327"],
		},
		{
			name: "El Biod",
			gps: ["-0.1332521", "33.7632011"],
		},
	],
	Tissemsilt: [
		{
			name: "Tissemsilt",
			gps: ["1.81666667", "35.6"],
		},
		{
			name: "Ammari",
			gps: ["1.66226", "35.57713"],
		},
		{
			name: "Beni Chaib",
			gps: ["1.79953", "35.82014"],
		},
		{
			name: "Beni Lahcene",
			gps: ["1.68954", "35.81298"],
		},
		{
			name: "Boucaid",
			gps: ["1.61978", "35.89026"],
		},
		{
			name: "Bordj Bou Naama",
			gps: ["1.616667", "35.85"],
		},
		{
			name: "Bordj El Emir Abdelkader",
			gps: ["2.270966", "35.915747"],
		},
		{
			name: "Khemisti",
			gps: ["1.95", "35.6667"],
		},
		{
			name: "Larbaa",
			gps: ["1.4753226", "35.9130863"],
		},
		{
			name: "Lardjem",
			gps: ["1.54845", "35.74954"],
		},
		{
			name: "Layoune",
			gps: ["1.9981813", "35.6945979"],
		},
		{
			name: "Lazharia",
			gps: ["1.55999", "35.93729"],
		},
		{
			name: "Maacem",
			gps: ["1.55326", "35.65967"],
		},
		{
			name: "Melaab",
			gps: ["1.3323927", "35.7146011"],
		},
		{
			name: "Ouled Bessem",
			gps: ["1.864028", "35.6863373"],
		},
		{
			name: "Sidi Abed",
			gps: ["1.70505", "35.74514"],
		},
		{
			name: "Sidi Boutouchent",
			gps: ["1.9513", "35.82516"],
		},
		{
			name: "Sidi Lantri",
			gps: ["1.40223", "35.70152"],
		},
		{
			name: "Sidi Slimane",
			gps: ["1.68115", "35.86"],
		},
		{
			name: "Tamalaht",
			gps: ["1.6237244", "35.812611"],
		},
		{
			name: "Theniet El Had",
			gps: ["2.02878", "35.8709686"],
		},
		{
			name: "Tissemsilt",
			gps: ["1.8117142", "35.6074871"],
		},
		{
			name: "Youssoufia",
			gps: ["2.11275", "35.94806"],
		},
	],
	"El Bayadh": [
		{
			name: "El Bayadh",
			gps: ["1.02028", "33.6803"],
		},
		{
			name: "Rogassa",
			gps: ["0.9259415", "34.0183762"],
		},
		{
			name: "Stitten",
			gps: ["1.2233448", "33.757992"],
		},
		{
			name: "Brezina",
			gps: ["1.260767", "33.0993793"],
		},
		{
			name: "Ghassoul",
			gps: ["1.2030888", "33.3772724"],
		},
		{
			name: "Boualem",
			gps: ["1.5341377", "33.7290511"],
		},
		{
			name: "El Abiodh Sidi Cheikh",
			gps: ["0.53936", "32.898615"],
		},
		{
			name: "Aïn El Orak",
			gps: ["0.7385302", "33.4100573"],
		},
		{
			name: "Arbaouat",
			gps: ["0.5812401", "33.0882649"],
		},
		{
			name: "Bougtoub",
			gps: ["0.0892639", "34.0424186"],
		},
		{
			name: "El Kheiter",
			gps: ["0.0732471", "34.1433831"],
		},
		{
			name: "Kef El Ahmar",
			gps: ["0.6325722", "33.8500313"],
		},
		{
			name: "Boussemghoun",
			gps: ["0.02012", "32.86427"],
		},
		{
			name: "Chellala",
			gps: ["0.0556183", "33.0331321"],
		},
		{
			name: "Kraakda",
			gps: ["0.9572697", "33.3192686"],
		},
		{
			name: "El Bnoud",
			gps: ["0.2443814", "32.3121168"],
		},
		{
			name: "Cheguig",
			gps: ["1.22814", "34.16897"],
		},
		{
			name: "Sidi Ameur",
			gps: ["1.4330292", "33.7681957"],
		},
		{
			name: "El Mehara",
			gps: ["0.3644", "33.31163"],
		},
		{
			name: "Tousmouline",
			gps: ["0.308733", "33.6515655"],
		},
		{
			name: "Sidi Slimane",
			gps: ["1.730347", "33.847037"],
		},
		{
			name: "Sidi Tifour",
			gps: ["1.682711", "33.717201"],
		},
	],
	"Bordj Bou Arreridj": [
		{
			name: "Bordj Bou Arreridj",
			gps: ["4.766667", "36.066667"],
		},
		{
			name: "Bordj Ghédir",
			gps: ["4.89", "35.9"],
		},
		{
			name: "Bordj Zemoura",
			gps: ["4.855957", "36.292991"],
		},
		{
			name: "Colla",
			gps: ["4.661914", "36.262774"],
		},
		{
			name: "Djaafra",
			gps: ["4.66361", "36.29294"],
		},
		{
			name: "El Ach",
			gps: ["4.683333", "35.95"],
		},
		{
			name: "El Achir",
			gps: ["4.633333", "36.066667"],
		},
		{
			name: "El Anseur",
			gps: ["4.49", "36.2"],
		},
		{
			name: "El Hamadia",
			gps: ["4.747467", "35.9796037"],
		},
		{
			name: "El Main",
			gps: ["4.75", "36.366667"],
		},
		{
			name: "El M'hir",
			gps: ["4.38391", "36.11841"],
		},
		{
			name: "Ghilassa",
			gps: ["4.90625", "35.87175"],
		},
		{
			name: "Haraza",
			gps: ["4.22347", "36.15647"],
		},
		{
			name: "Hasnaoua",
			gps: ["4.79538", "36.1525"],
		},
		{
			name: "Khelil",
			gps: ["5.0290775", "36.1756433"],
		},
		{
			name: "Ksour",
			gps: ["4.59791", "35.99113"],
		},
		{
			name: "Mansoura",
			gps: ["4.4601059", "36.0818467"],
		},
		{
			name: "Medjana",
			gps: ["4.6680737", "36.1315666"],
		},
		{
			name: "Ouled Brahem",
			gps: ["5.07472", "35.87222"],
		},
		{
			name: "Ouled Dahmane",
			gps: ["3.466667", "36.366667"],
		},
		{
			name: "Ouled Sidi Brahim",
			gps: ["4.3345785", "36.228108"],
		},
		{
			name: "Rabta",
			gps: ["4.7501278", "35.9227679"],
		},
		{
			name: "Ras El Oued",
			gps: ["5.0359", "35.9498"],
		},
		{
			name: "Sidi Embarek",
			gps: ["4.91188", "36.1039"],
		},
		{
			name: "Tefreg",
			gps: ["4.733333", "36.316667"],
		},
		{
			name: "Taglait",
			gps: ["4.99974", "35.77164"],
		},
		{
			name: "Teniet En Nasr",
			gps: ["4.60123", "36.23152"],
		},
		{
			name: "Tassameurt",
			gps: ["4.823041", "36.2692932"],
		},
		{
			name: "Tixter",
			gps: ["5.0798893", "36.0471212"],
		},
	],
	Illizi: [
		{
			name: "Illizi",
			gps: ["8.4829473", "26.5080228"],
		},
		{
			name: "Djanet",
			gps: ["9.485", "24.555"],
		},
		{
			name: "Debdeb",
			gps: ["9.421463", "29.9675015"],
		},
		{
			name: "Bordj Omar Driss",
			gps: ["6.81667", "28.15"],
		},
		{
			name: "Bordj El Haouas",
			gps: ["9.4508", "24.5859"],
		},
		{
			name: "In Amenas",
			gps: ["9.565058", "28.038349"],
		},
	],
	"BoumerdÃ¨s": [
		{
			name: "Boumerdès",
			gps: ["3.48333", "36.7667"],
		},
		{
			name: "algérienne",
			gps: ["3.21667", "36.7"],
		},
		{
			name: "Boumerdès",
			gps: ["3.48333", "36.7667"],
		},
		{
			name: "Afir",
			gps: ["3.7029", "36.767596"],
		},
		{
			name: "Ammal",
			gps: ["3.590419", "36.634712"],
		},
		{
			name: "Baghlia",
			gps: ["3.8612438", "36.8155676"],
		},
		{
			name: "Ben Choud",
			gps: ["3.8805771", "36.8623517"],
		},
		{
			name: "Beni Amrane",
			gps: ["3.5922", "36.66855"],
		},
		{
			name: "Bordj Menaiel",
			gps: ["3.723056", "36.741667"],
		},
		{
			name: "Boudouaou",
			gps: ["3.4047805", "36.7313191"],
		},
		{
			name: "Boudouaou-El-Bahri",
			gps: ["3.3872711", "36.7732001"],
		},
		{
			name: "Boumerdes",
			gps: ["3.466667", "36.766667"],
		},
		{
			name: "Bouzegza Keddara",
			gps: ["3.47934", "36.62541"],
		},
		{
			name: "Chabet el Ameur",
			gps: ["3.7", "36.633333"],
		},
		{
			name: "Corso",
			gps: ["3.43333", "36.7565"],
		},
		{
			name: "Dellys",
			gps: ["3.914094", "36.913272"],
		},
		{
			name: "Djinet",
			gps: ["3.7231207", "36.8769773"],
		},
		{
			name: "El Kharrouba",
			gps: ["3.40641", "36.65768"],
		},
		{
			name: "Hammedi",
			gps: ["3.2676", "36.677"],
		},
		{
			name: "Issers",
			gps: ["3.668", "36.7234"],
		},
		{
			name: "Khemis El-Khechna",
			gps: ["3.32888", "36.64897"],
		},
		{
			name: "Larbatache",
			gps: ["3.37201", "36.63644"],
		},
		{
			name: "Leghata",
			gps: ["3.6831", "36.74902"],
		},
		{
			name: "Naciria",
			gps: ["3.828889", "36.7475"],
		},
		{
			name: "Ouled Aissa",
			gps: ["3.81431", "36.80666"],
		},
		{
			name: "Ouled Hedadj",
			gps: ["3.35", "36.72"],
		},
		{
			name: "Ouled Moussa",
			gps: ["3.368082", "36.6830127"],
		},
		{
			name: "Si Mustapha",
			gps: ["3.6153", "36.7247"],
		},
		{
			name: "Sidi Daoud",
			gps: ["3.85", "36.85"],
		},
		{
			name: "Souk El Had",
			gps: ["3.588908", "36.690926"],
		},
		{
			name: "Taourga",
			gps: ["3.946345", "36.79378"],
		},
		{
			name: "Thenia",
			gps: ["3.55388889", "36.72777778"],
		},
		{
			name: "Tidjelabine",
			gps: ["3.497222", "36.727778"],
		},
		{
			name: "Timezrit",
			gps: ["3.80625", "36.67339"],
		},
		{
			name: "Zemmouri",
			gps: ["3.6", "36.783333"],
		},
	],
	Khenchela: [
		{
			name: "Khenchela",
			gps: ["7.147", "35.434"],
		},
		{
			name: "Aïn Touila",
			gps: ["7.351656", "35.4353584"],
		},
		{
			name: "Babar",
			gps: ["7.1012878", "35.1691777"],
		},
		{
			name: "Baghai",
			gps: ["7.1143341", "35.5219228"],
		},
		{
			name: "Bouhmama",
			gps: ["6.74657", "35.32034"],
		},
		{
			name: "Chechar",
			gps: ["7.00404", "35.03799"],
		},
		{
			name: "Chelia",
			gps: ["6.77868", "35.36431"],
		},
		{
			name: "Djellal",
			gps: ["6.9", "34.916667"],
		},
		{
			name: "El Hamma",
			gps: ["7.0826197", "35.4635712"],
		},
		{
			name: "El Mahmal",
			gps: ["7.21319", "35.37381"],
		},
		{
			name: "El Oueldja",
			gps: ["6.6805458", "34.915637"],
		},
		{
			name: "Ensigha",
			gps: ["7.14298", "35.39729"],
		},
		{
			name: "Kais",
			gps: ["6.924305", "35.4946392"],
		},
		{
			name: "Khenchela",
			gps: ["7.144444", "35.431944"],
		},
		{
			name: "Khirane",
			gps: ["6.76045", "34.99861"],
		},
		{
			name: "M'Sara",
			gps: ["6.57315", "35.23857"],
		},
		{
			name: "M'Toussa",
			gps: ["7.2449684", "35.5993919"],
		},
		{
			name: "Ouled Rechache",
			gps: ["7.3531044", "35.2981216"],
		},
		{
			name: "Remila",
			gps: ["6.934948", "35.5710523"],
		},
		{
			name: "Tamza",
			gps: ["6.833333", "35.316667"],
		},
		{
			name: "Taouzient",
			gps: ["6.7526436", "35.5153212"],
		},
		{
			name: "Yabous",
			gps: ["6.64184", "35.40815"],
		},
	],
	Ghardaïa: [
		{
			name: "Ghardaïa",
			gps: ["3.67", "32.483"],
		},
		{
			name: "Berriane",
			gps: ["3.762817", "32.858825"],
		},
		{
			name: "Bounoura",
			gps: ["3.7077141", "32.4861624"],
		},
		{
			name: "Dhayet Bendhahoua",
			gps: ["3.6056646", "32.5370241"],
		},
		{
			name: "El Atteuf",
			gps: ["3.7478828", "32.4776188"],
		},
		{
			name: "El Guerrara",
			gps: ["4.4922066", "32.7903051"],
		},
		{
			name: "El Menia",
			gps: ["2.8797913", "30.5889377"],
		},
		{
			name: "Ghardaïa",
			gps: ["3.683333", "32.483333"],
		},
		{
			name: "Hassi Fehal",
			gps: ["3.6742627", "31.605285"],
		},
		{
			name: "Hassi Gara",
			gps: ["2.914896", "30.5522091"],
		},
		{
			name: "Mansoura",
			gps: ["3.7459731", "31.979559"],
		},
		{
			name: "Metlili",
			gps: ["3.6275482", "32.2729098"],
		},
		{
			name: "Sebseb",
			gps: ["3.5890102", "32.1640606"],
		},
		{
			name: "Zelfana",
			gps: ["4.2261314", "32.3972113"],
		},
	],
	Relizane: [
		{
			name: "Relizane",
			gps: ["0.55", "35.73333333"],
		},
		{
			name: "Aïn Rahma",
			gps: ["0.3929", "35.62464"],
		},
		{
			name: "Aïn Tarek",
			gps: ["1.130991", "35.7810566"],
		},
		{
			name: "Ammi Moussa",
			gps: ["1.11667", "35.86667"],
		},
		{
			name: "Belassel Bouzegza",
			gps: ["0.4989", "35.84067"],
		},
		{
			name: "Bendaoud",
			gps: ["0.52024", "35.71914"],
		},
		{
			name: "Beni Dergoun",
			gps: ["0.8", "35.78"],
		},
		{
			name: "Beni Zentis",
			gps: ["0.6636214", "36.1114865"],
		},
		{
			name: "Dar Ben Abdellah",
			gps: ["0.68719", "35.70098"],
		},
		{
			name: "Djidioua",
			gps: ["0.8306694", "35.9293707"],
		},
		{
			name: "El Guettar",
			gps: ["0.81576", "36.08781"],
		},
		{
			name: "El Hamadna",
			gps: ["0.75", "35.9"],
		},
		{
			name: "El Hassi",
			gps: ["1.05374", "35.77064"],
		},
		{
			name: "El Matmar",
			gps: ["0.4617691", "35.7309067"],
		},
		{
			name: "El Ouldja",
			gps: ["1.120723", "35.910803"],
		},
		{
			name: "Had Echkalla",
			gps: ["1.1472774", "35.6791913"],
		},
		{
			name: "Hamri",
			gps: ["0.69908", "36.02117"],
		},
		{
			name: "Kalaa",
			gps: ["0.32838", "35.58062"],
		},
		{
			name: "Lahlef",
			gps: ["0.9807014", "35.8928049"],
		},
		{
			name: "Mazouna",
			gps: ["0.876503", "36.125674"],
		},
		{
			name: "Mediouna",
			gps: ["0.75", "36.11667"],
		},
		{
			name: "Mendes",
			gps: ["0.8619118", "35.6497288"],
		},
		{
			name: "Merdja Sidi Abed",
			gps: ["1.0101628", "36.0034237"],
		},
		{
			name: "Ouarizane",
			gps: ["0.8990765", "36.0454209"],
		},
		{
			name: "Oued Essalem",
			gps: ["0.9246111", "35.5793249"],
		},
		{
			name: "Oued Rhiou",
			gps: ["0.916667", "35.966667"],
		},
		{
			name: "Ouled Aiche",
			gps: ["1.0008717", "35.8419602"],
		},
		{
			name: "Oued El Djemaa",
			gps: ["0.6814098", "35.7972093"],
		},
		{
			name: "Ouled Sidi Mihoub",
			gps: ["0.6917953", "35.9739775"],
		},
		{
			name: "Ramka",
			gps: ["1.33229", "35.8555"],
		},
		{
			name: "Relizane",
			gps: ["0.55", "35.733333"],
		},
		{
			name: "Sidi Khettab",
			gps: ["0.5111217", "35.9108119"],
		},
		{
			name: "Sidi Lazreg",
			gps: ["0.77503", "35.64372"],
		},
		{
			name: "Sidi M'Hamed Ben Ali",
			gps: ["0.84334", "36.14329"],
		},
		{
			name: "Sidi M'Hamed Benaouda",
			gps: ["0.58874", "35.60409"],
		},
		{
			name: "Sidi Saada",
			gps: ["0.2066803", "35.687139"],
		},
		{
			name: "Souk El Had",
			gps: ["1.24802", "35.95638"],
		},
		{
			name: "Yellel",
			gps: ["0.353611", "35.721944"],
		},
		{
			name: "Zemmora",
			gps: ["0.75", "35.716667"],
		},
	],
	Mila: [
		{
			name: "Mila",
			gps: ["6.26666667", "36.45"],
		},
		{
			name: "Ahmed Rachedi",
			gps: ["6.125338", "36.390508"],
		},
		{
			name: "Aïn Beida Harriche",
			gps: ["5.900749", "36.398702"],
		},
		{
			name: "Aïn Mellouk",
			gps: ["6.176435", "36.274991"],
		},
		{
			name: "Aïn Tine",
			gps: ["6.3250351", "36.3966563"],
		},
		{
			name: "Amira Arrès",
			gps: ["6.3963", "36.3219"],
		},
		{
			name: "Benyahia Abderrahmane",
			gps: ["6.0129118", "36.2329371"],
		},
		{
			name: "Bouhatem",
			gps: ["6.0141134", "36.3038513"],
		},
		{
			name: "Chelghoum Laid",
			gps: ["6.166667", "36.166667"],
		},
		{
			name: "Chigara",
			gps: ["6.22238", "36.56008"],
		},
		{
			name: "Derradji Bousselah",
			gps: ["5.958066", "36.339925"],
		},
		{
			name: "El Mechira",
			gps: ["6.231267", "36.010247"],
		},
		{
			name: "Elayadi Barbes",
			gps: ["5.880946", "36.440386"],
		},
		{
			name: "Ferdjioua",
			gps: ["5.9457493", "36.4089187"],
		},
		{
			name: "Grarem Gouga",
			gps: ["6.333333", "36.516667"],
		},
		{
			name: "Hamala",
			gps: ["6.3399696", "36.5716306"],
		},
		{
			name: "Mila",
			gps: ["6.266667", "36.45"],
		},
		{
			name: "Minar Zarza",
			gps: ["5.53991", "36.31737"],
		},
		{
			name: "Oued Athmania",
			gps: ["6.2862396", "36.2498108"],
		},
		{
			name: "Oued Endja",
			gps: ["6.1207581", "36.4306748"],
		},
		{
			name: "Oued Seguen",
			gps: ["6.419985", "36.170991"],
		},
		{
			name: "Ouled Khalouf",
			gps: ["6.138523", "36.058928"],
		},
		{
			name: "Rouached",
			gps: ["6.2669", "36.27435"],
		},
		{
			name: "Sidi Khelifa",
			gps: ["6.300383", "36.34969"],
		},
		{
			name: "Sidi Merouane",
			gps: ["6.2624", "36.5216"],
		},
		{
			name: "Tadjenanet",
			gps: ["6", "36.116667"],
		},
		{
			name: "Tassadane Haddada",
			gps: ["5.9460966", "36.4089248"],
		},
		{
			name: "Teleghma",
			gps: ["6.364167", "36.115278"],
		},
		{
			name: "Terrai Bainen",
			gps: ["6.1222084", "36.5308069"],
		},
		{
			name: "Tessala Lemtaï",
			gps: ["5.5905", "36.34225"],
		},
		{
			name: "Tiberguent",
			gps: ["6.0397339", "36.4095404"],
		},
		{
			name: "Yahia Beni Guecha",
			gps: ["5.59266", "36.23521"],
		},
		{
			name: "Zeghaia",
			gps: ["6.172538", "36.467949"],
		},
		{
			name: "Mila",
			gps: ["6.266667", "36.45"],
		},
		{
			name: "Minar Zarza",
			gps: ["5.53991", "36.31737"],
		},
		{
			name: "Oued Athmania",
			gps: ["6.2862396", "36.2498108"],
		},
		{
			name: "Oued Endja",
			gps: ["6.1207581", "36.4306748"],
		},
		{
			name: "Oued Seguen",
			gps: ["6.419985", "36.170991"],
		},
		{
			name: "Ouled Khalouf",
			gps: ["6.138523", "36.058928"],
		},
		{
			name: "Rouached",
			gps: ["6.2669", "36.27435"],
		},
		{
			name: "Sidi Khelifa",
			gps: ["6.300383", "36.34969"],
		},
		{
			name: "Sidi Mérouane",
			gps: ["6.2624", "36.5216"],
		},
		{
			name: "Tadjenanet",
			gps: ["6", "36.116667"],
		},
		{
			name: "Tassadane Haddada",
			gps: ["5.9460966", "36.4089248"],
		},
		{
			name: "Teleghma",
			gps: ["6.364167", "36.115278"],
		},
		{
			name: "Terrai Bainen",
			gps: ["6.1222084", "36.5308069"],
		},
		{
			name: "Tessala Lemtaï",
			gps: ["5.5905", "36.34225"],
		},
		{
			name: "Tiberguent",
			gps: ["6.0397339", "36.4095404"],
		},
		{
			name: "Yahia Beni Guecha",
			gps: ["5.59266", "36.23521"],
		},
		{
			name: "Zeghaia",
			gps: ["6.172538", "36.467949"],
		},
	],
	"El Oued": [
		{
			name: "El Oued",
			gps: ["6.867399", "33.368313"],
		},
		{
			name: "Robbah",
			gps: ["6.9107437", "33.2809965"],
		},
		{
			name: "Oued El Alenda",
			gps: ["6.75724", "33.22878"],
		},
		{
			name: "Bayadha",
			gps: ["6.914778", "33.33458"],
		},
		{
			name: "Nakhla",
			gps: ["6.9515133", "33.2773011"],
		},
		{
			name: "Guemar",
			gps: ["6.7971897", "33.492162"],
		},
		{
			name: "Kouinine",
			gps: ["6.8262863", "33.4042639"],
		},
		{
			name: "Reguiba",
			gps: ["6.7123032", "33.5642125"],
		},
		{
			name: "Hamraia",
			gps: ["6.2305784", "34.1109518"],
		},
		{
			name: "Taghzout",
			gps: ["6.7958164", "33.474838"],
		},
		{
			name: "Debila",
			gps: ["6.937995", "33.5063336"],
		},
		{
			name: "Hassani Abdelkrim",
			gps: ["6.8948804", "33.4771662"],
		},
		{
			name: "Hassi Khalifa",
			gps: ["7.0287609", "33.6011804"],
		},
		{
			name: "Taleb Larbi",
			gps: ["7.517309", "33.727552"],
		},
		{
			name: "Douar El Ma",
			gps: ["7.3759", "33.26275"],
		},
		{
			name: "Sidi Aoun",
			gps: ["6.90079", "33.54212"],
		},
		{
			name: "Trifaoui",
			gps: ["6.9357204", "33.4232394"],
		},
		{
			name: "Magrane",
			gps: ["6.95", "33.58333"],
		},
		{
			name: "Beni Guecha",
			gps: ["7.336121", "33.998027"],
		},
		{
			name: "Ourmas",
			gps: ["6.780839", "33.4136395"],
		},
		{
			name: "Still",
			gps: ["5.9138203", "34.2594156"],
		},
		{
			name: "M'Rara",
			gps: ["5.28792", "33.65265"],
		},
		{
			name: "Sidi Khellil",
			gps: ["5.9587955", "33.8376985"],
		},
		{
			name: "Tendla",
			gps: ["6.0337687", "33.6752114"],
		},
		{
			name: "El Ogla",
			gps: ["6.9456768", "33.2465839"],
		},
		{
			name: "Mih Ouansa",
			gps: ["6.70529", "33.19708"],
		},
		{
			name: "El M'Ghair",
			gps: ["5.924205", "33.9505513"],
		},
		{
			name: "Djamaa",
			gps: ["5.9909821", "33.5313787"],
		},
		{
			name: "Oum Touyour",
			gps: ["5.8333111", "34.1533662"],
		},
		{
			name: "Sidi Amrane",
			gps: ["6.0117531", "33.4963135"],
		},
	],
	"Souk Ahras": [
		{
			name: "Souk Ahras",
			gps: ["7.954102", "36.287456"],
		},
		{
			name: "Sedrata",
			gps: ["7.53147", "36.1284"],
		},
		{
			name: "Hanancha",
			gps: ["7.83333", "36.21667"],
		},
		{
			name: "Mechroha",
			gps: ["7.835441", "36.357232"],
		},
		{
			name: "Ouled Driss",
			gps: ["8.01667", "36.35"],
		},
		{
			name: "Tiffech",
			gps: ["7.69879", "36.15659"],
		},
		{
			name: "Zaarouria",
			gps: ["7.9577065", "36.2272079"],
		},
		{
			name: "Taoura",
			gps: ["8.042679", "36.170031"],
		},
		{
			name: "Drea",
			gps: ["7.883333", "36.116667"],
		},
		{
			name: "Heddada",
			gps: ["8.3377472", "36.2306963"],
		},
		{
			name: "Khedara",
			gps: ["8.317938", "36.283859"],
		},
		{
			name: "Merahna",
			gps: ["8.174171", "36.196841"],
		},
		{
			name: "Ouled Moumene",
			gps: ["8.39833", "36.41083"],
		},
		{
			name: "Bir Bou Haouch",
			gps: ["7.4666667", "36.05"],
		},
		{
			name: "M'daourouch",
			gps: ["7.819721", "36.074756"],
		},
		{
			name: "Oum El Adhaim",
			gps: ["7.602539", "36.033831"],
		},
		{
			name: "Aïn Zana",
			gps: ["8.1911659", "36.4004909"],
		},
		{
			name: "Aïn Soltane",
			gps: ["7.36911", "36.17844"],
		},
		{
			name: "Ouillen",
			gps: ["8.12217", "36.32926"],
		},
		{
			name: "Sidi Fredj",
			gps: ["8.1952751", "36.1537121"],
		},
		{
			name: "Safel El Ouiden",
			gps: ["7.46117", "35.98394"],
		},
		{
			name: "Ragouba",
			gps: ["7.71833", "36.08309"],
		},
		{
			name: "Khemissa",
			gps: ["7.65909", "36.1885"],
		},
		{
			name: "Oued Keberit",
			gps: ["7.91667", "35.93333"],
		},
		{
			name: "Terraguelt",
			gps: ["7.56007", "35.9032"],
		},
		{
			name: "Zouabi",
			gps: ["7.44394", "36.11481"],
		},
	],
	"Aïn Temouchent": [
		{
			name: "Aïn Témouchent",
			gps: ["-1.13333", "35.3"],
		},
		{
			name: "Aïn Témouchent",
			gps: ["-1.14099", "35.28954"],
		},
		{
			name: "Aïn Tolba",
			gps: ["-1.24889", "35.2483"],
		},
		{
			name: "Aoubellil",
			gps: ["-0.992578", "35.136467"],
		},
		{
			name: "Beni Saf",
			gps: ["-1.383671", "35.302089"],
		},
		{
			name: "Bouzedjar",
			gps: ["-1.166954", "35.574403"],
		},
		{
			name: "Chaabat El Leham",
			gps: ["-1.101063", "35.336077"],
		},
		{
			name: "Chentouf",
			gps: ["-1.029314", "35.303673"],
		},
		{
			name: "El Amria",
			gps: ["-1.014905", "35.524726"],
		},
		{
			name: "El Emir Abdelkader",
			gps: ["-1.403305", "35.224346"],
		},
		{
			name: "El Malah",
			gps: ["-1.092941", "35.392592"],
		},
		{
			name: "El Messaid",
			gps: ["-1.122054", "35.541961"],
		},
		{
			name: "Hammam Bouhadjar",
			gps: ["-0.970488", "35.379122"],
		},
		{
			name: "Hassasna",
			gps: ["-0.987117", "35.272216"],
		},
		{
			name: "Hassi El Ghella",
			gps: ["-1.053014", "35.454955"],
		},
		{
			name: "Oued Berkeche",
			gps: ["-0.983722", "35.22226"],
		},
		{
			name: "Oued Sabah",
			gps: ["-0.811476", "35.372509"],
		},
		{
			name: "Ouled Boudjemaa",
			gps: ["-1.192467", "35.473087"],
		},
		{
			name: "Ouled Kihal",
			gps: ["-1.235565", "35.368681"],
		},
		{
			name: "Oulhaça El Gheraba",
			gps: ["-1.504494", "35.233045"],
		},
		{
			name: "Sidi Ben Adda",
			gps: ["-1.181293", "35.305306"],
		},
		{
			name: "Sidi Boumedienne",
			gps: ["-0.89294", "35.354152"],
		},
		{
			name: "Sidi Ouriache",
			gps: ["-1.506329", "35.188751"],
		},
		{
			name: "Sidi Safi",
			gps: ["-1.313403", "35.280555"],
		},
		{
			name: "Tamzoura",
			gps: ["-0.656589", "35.409418"],
		},
		{
			name: "Terga",
			gps: ["-1.177527", "35.418486"],
		},
	],
	Tipaza: [
		{
			name: "Tipaza",
			gps: ["2.449436", "36.591859"],
		},
		{
			name: "Menaceur",
			gps: ["2.240524", "36.494734"],
		},
		{
			name: "Larhat",
			gps: ["1.801414", "36.55867"],
		},
		{
			name: "Douaouda",
			gps: ["2.783333", "36.666667"],
		},
		{
			name: "Bourkika",
			gps: ["2.475915", "36.4931118"],
		},
		{
			name: "Khemisti",
			gps: ["2.6808357", "36.62586"],
		},
		{
			name: "Aghbal",
			gps: ["1.83903", "36.49183"],
		},
		{
			name: "Hadjout",
			gps: ["2.41382", "36.51257"],
		},
		{
			name: "Sidi Amar",
			gps: ["2.306373", "36.542407"],
		},
		{
			name: "Gouraya",
			gps: ["1.904926", "36.567426"],
		},
		{
			name: "Nador",
			gps: ["2.3929596", "36.5697694"],
		},
		{
			name: "Chaiba",
			gps: ["2.7292872", "36.6245347"],
		},
		{
			name: "Aïn Tagourait",
			gps: ["2.60951", "36.603608"],
		},
		{
			name: "Cherchell",
			gps: ["2.19718", "36.6085"],
		},
		{
			name: "Damous",
			gps: ["1.7042971", "36.5494659"],
		},
		{
			name: "Merad",
			gps: ["2.4267769", "36.4762392"],
		},
		{
			name: "Fouka",
			gps: ["2.75", "36.666667"],
		},
		{
			name: "Bou Ismaïl",
			gps: ["2.69007", "36.64262"],
		},
		{
			name: "Ahmar El Aïn",
			gps: ["2.563934", "36.478172"],
		},
		{
			name: "Bouharoun",
			gps: ["2.654829", "36.623381"],
		},
		{
			name: "Sidi Ghiles",
			gps: ["2.1227646", "36.5825211"],
		},
		{
			name: "Messelmoun",
			gps: ["2.002387", "36.563186"],
		},
		{
			name: "Sidi Rached",
			gps: ["2.533207", "36.562393"],
		},
		{
			name: "Koléa",
			gps: ["2.76495", "36.640463"],
		},
		{
			name: "Attatba",
			gps: ["2.6752567", "36.5733539"],
		},
		{
			name: "Sidi Semiane",
			gps: ["2.09722", "36.50278"],
		},
		{
			name: "Beni Milleuk",
			gps: ["1.749701", "36.416862"],
		},
		{
			name: "Hadjeret Ennous",
			gps: ["2.051997", "36.57325"],
		},
	],
	Oran: [
		{
			name: "Oran",
			gps: ["-0.649256", "35.702788"],
		},
		{
			name: "Gdyel",
			gps: ["-0.423746", "35.782171"],
		},
		{
			name: "Bir El Djir",
			gps: ["-0.554123", "35.736759"],
		},
		{
			name: "Hassi Bounif",
			gps: ["-0.5", "35.7"],
		},
		{
			name: "Es Senia",
			gps: ["-0.616667", "35.65"],
		},
		{
			name: "Arzew",
			gps: ["-0.32", "35.87"],
		},
		{
			name: "Bethioua",
			gps: ["-0.259573", "35.80471"],
		},
		{
			name: "Marsat El Hadjadj",
			gps: ["-0.166667", "35.783333"],
		},
		{
			name: "Aïn El Turk",
			gps: ["-0.75", "35.73"],
		},
		{
			name: "El Ançor",
			gps: ["-0.868961", "35.686729"],
		},
		{
			name: "Oued Tlelat",
			gps: ["-0.45", "35.55"],
		},
		{
			name: "Tafraoui",
			gps: ["-0.5271427", "35.4830526"],
		},
		{
			name: "Sidi Chami",
			gps: ["-0.52", "35.65"],
		},
		{
			name: "Boufatis",
			gps: ["-0.4113007", "35.6796096"],
		},
		{
			name: "Mers El Kébir",
			gps: ["-0.72", "35.74"],
		},
		{
			name: "Bou-Sfer",
			gps: ["-0.810556", "35.7108"],
		},
		{
			name: "El Kerma",
			gps: ["-0.5785847", "35.6127206"],
		},
		{
			name: "El Braya",
			gps: ["-0.519501", "35.6296485"],
		},
		{
			name: "Hassi Ben Okba",
			gps: ["-0.4657173", "35.7294564"],
		},
		{
			name: "Ben Freha",
			gps: ["-0.419176", "35.693374"],
		},
		{
			name: "Hassi Mefsoukh",
			gps: ["-0.374254", "35.801404"],
		},
		{
			name: "Sidi Benyebka",
			gps: ["-0.394682", "35.829435"],
		},
		{
			name: "Misserghin",
			gps: ["-0.729912", "35.619939"],
		},
		{
			name: "Boutlelis",
			gps: ["-0.899924", "35.572955"],
		},
		{
			name: "Aïn El Kerma",
			gps: ["-0.975702", "35.648979"],
		},
		{
			name: "Aïn El Bia",
			gps: ["-0.283333", "35.816667"],
		},
		{
			name: "alaimia",
			gps: ["-0.21943", "35.67492"],
		},
	],
	"Ain Defla": [
		{
			name: "Aïn Defla",
			gps: ["2.16667", "36.3167"],
		},
		{
			name: "Aïn Defla",
			gps: ["1.9703", "36.2652"],
		},
		{
			name: "Aïn Bouyahia",
			gps: ["1.766667", "36.283333"],
		},
		{
			name: "Aïn Defla",
			gps: ["1.9703", "36.2652"],
		},
		{
			name: "Aïn Lechiekh",
			gps: ["2.40417", "36.1569"],
		},
		{
			name: "Aïn Soltane",
			gps: ["2.294925", "36.247128"],
		},
		{
			name: "Aïn Torki",
			gps: ["2.301389", "36.332778"],
		},
		{
			name: "Arib",
			gps: ["2.066667", "36.283333"],
		},
		{
			name: "Bathia",
			gps: ["1.83917", "35.91616"],
		},
		{
			name: "Belaas",
			gps: ["1.8512", "35.9821"],
		},
		{
			name: "Ben Allal",
			gps: ["2.16", "36.31"],
		},
		{
			name: "Birbouche",
			gps: ["2.483333", "36.1"],
		},
		{
			name: "Bir Ould Khelifa",
			gps: ["2.233333", "36.183333"],
		},
		{
			name: "Bordj Emir Khaled",
			gps: ["2.2056", "36.1222"],
		},
		{
			name: "Boumedfaa",
			gps: ["2.47651", "36.370321"],
		},
		{
			name: "Bourached",
			gps: ["1.9292", "36.1691"],
		},
		{
			name: "Djelida",
			gps: ["2.083333", "36.2"],
		},
		{
			name: "Djemaa Ouled Cheikh",
			gps: ["2.005", "36.07793"],
		},
		{
			name: "Djendel",
			gps: ["2.40901", "36.218657"],
		},
		{
			name: "El Abadia",
			gps: ["1.684444", "36.269167"],
		},
		{
			name: "El Amra",
			gps: ["1.847", "36.305"],
		},
		{
			name: "El Attaf",
			gps: ["1.6702", "36.2229"],
		},
		{
			name: "El Hassania",
			gps: ["1.91699", "35.94641"],
		},
		{
			name: "El Maine",
			gps: ["1.75824", "36.05086"],
		},
		{
			name: "Hammam Righa",
			gps: ["2.4", "36.383333"],
		},
		{
			name: "Hoceinia",
			gps: ["2.4", "36.316667"],
		},
		{
			name: "Khemis Miliana",
			gps: ["2.2", "36.26"],
		},
		{
			name: "Mekhatria",
			gps: ["1.9597495", "36.3025793"],
		},
		{
			name: "Miliana",
			gps: ["2.21", "36.29"],
		},
		{
			name: "Oued Chorfa",
			gps: ["2.516667", "36.2"],
		},
		{
			name: "Oued Djemaa",
			gps: ["0.683333", "35.8"],
		},
		{
			name: "Rouina",
			gps: ["1.816667", "36.25"],
		},
		{
			name: "Sidi Lakhdar",
			gps: ["2.161725", "36.265349"],
		},
		{
			name: "Tacheta Zougagha",
			gps: ["1.65", "36.35"],
		},
		{
			name: "Tarik Ibn Ziad",
			gps: ["2.15", "36"],
		},
		{
			name: "Tiberkanine",
			gps: ["1.633333", "36.166667"],
		},
		{
			name: "Zeddine",
			gps: ["1.85", "36.166667"],
		},
		{
			name: "Aïn Defla",
			gps: ["1.9703", "36.2652"],
		},
		{
			name: "Aïn Lechiakh",
			gps: ["2.40417", "36.1569"],
		},
		{
			name: "Bathia",
			gps: ["1.83917", "35.91616"],
		},
		{
			name: "Bordj Emir Khaled",
			gps: ["2.2056", "36.1222"],
		},
		{
			name: "Boumedfaa",
			gps: ["2.47651", "36.370321"],
		},
		{
			name: "Djendel",
			gps: ["2.40901", "36.218657"],
		},
		{
			name: "Djelida",
			gps: ["2.083333", "36.2"],
		},
		{
			name: "El Abadia",
			gps: ["1.684444", "36.269167"],
		},
		{
			name: "El Amra",
			gps: ["1.847", "36.305"],
		},
		{
			name: "El Attaf",
			gps: ["1.6702", "36.2229"],
		},
		{
			name: "Hammam Righa",
			gps: ["2.4", "36.383333"],
		},
		{
			name: "Khemis Miliana",
			gps: ["2.2", "36.26"],
		},
		{
			name: "Miliana",
			gps: ["2.21", "36.29"],
		},
		{
			name: "Rouina",
			gps: ["1.816667", "36.25"],
		},
		{
			name: "Aïn Defla",
			gps: ["1.9703", "36.2652"],
		},
		{
			name: "Aïn Lechiekh",
			gps: ["2.40417", "36.1569"],
		},
		{
			name: "Aïn Soltane",
			gps: ["2.294925", "36.247128"],
		},
		{
			name: "Aïn Torki",
			gps: ["2.301389", "36.332778"],
		},
		{
			name: "Arib",
			gps: ["2.066667", "36.283333"],
		},
		{
			name: "Bathia",
			gps: ["1.83917", "35.91616"],
		},
		{
			name: "Belaas",
			gps: ["1.8512", "35.9821"],
		},
		{
			name: "Ben Allal",
			gps: ["2.16", "36.31"],
		},
		{
			name: "Birbouche",
			gps: ["2.483333", "36.1"],
		},
		{
			name: "Bir Ould Khelifa",
			gps: ["2.233333", "36.183333"],
		},
		{
			name: "Bordj Emir Khaled",
			gps: ["2.2056", "36.1222"],
		},
		{
			name: "Boumedfaa",
			gps: ["2.47651", "36.370321"],
		},
		{
			name: "Bourached",
			gps: ["1.9292", "36.1691"],
		},
		{
			name: "Djelida",
			gps: ["2.083333", "36.2"],
		},
		{
			name: "Djemaa Ouled Cheikh",
			gps: ["2.005", "36.07793"],
		},
		{
			name: "Djendel",
			gps: ["2.40901", "36.218657"],
		},
		{
			name: "El Abadia",
			gps: ["1.684444", "36.269167"],
		},
		{
			name: "El Amra",
			gps: ["1.847", "36.305"],
		},
		{
			name: "El Attaf",
			gps: ["1.6702", "36.2229"],
		},
		{
			name: "El Hassania",
			gps: ["1.91699", "35.94641"],
		},
		{
			name: "El Maine",
			gps: ["1.75824", "36.05086"],
		},
		{
			name: "Hammam Righa",
			gps: ["2.4", "36.383333"],
		},
		{
			name: "Hoceinia",
			gps: ["2.4", "36.316667"],
		},
		{
			name: "Khemis Miliana",
			gps: ["2.2", "36.26"],
		},
		{
			name: "Mekhatria",
			gps: ["1.9597495", "36.3025793"],
		},
		{
			name: "Miliana",
			gps: ["2.21", "36.29"],
		},
		{
			name: "Oued Chorfa",
			gps: ["2.516667", "36.2"],
		},
		{
			name: "Oued Djemaa",
			gps: ["0.683333", "35.8"],
		},
		{
			name: "Rouina",
			gps: ["1.816667", "36.25"],
		},
		{
			name: "Sidi Lakhdar",
			gps: ["2.161725", "36.265349"],
		},
		{
			name: "Tacheta Zougagha",
			gps: ["1.65", "36.35"],
		},
		{
			name: "Tarik Ibn Ziad",
			gps: ["2.15", "36"],
		},
		{
			name: "Tiberkanine",
			gps: ["1.633333", "36.166667"],
		},
		{
			name: "Zeddine",
			gps: ["1.85", "36.166667"],
		},
	],
};
