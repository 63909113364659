export class charterManageBookingsSaves {
	useService: boolean = false;
	firstDay;
	lastDay;
	userB2B;
	isAdmin;
	customers;

	airlines;
	airports;

	/* bookings display */
	bookings = {
		bookings: null,
		originalBookings: null,
		tableBookings: null,
	};

	/* date */
	date = {
		dateType: null,
		dateTypeValue: null,
	};

	/* agencies */
	agenciesData = {
		agencies: null,
		agenciesDPD: null,
		selectedAgencyValue: null,
		selectedAgency: null,
	};

	/* platform */
	platformsData = {
		platformsList: null,
		selectedPlatformValue: null,
		selectedPlatform: null,
		platformDPD: null,
	};

	/* validating carriers */
	validatingCarriersData = {
		validatingCarriersCodes: null,
		validatingCarriersLabels: null,
		selectedCompanyValue: null,
		selectedCompany: null,
	};

	/* input text */
	customerName;

	constructor() {}
}
