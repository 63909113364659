import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedRoomModel } from "@app/shared/models/sharedRoomModel";
import { DateService } from "@app/shared/services/date.service";
import { NavigationService } from "@app/shared/services/navigation.service";
import { cloneDeep } from "lodash";
import { Observable, ObservableInput, of } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { BaseService } from "../../../shared/services/base.service";
import { HotelService } from "../../../shared/services/hotel.service";
import {
	AVAILABILITY_CHILD_ROUTE,
	HOTEL_BOOKING_ROUTE,
} from "../../../shared/services/routes.pages";
import { ShareVars } from "../../../shared/services/share.vars";
import { NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import {
	CustomDatepickerI18nService,
	I18n,
} from "@app/shared/services/custom-datepicker-i18n.service";
import { LocalizationService } from "@app/shared/services/localization.service";
import { environment } from "environments/environment";
import { MimirService } from "@app/shared/services/mimir.service";

type SearchParams = {
	hotelDeparture: string;
	checkIn: string;
	checkOut: string;
	nights: number;
	rooms: number;
	adult_total: number;
	child_total: number;
}

@Component({
	selector: "app-hotel-search-shared",
	templateUrl: "./hotel-search-shared.component.html",
	styleUrls: ["./hotel-search-shared.component.scss"],
	providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }],
})
export class HotelSearchSharedComponent implements OnInit {
	/*for flight hotel search*/
	@Input() selectedCity: string;
	@Input() flightAdults: number;
	@Input() flightChildren: number;
	@Input() flightChildrenAge: [];
	@Input() flightCheckIn: string;
	@Input() flightCheckOut: string;
	@Input() flightSelectedHotel: any;
	@Input() flightHotelNights: number;
	@Input() flightHotelRooms: number;
	@Input() flightHotelProviders: [];
	/*for flight hotel search*/

	@Input() searchSource: string;

	isCheckInDateValid;
	minDate;
	hotelDeparture;
	hotelDepartureName: string = null;
	hotelRooms: number = 1;
	hotelNights: number = 1;
	hotelCheckInDate;
	hotelCheckOutDate;
	hotelRoomsToBook = Array<SharedRoomModel>();
	minDateCheckOut;
	enableCheckOut: boolean = false;
	progressBarValue: boolean = false;
	errorHotelsList;
	hotelProviders = new Array();
	shareVarsRef;

	destinationInputID: string;
	checkINDateID: string;
	checkOUTDateID: string;

	availabilityRoute: string = HOTEL_BOOKING_ROUTE + AVAILABILITY_CHILD_ROUTE;
	availabilityRouteParams = {};
	usingAvailabilityRouteParams: boolean = false;
	show_dropdown: boolean = false;
	maxNightsList = Array.from(Array(30).keys()).map((x) => ++x);

	adultNationalityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		ariaButtonsTextTranslation: {},
		dropdownTypeString: false,
	};
	nationality = "DZA-DZ";

	@ViewChild("dropdown_rooms") dropdown_rooms: ElementRef;
	@ViewChild("dropdown_rooms_button") dropdown_rooms_button: ElementRef;

	@HostListener("document:click", ["$event"]) clickout($event) {
		if (this.dropdown_rooms.nativeElement.contains($event.target)) {
			this.show_dropdown = true;
		} else {
			if (!this.dropdown_rooms_button.nativeElement.contains($event.target)) {
				this.show_dropdown = false;
			}
		}
	}

	constructor(
		private hotelService: HotelService,
		private baseService: BaseService,
		private navigationService: NavigationService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private localizationService: LocalizationService,
		private mimirService: MimirService
	) {
		this.minDate = DateService.getTodayDate();

		this.router.routeReuseStrategy.shouldReuseRoute = function () {
			return false;
		};
	}

	ngOnInit() {
		this.shareVarsRef = ShareVars;

		this.destinationInputID = this.searchSource + "_search_destination_input";
		this.checkINDateID = this.searchSource + "_search_chek_in_date_input";
		this.checkOUTDateID = this.searchSource + "_search_chek_out_date_input";
		if (this.shareVarsRef.countryCodes.length > 0) {
			this.initCountriesDpd();
		} else {
			this.localizationService.getCountries().subscribe((data) => {
				this.shareVarsRef.setCountriesInfo(data);
				this.initCountriesDpd();
			});
		}

		switch (this.searchSource) {
			case "hotel":
				this.initSearchHotels();
				break;

			case "hotel_availability":
				this.activatedRoute.queryParams.subscribe((urlParams) => {
					if (Object.keys(urlParams).length == 0) {
						this.usingAvailabilityRouteParams = false;
					} else {
						this.usingAvailabilityRouteParams = true;
					}

					this.initSearchHotels(urlParams);
				});
				break;

			case "flight":
				this.initSearchFlight();
				break;
		}
	}

	initCountriesDpd() {
		this.adultNationalityDropdown.ariaButtonsText = this.shareVarsRef.countryCodes;
		this.adultNationalityDropdown.ariaButtonsTextTranslation =
			this.shareVarsRef.countryNameDictionnary;

		for (let i = 0; i < this.adultNationalityDropdown.ariaButtonsText.length; i++) {
			this.adultNationalityDropdown.ariaButtonsID[i] =
				"flight_passenger_pax_adult_nationality";
		}
	}

	initSearchFlight() {
		this.createRoom(this.flightAdults, this.flightChildren, this.flightChildrenAge);

		this.hotelCheckInDate = DateService.toObjectDate(this.hotelCheckInDate, this.flightCheckIn);
		this.hotelCheckOutDate = DateService.toObjectDate(
			this.hotelCheckOutDate,
			this.flightCheckOut
		);

		let varDate = DateService.toNewDate(this.hotelCheckInDate);
		varDate.setDate(varDate.getDate() + 1);
		this.minDateCheckOut = DateService.setDate(this.minDateCheckOut, varDate);
		this.enableCheckOut = true;

		this.hotelDepartureName = this.flightSelectedHotel.description;
		this.hotelDeparture = cloneDeep(this.flightSelectedHotel);

		this.hotelNights = this.flightHotelNights;
		this.hotelRooms = this.flightHotelRooms;

		this.hotelProviders = cloneDeep(this.flightHotelProviders);
	}

	inputSearchHotel = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap((term) => this.displayAutocompleteResults(term))
		);
	};

	formatterHotel = (result: string) => {
		return result["description"];
	};

	displayAutocompleteResults(term): ObservableInput<any> {
		let result = null;
		this.progressBarValue = true;

		result = this.hotelService
			.getAutocompleteHotel(term)
			.map((response: Response) => this.mapAutocompleteData(response))
			.pipe(catchError((error) => of([])));

		return result;
	}

	mapAutocompleteData(response) {
		this.hotelProviders = null;
		this.hotelProviders = cloneDeep(response["providers"]);

		this.checkAutoCompleteResult(response["predictions"]);

		return response["predictions"];
	}

	checkAutoCompleteResult(r) {
		this.progressBarValue = false;

		if (r == undefined) {
			this.hotelDeparture = null;
		} else {
			if (r.length == 0 && this.hotelDeparture) {
				this.hotelDeparture = null;
			}
		}
	}

	selectDepartureHotel(hotel) {
		ShareVars.usingTreeSearch = false;
		this.hotelDeparture = hotel.item;
		// console.log(this.hotelDeparture);
		this.hotelDepartureName = hotel.item.description;
		this.baseService.focusOnElement(this.checkINDateID);
	}

	checkChangeItem(value) {
		//ShareVars.usingTreeSearch = false;
		if (value == "") {
			this.hotelDeparture = null;
		}
	}

	selectedNights(nightsNumber) {
		if (this.hotelCheckInDate) {
			if (!DateService.isEmptyDate(this.hotelCheckInDate)) {
				this.hotelNights = +nightsNumber;

				let varDate = DateService.toNewDate(this.hotelCheckInDate);
				varDate.setDate(varDate.getDate() + +nightsNumber);

				this.hotelCheckOutDate = DateService.setDate(this.hotelCheckOutDate, varDate);
			}
		}
	}

	calculateNights($event) {
		// the result is in millsec, convert it to days(nights)
		this.hotelCheckOutDate = $event;

		if (
			this.hotelCheckOutDate &&
			this.hotelCheckInDate &&
			!DateService.isEmptyDate(this.hotelCheckInDate) &&
			!DateService.isEmptyDate(this.hotelCheckOutDate)
		) {
			let outd = DateService.toNewDate(this.hotelCheckOutDate).getTime();
			let ind = DateService.toNewDate(this.hotelCheckInDate).getTime();
			this.hotelNights = Math.ceil((outd - ind) / (1000 * 3600 * 24));
		} else {
			this.hotelNights = 0;
		}
	}

	// afect new value to check-in. Check-out must be > check-in date
	// when using cookies, to set minDate of check out
	onCheckInChanged(value, from) {
		this.hotelCheckInDate = value;

		let varDate = DateService.toNewDate(this.hotelCheckInDate);
		varDate.setDate(varDate.getDate() + 1);

		this.minDateCheckOut = DateService.setDate(this.minDateCheckOut, varDate);

		this.enableCheckOut = true;

		//set checkOut to null if checkIn > checkOut and the change is from the ui, not when initSearchHotels from cookies
		if (this.hotelCheckOutDate && !DateService.isEmptyDate(this.hotelCheckOutDate)) {
			let hotelNights = Math.ceil(
				(DateService.toNewDate(this.hotelCheckOutDate).getTime() -
					DateService.toNewDate(this.hotelCheckInDate).getTime()) /
					(1000 * 3600 * 24)
			);

			this.hotelNights = hotelNights;

			if (from !== "cookies" && hotelNights < 1) {
				this.hotelCheckOutDate = DateService.initEmptyDate(this.hotelCheckOutDate);
				this.hotelNights = 0;
			}
		}

		// this.baseService.focusOnElement(this.checkOUTDateID);
	}

	getArray(l: number): any[] {
		return new Array(l);
	}

	getQueryParams() {
		this.hotelRooms = this.hotelRoomsToBook.length;

		const queryParams = {};
		let adult_total = 0;
		let child_total = 0;

		if (ShareVars.usingTreeSearch) {
			this.hotelDeparture = this.shareVarsRef.treeSearchDeparture;
			this.hotelDepartureName = this.hotelDeparture.description;
			this.hotelProviders = ShareVars.treeProviders;
			// console.log(">>>>", this.hotelDeparture);
		}

		for (let i = 0; i < this.hotelRoomsToBook.length; i++) {
			let n_childs = this.hotelRoomsToBook[i].child_nbr;
			this.hotelRoomsToBook[i].child_age = this.hotelRoomsToBook[i].child_age
				.slice(0, n_childs)
				.sort((a, b) => a - b);
			queryParams["adults_nbr_" + (i + 1)] = this.hotelRoomsToBook[i].adult_nbr;
			adult_total += this.hotelRoomsToBook[i].adult_nbr;

			queryParams["child_nbr_" + (i + 1)] = this.hotelRoomsToBook[i].child_nbr;
			child_total += this.hotelRoomsToBook[i].child_nbr;

			for (let j = 0; j < this.hotelRoomsToBook[i].child_nbr; j++) {
				queryParams["child_age_" + (i + 1) + "_" + (j + 1)] =
					this.hotelRoomsToBook[i].child_age[j];
			}
		}

		queryParams["checkIn"] = DateService.toStringDate(this.hotelCheckInDate);

		queryParams["checkOut"] = DateService.toStringDate(this.hotelCheckOutDate);
		queryParams["hotelObj"] = this.hotelDeparture;

		queryParams["hotelDeparture"] = this.hotelDeparture.description;
		queryParams["hotelDepartureID"] = this.hotelDeparture.id;

		queryParams["nights"] = this.hotelNights;
		queryParams["rooms"] = this.hotelRooms;
		queryParams["adult_total"] = adult_total;
		queryParams["child_total"] = child_total;
		queryParams["totalProviders"] = this.hotelProviders.length;
		queryParams["customer_nationality"] = this.nationality;
		for (let i = 0; i < this.hotelProviders.length; i++) {
			queryParams["providers_" + (i + 1)] = this.hotelProviders[i];
		}

		this.hotelService.saveHotelSearchParams(JSON.stringify(queryParams));

		return queryParams;
	}

	searchHotels() {
		ShareVars.showHotelResultsError = false;
		ShareVars.showHotelResultsErrorMessage = null;

		ShareVars.showHotelItemResultsError = false;
		ShareVars.showHotelItemResultsErrorMessage = null;

		this.hotelService.removeAllData();

		let queryParams = this.getQueryParams();
		this.hotelService.setHotelBookingHotelSearchParams(queryParams);

		if (this.searchSource == "hotel_availability") {
			if (this.usingAvailabilityRouteParams) {
				this.router.navigate([], {
					queryParams: queryParams,
					relativeTo: this.activatedRoute,
				});
			} else {
				this.router.onSameUrlNavigation = "reload";
				this.navigationService.goToHotelAvailabilityPage();
			}
		} else {
			this.baseService.hasMapView() && this.baseService.preferMapView() ? this.navigationService.goToHotelAvailabilityMapviewPage() : this.navigationService.goToHotelAvailabilityPage();
		}
	}

	setParamsToCookies() {
		this.hotelService.removeAllData();
		const params = this.getQueryParams();
		this.availabilityRouteParams = cloneDeep(params);
		this.hotelService.setHotelBookingHotelSearchParams(params);
		this.hotelService.saveHotelSearchParams(JSON.stringify(params));
	}

	async initSearchHotels(urlParams?) {
		const hotelSearchParamsService = this.hotelService.getHotelBookingHotelSearchParams();

		let queryParams = null;

		if (hotelSearchParamsService) {
			queryParams = cloneDeep(hotelSearchParamsService);
		} else {
			if (urlParams && Object.keys(urlParams).length > 0) {
				queryParams = cloneDeep(urlParams);

				if (Object.keys(queryParams).length == 0) {
					queryParams = null;
				} else {
					queryParams = this.baseService.fixHotelParamsFromURL(queryParams);
				}
			} else {
				queryParams = this.hotelService.getHotelSearchParams();

				if (queryParams) {
					queryParams = JSON.parse(queryParams);
				}
			}
		}
		if (queryParams) {
			this.nationality = queryParams["customer_nationality"];

			this.hotelRooms = queryParams["rooms"];

			for (let i = 0; i < this.hotelRooms; i++) {
				this.createRoom();

				this.hotelRoomsToBook[i].adult_nbr = queryParams["adults_nbr_" + (i + 1)];
				this.hotelRoomsToBook[i].child_nbr = queryParams["child_nbr_" + (i + 1)];

				for (let j = 0; j < this.hotelRoomsToBook[i].child_nbr; j++) {
					this.hotelRoomsToBook[i].child_age[j] =
						queryParams["child_age_" + (i + 1) + "_" + (j + 1)];
				}
			}

			//checkIN
			this.hotelCheckInDate = DateService.toObjectDate(
				this.hotelCheckInDate,
				queryParams["checkIn"]
			);

			//checkOut
			this.hotelCheckOutDate = DateService.toObjectDate(
				this.hotelCheckOutDate,
				queryParams["checkOut"]
			);

			if (
				this.baseService.compareDates(DateService.getTodayDate(), this.hotelCheckOutDate) ==
				1
			) {
				this.hotelCheckOutDate = null;
			}

			if (
				this.baseService.compareDates(DateService.getTodayDate(), this.hotelCheckInDate) ==
				1
			) {
				this.hotelCheckInDate = null;
			} else {
				this.onCheckInChanged(this.hotelCheckInDate, "cookies");
			}

			this.hotelDepartureName = queryParams["hotelDeparture"];
			const hotelDepartureId = +queryParams["hotelDepartureID"];

			// console.log(ShareVars.usingTreeSearch);
			if (!ShareVars.usingTreeSearch) {
				this.hotelDeparture = queryParams["hotelObj"];
				for (let i = 0; i < queryParams["totalProviders"]; i++) {
					this.hotelProviders[i] = queryParams["providers_" + (i + 1)];
				}
			} else {
				this.hotelProviders = ShareVars.treeProviders;
			}

			this.hotelNights = +queryParams["nights"];
		} else {
			this.createRoom();
		}
	}

	createRoom(adult_nbr?: number, child_nbr?: number, children_age?: []) {
		if (this.hotelRoomsToBook.length < 4) {
			const room = new SharedRoomModel();

			if (adult_nbr) {
				room.adult_nbr = adult_nbr;
			}

			if (child_nbr) {
				room.child_nbr = child_nbr;
			}

			if (children_age) {
				for (let i = 0; i < children_age.length; i++) {
					room.child_age[i] = children_age[i];
				}
			}

			this.hotelRoomsToBook.push(room);
		}
	}

	disableSearchHotelsButton(): boolean {
		let test = true;
		if (
			(this.hotelDeparture || this.shareVarsRef.treeSearchDeparture.id != null) &&
			this.hotelCheckInDate &&
			this.hotelCheckOutDate &&
			this.hotelRoomsToBook.length > 0
		) {
			//if (this.hotelCheckInDate && this.hotelCheckOutDate && this.hotelRoomsToBook.length > 0) {
			if (
				!DateService.isEmptyDate(this.hotelCheckInDate) &&
				!DateService.isEmptyDate(this.hotelCheckOutDate)
			) {
				for (let i = 0; i < this.hotelRoomsToBook.length; i++) {
					if (this.hotelRoomsToBook[i].child_nbr > 0) {
						for (let j = 0; j < this.hotelRoomsToBook[i].child_nbr; j++) {
							if (this.hotelRoomsToBook[i].child_age[j] === null) {
								return true;
							} else {
								test = false;
							}
						}
					} else {
						test = false;
					}
				}
			}
		}

		return test;
	}

	changeRoomSelector(nightsID, roomsID) {
		this.shareVarsRef.showSharedRooms = !this.shareVarsRef.showSharedRooms;

		let elm1: HTMLElement = document.getElementById(nightsID) as HTMLElement;
		let elm2: HTMLElement = document.getElementById(roomsID) as HTMLElement;

		elm1.classList.remove("show");
		elm2.classList.remove("show");
	}

	getTravelersNumber() {
		if (this.hotelRoomsToBook.length === 0) {
			return 0;
		} else {
			return this.hotelRoomsToBook
				.map((el) => el.adult_nbr + el.child_nbr)
				.reduce((sum, current) => sum + current);
		}
	}

	addRoom() {
		this.show_dropdown = true;
		this.createRoom();
	}

	deleteRoom(index: number) {
		this.show_dropdown = true;
		this.hotelRoomsToBook.splice(index, 1);
	}

	openDropdown() {
		let elm1: HTMLElement = document.getElementById(
			this.searchSource + "_search_select_rooms_button"
		) as HTMLElement;
		elm1.focus();

		let elm2: HTMLElement = document.getElementById(
			this.searchSource + "_search_select_rooms_dropdown"
		) as HTMLElement;
		elm2.classList.add("show");
	}

	openDropdownM() {
		this.show_dropdown = true;
	}

	openDropdownFB() {
		this.show_dropdown = !this.show_dropdown;
	}

	closeDropdown() {
		let elm1: HTMLElement = document.getElementById(
			this.searchSource + "_search_select_rooms_button"
		) as HTMLElement;
		elm1.blur();

		let elm2: HTMLElement = document.getElementById(
			this.searchSource + "_search_select_rooms_dropdown"
		) as HTMLElement;
		elm2.classList.remove("show");
	}

	closeDropdownM() {
		if (!this.show_dropdown) {
			let elm1: HTMLElement = document.getElementById(
				this.searchSource + "_search_select_rooms_button"
			) as HTMLElement;
			elm1.blur();

			let elm2: HTMLElement = document.getElementById(
				this.searchSource + "_search_select_rooms_dropdown"
			) as HTMLElement;
			elm2.classList.remove("show");
		} else {
			this.openDropdown();
		}

		this.show_dropdown = false;
	}

	/* madiha travel */
	closeDropdownMT() {
		if (!this.show_dropdown) {
			let elm1: HTMLElement = document.getElementById(
				this.searchSource + "_search_select_rooms_button"
			) as HTMLElement;
			elm1.blur();

			let elm2: HTMLElement = document.getElementById(
				this.searchSource + "_search_select_rooms_dropdown"
			) as HTMLElement;
			elm2.classList.remove("show");
		} else {
			this.openDropdown();
		}

		this.show_dropdown = false;
	}

	closeDropDownMTTimer() {
		setTimeout(() => {
			this.closeDropdownMT();
		}, 100);
	}

	isArabic() {
		return this.baseService.isArabic();
	}

	showTreeSearch() {
		ShareVars.showTreeSearch = true;
	}
	setNationality(nat) {
		this.nationality = nat;
	}

	clearInput(input_name: string, input_id: string) {
		const input = document.getElementById(input_id) as HTMLInputElement
		switch (input_name) {
			case "destination":
				input.value = ""
				this.selectDepartureHotel(null)
				break
			default:
				break
		}
	}
	isInputEmpty(input_id: string) {
		const input = document.getElementById(input_id) as HTMLInputElement
		if (input && input.value.length) return false
		return true
	}

	getParamsResume() {
		const queryParams = {};
		let adult_total = 0;
		let child_total = 0;
		let child_ages: number[][] = []

		for (let room of this.hotelRoomsToBook) {
			adult_total += room.adult_nbr
			child_total += room.child_nbr
			let child_age = []
			for (let x=0; x<child_total; x++) {
				child_age.push(room.child_age[x])
			}
			child_ages.push(child_age)
		}

		queryParams["checkIn"] = DateService.toStringDate(this.hotelCheckInDate);
		queryParams["checkOut"] = DateService.toStringDate(this.hotelCheckOutDate);

		queryParams["hotelDeparture"] = this.hotelDeparture.description;

		queryParams["nights"] = this.hotelNights;
		queryParams["rooms"] = this.hotelRoomsToBook.length;
		queryParams["adult_total"] = adult_total;
		queryParams["child_total"] = child_total;
		queryParams["child_ages"] = child_ages;

		return queryParams
	}

	canSearch() {
		try {
			this.getParamsResume()
			return true
		} catch {
			return false
		}
	}
}
