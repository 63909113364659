import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { environment } from "environments/environment";

@Component({
	selector: "app-search-rooms-shared",
	templateUrl: "./search-rooms-shared.component.html",
	styleUrls: ["./search-rooms-shared.component.scss"],
})
export class SearchRoomsSharedComponent implements OnInit {
	@Input() room;
	@Input() searchSource: string;
	@Input() roomIndex: number = 0;
	@Input() totalPassengers;
	@Input() totalRooms;
	@Input() hotelRoomsToBook;
	@Output() openDropdown: EventEmitter<any> = new EventEmitter();
	@Output() deleteRoom: EventEmitter<any> = new EventEmitter();
	@Output() addRoom: EventEmitter<any> = new EventEmitter();

	maxChildAge = 13

	limitAges = [];

	constructor(private baseService: BaseService) {
		
		if (environment.assets === 'wiigo') {
			this.maxChildAge = 12
		}
	}

	ngOnInit() {
		this.initChildAgesLimit(this.maxChildAge);		
	}

	initChildAgesLimit(limit: number) {
		for (let i = 0; i < limit; i++) {
			this.limitAges[i] = i;
		}
	}

	decrement(type: string, chdAgeIndex?) {
		this.openDropdown.emit();

		switch (type) {
			case "ADT":
				this.room.adult_nbr--;
				break;

			case "CHD":
				this.room.child_nbr--;
				break;

			case "CHD_AGE":
				this.room.child_age[chdAgeIndex]--;
				break;
		}
	}

	increment(type: string, chdAgeIndex?) {
		this.openDropdown.emit();

		switch (type) {
			case "ADT":
				this.room.adult_nbr++;
				break;

			case "CHD":
				this.room.child_nbr++;
				break;

			case "CHD_AGE":
				this.room.child_age[chdAgeIndex]++;
				break;
		}
	}

	checkAge(chdAgeIndex) {
		if (this.room.child_age[chdAgeIndex] < 0 || this.room.child_age[chdAgeIndex] > this.maxChildAge) {
			this.room.child_age[chdAgeIndex] = 3;
		}
	}

	updateChildAge(value, chdAgeIndex) {
		this.openDropdown.emit();
		this.room.child_age[chdAgeIndex] = value;
	}

	deleteRoomEvent() {
		this.deleteRoom.emit(this.roomIndex);
	}

	keepOpened() {
		this.openDropdown.emit();
	}
}
