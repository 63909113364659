import { Component, Input, OnInit } from "@angular/core";
import { BaseService } from "../../services/base.service";
import { HOTEL_BOOKING_ROUTE, AVAILABILITY_CHILD_ROUTE } from "../../services/routes.pages";
import { HotelService } from "../../services/hotel.service";
import { NavigationService } from "../../services/navigation.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "environments/environment";
import { toInt } from "ngx-bootstrap/chronos/utils/type-checks";
import { ShareVars } from "@app/shared/services/share.vars";
@Component({
	selector: "app-hotel-offers",
	templateUrl: "./hotel-offers.component.html",
	styleUrls: ["./hotel-offers.component.scss"],
})
export class HotelOffersComponent implements OnInit {
	hotelOffers: any;
	hotelAvailabilityRoute: string = HOTEL_BOOKING_ROUTE + AVAILABILITY_CHILD_ROUTE;
	hotelAvailabilityRouteParams = {};
	staticURL = "";
	env: any;
	@Input() hideBook: boolean = false;

	constructor(
		private hotelService: HotelService,
		private baseService: BaseService,
		private activatedRoute: ActivatedRoute,
		private navigation: NavigationService,
		private router: Router
	) {
		this.env = environment;
	}

	ngOnInit() {
		this.staticURL = environment.staticApi;
		if (ShareVars.hotelOffers.length > 0) {
			this.hotelOffers = ShareVars.hotelOffers;
			this.hotelService.setRecommendedHotels(
				this.hotelOffers.filter((hotel: any) => hotel.recommended == 1)
			);
		} else {
			this.hotelService.getHotelOffers().subscribe((data) => {
				this.hotelOffers = data;
				this.hotelOffers = data.filter(
					(el: any) => el.type == environment.mode || el.type == "B2B2C"
				);
				this.hotelService.setRecommendedHotels(
					this.hotelOffers.filter((hotel: any) => hotel.recommended == 1)
				);
			});
		}
	}

	formatPrice(price: number): string {
		return this.baseService.formatPrice(price);
	}

	goToHotelAvailability(hotel) {
		this.hotelService.removeAllData();
		let queryParams = {}; //hotel['search_criteria'];

		queryParams["checkIn"] = hotel["search_criteria"]["checkin"].split(" ")[0];
		queryParams["checkOut"] = hotel["search_criteria"]["checkout"].split(" ")[0];

		queryParams["hotelDeparture"] = hotel["location"];
		queryParams["hotelDepartureID"] = hotel["search_criteria"]["location_id"];
		queryParams["nights"] = hotel["search_criteria"]["nights"];

		for (let i = 0; i < hotel["search_criteria"]["providers"].length; i++) {
			queryParams["providers_" + (i + 1)] = hotel["search_criteria"]["providers"][i];
		}

		let adultsSum = 0;
		let childrenSum = 0;

		for (let i = 0; i < hotel["search_criteria"]["rooms"].length; i++) {
			adultsSum =
				adultsSum + Number(hotel["search_criteria"]["rooms"][i]["number_of_adults"]);
			childrenSum =
				childrenSum + Number(hotel["search_criteria"]["rooms"][i]["number_of_children"]);
			queryParams["adults_nbr_" + (i + 1)] = Number(
				hotel["search_criteria"]["rooms"][i]["number_of_adults"]
			);
			queryParams["child_nbr_" + (i + 1)] = Number(
				hotel["search_criteria"]["rooms"][i]["number_of_children"]
			);
		}

		queryParams["adult_total"] = adultsSum;
		queryParams["child_total"] = childrenSum;

		queryParams["rooms"] = hotel["search_criteria"]["rooms"].length;
		queryParams["totalProviders"] = hotel["search_criteria"]["providers"].length;

		if (hotel["search_criteria"]["code"] != undefined) {
			queryParams["code"] = hotel["search_criteria"]["code"];
		}

		this.hotelService.setHotelBookingHotelSearchParams(queryParams);
		this.navigation.goToHotelAvailabilityPage();
	}

	isArabic() {
		return this.baseService.isArabic();
	}
	scrollLeft() {
		let scroll_div = document.getElementById("scroll_column_display");
		scroll_div.scrollBy(-343, 0);
	}
	scrollRight() {
		let scroll_div = document.getElementById("scroll_column_display");
		scroll_div.scrollBy(343, 0);
	}
}
