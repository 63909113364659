import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "app-loader-child",
	templateUrl: "./loader-child.component.html",
	styleUrls: ["./loader-child.component.scss"],
})
export class LoaderChildComponent implements OnInit {
	@Input() loaderChildMessage: string;

	constructor() {}

	ngOnInit() {}
}
