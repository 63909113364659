import { Component, OnInit } from "@angular/core";
import { HotelService } from "../../../shared/services/hotel.service";
import { BaseService } from "../../../shared/services/base.service";
import { cloneDeep } from "lodash";

@Component({
	selector: "app-hotel-b2b-review",
	templateUrl: "./hotel-b2b-review.component.html",
	styleUrls: ["./hotel-b2b-review.component.scss"],
})
export class HotelB2bReviewComponent implements OnInit {
	hotelItem;
	guestsInfosArray;
	clientInfos;
	cancellationPolicies = [];
	comments;

	constructor(
		private hotelService: HotelService,
		private baseService: BaseService
	) {}

	ngOnInit() {
		this.getPaxData();
	}

	getPaxData() {
		this.hotelItem = cloneDeep(this.hotelService.getHotelBookingHotelItem());
		this.guestsInfosArray = cloneDeep(this.hotelService.guestsInfosArray);
		this.clientInfos = cloneDeep(this.hotelService.clientInfos);

		if (this.hotelItem.combinedRooms == false) {
			this.cancellationPolicies =
				this.hotelService.hotelBookingHotelCheckRateRooms["result"]["rooms"];
		} else {
			if (
				this.hotelService.getHotelBookingCancellationPolicyCombinedRooms() &&
				this.hotelService.getHotelBookingCancellationPolicyCombinedRooms().length > 0
			) {
				this.cancellationPolicies =
					this.hotelService.getHotelBookingCancellationPolicyCombinedRooms();
			} else {
				this.cancellationPolicies.push("empty");
			}
		}

		this.comments = this.hotelService.getHotelBookingHotelSelectedComment();
	}

	formatHotelPrice(price: number): string {
		return this.baseService.formatPrice(price);
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}
}
