import { Component, Input, OnInit } from "@angular/core";
import { BaseService } from "../../../shared/services/base.service";
import { LoginService } from "../../../shared/services/login.service";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ShareVars } from "../../../shared/services/share.vars";
import { ERROR_MESSAGE_GETTING_DATA } from "../../../shared/services/display.messages";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-forget-password",
	templateUrl: "./forget-password.component.html",
	styleUrls: ["./forget-password.component.scss"],
})
export class ForgetPasswordComponent implements OnInit {
	username;
	sucessResetPWD: boolean = false;
	@Input() type;

	constructor(
		private translateService: TranslateService,
		private baseService: BaseService,
		private loginService: LoginService,
		private navigationService: NavigationService
	) {}

	ngOnInit() {}

	forgetPassword() {
		ShareVars.isBusy = true;
		const resetPWDData: any = {};

		if (this.type === "b2c") {
			resetPWDData.email = this.username;
		} else {
			resetPWDData.username = this.username;
		}

		this.loginService.forgetPassword(resetPWDData, this.type).subscribe((data) => {
			ShareVars.isBusy = false;

			if (data.error) {
				this.sucessResetPWD = false;
				this.baseService.showNotyf("error", data.description);
			} else {
				this.sucessResetPWD = true;
				this.baseService.showNotyf("success", "FORGET_PWD_SUCCESS");
			}
		});
	}

	goToLoginPage() {
		this.navigationService.goToB2BLogin();
	}

	isArabic() {
		return this.baseService.isArabic();
	}
}
