import { Component, OnInit } from "@angular/core";
import { ShareVars } from "@app/shared/services/share.vars";
import { VisaApplicationService } from "@app/shared/services/visa.application.service";
import { doc_type } from "@app/visa/visa.type";
import { filter } from "rxjs/operators";

@Component({
	selector: "app-visa-pax-review-booking",
	templateUrl: "./visa-pax-review-booking.component.html",
	styleUrls: ["./visa-pax-review-booking.component.scss"],
})
export class VisaPaxReviewBookingComponent implements OnInit {
	visa = null;
	docType = doc_type;
	showDescription = true;

	constructor(private visaService: VisaApplicationService) {
		this.visaService.visa$.pipe(filter((data) => !!data)).subscribe((visa) => {
			this.visa = visa;
		});
	}

	ngOnInit() {
		ShareVars.isBusy = false;
	}

	getCountryName = (code) => this.visaService.countryNameFromCode(code);

	// getFormuleDescription(){
	//   return this.visaService.formuleDescription(this.visa.formuleId);
	// }
}
