import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { CHECKING_BOOKING_WAITING_MESSAGE, ERROR_INTERNET_CONNECTION, HOTEL_NOT_BOOKABLE_MESSAGE } from "@app/shared/services/display.messages";
import { HotelService } from "@app/shared/services/hotel.service";
import { NavigationService } from "@app/shared/services/navigation.service";
import { ShareVars } from "@app/shared/services/share.vars";
import { TaskService } from "@app/shared/services/task.service";
import { cloneDeep, get as _get } from "lodash";

@Component({
    selector: "hotel-task-view",
    templateUrl: "./hotel-task-view.component.html",
    styleUrls: ["./hotel-task-view.component.scss"],
})
export class HotelTaskViewComponent implements OnInit, OnChanges {
    @Input() hotelItem;
    @Input() closeTask;
    hotel;
    boards: any[] = [];
    rooms: any[] = [];
    rates: any[] = [];
    combinedRooms: any[] = [];
    combinedRates: any[] = [];

    selectedRate: any[] = [];
    selectedRateCombined = null;

    coloredStars: any[];
    nonColoredStars: any[];

    reselect = true

    total = '';
    totalPrice = 0

    isLoading = false;
    isBooking = false;
    changed = true

    selectedCancellation = ""
    isCancellationLoading = false
    fetchedCancellations = {}

    showCarousel = false
    carouselIndex = 0

    constructor(
        private baseService: BaseService,
        private hotelService: HotelService,
        private taskService: TaskService,
        private navigationService: NavigationService
    ) {}

    ngOnInit(): void {
        if (this.needLoading() && this.hotelItem) {
            this.isLoading = true;
        }
    }

    ngOnChanges(): void {
        if (this.hotel && this.hotelItem && this.hotel.code !== this.hotelItem.code) {
            this.reselect = true
            this.changed = true
            this.total = ""
            this.totalPrice = 0
            this.showCarousel && this.toggleCarousel()
        }
        this.hotel = this.hotelItem;
        if (this.needLoading() && !this.isLoading) {
            this.isLoading = true;
            this.getHotelMoreInfo(this.hotel)
        }
        this.rates = []
        this.rooms = []
        this.combinedRooms = []
        this.boards = []

        this.updateHotelItem(this.hotel);

        // If user changes current task reselect the first one for the moment
        // Update later
        if (!this.hotel.combinedRooms && this.changed) {
            // Not combined
            this.reselect = true
            this.selectedRate = []
            for (let x=0; x<this.rates.length; x++) {
                this.selectedRate.push(this.rates[x][0][0])
            }
            let t = 0
            let c = "DZD"
            for (let rate of this.selectedRate) {
                if (!rate.net) continue
                t += rate.net.total
                c = rate.net.currency
            }
            this.total = `${this.formatHotelPrice(t)} ${c}`
            this.totalPrice = t

            this.reselect = false
            this.changed = false
        }

        this.taskService.getSelectedTask().params !== this.hotelService.getHotelSearchParams() && this.closeTask()
    }

    getEmails(): any {
        const email = this.hotel.mail;
        if (!email) return "";
        const result = new Array();
        let last = -1;

        for (let i = 0; i < email.length; i++) {
            if (email[i] === ";") {
                result.push(email.slice(last + 1, i));
                last = i;
            }
        }
        result.push(email.slice(last + 1));
        return result;
    }

    getStars() {
        let stars = 5;
        if (!this.hotel.stars) return 0
        switch (this.hotel.stars.toLowerCase()) {
            case "one":
                stars = 1;
                break;
            case "two":
                stars = 2;
                break;
            case "three":
                stars = 3;
                break;
            case "four":
                stars = 4;
                break;
            case "five":
                stars = 5;
                break;
            default:
                stars = 0;
                break;
        }
        return stars;
    }

    objToArray(obj: any) {
        if (typeof obj !== "object") return [];
        return [...Object.values(obj).map((v) => v)];
    }

    formatHotelPrice(value) {
        return this.baseService.formatPrice(value);
    }

    getPersonsMap(str) {
        return new Array(parseInt(str) || 0);
    }

    getPersons() {
        let a = 0
        let c = 0
        const hotelParams = this.getHotelParams()
        Object.entries(hotelParams).forEach(([k, v]) => {
            if (k.startsWith("adults_nbr_")) {
                a += parseInt(v as string)
            } else if (k.startsWith("child_nbr_")) {
                c += parseInt(v as string)
            }
        })
        return {
            adults: a,
            children: c
        }
    }

    getCurrentUrl() {
        return window.location.href;
    }

    getDescription(room) {
        if (typeof room.description !== "string") return null;
        return room.description;
    }

    async getHotelMoreInfo(hotel) {
        return new Promise((resolve, reject) => {
            this.hotelService
                .getHotelsListFromHotelParams(
                    { code: hotel.provider, id: hotel.provider_id },
                    this.getHotelParams(),
                    hotel.code,
                    hotel["hotelCodeCrypted"]
                )
                .subscribe(
                    (data) => {
                        if (data["result"] && data["result"][hotel.code]) {
                            this.reselect = true
                            this.taskService.setSelectedTask({
                                ...this.taskService.getSelectedTask(),
                                data: data["result"][hotel.code],
                                needLoading: false,
                                alreadyLoaded: true
                            });
                            this.updateHotelItem(data["result"][hotel.code]);
                        }
                        resolve(true);
                    },
                    (error) => {
                        console.error("more rooms error", error);
                        reject(false);
                    },
                    () => {
                        this.isLoading = false;
                    }
                );
        });
    }

    updateHotelItem(newHotel) {
        const oldHotel = cloneDeep(this.hotel);
        if (newHotel.combinedRooms) {
            this.updateHotelCombined(newHotel);
        } else {
            oldHotel["rooms"] = newHotel["rooms"];
            oldHotel["rates"] = newHotel["rates"];
            oldHotel["images"] = newHotel["images"];
            this.hotel = oldHotel;
            if (this.reselect) {
                this.selectedRate = []
                for (let rateIndex=0; rateIndex<this.rates.length; rateIndex++) {
                    // this.selectedRate.push(this.rates[rateIndex][0][0])
                    this.selectRate(this.rates[rateIndex][0][0], rateIndex)
                }
                this.reselect = false
            }

            for (let x=0; x<this.selectedRate.length; x++) {
                this.selectedRate[x]["rateObject"] = this.hotel.rates[this.selectedRate[x].id] && this.selectedRate[x].roomCode ?
                this.hotel.rates[this.selectedRate[x].id][this.selectedRate[x].roomCode].find((r) => r.rateKey === this.selectedRate[x].rateKey)
                : null
            }
        }
        this.updateStates();
    }

    updateHotelCombined(newHotel) {
        const hotelParams = this.getHotelParams()
        this.combinedRates = Object.values(newHotel.rooms)
            .map((el) =>
                el["rates"].map((r, index) => {
                    r.roomCombination = el["roomCombination"];
                    r.roomCombinationCode = el["roomCombinationCode"];
                    r.persons = `${hotelParams[`adults_nbr_${index+1}`]}-${hotelParams[`child_nbr_${index+1}`]}`
                    r.price = r.net.total
                    r.currency = r.net.currency
                    r.rateObject = r
                    return r;
                })
            )
            //@ts-ignore
            .flat()
            .sort((a, b) => a.net.total - b.net.total);
        this.selectRateCombined(this.combinedRates[0]);
        const rooms = []
        for (let rate of this.combinedRates) {
            rate.roomCombination.forEach(room => {
                if (!rooms.find((r) => r.code === room.code)) {
                    rooms.push(room)
                }
            });
        }
        this.combinedRooms = rooms
    }

    updateStates() {
        this.boards = this.objToArray(this.hotel.boards);
        this.rooms = this.objToArray(this.hotel.rooms);
        this.rates = this.objToArray(this.hotel.rates);

        this.objToArray(this.rates).forEach((r, i) => {
            this.rates[i] = this.objToArray(r);
        });
        this.rates = this.objToArray(this.rates);

        const hotelParams = this.getHotelParams()

        let personsPerRooms = []
        for (let i = 0; i < hotelParams["rooms"]; i++) {
            personsPerRooms[i] = {
                adults: hotelParams["adults_nbr_" + (i + 1)],
                children: hotelParams["child_nbr_" + (i + 1)],
            };

            for (let j = 0; j < personsPerRooms[i]["child"]; j++) {
                personsPerRooms[i]["child_age_" + (i + 1) + "_" + (j + 1)] =
                    hotelParams["child_age_" + (i + 1) + "_" + (j + 1)];
            }
        }

        const tmp = []
        personsPerRooms.forEach((el) => {
            for (let rate of this.rates) {
                if (rate[0][0].adults == el.adults && rate[0][0].children == el.children) {
                    tmp.push(rate)
                    break
                }
            }
        })

        this.rates = tmp

        if (!this.boards.length) {
            for (let rate of this.rates) {
                for (let rooms of rate) {
                    for (let room of rooms) {
                        if (!this.boards.includes(room.boardName)) this.boards.push(room.boardName)
                    }
                }
            }
        }

        let tmp_stars = this.getStars();
        this.coloredStars = new Array(tmp_stars);
        this.nonColoredStars = new Array(5 - tmp_stars);
    }

    needLoading() {
        const task = this.taskService.getSelectedTask();
        if (task.alreadyLoaded) return false
        let res = task && task.needLoading
        let rs = false
        if (this.hotel.combinedRooms) {
            rs = (this.hotel.rooms && this.hotel.rooms.length)
        } else {
            rs = (this.hotel.rates && this.hotel.rates.length)
        }
        if (!rs) return true
        return res && rs
    }

    selectRate(room, rateIndex: number, src?: string) {
        if (src==="update" && !this.reselect) return
        if (this.hotel.combinedRooms) return
        this.selectedRate[rateIndex] = {
            ...room,
            rateObject: this.hotel.rates && this.hotel.rates.length ? this.hotel.rates[room.id][room.roomCode].find((r) => r.rateKey === room.rateKey) : null
        }

        let t = 0
        let c = "DZD"
        for (let rate of this.selectedRate) {
            if (!rate.net) continue
            t += rate.net.total
            c = rate.net.currency
        }
        this.total = `${this.formatHotelPrice(t)} ${c}`
        this.totalPrice = t
    }

    selectRateCombined(rate) {
        if (!this.hotel.combinedRooms) return
        this.selectedRateCombined = rate
        if (!rate || !rate.net) return "0.00 DA"
        this.total = `${this.formatHotelPrice(rate.net.total)} ${rate.net.currency}`
        this.totalPrice = rate.net.total
    }

    isSelectedRate(rate, rateIndex) {
        if (this.selectedRate[rateIndex]) {
            return this.selectedRate[rateIndex].rateKey == rate.rateKey
        }
        return false
    }

    checkRatesNotCombined(rateKey, hotelCodeCrypted) {
		let request = {};
        const queryParamsHotelItem = JSON.parse(this.hotelService.getHotelSearchParams())

		request["checkInDate"] = queryParamsHotelItem["checkIn"];
		request["checkOutDate"] = queryParamsHotelItem["checkOut"];
		request["hotelCode"] = this.hotel.code;
		request["language"] = this.baseService.getLanguage();
		request["provider"] = this.hotelItem["provider"];
		request["provider_id"] = this.hotelItem["provider_id"];
		request["trace"] = true;

		if (rateKey) {
			request["rateKey_1"] = rateKey;
			request["room"] = 1;
		} else {
			for (let i = 0; i < this.selectedRate.length; i++) {
				request["rateKey_" + (i + 1)] = this.selectedRate[i]["rateKey"];
			}

			request["room"] = queryParamsHotelItem["rooms"];
		}

		if (hotelCodeCrypted) {
			request["hotelCodeCrypted"] = hotelCodeCrypted;
		}

		return this.hotelService.checkHotelCheckRates(request);
	}

    mapCancellationPoliciesCallCheckRateFalse(rate) {
		let tempRoom = {};
		tempRoom["name"] = rate["roomName"];
		tempRoom["rates"] = [];
		tempRoom["rates"][0] = {};
		tempRoom["rates"][0]["cancellationPolicies"] = rate["cancellationPolicies"];
		tempRoom["rates"][0]["rateComments"] = rate["comment"];
		return cloneDeep(tempRoom);
	}

    bookNotCombined() {
        if (this.hotel.callRoomAvailability) {
            ShareVars.showChildLoader = true;
            ShareVars.childLoaderMessage = CHECKING_BOOKING_WAITING_MESSAGE;
            this.isBooking = true
            this.checkRatesNotCombined(
				null,
				this.hotelItem["hotelCodeCrypted"]
			).subscribe(
				(data) => {
					ShareVars.showChildLoader = false;
					ShareVars.childLoaderMessage = null;
					if (data["result"]["updatedRateKeys"]) {
						this.selectedRate.forEach(
							(el, index) => (el.rateKey = data["result"]["rooms"][index]["rateKey"])
						);
					}
					this.hotelService.setHotelBookingHotelCode(this.hotel.code);
					this.hotelService.setHotelBookingHotelCodeCrypted(
						this.hotelItem["hotelCodeCrypted"]
					);

					this.hotelService.setHotelBookingHotelItem(this.hotelItem);

					if (_get(data, "result.rooms[0].rates[0].cancellationPolicies")) {
						this.selectedRate.forEach((room, index) => {
							room["cancellationPolicies"] = data["result"]["rooms"][index]["rates"][0].cancellationPolicies;
						});
					} else if (_get(data, "result.cancellationPolicies.cancellationPolicies")) {
						this.hotelService.setHotelBookingCancellationPolicyCombinedRooms(
                            data["result"]["cancellationPolicies"]["cancellationPolicies"]
						);
					}

                    for (let x=0; x<this.selectedRate.length; x++) {
                        this.selectedRate[x]['persons'] = this.selectedRate[x]['adults']+'-'+this.selectedRate[x]['children']
                        this.selectedRate[x]["rateObject"] = this.selectedRate[x]
                        this.selectedRate[x]["roomName"] = this.hotel.rooms[this.selectedRate[x].roomCode].name
                        let net = this.selectedRate[x].net
                        this.selectedRate[x]["price"] = net.total
                        this.selectedRate[x]["currency"] = net.currency
                        if (!this.selectedRate[x]["rateObject"]) {
                            this.closeTask()
                            break
                        }
                    }

					this.hotelService.setHotelBookingHotelRates(this.selectedRate);
					this.hotelService.hotelBookingHotelCheckRateRooms = data;

                    this.hotelService.setHotelBookingHotelSearchParams(this.getHotelParams());
					this.hotelService.setHotelBookingHotelTotalPrice(
						data["result"]["totalNet"]["total"]
					);
					this.hotelService.setHotelBookingHotelPriceCurrency(
						data["result"]["totalNet"]["currency"]
					);
					this.hotelService.setHotelBookingHotelSelectedProvider(data["provider"]);
					this.hotelService.setHotelBookingHotelSelectedComment(
						data["result"]["comments"]
					);

					this.baseService.setPaxType("hotel");
					this.navigationService.goToHotelGuestPage();
                    this.closeTask()
				},
				(error) => {
					ShareVars.showChildLoader = false;
					ShareVars.childLoaderMessage = null;

					if (error["errorStatus"] != 401) {
						ShareVars.showError = true;
						ShareVars.errorMessage = HOTEL_NOT_BOOKABLE_MESSAGE;
					}
				}, () => (this.isBooking = false)
			);
        } else {
            for (let x=0; x<this.selectedRate.length; x++) {
                this.selectedRate[x]['persons'] = this.selectedRate[x]['adults']+'-'+this.selectedRate[x]['children']
                this.selectedRate[x]["rateObject"] = this.hotel.rates[this.selectedRate[x].id][this.selectedRate[x].roomCode].find((r) => r.rateKey === this.selectedRate[x].rateKey)
                this.selectedRate[x]["roomName"] = this.hotel.rooms[this.selectedRate[x].roomCode].name
                let net = this.selectedRate[x].net
                this.selectedRate[x]["price"] = net.total
                this.selectedRate[x]["currency"] = net.currency
            }
    
            if (!this.hotel.callCheckRates) {
                for (let roomIndex = 0; roomIndex < this.selectedRate.length; roomIndex++) {
                    let tempRates = Object.keys(this.hotelItem["rates"])[0]
                    let tempRoom = this.mapCancellationPoliciesCallCheckRateFalse(Object.keys(tempRates[roomIndex])[0]);
                    if (!this.hotelService.hotelBookingHotelCheckRateRooms) {
                        this.hotelService.hotelBookingHotelCheckRateRooms = {};
                        this.hotelService.hotelBookingHotelCheckRateRooms["result"] = {};
                        this.hotelService.hotelBookingHotelCheckRateRooms["result"]["rooms"] = [];
                    }
        
                    this.hotelService.hotelBookingHotelCheckRateRooms["result"]["rooms"].push(tempRoom);
                }
            }
    
            this.hotelService.setHotelBookingHotelCode(this.hotel.code);
            this.hotelService.setHotelBookingHotelCodeCrypted(this.hotelItem["hotelCodeCrypted"]);
    
            this.hotelService.setHotelBookingHotelRates(this.selectedRate);
            this.hotelService.setHotelBookingHotelItem(this.hotelItem);
    
            let t = 0
            let c = "DZD"
            for (let rate of this.selectedRate) {
                t += rate.net.total
                c = rate.net.currency
            }
            this.hotelService.setHotelBookingHotelTotalPrice(t);

            this.hotelService.setHotelBookingHotelPriceCurrency(c);

            this.hotelService.setHotelBookingHotelSelectedProvider(this.hotel.provider);

            this.hotelService.setHotelBookingHotelSelectedComment(null);

            this.baseService.setPaxType("hotel");
            this.navigationService.goToHotelGuestPage();
            this.closeTask()
        }
    }

    checkRatesCombined(rateKey, isCancellationPolicy = false) {
		let request = {};
        let queryParamsHotelItem = this.getHotelParams()
		request["checkInDate"] = queryParamsHotelItem["checkIn"];
		request["checkOutDate"] = queryParamsHotelItem["checkOut"];
		request["hotelCode"] = this.hotel.code;
		request["language"] = this.baseService.getLanguage();
		request["provider"] = this.hotelItem["provider"];
		request["provider_id"] = this.hotelItem["provider_id"];
		request["rateKey_1"] = rateKey;
		request["room"] = queryParamsHotelItem["rooms"];
		request["trace"] = true;
		request["isCancellationPolicy"] = isCancellationPolicy;

		this.hotelItem["hotelCodeCrypted"] && (request["hotelCodeCrypted"] = this.hotelItem["hotelCodeCrypted"]);

		return this.hotelService.checkHotelCheckRates(request);
	}

    bookCombined() {
        ShareVars.showChildLoader = true;
		ShareVars.childLoaderMessage = CHECKING_BOOKING_WAITING_MESSAGE;

        const queryParamsHotelItem = this.getHotelParams()
        this.isBooking = true
        this.checkRatesCombined(this.selectedRateCombined.rateKey).subscribe(
			(data) => {
				ShareVars.showChildLoader = false;
				ShareVars.childLoaderMessage = null;

                if (data["result"]["IBE_Errors"] && data["result"]["IBE_Errors"].length) {
                    ShareVars.showError = true
                    ShareVars.errorMessage = HOTEL_NOT_BOOKABLE_MESSAGE
                    return
                }

				this.hotelService.setHotelBookingHotelCode(this.hotel.code);
				this.hotelService.setHotelBookingHotelCodeCrypted(
					this.hotelItem["hotelCodeCrypted"]
				);

                const tempRates = [this.selectedRateCombined]
                for (let x=0; x<tempRates.length; x++) {
                    let { adults, children } = this.getPersons()
                    tempRates[x]['persons'] = `${adults}-${children}`
                    if (!tempRates[x]["rateObject"]) tempRates[x]["rateObject"] = this.hotel.rates[tempRates[x].id][tempRates[x].roomCode].find((r) => r.rateKey === tempRates[x].rateKey)
                        if (this.hotel.rooms && this.hotel.rooms[tempRates[x].roomCombinationCode]) tempRates[x]["roomName"] = this.hotel.rooms[tempRates[x].roomCombinationCode].roomCombination[0].name
                    else if (tempRates[x]["rateObject"]) tempRates[x]["roomName"] = !!tempRates[x]["rateObject"]["roomCombination"].find((r) => r.code === tempRates[x]["rateObject"]["roomCombinationCode"]) ? tempRates[x]["rateObject"]["roomCombination"].find((r) => r.code === tempRates[x]["rateObject"]["roomCombinationCode"]).name : "-"
                    let net = tempRates[x].net
                    tempRates[x]["price"] = net.total
                    tempRates[x]["currency"] = net.currency
                }

				this.hotelService.setHotelBookingHotelRates(tempRates);
				if (_get(data, "result.cancellationPolicies.cancellationPolicies")) {
					this.hotelService.setHotelBookingCancellationPolicyCombinedRooms(
						data["result"]["cancellationPolicies"]["cancellationPolicies"]
					);
				} else if (_get(data, "result.rooms[0].rates[0].cancellationPolicies")) {
					this.hotelService.setHotelBookingCancellationPolicyCombinedRooms(
						data["result"]["rooms"][0]["rates"][0]["cancellationPolicies"]
					);
				}

				this.hotelService.setHotelBookingHotelItem(this.hotel);

				this.hotelService.hotelBookingHotelCheckRateRooms = data;

				this.hotelService.setHotelBookingHotelSearchParams(queryParamsHotelItem);
				this.hotelService.setHotelBookingHotelTotalPrice(
					data["result"]["totalNet"]["total"]
				);
				this.hotelService.setHotelBookingHotelPriceCurrency(
					data["result"]["totalNet"]["currency"]
				);
				this.hotelService.setHotelBookingHotelSelectedProvider(data["provider"]);
				this.hotelService.setHotelBookingHotelSelectedComment(data["result"]["comments"]);

				this.baseService.setPaxType("hotel");
				this.navigationService.goToHotelGuestPage();
                this.closeTask()
			},
			() => {
				ShareVars.showError = true;
				ShareVars.errorMessage = HOTEL_NOT_BOOKABLE_MESSAGE;
				
			},
            () => (this.isBooking = false)
		);
    }

    getHotelParams() {
        return this.hotelService.getHotelSearchParams() ? JSON.parse(this.hotelService.getHotelSearchParams()) : {}
    }

    isCancellationSelected(rateKey: string) {
        return this.selectedCancellation === rateKey
    }

    selectCancellation(rateKey: string) {
        if (this.selectedCancellation === rateKey) {
            this.selectedCancellation = ""
        } else {
            this.selectedCancellation = rateKey
            if (this.fetchedCancellations[rateKey]) return
            this.isCancellationLoading = true
            if (this.hotel.combinedRooms) {
                this.checkRatesCombined(
                    rateKey,
                    this.hotelItem["hotelCodeCrypted"]
                ).subscribe(
                    (data) => {
                        if (data["result"] && data["result"]["cancellationPolicies"]) {
                            this.fetchedCancellations[rateKey] = data["result"]["cancellationPolicies"]["cancellationPolicies"]
                        }
                    },
                    (error) => {
                        console.log(error)
                    },
                    () => {
                        this.isCancellationLoading = false
                    }
                )
            } else {
                this.checkRatesNotCombined(
                    rateKey,
                    this.hotelItem["hotelCodeCrypted"]
                ).subscribe(
                    (data) => {
                        if (data["result"] && data["result"]["cancellationPolicies"]) {
                            this.fetchedCancellations[rateKey] = data["result"]["cancellationPolicies"]["cancellationPolicies"]
                        }
                    },
                    (error) => {
                        console.log(error)
                    },
                    () => {
                        this.isCancellationLoading = false
                    }
                )
            }
        }
    }

    toggleCarousel(index?: number) {
        this.carouselIndex = index || 0
        this.showCarousel = !this.showCarousel
    }
}
