import {
	Component,
	OnInit,
	Output,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	EventEmitter,
} from "@angular/core";
import { FlightService } from "@app/shared/services/flight.service";
import { Router } from "@angular/router";
import { AIRLINE_LOGO_URL, STATIC_FILES_URL } from "@app/shared/services/urls_b2c";
import { BaseService } from "../../services/base.service";
import { environment } from "../../../../environments/environment";
import { NavigationService } from "../../services/navigation.service";
import { cloneDeep } from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { ShareVars } from "../../services/share.vars";

@Component({
	selector: "app-flight-details",
	templateUrl: "./flight-details.component.html",
	styleUrls: ["./flight-details.component.scss"],
})
export class FlightDetailsComponent implements OnInit, OnChanges, OnDestroy {
	@Input() object: any = {};
	@Input() airlineDictionary: any = {};
	@Input() airportDictionary: any = {};
	@Input() userDashboard: boolean = false;
	@Input() source: string = null;
	@Output() modalEvent = new EventEmitter();
	flightsDepartures = [];
	flightsReturns = [];
	provider: string;

	fileExt = ".png";
	airlineLogoAddress = AIRLINE_LOGO_URL;
	flightDeparturesLimit = 3;
	flightReturnsLimit = 3;
	showMoreDepartureState = "Show more";
	showMoreReturnState = "Show more";
	chevronWayDepState = "fa fa-chevron-down medium-size";
	chevronWayRetState = "fa fa-chevron-down medium-size";
	flightsReturnAuthorized = [];

	selectedDeparture;
	selectedReturn;
	choosedRecommendation = {};

	flightObject = {};
	flightBounds;
	userType;
	initPax: boolean = false;
	modalElement;
	showModal: boolean = false;
	openConditions: boolean = false;
	waitingConditionsData: boolean = false;
	boundsNames;
	flightConditionsModalData;

	isZoneB: boolean = false;

	constructor(
		public flightService: FlightService,
		private baseService: BaseService,
		private navigationService: NavigationService,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		this.provider = this.object.recommendation.provider;

		this.isZoneB = this.flightService.isZoneB(this.provider);

		if (this.source == "pax" || this.source == "pax-review") {
			if (this.object["recommendation"]) {
				this.flightBounds = this.object["recommendation"]["flights"].length;
			}
			this.userType = this.baseService.b2bUserType;
			this.initFlightObj();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		// Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		// Add 'implements OnInit' to the class.
		// console.log('>>>> ' + this.recommendation['flights'][0]['departure']['locationId']);

		if (environment.CLIENTNAME == "algerie-booking") {
			this.initArraysAB();
		}

		/* this.flightService.b2cState.subscribe(value => {
      console.log('hohoho');
      this.initArrays();
    });*/
		// console.log(this.flightsReturnAuthorized);
	}

	ngOnDestroy() {}

	initArraysAB() {
		// console.log("flightObj details: ", this.object);

		if (Object.keys(this.object).length > 0) {
			for (let i = 0; i < this.object.recommendation["flights"].length; i++) {
				this.flightsDepartures[i] = this.object.recommendation["flights"][i];
			}

			if (this.flightService.flightVars.tripType == "rt") {
				this.flightsReturns = this.object.recommendation["flights"][1];
			}
		}
	}

	showMore(way) {
		if (way === "departure") {
			if (this.showMoreDepartureState === "Show more") {
				this.flightDeparturesLimit = this.flightsDepartures.length;
				this.showMoreDepartureState = "Show less";
				this.chevronWayDepState = "fa fa-chevron-up medium-size";
			} else {
				this.flightDeparturesLimit = 3;
				this.showMoreDepartureState = "Show more";
				this.chevronWayDepState = "fa fa-chevron-down medium-size";
			}
		}
		if (way === "return") {
			if (this.showMoreReturnState === "Show more") {
				this.flightReturnsLimit = this.flightsReturns.length;
				this.showMoreReturnState = "Show less";
				this.chevronWayRetState = "fa fa-chevron-up medium-size";
			} else {
				this.flightReturnsLimit = 3;
				this.showMoreReturnState = "Show more";
				this.chevronWayRetState = "fa fa-chevron-down medium-size";
			}
		}
	}

	selectDepFlight(event, flightId, flight) {
		// console.log(flightId);
		this.flightsReturnAuthorized = Object.keys(this.extractSubRecommandations(flightId)).map(
			Number
		);
		this.selectedDeparture = flight;
		// console.log(">>>>>>" + JSON.stringify(flight));
	}

	selectRetFlight(event, flightId, flight) {
		// console.log(flightId);
		this.selectedReturn = flight;
		// console.log(">>>>>>" + JSON.stringify(flight));
	}

	isDisabled(i, flightId) {
		return !this.flightsReturnAuthorized.includes(flightId);
	}

	extractSubRecommandations(flightId) {
		const subRecommandations = this.object.recommendation["subRecommendations"];
		return subRecommandations[flightId];
	}

	openModal(m, type) {
		let index = 0;
		type == "departure" ? (index = 0) : (index = 1);
		m.segments.map((seg, i) => {
			return (seg.fareDetails = this.object.recommendation.fareDetails[index][i]);
		});
		m["price"] = this.object.recommendation.price.total;
		m["flightBookingClass"] = this.object.recommendation["flightBookingClass"];
		m["flightBookingClassTitle"] = this.object.recommendation["flightBookingClassTitle"];
		this.modalEvent.emit(m);
	}

	formatFlightPrice(price: number): string {
		return this.baseService.formatPrice(price);
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}

	initFlightObj() {
		switch (this.flightService.flightVars.tripType) {
			case "rt":
				this.flightObject["departure"] = this.object["recommendation"]["flights"][0];
				this.flightObject["arrival"] = this.object["recommendation"]["flights"][1];
				break;

			case "ow":
				this.flightObject["departure"] = this.object["recommendation"]["flights"][0];
				this.flightObject["arrival"] = null;
				break;

			case "md":
				this.flightObject["departure"] = this.object["recommendation"]["flights"];
				this.flightObject["arrival"] = null;
				break;
		}

		this.flightObject["airportDictionary"] = this.object.airportDictionary;
		this.flightObject["airlineDictionary"] = this.object.airlineDictionary;

		if (this.object["recommendation"]) {
			this.initPax = true;
			this.flightObject["baggage"] = this.object["recommendation"]["baggage"];
			this.flightObject["price"] = this.object["recommendation"]["price"];
			this.flightObject["fareDetails"] = this.object["recommendation"]["fareDetails"];
			this.flightObject["bookingKey"] = this.object["recommendation"]["bookingKey"];
			this.flightObject["validatingCarrier"] = this.object["recommendation"]["validatingCarrier"];

			if (this.isZoneB) {
				this.flightObject["FareSourceCode"] = this.object["recommendation"]["FareSourceCode"];
				this.flightObject["FareType"] = this.object["recommendation"]["FareType"];
			}
		}

		this.initArraysAB()
	}

	openModal2(m) {
		this.modalElement = cloneDeep(m);
		this.modalElement["price"] = this.flightObject["price"].total;
		this.modalElement["flightBookingClass"] = this.flightService.flightVars.tripType;
		this.modalElement["flightBookingClassTitle"] = this.flightService.flightVars["className"];
		this.showModal = true;
	}

	timeDifference(fd1, time1, fd2, time2, sort?: string) {
		fd1 = fd1.split("-");
		fd2 = fd2.split("-");
		time1 = time1.split(":");
		time2 = time2.split(":");

		fd1 = fd1.map((el) => Number(el));
		fd2 = fd2.map((el) => Number(el));

		let diff: any;
		const date1 = new Date(fd1[2], fd1[1] - 1, fd1[0], time1[0], time1[1]).getTime();
		const date2 = new Date(fd2[2], fd2[1] - 1, fd2[0], time2[0], time2[1]).getTime();
		diff = date2 - date1;
		let msec = Math.abs(diff);
		const hh = Math.floor(msec / 1000 / 60 / 60);
		msec -= hh * 1000 * 60 * 60;
		const mm = Math.floor(msec / 1000 / 60);
		msec -= mm * 1000 * 60;
		const ss = Math.floor(msec / 1000);
		msec -= ss * 1000;

		if (sort && sort == "sort") {
			let newTime = {};
			newTime["hours"] = hh;
			newTime["minutes"] = mm;
			return newTime;
		} else {
			return (
				hh +
				" " +
				this.translateService.instant("HOURS") +
				" " +
				mm +
				" " +
				this.translateService.instant("MINUTES")
			);
		}
	}

	openConditionsModal(data) {
		this.waitingConditionsData = false;

		for (let i = 0; i < data["result"]["fare"].length; i++) {
			if (data["result"]["fare"][i]["Flight_Conditions"]) {
				if (data["result"]["fare"][i]["Flight_Conditions"].length > 0) {
					this.flightConditionsModalData.push(
						cloneDeep(data["result"]["fare"][i]["Flight_Conditions"])
					);
				} else {
					this.flightConditionsModalData.push(["NO_CONDITIONS_FOUND"]);
				}
			} else {
				this.flightConditionsModalData.push(["NO_CONDITIONS_FOUND"]);
			}
		}

		this.flightConditionsModalData.splice(this.flightBounds);
	}

	getSelectedFlightConditions(onlyBaggage?) {
		// TODO duplicate code from methods getSelectedFlightConditions and getFlightConditions on component FlightRecommendationComponent
		if (!onlyBaggage) {
			this.openConditions = true;
			this.waitingConditionsData = true;
			this.flightConditionsModalData = new Array();
		} else {
			ShareVars.isBusy = true;
		}

		this.boundsNames = new Array();
		let request = {};

		//DEPARTURE DATA
		for (let i = 0; i < this.flightObject["departure"].length; i++) {
			for (let j = 0; j < this.flightObject["departure"][i]["segments"].length; j++) {
				request["bookingClass_" + (i + 1) + "s" + (j + 1)] =
					this.flightObject["fareDetails"][i][j]["reservationClass"];
				request["cabin_" + (i + 1) + "s" + (j + 1)] =
					this.flightObject["fareDetails"][i][j]["cabin"];

				let dateTestNew =
					this.flightObject["departure"][i]["segments"][j]["departure"]["full_date"];
				const dateNew =
					dateTestNew.slice(6) +
					"-" +
					dateTestNew.slice(3, 5) +
					"-" +
					dateTestNew.slice(0, 2);
				let time = this.flightObject["departure"][i]["segments"][j]["departure"]["time"];
				if (!time) {
					this.flightObject["departure"][i]["segments"][j]["flightOrtrainNumber"] =
						"OPEN";
					this.flightObject["departure"][i]["segments"][j]["marketingCarrier"] = "AH";
				} else {
					request["departureDate_" + (i + 1) + "s" + (j + 1)] =
						dateNew + " " + time + ":00";
				}
				request["flightNumber_" + (i + 1) + "s" + (j + 1)] =
					this.flightObject["departure"][i]["segments"][j]["flightOrtrainNumber"];
				request["marketingCompany_" + (i + 1) + "s" + (j + 1)] =
					this.flightObject["departure"][i]["segments"][j]["marketingCarrier"];
				request["from_" + (i + 1) + "s" + (j + 1)] =
					this.flightObject["departure"][i]["segments"][j]["departure"]["locationId"];
				request["to_" + (i + 1) + "s" + (j + 1)] =
					this.flightObject["departure"][i]["segments"][j]["arrival"]["locationId"];
			}

			request["destination_" + (i + 1)] =
				this.flightObject["departure"][i]["segments"][
					this.flightObject["departure"][i]["segments"].length - 1
				]["arrival"]["locationId"];
			request["origin_" + (i + 1)] =
				this.flightObject["departure"][i]["segments"][0]["departure"]["locationId"];

			this.boundsNames.push(
				request["origin_" + (i + 1)] + "-" + request["destination_" + (i + 1)]
			);

			request["nrOfSegments_" + (i + 1)] =
				this.flightObject["departure"][i]["segments"].length;
		}

		//RETURN DATA
		if (this.flightObject["arrival"]) {
			for (let i = 0; i < this.flightObject["arrival"][0]["segments"].length; i++) {
				request["bookingClass_2s" + (i + 1)] =
					this.flightObject["fareDetails"][1][i]["reservationClass"];
				request["cabin_2s" + (i + 1)] = this.flightObject["fareDetails"][1][i]["cabin"];

				let dateTestNew =
					this.flightObject["arrival"][0]["segments"][i]["departure"]["full_date"];
				const dateNew =
					dateTestNew.slice(6) +
					"-" +
					dateTestNew.slice(3, 5) +
					"-" +
					dateTestNew.slice(0, 2);
				let time = this.flightObject["arrival"][0]["segments"][i]["departure"]["time"];
				if (!time) {
					this.flightObject["arrival"][0]["segments"][i]["flightOrtrainNumber"] = "OPEN";
					this.flightObject["arrival"][0]["segments"][i]["marketingCarrier"] = "AH";
					request["departureDate_2s" + (i + 1)] = dateNew + " 00:00:00";
				} else {
					request["departureDate_2s" + (i + 1)] = dateNew + " " + time + ":00";
				}

				request["flightNumber_2s" + (i + 1)] =
					this.flightObject["arrival"][0]["segments"][i]["flightOrtrainNumber"];
				request["marketingCompany_2s" + (i + 1)] =
					this.flightObject["arrival"][0]["segments"][i]["marketingCarrier"];
				request["from_2s" + (i + 1)] =
					this.flightObject["arrival"][0]["segments"][i]["departure"]["locationId"];
				request["to_2s" + (i + 1)] =
					this.flightObject["arrival"][0]["segments"][i]["arrival"]["locationId"];
			}

			request["destination_2"] =
				this.flightObject["arrival"][0]["segments"][
					this.flightObject["arrival"][0]["segments"].length - 1
				]["arrival"]["locationId"];
			request["origin_2"] =
				this.flightObject["arrival"][0]["segments"][0]["departure"]["locationId"];

			this.boundsNames.push(request["origin_2"] + "-" + request["destination_2"]);

			request["nrOfSegments_2"] = this.flightObject["arrival"][0]["segments"].length;
		}

		request["bookingKey"] = this.flightObject["bookingKey"];
		request["bounds"] = this.flightBounds;

		if (this.isZoneB) {
			request["FareSourceCode"] = this.flightObject["FareSourceCode"];
			request["FareType"] = this.flightObject["FareType"];
		} else {
			request["fareAdvise"] =
				this.flightObject["fareDetails"][0][0]["fares"]["fareAdvise"][0];
		}

		request["fareRuleType"] = "classicalRules";
		request["nrOfAdult"] = this.flightService.flightVars.nrOfAdult;
		request["nrOfChild"] = this.flightService.flightVars.nrOfChild;
		request["nrOfInfant"] = this.flightService.flightVars.nrOfInfant;
		request["provider_id"] = this.provider["id"];

		this.flightService.getFlightConditions(request).subscribe(
			(data) => {
				!onlyBaggage ? this.openConditionsModal(data) : (ShareVars.isBusy = false);

				this.flightObject["baggage"] = data["result"]["baggageAllowance"];
				this.object["recommendation"]["baggage"] = data["result"]["baggageAllowance"];
				this.flightService.setRecommendation(this.object);
			},
			(error) => {
				if (!onlyBaggage) {
					this.waitingConditionsData = false;
					this.flightConditionsModalData = new Array();
					this.flightConditionsModalData.push(["NO_CONDITIONS_FOUND"]);
				} else {
					ShareVars.isBusy = false;
				}
			}
		);
	}

	hideConditionsModal() {
		this.openConditions = false;
	}

	/* madiha travel, sparrow */
	goToFlightAvailability() {
		this.navigationService.goToFlightAvailabilityPage();
	}
}
