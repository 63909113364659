import { environment } from "../../../environments/environment";

export const IMAGES_URL = environment.staticApi;

export const BASE_API = environment.serverURL + "/api";
export const API = BASE_API + "/b2b/";
export const MIMIR_API = environment.production ? "https://mimir.algebratec.com/api/log" : "https://mimir.algebratec.com/staging/api/log"
export const ADMIN_LOGIN = API + "user/login";
export const SILENT_LOGIN = API + "user/silentlogin";
export const AGENCY_SESSION = API + "agency/self";
export const USER_SESSION = API + "user/current";
export const AGENCY_AGENCIES_B2B = API + "agency";
export const AGENCY_UPDATE_B2B = API + "agency/updateMyAgency";
export const AGENCY_PLATFORMS_B2B = API + "agency/platform";
export const AGENCY_REGISTER_B2B = API + "agency/register";
export const AGENCY_PROVIDERS_B2B = API + "provider";
export const ADMIN_LOGOUT = API + "user/logout";
export const ADMIN_CHANGE_PASSWORD = API + "user/changePassword";
export const ADMIN_FORGET_PASSWORD = API + "user/generateResetLink";
export const ADMIN_RESET_PASSWORD = API + "user/resetPassword";

/* flight urls_b2b */

export const FLIGHT_PROVIDERS_B2B_URL = BASE_API + "/common/statics/getFlightProviders";
export const FLIGHT_ALL_PROVIDERS_B2B_URL = BASE_API + "/common/statics/getAllFlightProviders";
export const AIRPORT_AUTOCOMPLETE_B2B_URL = BASE_API + "/common/statics/airportAutoComplete";
export const FLIGHT_AVAILABILITY_B2B_URL = API + "flight/availability";
export const FLIGHT_CALENDAR_B2B_URL = API + "flight/calendar";
export const FLIGHT_CONFIRMATION_B2B_URL = API + "flight/confirmation";
export const FLIGHT_AIR_VALIDATE_B2B_URL = API + "flight/airvalidate";
export const AGENCY_FLIGHT_PROVIDERS_B2B_URL = API + "flight/getFlightProviders";
export const FLIGHT_FARE_CONDITIONS_B2B_URL = API + "flight/fare";
export const FLIGHT_ETICKET_MINI_RULES_B2B_URL = API + "flight/getMiniRulesFromEticket";
export const FLIGHT_SEND_BOOKING_BYEMAIL_B2B_URL = API + "flight/sendByEmail";
export const FLIGHT_MANAGE_BOOKINGS_VALIDATING_CARRIERS_B2B_URL =
	API + "flight/getValidatingCarriers";
export const FLIGHT_MANAGE_BOOKINGS_FLIGHTS_B2B_URL = API + "flight/getFlightBookings";
export const FLIGHT_SCHEDULE_B2B_URL = API + "flight/getFlightSchedule";
export const FLIGHT_PNR_B2B_URL = API + "flight/getPnrClient";
// export const FLIGHT_ETICKET_PNR_B2B_URL = API + 'flight/getTicket';
export const FLIGHT_GET_PNR_B2B_URL = API + "flight/retrievePNR";
export const FLIGHT_REFRESH_B2B_URL = API + "flight/refresh";
export const FLIGHT_SYNCH_ETICKET_B2B_URL = API + "flight/editPnr";
export const FLIGHT_CANCEL_ETICKET_B2B_URL = API + "flight/cancelDocument";
export const FLIGHT_CANCEL_ETICKET_B2B_ADMIN_URL = API + "flight/cancel";
export const FLIGHT_TRANSFERT_ETICKET_B2B_URL = API + "flight/transfertBooking";
export const FLIGHT_CONFIRM_ONHOLD_ETICKET_B2B_URL = API + "flight/onHoldConfirmation";
export const FLIGHT_AMADEUS_MARKUP_B2B_URL = API + "flight/amadeusMarkup";
export const FLIGHT_AMADEUS_AIRLINE_MARKUP_B2B_URL = API + "flight/amadeusAirlineMarkup";
export const FLIGHT_AMADEUS_ZONEA_DESTINATION_B2B_URL = API + "flight/getZoneADestinations";
export const FLIGHT_AMADEUS_MARKUP_AGENCY_B2B_URL = API + "flight/amadeusMarkupAgency";
export const FLIGHT_PRINT_BOOKING_B2B = API + "flight/printTickets";

export const FLIGHT_CRYPTIC_B2B_URL = API + "flight/getPNRInCryptic";
/* hotel urls_b2b */
export const HOTEL_CHECK_RATES_B2B_URL = API + "hotel/checkRates";
export const HOTEL_AUTOCOMPLETE_B2B_URL = BASE_API + "/common/statics/cityAutocomplete";
export const HOTEL_AVAILABILITY_B2B_URL = API + "hotel/availability";
export const HOTEL_CONFIRMATION_B2B_URL = API + "hotel/confirmation";
export const HOTEL_MANAGE_BOOKINGS_HOTELS_B2B_URL = API + "hotel/bookingsList";
export const HOTEL_MANAGE_BOOKINGS_ROOMING_LIST_B2B_URL = API + "hotel/roomingList";
export const HOTEL_MANAGE_BOOKINGS_VOUCHER_B2B_URL = API + "hotel/voucher/";
export const HOTEL_SEND_BOOKING_BYEMAIL_B2B_URL = API + "hotel/sendByEmail";
export const HOTEL_CANCEL_B2B_URL = API + "hotel/cancel?provider=";
export const HOTEL_REFRESH_B2B_URL = API + "hotel/refresh?provider=";
export const HOTEL_CANCELLATION_CHARGES_B2B_URL =
	API + "hotel/cancellationCharges?booking_reference=";
export const HOTEL_OFFERS_B2B_URL = API + "hotel/offer";
export const HOTEL_TOP_DESTINATIONS_B2B_URL = API + "hotel/topDestinations";
export const HOTEL_PRINT_BOOKING_B2B = API + "hotel/printVoucher";
export const HOTEL_UPDATE_REMARK_B2B = API + "hotel/updateRemark";
export const HOTEL_EXCLUDE_B2B_URL = API + "hotel/exclude";
export const HOTEL_INCLUDE_B2B_URL = API + "hotel/include/";

/* package urls_b2b */
export const PACKAGE_MANAGE_BOOKINGS_PACKAGES_B2B_URL = API + "package/booking";
export const PACKAGE_SEND_BOOKING_BYEMAIL_B2B_URL = API + "package/booking/sendByEmail";
export const PACKAGE_PACKAGE_DESTINATION_B2B_URL =
	BASE_API + "/common/statics/package-destinations?all=true";
export const PACKAGE_PACKAGES_LIST_B2B_URL = API + "package";
export const PACKAGES_DISTINATIONS_B2B_URL = BASE_API + "/common/statics/package-destinations";
export const PACKAGES_DEPARTURES_B2B_URL = BASE_API + "/common/statics/package-departures";
export const PACKAGES_AVAILABILITY_B2B_URL = API + "package/booking/availability";
export const PACKAGES_CONFIRATION_B2B_URL = API + "package/booking/confirmation";
export const PACKAGES_CONFIRATION_ONHOLD_B2B_URL = API + "package/booking/onHoldConfirmation/";
export const PACKAGES_CONFIRATION_CANCEL_B2B_URL = API + "package/booking/cancel/";
export const PACKAGE_PRINT_BOOKING_B2B = API + "package/booking/printVoucher";

/* charter urls_b2b */
export const CHARTER_MANAGE_BOOKINGS_CHARTER_B2B_URL = API + "flight/charter/book";
export const CHARTER_GET_B2B_URL = API + "flight/charter";
export const CHARTER_CONFIRMATION_B2B_URL = API + "flight/charter/confirmation";
export const CHARTER_GET_BOOKINGS = API + "flight/charter/bookings";
export const CHARTER_CONFIRM_BOOKING = API + "flight/charter/booking/confirm";
export const CHARTER_CANCEL_BOOKING = API + "flight/charter/booking/cancel";
export const CHARTER_PRINT_BOOKING_B2B = API + "flight/charter/booking/eticket";
export const CHARTER_UPDATE_B2B_URL = API + "flight/charter/update";

/* account history */
export const ACCOUNT_HISTORY_B2B_URL = API + "finances/account/";

/* markup history */
export const MARKUP_HISTORY_B2B_URL = API + "finances/markup/";

/* manage agent */
export const AGENT_B2B_URL = API + "c_user";
export const DELETE_AGENT_B2B_URL = API + "user/";
export const ADD_AGENT_B2B_URL = API + "user";
export const UPDATE_AGENT_B2B_URL = API + "user/update";

/* Visa */
export const VISA_B2B_URL = API + "visa/";
export const VISA_FORMULES_URL = API + "visa/formules";
export const VISA_SEND_DATA_URL = API + "visa/confirmation";
export const VISA_ALL_SUBSCRIPTION_URL = API + "visa/subscriptions";
export const VISA_ONE_SUBSCRIPTION_URL = API + "visa/subscription/";

/* insurance */
export const INSURANCE_AMANA_URL = API + "insurance/amana";

/* Analytics */
export const ANALYTICS_B2B_URL = API + 'analytics/';

/* print b2b confirmation */
