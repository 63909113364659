import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { IMAGES_URL } from "../../services/urls_b2b";

@Component({
	selector: "app-shared-package-details",
	templateUrl: "./package-details.component.html",
	styleUrls: ["./package-details.component.scss"],
})
export class PackageDetailsComponent implements OnInit, OnDestroy {
	@Input() showModal: boolean;
	@Input() packageObj: any;
	@Input() roomsNumber: number;
	@Input() roomsToBook: any;
	@Input() isConfimation: boolean = false;

	imagesURL = IMAGES_URL;

	reqRoomsHeight: number = 41.5;
	rooms;

	constructor(private baseService: BaseService) {}

	ngOnInit(): void {
		this.rooms = this.getRoomsList(this.rooms, this.packageObj, this.roomsToBook);
	}

	ngOnDestroy(): void {}

	// get rooms list coz it is not a table
	// private getRoomsList(room): any {

	//   let roomsList: any[];
	//   roomsList = new Array(Object.keys(room.rooms).length);

	//   for (let i = 0; i < roomsList.length; i++) {
	//     roomsList[i] = Object.keys(room.rooms)[i];
	//   }
	//   this.reqRoomsHeight = this.reqRoomsHeight * roomsList.length;
	//   return roomsList;
	// }

	getRoomsList(rooms, packageObj, roomsToBook) {
		rooms = new Array();

		if (roomsToBook) {
			for (let i = 0; i < roomsToBook.length; i++) {
				for (let j = 0; j < roomsToBook[i].length; j++) {
					for (let step of packageObj.steps) {
						if (step["type"] == "HOTEL") {
							for (let reqsRoom of step["hotel"]["rooms"]) {
								for (let rate of reqsRoom["rates"]) {
									if (rate["id"] == roomsToBook[i][j].rateID) {
										const roomInfos = {};
										roomInfos["adults_number"] = rate["adults"];
										roomInfos["children_number"] = rate["children_number"];
										roomInfos["room_name"] = reqsRoom["name"];
										roomInfos["rate_board"] = rate["board"];
										roomInfos["rate_comment"] = rate["comment"];
										roomInfos["rate_price"] = rate["price"];
										roomInfos["stepIndex"] = roomsToBook[i][j].stepIndex;
										rooms.push(roomInfos);
									}
								}
							}
						}
					}
				}
			}
		}
		return rooms;
	}

	// show details modal
	showModalPackageDetails() {
		this.showModal = true;
	}

	hideModalPackageDetails() {
		this.showModal = false;
	}

	formatPrice(price: number) {
		return this.baseService.formatPrice(price);
	}

	isArabic() {
		return this.baseService.isArabic();
	}

	isEnglish() {
		return this.baseService.isEnglish();
	}
}
