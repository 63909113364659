import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import {
	CHARTER_GET_B2B_URL,
	CHARTER_CONFIRMATION_B2B_URL,
	CHARTER_UPDATE_B2B_URL,
} from "./urls_b2b";

@Injectable()
export class CharterSearchService {
	selectedCharter = {
		id: 0,
		adult_rate: 0,
		child_rate: 0,
		infant_rate: 0,
	};

	charters = [];

	charterItem = null;
	confirmationModalElement = null;

	pax = {
		adults: 0,
		children: 0,
		infants: 0,
	};

	holder: any;
	adultsPax = [];
	childrenPax = [];
	infantsPax = [];

	constructor(private baseService: BaseService) {}

	getCharters() {
		const headers = this.baseService.createRequestHeaders(null);
		return this.baseService.getRequestCode(CHARTER_GET_B2B_URL, headers);
	}

	getSelectedCharter() {
		return this.charterItem;
	}

	setClientInfos(clientInfos) {
		this.holder = clientInfos;
	}

	setAdultsInfos(adultsInfosArray) {
		this.adultsPax = adultsInfosArray;
	}

	setInfantsInfos(infantsInfosArray) {
		this.infantsPax = infantsInfosArray;
	}

	setChildrenInfos(childrenInfosArray) {
		this.childrenPax = childrenInfosArray;
	}

	getBookingRequest() {
		return {
			id: this.selectedCharter.id,
			holder: this.holder,
			adults: this.adultsPax,
			children: this.childrenPax,
			infants: this.infantsPax,
		};
	}

	confirmCharterBooking(request) {
		return this.baseService.tokenPostRequest(CHARTER_CONFIRMATION_B2B_URL, request);
	}

	totalPrice = (): number => {
		const adult_rate = +this.selectedCharter.adult_rate;
		const children_rate = +this.selectedCharter.child_rate;
		const infant_rate = +this.selectedCharter.infant_rate;
		const adults = +this.pax.adults;
		const children = +this.pax.children;
		const infants = +this.pax.infants;
		return adults * adult_rate + children * children_rate + infants * infant_rate;
	};
}
