import { Component, OnInit } from "@angular/core";
import { PackagesService } from "../../../shared/services/packages.service";
import { BaseService } from "../../../shared/services/base.service";
import { cloneDeep } from "lodash";

@Component({
	selector: "app-package-b2b-review",
	templateUrl: "./package-b2b-review.component.html",
	styleUrls: ["./package-b2b-review.component.scss"],
})
export class PackageB2bReviewComponent implements OnInit {
	clientInfos;
	travellersInfosArray;
	packageObj;

	constructor(
		private packagesService: PackagesService,
		private baseService: BaseService
	) {}

	ngOnInit() {
		this.clientInfos = cloneDeep(this.packagesService.clientInfos);
		this.travellersInfosArray = cloneDeep(this.packagesService.travellersInfosArray);
		this.packageObj = cloneDeep(this.packagesService.packageObj);
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}
}
