import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HOTEL_CONFIRMATION_API_URL } from "./urls_b2c";
import { HOTEL_CONFIRMATION_B2B_URL } from "./urls_b2b";

@Injectable()
export class HotelConfirmationService {
	confirmationModalElement = null;

	constructor(private baseService: BaseService) {}

	getHotelConfirmationModalElement() {
		return this.confirmationModalElement;
	}

	setHotelConfirmationModalElement(data) {
		this.confirmationModalElement = { ...data };
	}

	confirmHotelBooking(request) {
		let correctUrl;
		this.baseService.b2bUserType == "B2B"
			? (correctUrl = HOTEL_CONFIRMATION_B2B_URL)
			: (correctUrl = HOTEL_CONFIRMATION_API_URL);
		return this.baseService.tokenPostRequest(correctUrl, request);
	}
}
