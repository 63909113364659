import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep } from "lodash";
import { DateService } from "../../services/date.service";
import { HotelService } from "../../services/hotel.service";
import { NavigationService } from "../../services/navigation.service";

@Component({
	selector: "app-hotel-pax-review-booking",
	templateUrl: "./hotel-pax-review-booking.component.html",
	styleUrls: ["./hotel-pax-review-booking.component.scss"],
})
export class HotelPaxReviewBookingComponent implements OnInit, AfterViewInit {
	@Input() hotel;
	@Input() checkDateNotFound = true;
	@Input() checkInData;
	@Input() checkOutData;
	@Input() sourceUD: boolean = false;
	@Input() source: string = null;
	checkIn: string;
	checkOut: string;
	comments: string;
	nights: number;

	constructor(
		private hotelService: HotelService,
		private navigationService: NavigationService,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		let searchParams = cloneDeep(this.hotelService.getHotelBookingHotelSearchParams());
		this.comments = this.hotelService.getHotelBookingHotelSelectedComment();
		if (this.checkDateNotFound) {
			if (searchParams && this.hotel) {
				this.checkIn = searchParams["checkIn"];
				this.checkOut = searchParams["checkOut"];
				this.nights = this.calculateNights(this.checkIn, this.checkOut);
			}
		}
	}

	ngAfterViewInit() {}

	calculateNights(checkIn, checkOut): number {
		let nights = 0;

		if (checkOut && checkIn) {
			let x = null;
			x = DateService.toObjectDate(x, checkIn);

			let y = null;
			y = DateService.toObjectDate(x, checkOut);

			let outd = DateService.toNewDate(y).getTime();
			let ind = DateService.toNewDate(x).getTime();

			nights = Math.ceil((outd - ind) / (1000 * 3600 * 24));
		}

		return nights;
	}

	goToHotelAvailability() {
		this.navigationService.goToHotelAvailabilityPage();
	}

	getEmails(email: string): any {
		const result = new Array();
		let last = -1;

		for (let i = 0; i < email.length; i++) {
			if (email[i] === ";") {
				result.push(email.slice(last + 1, i));
				last = i;
			}
		}
		result.push(email.slice(last + 1).trim());
		return result;
	}
}
