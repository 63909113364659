import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { ShareVars } from "../../services/share.vars";

@Component({
	selector: "app-modal",
	templateUrl: "./modal.component.html",
	styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
	@Input() showModal = false;
	@Input() title;
	@Input() modalWidth;
	@Input() modalWidthPx;
	@Input() modalHeight;
	@Input() actionButton = "";
	@Input() disableButton: boolean = true;
	@Input() bookPackage: boolean = false;
	@Output() bookPackageClicked = new EventEmitter();
	@Output() buttonClicked = new EventEmitter();
	@Output() closeModalFrom2NDParent = new EventEmitter();
	animationClose: boolean = false;

	constructor() {}

	ngOnInit() {}

	closeModal() {
		this.showModal = false;
		this.closeModalFrom2NDParent.emit();

		if (ShareVars.signUpModalOn) {
			//reset all vars of signup
			ShareVars.initSignUpData();
			ShareVars.signUpModalOn = false;
			ShareVars.signup_from = null;
		}
	}

	sendEvent() {
		this.buttonClicked.emit();
	}

	bookPackageAction() {
		if (!this.disableButton) {
			this.bookPackageClicked.emit();
		}
	}
}
