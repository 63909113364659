import { Component, OnInit, Input } from "@angular/core";
import { LocalizationService } from "../../services/localization.service";
import { FlightService } from "../../services/flight.service";
import { PackagesService } from "../../services/packages.service";
import { HotelService } from "../../services/hotel.service";
import { switchMap } from "rxjs/operators";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: "app-agency-panel",
	templateUrl: "./agency-panel.component.html",
	styleUrls: ["./agency-panel.component.scss"],
})
export class AgencyPanelComponent implements OnInit {
	@Input() paxType: string;

	selectedWilayaName: string = null;
	selectedCommuneName: string = null;
	selectedAgencyName: string = null;
	selectedDepartment: number = null;
	accordionOpen: boolean = false;
	selectedWilaya = null;
	selectedCommune = null;
	selectedAgency = null;

	wilayas = [];
	agencies = [];
	communes = [];

	showLoadingCommunes: boolean = false;
	showLoadingAgencies: boolean = false;

	bookingConfirmationRequest = null;

	departmentDropdown = {
		mainButtonID: "agency_panel_departement_dropdown",
		mainButtonText: null,
		dropdownAriaID: "agency_panel_departement_dropdown_aria",
		ariaButtonsID: [],
		ariaButtonsText: [],
		dropdownTypeString: false,
	};

	townDropdown = {
		mainButtonID: "agency_panel_town_dropdown",
		mainButtonText: null,
		dropdownAriaID: "agency_panel_town_dropdown_aria",
		ariaButtonsID: [],
		ariaButtonsText: [],
		dropdownTypeString: false,
	};

	agencyDropdown = {
		mainButtonID: "agency_panel_agency_dropdown",
		mainButtonText: null,
		dropdownAriaID: "agency_panel_agency_dropdown_aria",
		ariaButtonsID: [],
		ariaButtonsText: [],
		dropdownTypeString: true,
	};

	constructor(
		private localizationService: LocalizationService,
		private flightService: FlightService,
		private packagesService: PackagesService,
		private hotelService: HotelService
	) {}

	ngOnInit() {
		switch (this.paxType) {
			case "flight":
				this.bookingConfirmationRequest = this.flightService.getConfirmationRequest();
				break;

			case "package":
				this.bookingConfirmationRequest =
					this.packagesService.getPackageBookingConfirmationRequest();
				break;

			case "hotel":
				this.bookingConfirmationRequest = this.hotelService.getHotelConfirmationRequest();
				break;
		}

		this.localizationService
			.getDepartementsOfSale()
			.subscribe((data) => this.initWilayas(data));
	}

	initWilayas(wilayasList): any {
		//this.bookingConfirmationRequest["point_of_sale_id"] = null;
		this.wilayas = wilayasList.sort((a, b) =>
			a.department_name
				? a.department_name.toLowerCase().localeCompare(b.department_name.toLowerCase())
				: false
		);

		//this.selectedWilayaName = wilayasList[0].name;
		//this.selectedWilaya = wilayasList[0];
		//return this.getCommunuesList(wilayasList[0]["id"]);
	}

	initCommunes(communesList) {
		console.log("commune list : ", communesList);
		this.bookingConfirmationRequest["point_of_sale_id"] = null;
		this.showLoadingCommunes = false;
		this.communes = communesList;
		this.selectedCommuneName = this.communes[0]["name"];
		this.selectedCommune = this.communes[0];
		this.getAgenciesList(this.selectedCommune["id"]).subscribe((data) =>
			this.initAgencies(data)
		);
	}

	initAgencies(agenciesList) {
		this.showLoadingAgencies = false;
		this.agencies = agenciesList;
		this.bookingConfirmationRequest["point_of_sale_id"] = null;

		if (this.agencies.length > 0) {
			this.selectedAgencyName = this.agencies[0]["name"];
			this.selectedAgency = this.agencies[0];
			this.bookingConfirmationRequest["point_of_sale_id"] = this.agencies[0]["id"];
		} else {
			this.selectedAgencyName = "NO_TRAVEL_AGENCY_FOUND";
			this.selectedAgency = null;
		}
	}

	getCommunuesList(wilayaID): any {
		return this.localizationService.getTowns(wilayaID);
	}

	getAgenciesList(townID): any {
		return this.localizationService.getPointsOfSale(townID);
	}

	setWilaya(wIndex: number) {
		this.bookingConfirmationRequest["point_of_sale_id"] = null;
		this.showLoadingCommunes = true;
		this.selectedWilayaName = this.wilayas[wIndex]["name"];
		this.selectedWilaya = this.wilayas[wIndex];

		this.getCommunuesList(this.selectedWilaya["id"]).subscribe((data) =>
			this.initCommunes(data)
		);
	}

	setCommune(cIndex) {
		this.bookingConfirmationRequest["point_of_sale_id"] = null;
		this.showLoadingAgencies = true;
		this.selectedCommune = this.communes[cIndex];
		this.selectedCommuneName = this.communes[cIndex]["name"];

		this.getAgenciesList(this.communes[cIndex]["id"]).subscribe((data) =>
			this.initAgencies(data)
		);
	}

	setAgency(agency) {
		this.selectedAgencyName = agency["name"];
		this.selectedAgency = agency;

		console.log("ag is : ", agency);
		this.bookingConfirmationRequest["point_of_sale_id"] = agency["id"];
		this.hotelService.setHotelConfirmationRequest(this.bookingConfirmationRequest);
		console.log(this.hotelService.hotelConfirmationRequest);
	}

	confirmBooking() {
		console.log(
			this.selectedWilayaName,
			"-",
			this.selectedCommuneName,
			"-",
			this.selectedAgencyName
		);
	}

	getDepartmentBtnsAriaID() {
		for (let i = 0; i < this.wilayas.length; i++) {
			this.departmentDropdown.ariaButtonsID[i] =
				"agency_panel_departement_dropdown_wilaya_" + i + "_button";
		}

		return this.departmentDropdown.ariaButtonsID;
	}

	getDepartmentBtnsAriaText() {
		for (let i = 0; i < this.wilayas.length; i++) {
			this.departmentDropdown.ariaButtonsText[i] = this.wilayas[i]["name"];
		}

		return this.departmentDropdown.ariaButtonsText;
	}

	getTownBtnsAriaID() {
		this.townDropdown.ariaButtonsID = [];
		for (let i = 0; i < this.communes.length; i++) {
			this.townDropdown.ariaButtonsID[i] =
				"agency_panel_town_dropdown_commune_" + i + "_button";
		}

		return this.townDropdown.ariaButtonsID;
	}

	getTownBtnsAriaText() {
		this.townDropdown.ariaButtonsText = [];
		for (let i = 0; i < this.communes.length; i++) {
			this.townDropdown.ariaButtonsText[i] = this.communes[i]["name"];
		}

		return this.townDropdown.ariaButtonsText;
	}

	getAgencyBtnsAriaID() {
		for (let i = 0; i < this.agencies.length; i++) {
			this.agencyDropdown.ariaButtonsID[i] =
				"agency_panel_agency_dropdown_selected_agency_" + i + "_button";
		}

		return this.agencyDropdown.ariaButtonsID;
	}

	getAgencyBtnsAriaText() {
		for (let i = 0; i < this.agencies.length; i++) {
			this.agencyDropdown.ariaButtonsText[i] = this.agencies[i]["name"];
		}

		return this.agencyDropdown.ariaButtonsText;
	}
	onWilayaSelect(depId) {
		if (this.selectedDepartment != depId) {
			this.selectedDepartment = depId;
			this.accordionOpen = true;
			this.agencies = [];
			this.localizationService.getAgenciesOfSale(depId).subscribe((data) => {
				this.agencies = data;
				console.log(this.agencies);
			});
			return;
		}
		this.accordionOpen = !this.accordionOpen;
	}
}
