import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { FLIGHT_AIR_VALIDATE_API_URL } from "./urls_b2c";
import { FLIGHT_AIR_VALIDATE_B2B_URL } from "./urls_b2b";
import { UrlBuilder } from "./urls_b2c";

@Injectable()
export class AirValidateService {
	constructor(private baseService: BaseService) {}

	airValidateFlightBooking(request) {
		let correctUrl;
		let payload = {};
		this.baseService.b2bUserType == "B2B"
			? (correctUrl = FLIGHT_AIR_VALIDATE_B2B_URL)
			: (correctUrl = FLIGHT_AIR_VALIDATE_API_URL);

		let airValidateURL = new UrlBuilder(correctUrl);

		for (let key in request) {
			airValidateURL.addParam(key, request[key]);
			payload[key] = request[key];
		}

		return this.baseService.tokenPostRequest(correctUrl, payload);
		//return this.baseService.tokenGetRequest(airValidateURL.getUrl());
	}
}
