import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { Observable, ObservableInput, of } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, catchError } from "rxjs/operators";
import { FlightService } from "@app/shared/services/flight.service";
import { ShareVars } from "../../../shared/services/share.vars";
import { BaseService } from "../../../shared/services/base.service";
import {
	CustomDatepickerI18nService,
	I18n,
} from "@app/shared/services/custom-datepicker-i18n.service";
import { NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "environments/environment";
import { cloneDeep } from "lodash";

@Component({
	selector: "app-flight-search-fileds",
	templateUrl: "./flight-search-fileds.component.html",
	styleUrls: ["./flight-search-fileds.component.scss"],
	providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }],
})
export class FlightSearchFiledsComponent implements OnInit {
	@Input() filedSearch;
	@Input() filedSearchIndex;
	@Input() rtType;
	@Input() searchSource;
	@Output() deleteDestination = new EventEmitter();
	@Input() displayMultipleCabinClass;
	@Input() classDropDownID;
	@Input() classDropDownText;
	@Input() classCorrespondances;
	@Input() classTitles;
	@Input() filedsLength;
	@Input() fieldDisplay;

	shareVarsRef = ShareVars;

	filedsSearchDInputsID: string;
	filedsSearchRInputsID: string;
	filedsDatesDInputsID: string;
	filedsDatesRInputsID: string;

	constructor(public flightService: FlightService, private baseService: BaseService) {}

	ngOnInit() {
		this.filedsSearchDInputsID =
			ShareVars.SEARCH_FLIGHT_DEPARTURE_INPUT_ID + this.filedSearchIndex;
		this.filedsSearchRInputsID = "flight_search_return_flight_input_" + this.filedSearchIndex;
		this.filedsDatesDInputsID =
			"flight_search_departure_flight_date_button_" + this.filedSearchIndex;
		this.filedsDatesRInputsID =
			"flight_search_return_flight_date_button_" + this.filedSearchIndex;
	}

	formatter(result: string) {
		return result["display"];
	}

	searchDepartureFlight = (text$: Observable<string>) => {
		//const self = this;
		return text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap((term) => this.displayAutocompleteFlightsResults(term, "Departure"))
		);
	};

	selectDeparture(p) {
		this.filedSearch.departureItem = p;
		if (!p) {
			this.filedSearch.flightDepartureName = ""
			return
		}
		this.filedSearch.flightDepartureName = p["item"]["display"];
		if (this.fieldDisplay) {
			this.fieldDisplay.departureName = `${this.filedSearch.departureItem.item.name} - ${this.filedSearch.departureItem.item.country_name}`;
			this.fieldDisplay.departureDisplay = `${this.filedSearch.departureItem.item.city_name} (${this.filedSearch.departureItem.item.iata})`;
		}
		this.baseService.focusOnElement(this.filedsSearchRInputsID);
	}

	setClass(cabinClass, direction) {
		this.filedSearch["cabinClass_" + direction + "_bound"] =
			this.classCorrespondances[cabinClass];
		this.filedSearch["cabinClassName_" + direction + "_bound"] = this.classTitles[cabinClass];
	}

	displayAutocompleteFlightsResults(term, type): ObservableInput<any> {
		let result = null;

		this.filedSearch.flightDepartureSpinner = type === "Departure";
		this.filedSearch.flightArrivalSpinner = type === "Arrival";

		result = this.flightService.getAirports(term).pipe(catchError((error) => of([])));

		this.checkAutoCompleteFlightResult(result, type);
		return result;
	}
h
	checkAutoCompleteFlightResult(result, type) {
		result.subscribe(
			(data) => {
				if (type === "Departure") {
					this.filedSearch.flightDepartureSpinner = false;
					if (data.length == 0 && this.filedSearch.departureItem) {
						this.filedSearch.departureItem = null;
					}
					return;
				}
				this.filedSearch.flightArrivalSpinner = false;
				if (data.length == 0 && this.filedSearch.returnItem) {
					this.filedSearch.returnItem = null;
				}
			},
			(error) => {
				this.filedSearch.flightDepartureSpinner = false;
				this.filedSearch.flightArrivalSpinner = false;
			}
		);
	}

	checkChangeItem(value, type) {
		//type: 1==departure, 2==arrival or return
		if (value != "") return;

		if (type == 1) {
			this.filedSearch.departureItem = null;
			if (this.fieldDisplay) {
				this.fieldDisplay.departureDisplay = "";
				this.fieldDisplay.departureName = "";
			}
			return;
		}
		this.filedSearch.returnItem = null;
		if (this.fieldDisplay) {
			this.fieldDisplay.arrivalDisplay = "";
			this.fieldDisplay.arrivalName = "";
		}
	}

	selectReturn(p) {
		this.filedSearch.returnItem = p;
		if (!p) {
			this.filedSearch.flightArrivalName = ""
			return
		}
		this.filedSearch.flightArrivalName = p["item"]["display"];
		if (this.fieldDisplay) {
			this.fieldDisplay.arrivalName = `${this.filedSearch.returnItem.item.name} - ${this.filedSearch.returnItem.item.country_name}`;
			this.fieldDisplay.arrivalDisplay = `${this.filedSearch.returnItem.item.city_name} (${this.filedSearch.returnItem.item.iata})`;
		}
		this.baseService.focusOnElement(this.filedsDatesDInputsID);
	}

	searchArrivalFlight = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap((term) => this.displayAutocompleteFlightsResults(term, "Arrival"))
		);
	};

	removeDestination(filedSearchIndex) {
		this.deleteDestination.emit(filedSearchIndex);
	}

	setNewMinDateDepartre($event, returnDateRef) {
		this.filedSearch.modelDeparture = $event;
		ShareVars.minDateFlightSearch[this.filedSearchIndex + 1] = $event;

		returnDateRef.minDate = $event;

		if (environment.assets == "volz" && this.rtType == "rt" && !this.flightService.openReturn) {
			this.baseService.focusOnElement(this.filedsDatesRInputsID);
		}

		if (this.rtType == "md") {
			this.baseService.focusOnElement(
				ShareVars.SEARCH_FLIGHT_DEPARTURE_INPUT_ID + (this.filedSearchIndex + 1)
			);
		}
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}

	ngOnChanges(changes: SimpleChanges) {
		this.classDropDownID = this.baseService.removeElementFromArray(
			this.classDropDownID,
			"flight_search_class_dropdown_multiple"
		);
		this.classDropDownText = this.baseService.removeElementFromArray(
			this.classDropDownText,
			"MULTIPLE"
		);
	}
	switchInput() {
		let tmp = cloneDeep(this.filedSearch.departureItem);
		tmp = (tmp && tmp.item) ? tmp.item : tmp
		this.filedSearch.departureItem = (this.filedSearch.returnItem && this.filedSearch.returnItem.item) ? this.filedSearch.returnItem.item : this.filedSearch.returnItem;
		this.filedSearch.returnItem = tmp;
		if (!tmp && !this.filedSearch.departureItem) return
		if (!tmp) {
			this.fieldDisplay.arrivalDisplay = "";
			this.fieldDisplay.arrivalName = "";
		} else {
			this.fieldDisplay.arrivalName = `${tmp.name} - ${tmp.country_name}`;
			this.fieldDisplay.arrivalDisplay = `${tmp.city_name} (${tmp.iata})`;
		}
		if (!this.filedSearch.departureItem) {
			this.fieldDisplay.departureName =  ""
			this.fieldDisplay.departureDisplay =  ""
		} else {
			this.fieldDisplay.departureName = `${this.filedSearch.departureItem.name} - ${this.filedSearch.departureItem.country_name}`;
			this.fieldDisplay.departureDisplay = `${this.filedSearch.departureItem.city_name} (${this.filedSearch.departureItem.iata})`;

		}
	}

	clearInput(input_name: string, input_id: string) {
		const input = document.getElementById(input_id) as HTMLInputElement
		switch (input_name) {
			case "departure":
				input.value = ""
				this.selectDeparture(null)
				break
			case "return":
				input.value = ""
				this.selectReturn(null)
				break
			default:
				break
		}
	}

	isInputEmpty(input_id: string) {
		const input = document.getElementById(input_id) as HTMLInputElement
		if (input && input.value.length) return false
		return true
	}
}
