import { Component, OnInit, Input } from "@angular/core";
import { PackagesService } from "../../services/packages.service";
import { BaseService } from "../../services/base.service";

@Component({
	selector: "app-fare-package",
	templateUrl: "./fare-package.component.html",
	styleUrls: ["./fare-package.component.scss"],
})
export class FarePackageComponent implements OnInit {
	@Input() entry: any = null;
	roomsNumber: number = null;
	adultNumber: number = null;
	childNumber: number = null;
	// roomsToBook = null;
	// rooms = null;
	totalPrice = null;
	currency: string = null;

	constructor(
		private packagesService: PackagesService,
		private baseService: BaseService
	) {}

	ngOnInit() {
		this.roomsNumber = this.packagesService.getPackageBookingPackageRoomsNumber();
		this.adultNumber = this.packagesService.getPackageBookingAdultTotalNumber();
		this.childNumber = this.packagesService.getPackageBookingChildTotalNumber();
		this.totalPrice = this.packagesService.getPackageBookingPackageTotalPrice();
		this.currency = this.entry["price"]["currency"];
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}

	formatPrice(price: number) {
		return this.baseService.formatPrice(price);
	}
}
