import { Component, OnInit, Input } from "@angular/core";
import { HotelService } from "../../services/hotel.service";
import { TaskService } from "@app/shared/services/task.service";

@Component({
	selector: "app-hotel-rating",
	templateUrl: "./hotel-rating.component.html",
	styleUrls: ["./hotel-rating.component.scss"],
})

// FIXME: change the component name to hotel-category, rating is something else we will do later
export class HotelRatingComponent implements OnInit {
	@Input() starsNumber: any;
	coloredStars: any[];
	oColoredStars: any[];

	constructor(private hotelService: HotelService) {}

	ngOnInit() {
		this.starsNumber = this.hotelService.getStars(this.starsNumber);

		this.coloredStars = new Array(this.starsNumber);
		this.oColoredStars = new Array(5 - this.starsNumber);
	}
}
