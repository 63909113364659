export class PackageTravellerClientModel {
	clientEmail: string;
	clientVerifyEmail: string;
	clientAddress: string;
	clientCountryCode: string;
	clientPhoneNumber: string;
	clientWilaya: string;
	clientTown: string;
	clientCountry: string;

	constructor() {
		this.clientEmail = null;
		this.clientVerifyEmail = null;
		this.clientAddress = null;
		this.clientCountryCode = "213";
		this.clientPhoneNumber = null;
		this.clientWilaya = "Adrar";
		this.clientTown = null;
		this.clientCountry = "Algerie";
	}
}
