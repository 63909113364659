import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import {
	PACKAGE_MANAGE_BOOKINGS_PACKAGES_B2B_URL,
	AGENT_B2B_URL,
	AGENCY_AGENCIES_B2B,
	PACKAGE_PACKAGE_DESTINATION_B2B_URL,
	PACKAGE_PACKAGES_LIST_B2B_URL,
	AGENCY_PLATFORMS_B2B,
} from "./urls_b2b";
import { UrlBuilder } from "./urls_b2c";
import { packageManageBookingsSaves } from "./package-manage-bookings-saves";

@Injectable()
export class PackageManageBookingsService {
	searchSaves = new packageManageBookingsSaves();

	constructor(private baseService: BaseService) {}

	getPackageBookings(
		firstDay,
		lastDay,
		dateType,
		modeType,
		countryCode,
		cityID,
		packageID,
		status,
		agentUName,
		customerName,
		paxName,
		reference,
		agencyID,
		platformID
	) {
		const urlBuilder = new UrlBuilder(PACKAGE_MANAGE_BOOKINGS_PACKAGES_B2B_URL)
			.addParam("date_type", dateType)
			.addParam("firstDay", firstDay)
			.addParam("lastDay", lastDay);

		if (modeType) {
			urlBuilder.addParam("type", modeType);
		}

		if (countryCode) {
			urlBuilder.addParam("country_code", countryCode);
		}

		if (cityID) {
			urlBuilder.addParam("city_id", cityID);
		}

		if (packageID) {
			urlBuilder.addParam("package_id", packageID);
		}

		if (status) {
			urlBuilder.addParam("status", status);
		}

		if (agentUName) {
			urlBuilder.addParam("agentUsername", agentUName);
		}

		if (customerName) {
			urlBuilder.addParam("customer", customerName);
		}

		if (paxName) {
			urlBuilder.addParam("pax", paxName);
		}

		if (reference) {
			urlBuilder.addParam("reference", reference);
		}

		if (agencyID) {
			urlBuilder.addParam("agency_id", agencyID);
		}

		if (platformID) {
			urlBuilder.addParam("platform_id", platformID);
		}

		return this.baseService.tokenGetRequest(urlBuilder.getUrl());
	}

	getPackageVoucher(id) {
		return this.baseService.tokenGetRequest(
			PACKAGE_MANAGE_BOOKINGS_PACKAGES_B2B_URL + "/" + id
		);
	}

	getAgentsList() {
		return this.baseService.tokenGetRequest(AGENT_B2B_URL);
	}

	getAgenciesList(term?: string) {
		term = term ? "?term=" + term : "";
		return this.baseService.tokenGetRequest(AGENCY_AGENCIES_B2B + term);
	}
	
	getPackageDestinations() {
		return this.baseService.tokenGetRequest(PACKAGE_PACKAGE_DESTINATION_B2B_URL);
	}

	getPackagesList() {
		return this.baseService.tokenGetRequest(PACKAGE_PACKAGES_LIST_B2B_URL);
	}

	getPlatformsList() {
		return this.baseService.tokenGetRequest(AGENCY_PLATFORMS_B2B + "?type=PACKAGE");
	}
}
