import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from "@angular/platform-browser";

@Component({
	selector: "app-map-shared",
	templateUrl: "./map-shared.component.html",
	styleUrls: ["./map-shared.component.scss"],
})
export class MapSharedComponent implements OnInit {
	@Input() mapUrl: string;
	sanitizedUrl: SafeResourceUrl;

	constructor(private sanitizer: DomSanitizer) {}

	ngOnInit() {}

	getURL() {
		return this.sanitizer.bypassSecurityTrustResourceUrl(this.mapUrl);
	}
}
