import { Component, OnInit } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { ShareVars } from "@app/shared/services/share.vars";
import { environment } from "environments/environment";

@Component({
	selector: "app-communication",
	templateUrl: "./communication.component.html",
	styleUrls: ["./communication.component.scss"],
})
export class CommunicationComponent implements OnInit {
	communications = [];
	showModalCom = false;
	selectedCommunication = {};
	page = 1;
	perPage = 10;
	pagesCount = 1;
	total = 0;
	numbers = [];
	token;
	headers;
	url = "";

	constructor(private baseService: BaseService) {
		ShareVars.isBusy = true;
		this.token = this.baseService.getToken();
		this.headers = this.baseService.createRequestHeaders(this.token);
		this.url = environment.serverURL + "/api/b2b/communication";
	}

	ngOnInit() {
		this.getAllcoms();
	}

	getAllcoms() {
		let url = this.url + "?page=" + this.page + "&per_page=" + this.perPage;
		this.baseService.getRequestCode(url, this.headers).subscribe((data) => {
			this.numbers = [];
			ShareVars.isBusy = false;
			this.communications = data.communication.data;
			this.total = data.communication.total;
			let x = this.total;
			let y = this.perPage;
			this.pagesCount = (x - (x % y)) / y;
			if (this.pagesCount == 0) {
				this.pagesCount = 1;
			}
			for (let i = 0; i < this.pagesCount; i++) {
				this.numbers.push(i);
			}
		});
	}

	openAttachment(file_path: string) {
		window.open(environment.staticApi + file_path);
	}

	openCommunication(communication) {
		this.showModalCom = true;
		this.selectedCommunication = communication;
		if (!communication.read) this.baseService.unreadMessages--;
		let payload = {
			communication_id: communication.id,
		};
		this.baseService.putRequestCode(this.url, this.headers, payload).subscribe((data) => {});
	}
}
