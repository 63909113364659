import { SharedRoomModel } from "../../../shared/models/sharedRoomModel";

export class PackageTravellersRoom {
	travellerInfos;
	travellerRoom: SharedRoomModel;

	constructor() {
		this.travellerInfos = new Array();
		this.travellerRoom = new SharedRoomModel();
	}
}
