export const countries = [
	"Tunisie",
	"Afghanistan",
	"Afrique-du-Sud",
	"Albanie",
	"Algerie",
	"Allemagne",
	"Andorre",
	"Angola",
	"Anguilla",
	"Antigua-et-Barbuda",
	"Antarctique",
	"Arabie-saoudite",
	"Argentine",
	"Armenie",
	"Aruba",
	"Ascension",
	"Australie",
	"Autriche",
	"Azerbaidjan",
	"Bahamas",
	"Bahrein",
	"Bangladesh",
	"Barbade",
	"Belgique",
	"Belize",
	"Benin",
	"Bermudes",
	"Bhoutan",
	"Bielorussie",
	"Birmanie",
	"Bolivie",
	"Bosnie-Herz�govine",
	"Botswana",
	"Bresil",
	"Brunei",
	"Bulgarie",
	"Burkina-Faso",
	"Burundi",
	"Cambodge",
	"Cameroun",
	"Canada",
	"Cap-Vert",
	"Iles-Caimans",
	"Republique-centrafricaine",
	"Chili",
	"Chine",
	"Chypre",
	"Colombie",
	"Comores",
	"Republique-d�mocratique-du-Congo",
	"Republique-du-Congo",
	"Iles-Cook",
	"Coree-du-Nord",
	"Coree-du-Sud",
	"Costa-Rica",
	"Cote-d'Ivoire",
	"Croatie",
	"Cuba",
	"Danemark",
	"Djibouti",
	"Egypte",
	"Emirats-arabes-unis",
	"Equateur",
	"Erythree",
	"Espagne",
	"Estonie",
	"Etats-Unis",
	"Ethiopie",
	"Finlande",
	"France",
	"Gabon",
	"Gambie",
	"Georgie",
	"Ghana",
	"Gibraltar",
	"Grece",
	"Grenade",
	"Guadeloupe",
	"Guatemala",
	"Guinee",
	"Guinee-Equatoriale",
	"Guinee-Bissau",
	"Guyana",
	"Guyane",
	"Haiti",
	"Honduras",
	"Hong Kong",
	"Hongrie",
	"Inde",
	"Indonesie",
	"Irak",
	"Iran",
	"Irlande",
	"Islande",
	"Italie",
	"Jamaique",
	"Japon",
	"Jordanie",
	"Kazakhstan",
	"Kenya",
	"Kirghizistan",
	"Kiribati",
	"Kosovo",
	"Koweit",
	"Laos",
	"Lesotho",
	"Lettonie",
	"Liban",
	"Liberia",
	"Libye",
	"Liechtenstein",
	"Lituanie",
	"Luxembourg",
	"Macao",
	"Macedoine du Nord",
	"Madagascar",
	"Malaisie",
	"Malawi",
	"Maldives",
	"Mali",
	"Malouines",
	"Malte",
	"Maroc",
	"Iles Marshall",
	"Martinique",
	"Maurice",
	"Mauritanie",
	"Mayotte",
	"Mexique",
	"Moldavie",
	"Monaco",
	"Mongolie",
	"Montenegro",
	"Mozambique",
	"Namibie",
	"Nepal",
	"Nicaragua",
	"Niger",
	"Nigeria",
	"Niue",
	"Norvege",
	"Nouvelle-Caledonie",
	"Nouvelle-Zelande",
	"Oman",
	"Ouganda",
	"Ouzbekistan",
	"Pakistan",
	"Palaos",
	"Palestine",
	"Panama",
	"Papouasie-Nouvelle-Guinee",
	"Paraguay",
	"Pays-Bas",
	"Perou",
	"Philippines",
	"Pologne",
	"Portugal",
	"Qatar",
	"La-Reunion",
	"Roumanie",
	"Royaume-Uni",
	"Russie",
	"Rwanda",
	"Iles-Salomon",
	"Salvador",
	"Senegal",
	"Serbie",
	"Seychelles",
	"Sierra-Leone",
	"Singapour",
	"Slovaquie",
	"Slovenie",
	"Somalie",
	"Soudan",
	"Soudan-du-Sud",
	"Sri-Lanka",
	"Suede",
	"Suisse",
	"Suriname",
	"Eswatini",
	"Syrie",
	"Tadjikistan",
	"Tanzanie",
	"Taiwan",
	"Tchad",
	"Tcheque",
	"Thailande",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinite-et-Tobago",
	"Tunisie",
	"Turkmenistan",
	"Turquie",
	"Tuvalu",
	"Ukraine",
	"Uruguay",
	"Vanuatu",
	"Venezuela",
	"Vietnam",
	"Yemen",
	"Zambie",
	"Zimbabwe",
];
