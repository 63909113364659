import { Component, OnInit, Injector, OnDestroy, Output, EventEmitter, Input } from "@angular/core";
import { LoginService } from "@app/shared/services/login.service";
import { BaseService } from "@app/shared/services/base.service";
import { NavigationService } from "@app/shared/services/navigation.service";

const TAG = "main:login:";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
	email = "";
	password = "";

	hasLoginError = false;
	watingForLoginResponse = false;
	isLoggedIn = false;
	loginRequest$;

	@Input() showSignUp: boolean;
	@Output() goToSignUp: EventEmitter<any> = new EventEmitter();
	@Output() goToForgetPassword: EventEmitter<any> = new EventEmitter();

	constructor(
		private loginService: LoginService,
		public baseService: BaseService,
		private navigationService: NavigationService
	) {
		this.isLoggedIn = this.loginService.isLoggedIn();

		// platformService.setupLoginPageForMobile();
	}

	ngOnInit() {}

	attempLogin() {
		this.watingForLoginResponse = true;
		this.hasLoginError = false;

		this.loginRequest$ = this.loginService.logIn(this.email, this.password);
		this.loginRequest$.subscribe(
			(data) => {
				this.hasLoginError = data["error"];
				this.watingForLoginResponse = false;
				this.isLoggedIn = !this.hasLoginError;
				this.baseService.setToken(data["token"]);
				if (this.isLoggedIn) {
					window.location.reload();
				}
			},
			(error) => {
				this.watingForLoginResponse = false;
				this.hasLoginError = true;
			},
			() => {
				this.watingForLoginResponse = false;
			}
		);
	}

	isArabic() {
		return this.baseService.isArabic();
	}

	//FIX: logout not working
	logout() {
		this.loginService.logout();
		this.isLoggedIn = false;
	}

	ngOnDestroy(): void {
		if (this.loginRequest$) {
			this.loginRequest$.unsubscribe();
		}
	}
}
