import {
	Component,
	OnInit,
	Output,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	EventEmitter,
} from "@angular/core";
import { AIRLINE_LOGO_URL, STATIC_FILES_URL } from "@app/shared/services/urls_b2c";
import { BaseService } from "../../services/base.service";
import { TranslateService } from "@ngx-translate/core";
import { CharterSearchService } from "@app/shared/services/charter-search.service";

@Component({
	selector: "app-charter-details",
	templateUrl: "./charter-details.component.html",
	styleUrls: ["./charter-details.component.scss"],
})
export class CharterDetailsComponent implements OnInit, OnChanges, OnDestroy {
	@Input() object: any = {};
	@Input() airlineDictionary: any = {};
	@Input() airportDictionary: any = {};
	@Input() userDashboard: boolean = false;
	@Input() source: string = null;
	@Output() modalEvent = new EventEmitter();

	fileExt = ".png";
	airlineLogoAddress = AIRLINE_LOGO_URL;
	initPax: boolean = false;
	modalElement;

	constructor(
		private baseService: BaseService,
		private translateService: TranslateService,
		public charterService: CharterSearchService
	) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges): void {}

	ngOnDestroy() {}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}

	timeDifference(fd1, time1, fd2, time2, sort?: string) {
		fd1 = fd1.split("-");
		fd2 = fd2.split("-");
		time1 = time1.split(":");
		time2 = time2.split(":");

		fd1 = fd1.map((el) => Number(el));
		fd2 = fd2.map((el) => Number(el));

		let diff: any;
		const date1 = new Date(fd1[2], fd1[1] - 1, fd1[0], time1[0], time1[1]).getTime();
		const date2 = new Date(fd2[2], fd2[1] - 1, fd2[0], time2[0], time2[1]).getTime();
		diff = date2 - date1;
		let msec = Math.abs(diff);
		const hh = Math.floor(msec / 1000 / 60 / 60);
		msec -= hh * 1000 * 60 * 60;
		const mm = Math.floor(msec / 1000 / 60);
		msec -= mm * 1000 * 60;
		const ss = Math.floor(msec / 1000);
		msec -= ss * 1000;

		if (sort && sort == "sort") {
			let newTime = {};
			newTime["hours"] = hh;
			newTime["minutes"] = mm;
			return newTime;
		} else {
			return (
				hh +
				" " +
				this.translateService.instant("HOURS") +
				" " +
				mm +
				" " +
				this.translateService.instant("MINUTES")
			);
		}
	}

	getTime = (date) =>
		new Date(date).toLocaleTimeString(navigator.language, {
			hour: "2-digit",
			minute: "2-digit",
		});
}
