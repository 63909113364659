import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "range",
})
export class RangePipe implements PipeTransform {
	// simple pipe to fill an array with incrementing numbers, to draw repeating views with ease, like the nights dropdown in hotel booking
	// x: *ngFor='let i in [] | range:10' is equal *ngFor='let i in [0,1,2,3,4,5,6,7,8,9]'
	// @value can be anything
	// @args must contain at lease one value represting the upper bound of the range
	// return: array with increamenting values starting from 0 to @args[0]-1 with length equal to @args[0]
	transform(value: any, args?: any): any {
		return new Array(args).fill(0).map((val, index) => index);
	}
}
