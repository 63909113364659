import { Component, OnInit, Input } from "@angular/core";
import { FlightService } from "../../services/flight.service";
import { HotelService } from "../../services/hotel.service";

@Component({
	selector: "app-card-payment",
	templateUrl: "./card-payment.component.html",
	styleUrls: ["./card-payment.component.scss"],
})
export class CardPaymentComponent implements OnInit {
	@Input() paxType;
	bookingConfirmationRequest = null;
	cardType = "VISA";
	month = "JANUARY";
	year = 2019;

	months = [
		"JANUARY",
		"FEBRUARY",
		"MARCH",
		"APRIL",
		"MAY",
		"JUNE",
		"JULY",
		"AUGUST",
		"SEPTEMBER",
		"OCTOBER",
		"NOVEMBER",
		"DECEMBER",
	];

	ariaButtonsMonthID = [
		"card_month_exp_class_dropdown_jan",
		"card_month_exp_class_dropdown_feb",
		"card_month_exp_class_dropdown_mar",
		"card_month_exp_class_dropdown_apl",
		"card_month_exp_class_dropdown_may",
		"card_month_exp_class_dropdown_jun",
		"card_month_exp_class_dropdown_jul",
		"card_month_exp_class_dropdown_aug",
		"card_month_exp_class_dropdown_sep",
		"card_month_exp_class_dropdown_oct",
		"card_month_exp_class_dropdown_nov",
		"card_month_exp_class_dropdown_dec",
	];

	years = [2019, 2020, 2021, 2022, 2023];
	ariaButtonsYearsID = [
		"card_month_exp_class_dropdown_2019",
		"card_month_exp_class_dropdown_2020",
		"card_month_exp_class_dropdown_2021",
		"card_month_exp_class_dropdown_2022",
		"card_month_exp_class_dropdown_2023",
	];

	constructor(private flightService: FlightService, private hotelService: HotelService) {}

	ngOnInit() {
		switch (this.paxType) {
			case "flight":
				this.bookingConfirmationRequest = this.flightService.getConfirmationRequest();
				this.initData();
				break;

			case "hotel":
				this.bookingConfirmationRequest = this.hotelService.getHotelConfirmationRequest();
				this.initData();
				break;
		}
	}

	initData() {
		this.bookingConfirmationRequest["card_type"] = this.cardType;
		this.bookingConfirmationRequest["card_number"] = null;
		this.bookingConfirmationRequest["card_exp_month"] = this.month;
		this.bookingConfirmationRequest["card_exp_year"] = this.year;
	}

	setCardType($event) {
		this.cardType = $event;
		this.bookingConfirmationRequest["card_type"] = $event;
	}

	setMonth($event) {
		this.month = $event;
		this.bookingConfirmationRequest["card_exp_month"] = $event;
	}

	setYear($event) {
		this.year = $event;
		this.bookingConfirmationRequest["card_exp_year"] = $event;
	}
}
