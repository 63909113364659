import { Pipe, PipeTransform } from "@angular/core";
import { cloneDeep } from "lodash";

@Pipe({
	name: "searchFilterPipe",
})
export class SearchFilterPipe implements PipeTransform {
	/**
	 *
	 * @param value : array
	 * @param filter : string
	 */

	transform(value: any, filter: any): any {
		if (!filter) return value;
		return value.filter((e) => e.toLowerCase().includes(filter.toLowerCase()));
	}
}
