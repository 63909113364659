import { Component, OnInit, Input } from "@angular/core";
import { BaseService } from "../../services/base.service";
import { HotelService } from "../../services/hotel.service";
import { ShareVars } from "../../services/share.vars";
import { FlightService } from "../../services/flight.service";
import { DateService } from "../../services/date.service";
import { NavigationService } from "../../services/navigation.service";

const FLIGHT_SEARCH_PARAMS = "flightSearchParams";

@Component({
	selector: "app-flight-hotel-search-shared",
	templateUrl: "./flight-hotel-search-shared.component.html",
	styleUrls: ["./flight-hotel-search-shared.component.scss"],
})
export class FlightHotelSearchSharedComponent implements OnInit {
	@Input() iataCode: string;
	@Input() airportDictionary;
	@Input() checkIn: string;
	@Input() checkOut: string;
	@Input() searchSource: string;

	hotelDestination: string;
	hotelCheckInDate: string;
	hotelCheckOutDate: string;
	hotelNights: number;
	hotelRooms: number;
	hotelAdults: number;
	hotelChildren: number;
	hotelChildAge = [];
	selectedHotel;
	selectedHotelProviders = new Array();
	selectedCity: string;
	enableSearchHotels: boolean = false;

	searchBG = ["http://www.freemagebank.com/wp-content/uploads/edd/2015/08/CR000007LR.jpg"];

	constructor(
		private baseService: BaseService,
		private hotelService: HotelService,
		private flightService: FlightService,
		private navigationService: NavigationService
	) {}

	ngOnInit() {
		this.initSearchHotel();
	}

	initSearchHotel() {
		this.selectedCity = this.airportDictionary[this.iataCode]["city_name"];
		this.hotelService.getAutocompleteHotel(this.selectedCity).subscribe(
			(data) => this.setSearchHotelParams(data),

			(error) => {
				console.log("error cant get hotels search", error);
			}
		);
	}

	setSearchHotelParams(data) {
		let countryName: string = this.airportDictionary[this.iataCode]["country_name"];
		let airportName: string = this.airportDictionary[this.iataCode]["name"];
		let searchResults = new Array();

		airportName = airportName.toLowerCase().trim().split(" ").join("");
		countryName = countryName.toLowerCase().trim().split(" ").join("");

		for (let i = 0; i < data["predictions"].length; i++) {
			if (data["predictions"][i].description.trim().toLowerCase().indexOf(countryName) > -1) {
				if (
					data["predictions"][i].description
						.trim()
						.toLowerCase()
						.split(" ")
						.join("")
						.indexOf(airportName) > -1
				) {
					searchResults.unshift(data["predictions"][i]);
				} else {
					searchResults.push(data["predictions"][i]);
				}
			}
		}

		this.selectedHotel = searchResults[0];
		this.selectedHotelProviders = data["providers"];

		this.hotelDestination = this.selectedHotel["description"];
		this.hotelCheckInDate = this.checkIn;
		this.hotelCheckOutDate = this.checkOut;

		this.hotelNights = this.calculateNights(this.checkIn, this.checkOut);
		this.hotelRooms = 1;

		this.enableSearchHotels = true;
	}

	calculateNights(checkIn: string, checkOut: string): number {
		let checkInObj;
		let checkOutObj;

		checkInObj = DateService.toObjectDate(checkInObj, checkIn);
		checkOutObj = DateService.toObjectDate(checkOutObj, checkOut);

		let outd = DateService.toNewDate(checkOutObj).getTime();

		let ind = DateService.toNewDate(checkInObj).getTime();

		return Math.ceil((outd - ind) / (1000 * 3600 * 24));
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}
}
