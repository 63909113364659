import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { ShareVars } from "../../services/share.vars";
import { NavigationService } from "../../services/navigation.service";
import { environment } from "environments/environment";

@Component({
	selector: "app-error-modal",
	templateUrl: "./error-modal.component.html",
	styleUrls: ["./error-modal.component.scss"],
})
export class ErrorModalComponent implements OnInit {
	@Input() errorModalShowModal: boolean = false;
	@Input() errorMessage: string = "";
	@Input() errorStatus: number = null;
	@Input() errorTitle: string = null;
	@Input() errorPayment: string = null;
	@Input() showHome: boolean = true;

	@ViewChild("signUpModal") signUpModal: ElementRef;

	showLogin: boolean = true;
	showSignup: boolean = false;
	showForgetPassword: boolean = false;
	animationClose: boolean = false;
	userType: string;

	constructor(private navigationService: NavigationService) {}

	ngOnInit() {
		if (!this.errorTitle) {
			this.errorTitle = "ERROR";
		}
		this.userType = environment.mode;
	}

	closeModal() {
		this.showLogin = true;
		this.showSignup = false;
		this.showForgetPassword = false;
		this.errorModalShowModal = false;
		ShareVars.showError = false;
		ShareVars.errorMessage = null;
		ShareVars.errorTitle = null;
	}

	showSignUp() {
		this.showSignup = true;
		this.showLogin = false;
		this.showForgetPassword = false;
	}

	showForgetPasswordCall() {
		this.showSignup = false;
		this.showLogin = false;
		this.showForgetPassword = true;
	}

	goToHomePage() {
		this.closeModal();
		this.navigationService.goToHomePage();
	}
}
