import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FlightManageBookingsService } from "../../../shared/services/flight-manage-bookings.service";
import { NavigationService } from "../../../shared/services/navigation.service";
import { BaseService } from "../../../shared/services/base.service";
import { ShareVars } from "../../../shared/services/share.vars";
import {
	CANCEL_BOOKING_IMPOSSIBLE,
	ERROR_MESSAGE_GETTING_DATA,
} from "../../../shared/services/display.messages";

@Component({
	selector: "app-flight-edit-ticket",
	templateUrl: "./flight-edit-ticket.component.html",
	styleUrls: ["./flight-edit-ticket.component.scss"],
})
export class FlightEditTicketComponent implements OnInit {
	eTicket;
	modalElement;
	airlineDictionary;
	airportDictionary;
	printEmail = null;
	tripType;
	flightObject = {};
	userB2B;
	confirmationModalState: boolean = false;
	confirmationModalTitle: string = "";
	showModalTransfert: boolean = false;
	errorMessage = "";

	constructor(
		private activatedRoute: ActivatedRoute,
		private flightManageBookingsService: FlightManageBookingsService,
		private navigationService: NavigationService,
		private baseService: BaseService,
		private router: Router
	) {
		ShareVars.isBusy = true;
	}

	ngOnInit() {
		this.getEticketURL();
	}

	getEticketURL() {
		this.activatedRoute.queryParamMap.subscribe((data: any) => {
			if (!data.params.pnr || !data.params.name) {
				this.navigationService.goToHomePage();
			} else {
				this.flightManageBookingsService
					.getEticketByPNR(data.params["pnr"], data.params["name"])
					.subscribe((response) => {
						if (response.success) {
							this.eTicket = response.data;
							this.modalElement = {
								isEditTicket: true,
								payment: response.payment,
								bookingKey: response.bookingKey,
								booking: {
									...this.eTicket,
									ticket: this.eTicket,
								},
							};
							this.tripType = "ow";
						} else {
							this.errorMessage = response.errors[0];
						}
						ShareVars.isBusy = false;
					});
			}
		});
	}

	confirmBooking() {
		ShareVars.isBusy = true;

		this.flightManageBookingsService.confirmOnHoldETicket(this.eTicket.id).subscribe((data) => {
			this.router.onSameUrlNavigation = "reload";
			this.navigationService.goToB2BFlightETicket(this.eTicket.id);
		});
	}

	printTicket(data) {
		const token = this.baseService.getToken();
		this.baseService.printBooking(this.modalElement.bookingKey, token, "B2C", "FLIGHT");
	}

	sendBookingByEmail(data) {
		ShareVars.isBusy = true;
		this.baseService
			.sendBookingByEmail(this.modalElement.bookingKey, data.email, "B2C", "FLIGHT")
			.subscribe(
				(data) => {
					ShareVars.isBusy = false;
					if (data["status"] != 200 && !data["ok"]) {
						ShareVars.showError = true;
						ShareVars.errorMessage = ERROR_MESSAGE_GETTING_DATA;
					}
				},
				(error) => {
					ShareVars.isBusy = false;
					ShareVars.showError = true;
					ShareVars.errorMessage = ERROR_MESSAGE_GETTING_DATA;
				}
			);
	}

	cancelEticket() {
		ShareVars.isBusy = true;

		this.flightManageBookingsService.cancelETicket(this.eTicket.id).subscribe((data) => {
			if (data["result"]["IBE_Errors"]) {
				ShareVars.isBusy = false;

				ShareVars.showError = true;
				ShareVars.errorMessage = CANCEL_BOOKING_IMPOSSIBLE;
			} else {
				this.router.onSameUrlNavigation = "reload";
				this.navigationService.goToB2BFlightETicket(this.eTicket.id);
			}
		});
	}

	synchronize() {
		ShareVars.isBusy = true;
		const syncData = {
			mode: "",
			type: "auto",
			id: this.eTicket["id"],
		};

		this.flightManageBookingsService.synchETicket(syncData).subscribe((data) => {
			this.router.onSameUrlNavigation = "reload";
			this.navigationService.goToB2BFlightETicket(this.eTicket.id);
		});
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}
}
