import { Injectable } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { BehaviorSubject } from "rxjs";
import { HotelService } from "./hotel.service";

type TaskType = "HOTEL" | "FLIGHT"
interface Task {
    id: string;
    name: string;
    type: TaskType;
    exp: Date;
    data: any;
    params?: Object
    needLoading?: boolean
    alreadyLoaded?: boolean
}

@Injectable({
    providedIn: "root"
})
export class TaskService {
    isAllowed = false;
    tasks = new BehaviorSubject<Task[]>([]);
    taskActivityModal = new BehaviorSubject(false)
    taskType: TaskType = "HOTEL";
    selectedTask = new BehaviorSubject<Task | null>(null)

    constructor(private baseService: BaseService, private hotelService: HotelService) {
        this.getTasksLocalStorage()
        this.baseService.b2bUser.asObservable().subscribe((data) => {
            this.isAllowed = !!data && !this.baseService.getB2CState().getValue()
            if (!this.isAllowed) this.closeTaskModal() 
        })
    }

    addTask(task: any) {
        if (task) {
            const tmp_tasks = this.tasks.getValue()
            let existing = tmp_tasks.find((t) => task.id === t.id)
            if (!!existing) return existing
            task.needLoading = task.data.callRoomAvailability
            tmp_tasks.push(task as Task)
            this.tasks.next(tmp_tasks)
            this.setLocalStorage()
            return task as Task
        }
        return null
    }

    getSelectedTask() {
        return this.selectedTask.getValue()
    }

    setSelectedTask(task: Task | null) {
        const currentTask = task
        if (currentTask) {
            const tasks = this.tasks.getValue()
            for (let x=0; x<tasks.length; x++) {
                if (tasks[x].id === task.id) {
                    tasks[x] = task
                    break
                }
            }
            this.tasks.next(tasks)
            this.setLocalStorage()
        }
        this.selectedTask.next(currentTask)
    } 

    removeTask(task: any) {
        if (!task) return
        const tmp_tasks = this.tasks.getValue()
        const tmp_task = task as Task;
        if (this.getSelectedTask() && this.getSelectedTask().id === tmp_task.id) {
            if (this.tasks.getValue().length) {
                this.setSelectedTask(this.tasks.getValue()[0])
            }
            else {
                this.setSelectedTask(null)
                this.closeTaskModal()
            }
        }
        this.tasks.next(tmp_tasks.filter((t) => t.id !== tmp_task.id))
        this.setLocalStorage()
    }

    closeTaskModal() {
        this.taskActivityModal.next(false)
    }

    openTaskModal() {
        this.taskActivityModal.next(true)
    }

    getTasksLocalStorage() {
        const tmp = localStorage.getItem("taskView")
        if (tmp) {
            try {
                this.tasks.next(JSON.parse(tmp))
            } catch (err) {
                console.log(err)
                this.tasks.next([])
            }
        }
    }

    setLocalStorage() {
        try {
            localStorage.setItem("taskView", JSON.stringify(this.tasks.getValue()))
        } catch {
            localStorage.setItem("taskView", "")
        }
    }
}
