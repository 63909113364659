export const COUNTRIES = [
	{
		name: "Afghanistan",
		topLevelDomain: [".af"],
		alpha2Code: "AF",
		alpha3Code: "AFG",
		callingCodes: ["93"],
		capital: "Kabul",
		altSpellings: ["AF", "AfÄ¡Ä�nistÄ�n"],
		relevance: "0",
		region: "Asia",
		subregion: "Southern Asia",
		translations: {
			de: "Afghanistan",
			es: "AfganistÃ¡n",
			fr: "Afghanistan",
			ja: "ã‚¢ãƒ•ã‚¬ãƒ‹ã‚¹ã‚¿ãƒ³",
			it: "Afghanistan",
		},
		population: 26023100,
		latlng: [33, 65],
		demonym: "Afghan",
		area: 652230,
		gini: 27.8,
		timezones: ["UTC+04:30"],
		borders: ["IRN", "PAK", "TKM", "UZB", "TJK", "CHN"],
		nativeName: "Ø§Ù�ØºØ§Ù†Ø³ØªØ§Ù†",
		currencies: ["AFN"],
		languages: ["ps", "uz", "tk"],
		numericCode: "004",
	},
	{
		name: "Ã…land Islands",
		topLevelDomain: [".ax"],
		alpha2Code: "AX",
		alpha3Code: "ALA",
		callingCodes: ["358"],
		capital: "Mariehamn",
		altSpellings: ["AX", "Aaland", "Aland", "Ahvenanmaa"],
		relevance: "0",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Ã…land",
			es: "Alandia",
			fr: "Ã…land",
			ja: "ã‚ªãƒ¼ãƒ©ãƒ³ãƒ‰è«¸å³¶",
			it: "Isole Aland",
		},
		population: 28875,
		latlng: [60.116667, 19.9],
		demonym: "Ã…landish",
		area: 1580,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Ã…land",
		currencies: ["EUR"],
		languages: ["sv"],
		numericCode: "248",
	},
	{
		name: "Albania",
		topLevelDomain: [".al"],
		alpha2Code: "AL",
		alpha3Code: "ALB",
		callingCodes: ["355"],
		capital: "Tirana",
		altSpellings: ["AL", "ShqipÃ«ri", "ShqipÃ«ria", "Shqipnia"],
		relevance: "0",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Albanien",
			es: "Albania",
			fr: "Albanie",
			ja: "ã‚¢ãƒ«ãƒ�ãƒ‹ã‚¢",
			it: "Albania",
		},
		population: 2893005,
		latlng: [41, 20],
		demonym: "Albanian",
		area: 28748,
		gini: 34.5,
		timezones: ["UTC+01:00"],
		borders: ["MNE", "GRC", "MKD", "KOS"],
		nativeName: "ShqipÃ«ria",
		currencies: ["ALL"],
		languages: ["sq"],
		numericCode: "008",
	},
	{
		name: "Algeria",
		topLevelDomain: [".dz"],
		alpha2Code: "DZ",
		alpha3Code: "DZA",
		callingCodes: ["213"],
		capital: "Algiers",
		altSpellings: ["DZ", "Dzayer", "AlgÃ©rie"],
		relevance: "0",
		region: "Africa",
		subregion: "Northern Africa",
		translations: {
			de: "Algerien",
			es: "Argelia",
			fr: "AlgÃ©rie",
			ja: "ã‚¢ãƒ«ã‚¸ã‚§ãƒªã‚¢",
			it: "Algeria",
		},
		population: 39500000,
		latlng: [28, 3],
		demonym: "Algerian",
		area: 2381741,
		gini: 35.3,
		timezones: ["UTC+01:00"],
		borders: ["TUN", "LBY", "NER", "ESH", "MRT", "MLI", "MAR"],
		nativeName: "Ø§Ù„Ø¬Ø²Ø§Ø¦Ø±",
		currencies: ["DZD"],
		languages: ["ar"],
		numericCode: "012",
	},
	{
		name: "American Samoa",
		topLevelDomain: [".as"],
		alpha2Code: "AS",
		alpha3Code: "ASM",
		callingCodes: ["1684"],
		capital: "Pago Pago",
		altSpellings: ["AS", "Amerika SÄ�moa", "Amelika SÄ�moa", "SÄ�moa Amelika"],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Polynesia",
		translations: {
			de: "Amerikanisch-Samoa",
			es: "Samoa Americana",
			fr: "Samoa amÃ©ricaines",
			ja: "ã‚¢ãƒ¡ãƒªã‚«é ˜ã‚µãƒ¢ã‚¢",
			it: "Samoa Americane",
		},
		population: 55519,
		latlng: [-14.33333333, -170],
		demonym: "American Samoan",
		area: 199,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "American Samoa",
		currencies: ["USD"],
		languages: ["en", "sm"],
		numericCode: "016",
	},
	{
		name: "Andorra",
		topLevelDomain: [".ad"],
		alpha2Code: "AD",
		alpha3Code: "AND",
		callingCodes: ["376"],
		capital: "Andorra la Vella",
		altSpellings: ["AD", "Principality of Andorra", "Principat d'Andorra"],
		relevance: "0.5",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Andorra",
			es: "Andorra",
			fr: "Andorre",
			ja: "ã‚¢ãƒ³ãƒ‰ãƒ©",
			it: "Andorra",
		},
		population: 76949,
		latlng: [42.5, 1.5],
		demonym: "Andorran",
		area: 468,
		gini: null,
		timezones: ["UTC+01:00"],
		borders: ["FRA", "ESP"],
		nativeName: "Andorra",
		currencies: ["EUR"],
		languages: ["ca"],
		numericCode: "020",
	},
	{
		name: "Angola",
		topLevelDomain: [".ao"],
		alpha2Code: "AO",
		alpha3Code: "AGO",
		callingCodes: ["244"],
		capital: "Luanda",
		altSpellings: ["AO", "RepÃºblica de Angola", "Ê�É›publika de an'É¡É”la"],
		relevance: "0",
		region: "Africa",
		subregion: "Middle Africa",
		translations: {
			de: "Angola",
			es: "Angola",
			fr: "Angola",
			ja: "ã‚¢ãƒ³ã‚´ãƒ©",
			it: "Angola",
		},
		population: 24383301,
		latlng: [-12.5, 18.5],
		demonym: "Angolan",
		area: 1246700,
		gini: 58.6,
		timezones: ["UTC+01:00"],
		borders: ["COG", "COD", "ZMB", "NAM"],
		nativeName: "Angola",
		currencies: ["AOA"],
		languages: ["pt"],
		numericCode: "024",
	},
	{
		name: "Anguilla",
		topLevelDomain: [".ai"],
		alpha2Code: "AI",
		alpha3Code: "AIA",
		callingCodes: ["1264"],
		capital: "The Valley",
		altSpellings: ["AI"],
		relevance: "0.5",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Anguilla",
			es: "Anguilla",
			fr: "Anguilla",
			ja: "ã‚¢ãƒ³ã‚®ãƒ©",
			it: "Anguilla",
		},
		population: 13452,
		latlng: [18.25, -63.16666666],
		demonym: "Anguillian",
		area: 91,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Anguilla",
		currencies: ["XCD"],
		languages: ["en"],
		numericCode: "660",
	},
	{
		name: "Antarctica",
		topLevelDomain: [".aq"],
		alpha2Code: "AQ",
		alpha3Code: "ATA",
		callingCodes: [],
		capital: "",
		altSpellings: [],
		relevance: "0",
		region: "Polar",
		subregion: "",
		translations: {
			de: "Antarktika",
			es: "AntÃ¡rtida",
			fr: "Antarctique",
			ja: "å�—æ¥µå¤§é™¸",
			it: "Antartide",
		},
		population: 1000,
		latlng: [-74.65, 4.48],
		demonym: "",
		area: 14000000,
		gini: null,
		timezones: [
			"UTC-03:00",
			"UTC+03:00",
			"UTC+05:00",
			"UTC+06:00",
			"UTC+07:00",
			"UTC+08:00",
			"UTC+10:00",
			"UTC+12:00",
		],
		borders: [],
		nativeName: "Antarctica",
		currencies: ["AUD", "GBP"],
		languages: ["en"],
		numericCode: "010",
	},
	{
		name: "Antigua and Barbuda",
		topLevelDomain: [".ag"],
		alpha2Code: "AG",
		alpha3Code: "ATG",
		callingCodes: ["1268"],
		capital: "Saint John's",
		altSpellings: ["AG"],
		relevance: "0.5",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Antigua und Barbuda",
			es: "Antigua y Barbuda",
			fr: "Antigua-et-Barbuda",
			ja: "ã‚¢ãƒ³ãƒ†ã‚£ã‚°ã‚¢ãƒ»ãƒ�ãƒ¼ãƒ–ãƒ¼ãƒ€",
			it: "Antigua e Barbuda",
		},
		population: 86295,
		latlng: [17.05, -61.8],
		demonym: "Antiguan, Barbudan",
		area: 442,
		gini: null,
		timezones: ["UTCâˆ’04:00"],
		borders: [],
		nativeName: "Antigua and Barbuda",
		currencies: ["XCD"],
		languages: ["en"],
		numericCode: "028",
	},
	{
		name: "Argentina",
		topLevelDomain: [".ar"],
		alpha2Code: "AR",
		alpha3Code: "ARG",
		callingCodes: ["54"],
		capital: "Buenos Aires",
		altSpellings: ["AR", "Argentine Republic", "RepÃºblica Argentina"],
		relevance: "0",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "Argentinien",
			es: "Argentina",
			fr: "Argentine",
			ja: "ã‚¢ãƒ«ã‚¼ãƒ³ãƒ�ãƒ³",
			it: "Argentina",
		},
		population: 43131966,
		latlng: [-34, -64],
		demonym: "Argentinean",
		area: 2780400,
		gini: 44.5,
		timezones: ["UTCâˆ’03:00"],
		borders: ["BOL", "BRA", "CHL", "PRY", "URY"],
		nativeName: "Argentina",
		currencies: ["ARS"],
		languages: ["es", "gn"],
		numericCode: "032",
	},
	{
		name: "Armenia",
		topLevelDomain: [".am"],
		alpha2Code: "AM",
		alpha3Code: "ARM",
		callingCodes: ["374"],
		capital: "Yerevan",
		altSpellings: [
			"AM",
			"Hayastan",
			"Republic of Armenia",
			"Õ€Õ¡ÕµÕ¡Õ½Õ¿Õ¡Õ¶Õ« Õ€Õ¡Õ¶Ö€Õ¡ÕºÕ¥Õ¿Õ¸Ö‚Õ©ÕµÕ¸Ö‚Õ¶",
		],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Armenien",
			es: "Armenia",
			fr: "ArmÃ©nie",
			ja: "ã‚¢ãƒ«ãƒ¡ãƒ‹ã‚¢",
			it: "Armenia",
		},
		population: 3006800,
		latlng: [40, 45],
		demonym: "Armenian",
		area: 29743,
		gini: 30.9,
		timezones: ["UTC+04:00"],
		borders: ["AZE", "GEO", "IRN", "TUR"],
		nativeName: "Õ€Õ¡ÕµÕ¡Õ½Õ¿Õ¡Õ¶",
		currencies: ["AMD"],
		languages: ["hy", "ru"],
		numericCode: "051",
	},
	{
		name: "Aruba",
		topLevelDomain: [".aw"],
		alpha2Code: "AW",
		alpha3Code: "ABW",
		callingCodes: ["297"],
		capital: "Oranjestad",
		altSpellings: ["AW"],
		relevance: "0.5",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Aruba",
			es: "Aruba",
			fr: "Aruba",
			ja: "ã‚¢ãƒ«ãƒ�",
			it: "Aruba",
		},
		population: 107394,
		latlng: [12.5, -69.96666666],
		demonym: "Aruban",
		area: 180,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Aruba",
		currencies: ["AWG"],
		languages: ["nl", "pa"],
		numericCode: "533",
	},
	{
		name: "Australia",
		topLevelDomain: [".au"],
		alpha2Code: "AU",
		alpha3Code: "AUS",
		callingCodes: ["61"],
		capital: "Canberra",
		altSpellings: ["AU"],
		relevance: "1.5",
		region: "Oceania",
		subregion: "Australia and New Zealand",
		translations: {
			de: "Australien",
			es: "Australia",
			fr: "Australie",
			ja: "ã‚ªãƒ¼ã‚¹ãƒˆãƒ©ãƒªã‚¢",
			it: "Australia",
		},
		population: 23868800,
		latlng: [-27, 133],
		demonym: "Australian",
		area: 7692024,
		gini: 30.5,
		timezones: [
			"UTC+05:00",
			"UTC+06:30",
			"UTC+07:00",
			"UTC+08:00",
			"UTC+09:30",
			"UTC+10:00",
			"UTC+10:30",
			"UTC+11:30",
		],
		borders: [],
		nativeName: "Australia",
		currencies: ["AUD"],
		languages: ["en"],
		numericCode: "036",
	},
	{
		name: "Austria",
		topLevelDomain: [".at"],
		alpha2Code: "AT",
		alpha3Code: "AUT",
		callingCodes: ["43"],
		capital: "Vienna",
		altSpellings: ["AT", "Ã–sterreich", "Osterreich", "Oesterreich"],
		relevance: "0",
		region: "Europe",
		subregion: "Western Europe",
		translations: {
			de: "Ã–sterreich",
			es: "Austria",
			fr: "Autriche",
			ja: "ã‚ªãƒ¼ã‚¹ãƒˆãƒªã‚¢",
			it: "Austria",
		},
		population: 8602112,
		latlng: [47.33333333, 13.33333333],
		demonym: "Austrian",
		area: 83871,
		gini: 26,
		timezones: ["UTC+01:00"],
		borders: ["CZE", "DEU", "HUN", "ITA", "LIE", "SVK", "SVN", "CHE"],
		nativeName: "Ã–sterreich",
		currencies: ["EUR"],
		languages: ["de"],
		numericCode: "040",
	},
	{
		name: "Azerbaijan",
		topLevelDomain: [".az"],
		alpha2Code: "AZ",
		alpha3Code: "AZE",
		callingCodes: ["994"],
		capital: "Baku",
		altSpellings: ["AZ", "Republic of Azerbaijan", "AzÉ™rbaycan RespublikasÄ±"],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Aserbaidschan",
			es: "AzerbaiyÃ¡n",
			fr: "AzerbaÃ¯djan",
			ja: "ã‚¢ã‚¼ãƒ«ãƒ�ã‚¤ã‚¸ãƒ£ãƒ³",
			it: "Azerbaijan",
		},
		population: 9636300,
		latlng: [40.5, 47.5],
		demonym: "Azerbaijani",
		area: 86600,
		gini: 33.7,
		timezones: ["UTC+04:00"],
		borders: ["ARM", "GEO", "IRN", "RUS", "TUR"],
		nativeName: "AzÉ™rbaycan",
		currencies: ["AZN"],
		languages: ["az", "hy"],
		numericCode: "031",
	},
	{
		name: "The Bahamas",
		topLevelDomain: [".bs"],
		alpha2Code: "BS",
		alpha3Code: "BHS",
		callingCodes: ["1242"],
		capital: "Nassau",
		altSpellings: ["BS", "Commonwealth of the Bahamas"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Bahamas",
			es: "Bahamas",
			fr: "Bahamas",
			ja: "ãƒ�ãƒ�ãƒž",
			it: "Bahamas",
		},
		population: 368390,
		latlng: [24.25, -76],
		demonym: "Bahamian",
		area: 13943,
		gini: null,
		timezones: ["UTCâˆ’05:00"],
		borders: [],
		nativeName: "Bahamas",
		currencies: ["BSD"],
		languages: ["en"],
		numericCode: "044",
	},
	{
		name: "Bahrain",
		topLevelDomain: [".bh"],
		alpha2Code: "BH",
		alpha3Code: "BHR",
		callingCodes: ["973"],
		capital: "Manama",
		altSpellings: ["BH", "Kingdom of Bahrain", "Mamlakat al-Baá¸¥rayn"],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Bahrain",
			es: "Bahrein",
			fr: "BahreÃ¯n",
			ja: "ãƒ�ãƒ¼ãƒ¬ãƒ¼ãƒ³",
			it: "Bahrein",
		},
		population: 1359800,
		latlng: [26, 50.55],
		demonym: "Bahraini",
		area: 765,
		gini: null,
		timezones: ["UTC+03:00"],
		borders: [],
		nativeName: "â€�Ø§Ù„Ø¨Ø­Ø±ÙŠÙ†",
		currencies: ["BHD"],
		languages: ["ar"],
		numericCode: "048",
	},
	{
		name: "Bangladesh",
		topLevelDomain: [".bd"],
		alpha2Code: "BD",
		alpha3Code: "BGD",
		callingCodes: ["880"],
		capital: "Dhaka",
		altSpellings: ["BD", "People's Republic of Bangladesh", "GÃ´nÃ´prÃ´jatÃ´ntri Bangladesh"],
		relevance: "2",
		region: "Asia",
		subregion: "Southern Asia",
		translations: {
			de: "Bangladesch",
			es: "Bangladesh",
			fr: "Bangladesh",
			ja: "ãƒ�ãƒ³ã‚°ãƒ©ãƒ‡ã‚·ãƒ¥",
			it: "Bangladesh",
		},
		population: 158875000,
		latlng: [24, 90],
		demonym: "Bangladeshi",
		area: 147570,
		gini: 32.1,
		timezones: ["UTC+06:00"],
		borders: ["MMR", "IND"],
		nativeName: "Bangladesh",
		currencies: ["BDT"],
		languages: ["bn"],
		numericCode: "050",
	},
	{
		name: "Barbados",
		topLevelDomain: [".bb"],
		alpha2Code: "BB",
		alpha3Code: "BRB",
		callingCodes: ["1246"],
		capital: "Bridgetown",
		altSpellings: ["BB"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Barbados",
			es: "Barbados",
			fr: "Barbade",
			ja: "ãƒ�ãƒ«ãƒ�ãƒ‰ã‚¹",
			it: "Barbados",
		},
		population: 285000,
		latlng: [13.16666666, -59.53333333],
		demonym: "Barbadian",
		area: 430,
		gini: null,
		timezones: ["UTCâˆ’04:00"],
		borders: [],
		nativeName: "Barbados",
		currencies: ["BBD"],
		languages: ["en"],
		numericCode: "052",
	},
	{
		name: "Belarus",
		topLevelDomain: [".by"],
		alpha2Code: "BY",
		alpha3Code: "BLR",
		callingCodes: ["375"],
		capital: "Minsk",
		altSpellings: [
			"BY",
			"BielaruÅ›",
			"Republic of Belarus",
			"Ð‘ÐµÐ»Ð¾Ñ€ÑƒÑ�Ñ�Ð¸Ñ�",
			"Ð ÐµÑ�Ð¿ÑƒÐ±Ð»Ð¸ÐºÐ° Ð‘ÐµÐ»Ð°Ñ€ÑƒÑ�ÑŒ",
			"Belorussiya",
			"Respublika Belarusâ€™",
		],
		relevance: "0",
		region: "Europe",
		subregion: "Eastern Europe",
		translations: {
			de: "WeiÃŸrussland",
			es: "Bielorrusia",
			fr: "BiÃ©lorussie",
			ja: "ãƒ™ãƒ©ãƒ«ãƒ¼ã‚·",
			it: "Bielorussia",
		},
		population: 9485300,
		latlng: [53, 28],
		demonym: "Belarusian",
		area: 207600,
		gini: 26.5,
		timezones: ["UTC+03:00"],
		borders: ["LVA", "LTU", "POL", "RUS", "UKR"],
		nativeName: "Ð‘ÐµÐ»Ð°Ñ€ÑƒÌ�Ñ�ÑŒ",
		currencies: ["BYR"],
		languages: ["be", "ru"],
		numericCode: "112",
	},
	{
		name: "Belgium",
		topLevelDomain: [".be"],
		alpha2Code: "BE",
		alpha3Code: "BEL",
		callingCodes: ["32"],
		capital: "Brussels",
		altSpellings: [
			"BE",
			"BelgiÃ«",
			"Belgie",
			"Belgien",
			"Belgique",
			"Kingdom of Belgium",
			"Koninkrijk BelgiÃ«",
			"Royaume de Belgique",
			"KÃ¶nigreich Belgien",
		],
		relevance: "1.5",
		region: "Europe",
		subregion: "Western Europe",
		translations: {
			de: "Belgien",
			es: "BÃ©lgica",
			fr: "Belgique",
			ja: "ãƒ™ãƒ«ã‚®ãƒ¼",
			it: "Belgio",
		},
		population: 11248330,
		latlng: [50.83333333, 4],
		demonym: "Belgian",
		area: 30528,
		gini: 33,
		timezones: ["UTC+01:00"],
		borders: ["FRA", "DEU", "LUX", "NLD"],
		nativeName: "BelgiÃ«",
		currencies: ["EUR"],
		languages: ["nl", "fr", "de"],
		numericCode: "056",
	},
	{
		name: "Belize",
		topLevelDomain: [".bz"],
		alpha2Code: "BZ",
		alpha3Code: "BLZ",
		callingCodes: ["501"],
		capital: "Belmopan",
		altSpellings: ["BZ"],
		relevance: "0",
		region: "Americas",
		subregion: "Central America",
		translations: {
			de: "Belize",
			es: "Belice",
			fr: "Belize",
			ja: "ãƒ™ãƒªãƒ¼ã‚º",
			it: "Belize",
		},
		population: 368310,
		latlng: [17.25, -88.75],
		demonym: "Belizean",
		area: 22966,
		gini: 53.1,
		timezones: ["UTCâˆ’06:00"],
		borders: ["GTM", "MEX"],
		nativeName: "Belize",
		currencies: ["BZD"],
		languages: ["en", "es"],
		numericCode: "084",
	},
	{
		name: "Benin",
		topLevelDomain: [".bj"],
		alpha2Code: "BJ",
		alpha3Code: "BEN",
		callingCodes: ["229"],
		capital: "Porto-Novo",
		altSpellings: ["BJ", "Republic of Benin", "RÃ©publique du BÃ©nin"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Benin",
			es: "BenÃ­n",
			fr: "BÃ©nin",
			ja: "ãƒ™ãƒŠãƒ³",
			it: "Benin",
		},
		population: 10315244,
		latlng: [9.5, 2.25],
		demonym: "Beninese",
		area: 112622,
		gini: 38.6,
		timezones: ["UTC+01:00"],
		borders: ["BFA", "NER", "NGA", "TGO"],
		nativeName: "BÃ©nin",
		currencies: ["XOF"],
		languages: ["fr"],
		numericCode: "204",
	},
	{
		name: "Bermuda",
		topLevelDomain: [".bm"],
		alpha2Code: "BM",
		alpha3Code: "BMU",
		callingCodes: ["1441"],
		capital: "Hamilton",
		altSpellings: ["BM", "The Islands of Bermuda", "The Bermudas", "Somers Isles"],
		relevance: "0.5",
		region: "Americas",
		subregion: "Northern America",
		translations: {
			de: "Bermuda",
			es: "Bermudas",
			fr: "Bermudes",
			ja: "ãƒ�ãƒŸãƒ¥ãƒ¼ãƒ€",
			it: "Bermuda",
		},
		population: 64237,
		latlng: [32.33333333, -64.75],
		demonym: "Bermudian",
		area: 54,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Bermuda",
		currencies: ["BMD"],
		languages: ["en"],
		numericCode: "060",
	},
	{
		name: "Bhutan",
		topLevelDomain: [".bt"],
		alpha2Code: "BT",
		alpha3Code: "BTN",
		callingCodes: ["975"],
		capital: "Thimphu",
		altSpellings: ["BT", "Kingdom of Bhutan"],
		relevance: "0",
		region: "Asia",
		subregion: "Southern Asia",
		translations: {
			de: "Bhutan",
			es: "ButÃ¡n",
			fr: "Bhoutan",
			ja: "ãƒ–ãƒ¼ã‚¿ãƒ³",
			it: "Bhutan",
		},
		population: 763920,
		latlng: [27.5, 90.5],
		demonym: "Bhutanese",
		area: 38394,
		gini: 38.1,
		timezones: ["UTC+06:00"],
		borders: ["CHN", "IND"],
		nativeName: "Ê¼brug-yul",
		currencies: ["BTN", "INR"],
		languages: ["dz"],
		numericCode: "064",
	},
	{
		name: "Bolivia",
		topLevelDomain: [".bo"],
		alpha2Code: "BO",
		alpha3Code: "BOL",
		callingCodes: ["591"],
		capital: "Sucre",
		altSpellings: [
			"BO",
			"Buliwya",
			"Wuliwya",
			"Plurinational State of Bolivia",
			"Estado Plurinacional de Bolivia",
			"Buliwya Mamallaqta",
			"Wuliwya Suyu",
			"TetÃ£ VolÃ­via",
		],
		relevance: "0",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "Bolivien",
			es: "Bolivia",
			fr: "Bolivie",
			ja: "ãƒœãƒªãƒ“ã‚¢å¤šæ°‘æ—�å›½",
			it: "Bolivia",
		},
		population: 11410651,
		latlng: [-17, -65],
		demonym: "Bolivian",
		area: 1098581,
		gini: 56.3,
		timezones: ["UTCâˆ’04:00"],
		borders: ["ARG", "BRA", "CHL", "PRY", "PER"],
		nativeName: "Bolivia",
		currencies: ["BOB", "BOV"],
		languages: ["es", "ay", "qu"],
		numericCode: "068",
	},
	{
		name: "Bonaire",
		topLevelDomain: [".an", ".nl"],
		alpha2Code: "BQ",
		alpha3Code: "BES",
		callingCodes: ["5997"],
		capital: "Kralendijk",
		altSpellings: ["BQ", "Boneiru"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: null,
			es: null,
			fr: null,
			ja: null,
			it: null,
		},
		population: 17408,
		latlng: [12.15, -68.266667],
		demonym: "Dutch",
		area: 294,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Bonaire",
		currencies: ["USD"],
		languages: ["nl"],
		numericCode: "535",
	},
	{
		name: "Bosnia and Herzegovina",
		topLevelDomain: [".ba"],
		alpha2Code: "BA",
		alpha3Code: "BIH",
		callingCodes: ["387"],
		capital: "Sarajevo",
		altSpellings: ["BA", "Bosnia-Herzegovina", "Ð‘Ð¾Ñ�Ð½Ð° Ð¸ Ð¥ÐµÑ€Ñ†ÐµÐ³Ð¾Ð²Ð¸Ð½Ð°"],
		relevance: "0",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Bosnien und Herzegowina",
			es: "Bosnia y Herzegovina",
			fr: "Bosnie-HerzÃ©govine",
			ja: "ãƒœã‚¹ãƒ‹ã‚¢ãƒ»ãƒ˜ãƒ«ãƒ„ã‚§ã‚´ãƒ“ãƒŠ",
			it: "Bosnia ed Erzegovina",
		},
		population: 3791622,
		latlng: [44, 18],
		demonym: "Bosnian, Herzegovinian",
		area: 51209,
		gini: 36.2,
		timezones: ["UTC+01:00"],
		borders: ["HRV", "MNE", "SRB"],
		nativeName: "Bosna i Hercegovina",
		currencies: ["BAM"],
		languages: ["bs", "hr", "sr"],
		numericCode: "070",
	},
	{
		name: "Botswana",
		topLevelDomain: [".bw"],
		alpha2Code: "BW",
		alpha3Code: "BWA",
		callingCodes: ["267"],
		capital: "Gaborone",
		altSpellings: ["BW", "Republic of Botswana", "Lefatshe la Botswana"],
		relevance: "0",
		region: "Africa",
		subregion: "Southern Africa",
		translations: {
			de: "Botswana",
			es: "Botswana",
			fr: "Botswana",
			ja: "ãƒœãƒ„ãƒ¯ãƒŠ",
			it: "Botswana",
		},
		population: 2070984,
		latlng: [-22, 24],
		demonym: "Motswana",
		area: 582000,
		gini: 61,
		timezones: ["UTC+02:00"],
		borders: ["NAM", "ZAF", "ZMB", "ZWE"],
		nativeName: "Botswana",
		currencies: ["BWP"],
		languages: ["en", "tn"],
		numericCode: "072",
	},
	{
		name: "Bouvet Island",
		topLevelDomain: [".bv"],
		alpha2Code: "BV",
		alpha3Code: "BVT",
		callingCodes: [""],
		capital: "",
		altSpellings: ["BV", "BouvetÃ¸ya", "Bouvet-Ã¸ya"],
		relevance: "0",
		region: "",
		subregion: "",
		translations: {
			de: "Bouvetinsel",
			es: "Isla Bouvet",
			fr: "ÃŽle Bouvet",
			ja: "ãƒ–ãƒ¼ãƒ™å³¶",
			it: "Isola Bouvet",
		},
		population: 0,
		latlng: [-54.43333333, 3.4],
		demonym: "",
		area: 49,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "BouvetÃ¸ya",
		currencies: ["NOK"],
		languages: [],
		numericCode: "074",
	},
	{
		name: "Brazil",
		topLevelDomain: [".br"],
		alpha2Code: "BR",
		alpha3Code: "BRA",
		callingCodes: ["55"],
		capital: "BrasÃ­lia",
		altSpellings: [
			"BR",
			"Brasil",
			"Federative Republic of Brazil",
			"RepÃºblica Federativa do Brasil",
		],
		relevance: "2",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "Brasilien",
			es: "Brasil",
			fr: "BrÃ©sil",
			ja: "ãƒ–ãƒ©ã‚¸ãƒ«",
			it: "Brasile",
		},
		population: 204772000,
		latlng: [-10, -55],
		demonym: "Brazilian",
		area: 8515767,
		gini: 54.7,
		timezones: ["UTCâˆ’05:00", "UTCâˆ’04:00", "UTCâˆ’03:00", "UTCâˆ’02:00"],
		borders: ["ARG", "BOL", "COL", "GUF", "GUY", "PRY", "PER", "SUR", "URY", "VEN"],
		nativeName: "Brasil",
		currencies: ["BRL"],
		languages: ["pt"],
		numericCode: "076",
	},
	{
		name: "British Indian Ocean Territory",
		topLevelDomain: [".io"],
		alpha2Code: "IO",
		alpha3Code: "IOT",
		callingCodes: ["246"],
		capital: "Diego Garcia",
		altSpellings: ["IO"],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Britisches Territorium im Indischen Ozean",
			es: "Territorio BritÃ¡nico del OcÃ©ano Ã�ndico",
			fr: "Territoire britannique de l'ocÃ©an Indien",
			ja: "ã‚¤ã‚®ãƒªã‚¹é ˜ã‚¤ãƒ³ãƒ‰æ´‹åœ°åŸŸ",
			it: "Territorio britannico dell'oceano indiano",
		},
		population: 3000,
		latlng: [-6, 71.5],
		demonym: "Indian",
		area: 60,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "British Indian Ocean Territory",
		currencies: ["USD"],
		languages: ["en"],
		numericCode: "086",
	},
	{
		name: "United States Minor Outlying Islands",
		topLevelDomain: [".us"],
		alpha2Code: "UM",
		alpha3Code: "UMI",
		callingCodes: [""],
		capital: "",
		altSpellings: ["UM"],
		relevance: "0",
		region: "Americas",
		subregion: "Northern America",
		translations: {
			de: "Kleinere Inselbesitzungen der Vereinigten Staaten",
			es: "Islas Ultramarinas Menores de Estados Unidos",
			fr: "ÃŽles mineures Ã©loignÃ©es des Ã‰tats-Unis",
			ja: "å�ˆè¡†å›½é ˜æœ‰å°�é›¢å³¶",
			it: "Isole minori esterne degli Stati Uniti d'America",
		},
		population: 300,
		latlng: [],
		demonym: "American",
		area: null,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "United States Minor Outlying Islands",
		currencies: ["USD"],
		languages: ["en"],
		numericCode: "581",
	},
	{
		name: "Virgin Islands (British)",
		topLevelDomain: [".vg"],
		alpha2Code: "VG",
		alpha3Code: "VGB",
		callingCodes: ["1284"],
		capital: "Road Town",
		altSpellings: ["VG"],
		relevance: "0.5",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Britische Jungferninseln",
			es: "Islas VÃ­rgenes del Reino Unido",
			fr: "ÃŽles Vierges britanniques",
			ja: "ã‚¤ã‚®ãƒªã‚¹é ˜ãƒ´ã‚¡ãƒ¼ã‚¸ãƒ³è«¸å³¶",
			it: "Isole Vergini Britanniche",
		},
		population: 28054,
		latlng: [18.431383, -64.62305],
		demonym: "Virgin Islander",
		area: 151,
		gini: null,
		timezones: ["UTC-04:00"],
		borders: [],
		nativeName: "British Virgin Islands",
		currencies: ["USD"],
		languages: ["en"],
		numericCode: "092",
	},
	{
		name: "Virgin Islands (U.S.)",
		topLevelDomain: [".vi"],
		alpha2Code: "VI",
		alpha3Code: "VIR",
		callingCodes: ["1 340"],
		capital: "Charlotte Amalie",
		altSpellings: ["VI", "USVI", "American Virgin Islands", "U.S. Virgin Islands"],
		relevance: "0.5",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Amerikanische Jungferninseln",
			es: "Islas VÃ­rgenes de los Estados Unidos",
			fr: "ÃŽles Vierges des Ã‰tats-Unis",
			ja: "ã‚¢ãƒ¡ãƒªã‚«é ˜ãƒ´ã‚¡ãƒ¼ã‚¸ãƒ³è«¸å³¶",
			it: "Isole Vergini americane",
		},
		population: 114743,
		latlng: [18.34, -64.93],
		demonym: "Virgin Islander",
		area: 346.36,
		gini: null,
		timezones: ["UTC-04:00"],
		borders: [],
		nativeName: "Virgin Islands of the United States",
		currencies: ["USD"],
		languages: ["en"],
		numericCode: "850",
	},
	{
		name: "Brunei",
		topLevelDomain: [".bn"],
		alpha2Code: "BN",
		alpha3Code: "BRN",
		callingCodes: ["673"],
		capital: "Bandar Seri Begawan",
		altSpellings: ["BN", "Nation of Brunei", " the Abode of Peace"],
		relevance: "0",
		region: "Asia",
		subregion: "South-Eastern Asia",
		translations: {
			de: "Brunei",
			es: "Brunei",
			fr: "Brunei",
			ja: "ãƒ–ãƒ«ãƒ�ã‚¤ãƒ»ãƒ€ãƒ«ã‚µãƒ©ãƒ¼ãƒ ",
			it: "Brunei",
		},
		population: 393372,
		latlng: [4.5, 114.66666666],
		demonym: "Bruneian",
		area: 5765,
		gini: null,
		timezones: ["UTC+08:00"],
		borders: ["MYS"],
		nativeName: "Negara Brunei Darussalam",
		currencies: ["BND"],
		languages: ["ms"],
		numericCode: "096",
	},
	{
		name: "Bulgaria",
		topLevelDomain: [".bg"],
		alpha2Code: "BG",
		alpha3Code: "BGR",
		callingCodes: ["359"],
		capital: "Sofia",
		altSpellings: ["BG", "Republic of Bulgaria", "Ð ÐµÐ¿ÑƒÐ±Ð»Ð¸ÐºÐ° Ð‘ÑŠÐ»Ð³Ð°Ñ€Ð¸Ñ�"],
		relevance: "0",
		region: "Europe",
		subregion: "Eastern Europe",
		translations: {
			de: "Bulgarien",
			es: "Bulgaria",
			fr: "Bulgarie",
			ja: "ãƒ–ãƒ«ã‚¬ãƒªã‚¢",
			it: "Bulgaria",
		},
		population: 7202198,
		latlng: [43, 25],
		demonym: "Bulgarian",
		area: 110879,
		gini: 28.2,
		timezones: ["UTC+02:00"],
		borders: ["GRC", "MKD", "ROU", "SRB", "TUR"],
		nativeName: "Ð‘ÑŠÐ»Ð³Ð°Ñ€Ð¸Ñ�",
		currencies: ["BGN"],
		languages: ["bg"],
		numericCode: "100",
	},
	{
		name: "Burkina Faso",
		topLevelDomain: [".bf"],
		alpha2Code: "BF",
		alpha3Code: "BFA",
		callingCodes: ["226"],
		capital: "Ouagadougou",
		altSpellings: ["BF"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Burkina Faso",
			es: "Burkina Faso",
			fr: "Burkina Faso",
			ja: "ãƒ–ãƒ«ã‚­ãƒŠãƒ•ã‚¡ã‚½",
			it: "Burkina Faso",
		},
		population: 18450494,
		latlng: [13, -2],
		demonym: "Burkinabe",
		area: 272967,
		gini: 39.8,
		timezones: ["UTC"],
		borders: ["BEN", "CIV", "GHA", "MLI", "NER", "TGO"],
		nativeName: "Burkina Faso",
		currencies: ["XOF"],
		languages: ["fr", "ff"],
		numericCode: "854",
	},
	{
		name: "Burundi",
		topLevelDomain: [".bi"],
		alpha2Code: "BI",
		alpha3Code: "BDI",
		callingCodes: ["257"],
		capital: "Bujumbura",
		altSpellings: [
			"BI",
			"Republic of Burundi",
			"Republika y'Uburundi",
			"RÃ©publique du Burundi",
		],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Burundi",
			es: "Burundi",
			fr: "Burundi",
			ja: "ãƒ–ãƒ«ãƒ³ã‚¸",
			it: "Burundi",
		},
		population: 9823827,
		latlng: [-3.5, 30],
		demonym: "Burundian",
		area: 27834,
		gini: 33.3,
		timezones: ["UTC+02:00"],
		borders: ["COD", "RWA", "TZA"],
		nativeName: "Burundi",
		currencies: ["BIF"],
		languages: ["fr", "rn"],
		numericCode: "108",
	},
	{
		name: "Cambodia",
		topLevelDomain: [".kh"],
		alpha2Code: "KH",
		alpha3Code: "KHM",
		callingCodes: ["855"],
		capital: "Phnom Penh",
		altSpellings: ["KH", "Kingdom of Cambodia"],
		relevance: "0",
		region: "Asia",
		subregion: "South-Eastern Asia",
		translations: {
			de: "Kambodscha",
			es: "Camboya",
			fr: "Cambodge",
			ja: "ã‚«ãƒ³ãƒœã‚¸ã‚¢",
			it: "Cambogia",
		},
		population: 15405157,
		latlng: [13, 105],
		demonym: "Cambodian",
		area: 181035,
		gini: 37.9,
		timezones: ["UTC+07:00"],
		borders: ["LAO", "THA", "VNM"],
		nativeName: "KÃ¢mpÅ­chÃ©a",
		currencies: ["KHR"],
		languages: ["km"],
		numericCode: "116",
	},
	{
		name: "Cameroon",
		topLevelDomain: [".cm"],
		alpha2Code: "CM",
		alpha3Code: "CMR",
		callingCodes: ["237"],
		capital: "YaoundÃ©",
		altSpellings: ["CM", "Republic of Cameroon", "RÃ©publique du Cameroun"],
		relevance: "0",
		region: "Africa",
		subregion: "Middle Africa",
		translations: {
			de: "Kamerun",
			es: "CamerÃºn",
			fr: "Cameroun",
			ja: "ã‚«ãƒ¡ãƒ«ãƒ¼ãƒ³",
			it: "Camerun",
		},
		population: 23344000,
		latlng: [6, 12],
		demonym: "Cameroonian",
		area: 475442,
		gini: 38.9,
		timezones: ["UTC+01:00"],
		borders: ["CAF", "TCD", "COG", "GNQ", "GAB", "NGA"],
		nativeName: "Cameroon",
		currencies: ["XAF"],
		languages: ["en", "fr"],
		numericCode: "120",
	},
	{
		name: "Canada",
		topLevelDomain: [".ca"],
		alpha2Code: "CA",
		alpha3Code: "CAN",
		callingCodes: ["1"],
		capital: "Ottawa",
		altSpellings: ["CA"],
		relevance: "2",
		region: "Americas",
		subregion: "Northern America",
		translations: {
			de: "Kanada",
			es: "CanadÃ¡",
			fr: "Canada",
			ja: "ã‚«ãƒŠãƒ€",
			it: "Canada",
		},
		population: 35749600,
		latlng: [60, -95],
		demonym: "Canadian",
		area: 9984670,
		gini: 32.6,
		timezones: [
			"UTCâˆ’08:00",
			"UTCâˆ’07:00",
			"UTCâˆ’06:00",
			"UTCâˆ’05:00",
			"UTCâˆ’04:00",
			"UTCâˆ’03:30",
		],
		borders: ["USA"],
		nativeName: "Canada",
		currencies: ["CAD"],
		languages: ["en", "fr"],
		numericCode: "124",
	},
	{
		name: "Cape Verde",
		topLevelDomain: [".cv"],
		alpha2Code: "CV",
		alpha3Code: "CPV",
		callingCodes: ["238"],
		capital: "Praia",
		altSpellings: ["CV", "Republic of Cabo Verde", "RepÃºblica de Cabo Verde"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Kap Verde",
			es: "Cabo Verde",
			fr: "Cap Vert",
			ja: "ã‚«ãƒ¼ãƒœãƒ™ãƒ«ãƒ‡",
			it: "Capo Verde",
		},
		population: 524833,
		latlng: [16, -24],
		demonym: "Cape Verdian",
		area: 4033,
		gini: 50.5,
		timezones: ["UTCâˆ’01:00"],
		borders: [],
		nativeName: "Cabo Verde",
		currencies: ["CVE"],
		languages: ["pt"],
		numericCode: "132",
	},
	{
		name: "Cayman Islands",
		topLevelDomain: [".ky"],
		alpha2Code: "KY",
		alpha3Code: "CYM",
		callingCodes: ["1345"],
		capital: "George Town",
		altSpellings: ["KY"],
		relevance: "0.5",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Kaimaninseln",
			es: "Islas CaimÃ¡n",
			fr: "ÃŽles CaÃ¯mans",
			ja: "ã‚±ã‚¤ãƒžãƒ³è«¸å³¶",
			it: "Isole Cayman",
		},
		population: 58238,
		latlng: [19.5, -80.5],
		demonym: "Caymanian",
		area: 264,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Cayman Islands",
		currencies: ["KYD"],
		languages: ["en"],
		numericCode: "136",
	},
	{
		name: "Central African Republic",
		topLevelDomain: [".cf"],
		alpha2Code: "CF",
		alpha3Code: "CAF",
		callingCodes: ["236"],
		capital: "Bangui",
		altSpellings: ["CF", "Central African Republic", "RÃ©publique centrafricaine"],
		relevance: "0",
		region: "Africa",
		subregion: "Middle Africa",
		translations: {
			de: "Zentralafrikanische Republik",
			es: "RepÃºblica Centroafricana",
			fr: "RÃ©publique centrafricaine",
			ja: "ä¸­å¤®ã‚¢ãƒ•ãƒªã‚«å…±å’Œå›½",
			it: "Repubblica Centrafricana",
		},
		population: 4900000,
		latlng: [7, 21],
		demonym: "Central African",
		area: 622984,
		gini: 56.3,
		timezones: ["UTC+01:00"],
		borders: ["CMR", "TCD", "COD", "COG", "SSD", "SDN"],
		nativeName: "KÃ¶dÃ¶rÃ¶sÃªse tÃ® BÃªafrÃ®ka",
		currencies: ["XAF"],
		languages: ["fr", "sg"],
		numericCode: "140",
	},
	{
		name: "Chad",
		topLevelDomain: [".td"],
		alpha2Code: "TD",
		alpha3Code: "TCD",
		callingCodes: ["235"],
		capital: "N'Djamena",
		altSpellings: ["TD", "Tchad", "Republic of Chad", "RÃ©publique du Tchad"],
		relevance: "0",
		region: "Africa",
		subregion: "Middle Africa",
		translations: {
			de: "Tschad",
			es: "Chad",
			fr: "Tchad",
			ja: "ãƒ�ãƒ£ãƒ‰",
			it: "Ciad",
		},
		population: 14037000,
		latlng: [15, 19],
		demonym: "Chadian",
		area: 1284000,
		gini: 39.8,
		timezones: ["UTC+01:00"],
		borders: ["CMR", "CAF", "LBY", "NER", "NGA", "SSD"],
		nativeName: "Tchad",
		currencies: ["XAF"],
		languages: ["fr", "ar"],
		numericCode: "148",
	},
	{
		name: "Chile",
		topLevelDomain: [".cl"],
		alpha2Code: "CL",
		alpha3Code: "CHL",
		callingCodes: ["56"],
		capital: "Santiago",
		altSpellings: ["CL", "Republic of Chile", "RepÃºblica de Chile"],
		relevance: "0",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "Chile",
			es: "Chile",
			fr: "Chili",
			ja: "ãƒ�ãƒª",
			it: "Cile",
		},
		population: 18006407,
		latlng: [-30, -71],
		demonym: "Chilean",
		area: 756102,
		gini: 52.1,
		timezones: ["UTCâˆ’06:00", "UTCâˆ’04:00"],
		borders: ["ARG", "BOL", "PER"],
		nativeName: "Chile",
		currencies: ["CLF", "CLP"],
		languages: ["es"],
		numericCode: "152",
	},
	{
		name: "China",
		topLevelDomain: [".cn"],
		alpha2Code: "CN",
		alpha3Code: "CHN",
		callingCodes: ["86"],
		capital: "Beijing",
		altSpellings: [
			"CN",
			"ZhÅ�ngguÃ³",
			"Zhongguo",
			"Zhonghua",
			"People's Republic of China",
			"ä¸­å�Žäººæ°‘å…±å’Œå›½",
			"ZhÅ�nghuÃ¡ RÃ©nmÃ­n GÃ²nghÃ©guÃ³",
		],
		relevance: "0",
		region: "Asia",
		subregion: "Eastern Asia",
		translations: {
			de: "China",
			es: "China",
			fr: "Chine",
			ja: "ä¸­å›½",
			it: "Cina",
		},
		population: 1371590000,
		latlng: [35, 105],
		demonym: "Chinese",
		area: 9640011,
		gini: 47,
		timezones: ["UTC+08:00"],
		borders: [
			"AFG",
			"BTN",
			"MMR",
			"HKG",
			"IND",
			"KAZ",
			"PRK",
			"KGZ",
			"LAO",
			"MAC",
			"MNG",
			"PAK",
			"RUS",
			"TJK",
			"VNM",
		],
		nativeName: "ä¸­å›½",
		currencies: ["CNY"],
		languages: ["zh"],
		numericCode: "156",
	},
	{
		name: "Christmas Island",
		topLevelDomain: [".cx"],
		alpha2Code: "CX",
		alpha3Code: "CXR",
		callingCodes: ["61"],
		capital: "Flying Fish Cove",
		altSpellings: ["CX", "Territory of Christmas Island"],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Australia and New Zealand",
		translations: {
			de: "Weihnachtsinsel",
			es: "Isla de Navidad",
			fr: "ÃŽle Christmas",
			ja: "ã‚¯ãƒªã‚¹ãƒžã‚¹å³¶",
			it: "Isola di Natale",
		},
		population: 2072,
		latlng: [-10.5, 105.66666666],
		demonym: "Christmas Island",
		area: 135,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Christmas Island",
		currencies: ["AUD"],
		languages: ["en"],
		numericCode: "162",
	},
	{
		name: "Cocos (Keeling) Islands",
		topLevelDomain: [".cc"],
		alpha2Code: "CC",
		alpha3Code: "CCK",
		callingCodes: ["61"],
		capital: "West Island",
		altSpellings: ["CC", "Territory of the Cocos (Keeling) Islands", "Keeling Islands"],
		relevance: "0",
		region: "Oceania",
		subregion: "Australia and New Zealand",
		translations: {
			de: "Kokosinseln",
			es: "Islas Cocos o Islas Keeling",
			fr: "ÃŽles Cocos",
			ja: "ã‚³ã‚³ã‚¹ï¼ˆã‚­ãƒ¼ãƒªãƒ³ã‚°ï¼‰è«¸å³¶",
			it: "Isole Cocos e Keeling",
		},
		population: 550,
		latlng: [-12.5, 96.83333333],
		demonym: "Cocos Islander",
		area: 14,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Cocos (Keeling) Islands",
		currencies: ["AUD"],
		languages: ["en"],
		numericCode: "166",
	},
	{
		name: "Colombia",
		topLevelDomain: [".co"],
		alpha2Code: "CO",
		alpha3Code: "COL",
		callingCodes: ["57"],
		capital: "BogotÃ¡",
		altSpellings: ["CO", "Republic of Colombia", "RepÃºblica de Colombia"],
		relevance: "0",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "Kolumbien",
			es: "Colombia",
			fr: "Colombie",
			ja: "ã‚³ãƒ­ãƒ³ãƒ“ã‚¢",
			it: "Colombia",
		},
		population: 48266600,
		latlng: [4, -72],
		demonym: "Colombian",
		area: 1141748,
		gini: 55.9,
		timezones: ["UTCâˆ’05:00"],
		borders: ["BRA", "ECU", "PAN", "PER", "VEN"],
		nativeName: "Colombia",
		currencies: ["COP"],
		languages: ["es"],
		numericCode: "170",
	},
	{
		name: "Comoros",
		topLevelDomain: [".km"],
		alpha2Code: "KM",
		alpha3Code: "COM",
		callingCodes: ["269"],
		capital: "Moroni",
		altSpellings: [
			"KM",
			"Union of the Comoros",
			"Union des Comores",
			"Udzima wa Komori",
			"al-Ittiá¸¥Ä�d al-QumurÄ«",
		],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Union der Komoren",
			es: "Comoras",
			fr: "Comores",
			ja: "ã‚³ãƒ¢ãƒ­",
			it: "Comore",
		},
		population: 784745,
		latlng: [-12.16666666, 44.25],
		demonym: "Comoran",
		area: 1862,
		gini: 64.3,
		timezones: ["UTC+03:00"],
		borders: [],
		nativeName: "Komori",
		currencies: ["KMF"],
		languages: ["ar", "fr"],
		numericCode: "174",
	},
	{
		name: "Republic of the Congo",
		topLevelDomain: [".cg"],
		alpha2Code: "CG",
		alpha3Code: "COG",
		callingCodes: ["242"],
		capital: "Brazzaville",
		altSpellings: ["CG", "Congo-Brazzaville"],
		relevance: "0",
		region: "Africa",
		subregion: "Middle Africa",
		translations: {
			de: "Kongo",
			es: "Congo",
			fr: "Congo",
			ja: "ã‚³ãƒ³ã‚´å…±å’Œå›½",
			it: "Congo",
		},
		population: 4620000,
		latlng: [-1, 15],
		demonym: "Congolese",
		area: 342000,
		gini: 47.3,
		timezones: ["UTC+01:00"],
		borders: ["AGO", "CMR", "CAF", "COD", "GAB"],
		nativeName: "RÃ©publique du Congo",
		currencies: ["XAF"],
		languages: ["fr", "ln"],
		numericCode: "178",
	},
	{
		name: "Democratic Republic of the Congo",
		topLevelDomain: [".cd"],
		alpha2Code: "CD",
		alpha3Code: "COD",
		callingCodes: ["243"],
		capital: "Kinshasa",
		altSpellings: ["CD", "DR Congo", "Congo-Kinshasa", "DRC"],
		relevance: "0",
		region: "Africa",
		subregion: "Middle Africa",
		translations: {
			de: "Kongo (Dem. Rep.)",
			es: "Congo (Rep. Dem.)",
			fr: "Congo (RÃ©p. dÃ©m.)",
			ja: "ã‚³ãƒ³ã‚´æ°‘ä¸»å…±å’Œå›½",
			it: "Congo (Rep. Dem.)",
		},
		population: 77267000,
		latlng: [0, 25],
		demonym: "Congolese",
		area: 2344858,
		gini: null,
		timezones: ["UTC+01:00", "UTC+02:00"],
		borders: ["AGO", "BDI", "CAF", "COG", "RWA", "SSD", "TZA", "UGA", "ZMB"],
		nativeName: "RÃ©publique dÃ©mocratique du Congo",
		currencies: ["CDF"],
		languages: ["fr", "ln", "kg", "sw", "lu"],
		numericCode: "180",
	},
	{
		name: "Cook Islands",
		topLevelDomain: [".ck"],
		alpha2Code: "CK",
		alpha3Code: "COK",
		callingCodes: ["682"],
		capital: "Avarua",
		altSpellings: ["CK", "KÅ«ki 'Ä€irani"],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Polynesia",
		translations: {
			de: "Cookinseln",
			es: "Islas Cook",
			fr: "ÃŽles Cook",
			ja: "ã‚¯ãƒƒã‚¯è«¸å³¶",
			it: "Isole Cook",
		},
		population: 14974,
		latlng: [-21.23333333, -159.76666666],
		demonym: "Cook Islander",
		area: 236,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Cook Islands",
		currencies: ["NZD"],
		languages: ["en"],
		numericCode: "184",
	},
	{
		name: "Costa Rica",
		topLevelDomain: [".cr"],
		alpha2Code: "CR",
		alpha3Code: "CRI",
		callingCodes: ["506"],
		capital: "San JosÃ©",
		altSpellings: ["CR", "Republic of Costa Rica", "RepÃºblica de Costa Rica"],
		relevance: "0",
		region: "Americas",
		subregion: "Central America",
		translations: {
			de: "Costa Rica",
			es: "Costa Rica",
			fr: "Costa Rica",
			ja: "ã‚³ã‚¹ã‚¿ãƒªã‚«",
			it: "Costa Rica",
		},
		population: 4773130,
		latlng: [10, -84],
		demonym: "Costa Rican",
		area: 51100,
		gini: 50.7,
		timezones: ["UTCâˆ’06:00"],
		borders: ["NIC", "PAN"],
		nativeName: "Costa Rica",
		currencies: ["CRC"],
		languages: ["es"],
		numericCode: "188",
	},
	{
		name: "Croatia",
		topLevelDomain: [".hr"],
		alpha2Code: "HR",
		alpha3Code: "HRV",
		callingCodes: ["385"],
		capital: "Zagreb",
		altSpellings: ["HR", "Hrvatska", "Republic of Croatia", "Republika Hrvatska"],
		relevance: "0",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Kroatien",
			es: "Croacia",
			fr: "Croatie",
			ja: "ã‚¯ãƒ­ã‚¢ãƒ�ã‚¢",
			it: "Croazia",
		},
		population: 4246800,
		latlng: [45.16666666, 15.5],
		demonym: "Croatian",
		area: 56594,
		gini: 33.7,
		timezones: ["UTC+01:00"],
		borders: ["BIH", "HUN", "MNE", "SRB", "SVN"],
		nativeName: "Hrvatska",
		currencies: ["HRK"],
		languages: ["hr"],
		numericCode: "191",
	},
	{
		name: "Cuba",
		topLevelDomain: [".cu"],
		alpha2Code: "CU",
		alpha3Code: "CUB",
		callingCodes: ["53"],
		capital: "Havana",
		altSpellings: ["CU", "Republic of Cuba", "RepÃºblica de Cuba"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Kuba",
			es: "Cuba",
			fr: "Cuba",
			ja: "ã‚­ãƒ¥ãƒ¼ãƒ�",
			it: "Cuba",
		},
		population: 11238317,
		latlng: [21.5, -80],
		demonym: "Cuban",
		area: 109884,
		gini: null,
		timezones: ["UTCâˆ’05:00"],
		borders: [],
		nativeName: "Cuba",
		currencies: ["CUC", "CUP"],
		languages: ["es"],
		numericCode: "192",
	},
	{
		name: "CuraÃ§ao",
		topLevelDomain: [".cw"],
		alpha2Code: "CW",
		alpha3Code: "CUW",
		callingCodes: ["5999"],
		capital: "Willemstad",
		altSpellings: [
			"CW",
			"Curacao",
			"KÃ²rsou",
			"Country of CuraÃ§ao",
			"Land CuraÃ§ao",
			"Pais KÃ²rsou",
		],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: null,
			es: null,
			fr: null,
			ja: null,
			it: null,
		},
		population: 154843,
		latlng: [12.116667, -68.933333],
		demonym: "Dutch",
		area: 444,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "CuraÃ§ao",
		currencies: ["ANG"],
		languages: ["nl", "pa", "en"],
		numericCode: "531",
	},
	{
		name: "Cyprus",
		topLevelDomain: [".cy"],
		alpha2Code: "CY",
		alpha3Code: "CYP",
		callingCodes: ["357"],
		capital: "Nicosia",
		altSpellings: [
			"CY",
			"KÃ½pros",
			"KÄ±brÄ±s",
			"Republic of Cyprus",
			"ÎšÏ…Ï€Ï�Î¹Î±ÎºÎ® Î”Î·Î¼Î¿ÎºÏ�Î±Ï„Î¯Î±",
			"KÄ±brÄ±s Cumhuriyeti",
		],
		relevance: "0",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Zypern",
			es: "Chipre",
			fr: "Chypre",
			ja: "ã‚­ãƒ—ãƒ­ã‚¹",
			it: "Cipro",
		},
		population: 858000,
		latlng: [35, 33],
		demonym: "Cypriot",
		area: 9251,
		gini: null,
		timezones: ["UTC+02:00"],
		borders: ["GBR"],
		nativeName: "ÎšÏ�Ï€Ï�Î¿Ï‚",
		currencies: ["EUR"],
		languages: ["el", "tr", "hy"],
		numericCode: "196",
	},
	{
		name: "Czech Republic",
		topLevelDomain: [".cz"],
		alpha2Code: "CZ",
		alpha3Code: "CZE",
		callingCodes: ["420"],
		capital: "Prague",
		altSpellings: ["CZ", "ÄŒeskÃ¡ republika", "ÄŒesko"],
		relevance: "0",
		region: "Europe",
		subregion: "Eastern Europe",
		translations: {
			de: "Tschechische Republik",
			es: "RepÃºblica Checa",
			fr: "RÃ©publique tchÃ¨que",
			ja: "ãƒ�ã‚§ã‚³",
			it: "Repubblica Ceca",
		},
		population: 10537818,
		latlng: [49.75, 15.5],
		demonym: "Czech",
		area: 78865,
		gini: 26,
		timezones: ["UTC+01:00"],
		borders: ["AUT", "DEU", "POL", "SVK"],
		nativeName: "ÄŒeskÃ¡ republika",
		currencies: ["CZK"],
		languages: ["cs", "sk"],
		numericCode: "203",
	},
	{
		name: "Denmark",
		topLevelDomain: [".dk"],
		alpha2Code: "DK",
		alpha3Code: "DNK",
		callingCodes: ["45"],
		capital: "Copenhagen",
		altSpellings: ["DK", "Danmark", "Kingdom of Denmark", "Kongeriget Danmark"],
		relevance: "1.5",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "DÃ¤nemark",
			es: "Dinamarca",
			fr: "Danemark",
			ja: "ãƒ‡ãƒ³ãƒžãƒ¼ã‚¯",
			it: "Danimarca",
		},
		population: 5678348,
		latlng: [56, 10],
		demonym: "Danish",
		area: 43094,
		gini: 24,
		timezones: ["UTCâˆ’04:00", "UTCâˆ’03:00", "UTCâˆ’01:00", "UTC", "UTC+01:00"],
		borders: ["DEU"],
		nativeName: "Danmark",
		currencies: ["DKK"],
		languages: ["da"],
		numericCode: "208",
	},
	{
		name: "Djibouti",
		topLevelDomain: [".dj"],
		alpha2Code: "DJ",
		alpha3Code: "DJI",
		callingCodes: ["253"],
		capital: "Djibouti",
		altSpellings: [
			"DJ",
			"Jabuuti",
			"Gabuuti",
			"Republic of Djibouti",
			"RÃ©publique de Djibouti",
			"Gabuutih Ummuuno",
			"Jamhuuriyadda Jabuuti",
		],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Dschibuti",
			es: "Yibuti",
			fr: "Djibouti",
			ja: "ã‚¸ãƒ–ãƒ�",
			it: "Gibuti",
		},
		population: 888000,
		latlng: [11.5, 43],
		demonym: "Djibouti",
		area: 23200,
		gini: 40,
		timezones: ["UTC+03:00"],
		borders: ["ERI", "ETH", "SOM"],
		nativeName: "Djibouti",
		currencies: ["DJF"],
		languages: ["fr", "ar"],
		numericCode: "262",
	},
	{
		name: "Dominica",
		topLevelDomain: [".dm"],
		alpha2Code: "DM",
		alpha3Code: "DMA",
		callingCodes: ["1767"],
		capital: "Roseau",
		altSpellings: ["DM", "Dominique", "Waiâ€˜tu kubuli", "Commonwealth of Dominica"],
		relevance: "0.5",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Dominica",
			es: "Dominica",
			fr: "Dominique",
			ja: "ãƒ‰ãƒŸãƒ‹ã‚«å›½",
			it: "Dominica",
		},
		population: 71293,
		latlng: [15.41666666, -61.33333333],
		demonym: "Dominican",
		area: 751,
		gini: null,
		timezones: ["UTCâˆ’04:00"],
		borders: [],
		nativeName: "Dominica",
		currencies: ["XCD"],
		languages: ["en"],
		numericCode: "212",
	},
	{
		name: "Dominican Republic",
		topLevelDomain: [".do"],
		alpha2Code: "DO",
		alpha3Code: "DOM",
		callingCodes: ["1809", "1829", "1849"],
		capital: "Santo Domingo",
		altSpellings: ["DO"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Dominikanische Republik",
			es: "RepÃºblica Dominicana",
			fr: "RÃ©publique dominicaine",
			ja: "ãƒ‰ãƒŸãƒ‹ã‚«å…±å’Œå›½",
			it: "Repubblica Dominicana",
		},
		population: 10528000,
		latlng: [19, -70.66666666],
		demonym: "Dominican",
		area: 48671,
		gini: 47.2,
		timezones: ["UTCâˆ’04:00"],
		borders: ["HTI"],
		nativeName: "RepÃºblica Dominicana",
		currencies: ["DOP"],
		languages: ["es"],
		numericCode: "214",
	},
	{
		name: "Ecuador",
		topLevelDomain: [".ec"],
		alpha2Code: "EC",
		alpha3Code: "ECU",
		callingCodes: ["593"],
		capital: "Quito",
		altSpellings: ["EC", "Republic of Ecuador", "RepÃºblica del Ecuador"],
		relevance: "0",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "Ecuador",
			es: "Ecuador",
			fr: "Ã‰quateur",
			ja: "ã‚¨ã‚¯ã‚¢ãƒ‰ãƒ«",
			it: "Ecuador",
		},
		population: 16027500,
		latlng: [-2, -77.5],
		demonym: "Ecuadorean",
		area: 276841,
		gini: 49.3,
		timezones: ["UTCâˆ’06:00", "UTCâˆ’05:00"],
		borders: ["COL", "PER"],
		nativeName: "Ecuador",
		currencies: ["USD"],
		languages: ["es"],
		numericCode: "218",
	},
	{
		name: "Egypt",
		topLevelDomain: [".eg"],
		alpha2Code: "EG",
		alpha3Code: "EGY",
		callingCodes: ["20"],
		capital: "Cairo",
		altSpellings: ["EG", "Arab Republic of Egypt"],
		relevance: "1.5",
		region: "Africa",
		subregion: "Northern Africa",
		translations: {
			de: "Ã„gypten",
			es: "Egipto",
			fr: "Ã‰gypte",
			ja: "ã‚¨ã‚¸ãƒ—ãƒˆ",
			it: "Egitto",
		},
		population: 89335600,
		latlng: [27, 30],
		demonym: "Egyptian",
		area: 1002450,
		gini: 30.8,
		timezones: ["UTC+02:00"],
		borders: ["ISR", "LBY", "SDN"],
		nativeName: "Ù…ØµØ±â€Ž",
		currencies: ["EGP"],
		languages: ["ar"],
		numericCode: "818",
	},
	{
		name: "El Salvador",
		topLevelDomain: [".sv"],
		alpha2Code: "SV",
		alpha3Code: "SLV",
		callingCodes: ["503"],
		capital: "San Salvador",
		altSpellings: ["SV", "Republic of El Salvador", "RepÃºblica de El Salvador"],
		relevance: "0",
		region: "Americas",
		subregion: "Central America",
		translations: {
			de: "El Salvador",
			es: "El Salvador",
			fr: "Salvador",
			ja: "ã‚¨ãƒ«ã‚µãƒ«ãƒ�ãƒ‰ãƒ«",
			it: "El Salvador",
		},
		population: 6401240,
		latlng: [13.83333333, -88.91666666],
		demonym: "Salvadoran",
		area: 21041,
		gini: 48.3,
		timezones: ["UTCâˆ’06:00"],
		borders: ["GTM", "HND"],
		nativeName: "El Salvador",
		currencies: ["SVC", "USD"],
		languages: ["es"],
		numericCode: "222",
	},
	{
		name: "Equatorial Guinea",
		topLevelDomain: [".gq"],
		alpha2Code: "GQ",
		alpha3Code: "GNQ",
		callingCodes: ["240"],
		capital: "Malabo",
		altSpellings: [
			"GQ",
			"Republic of Equatorial Guinea",
			"RepÃºblica de Guinea Ecuatorial",
			"RÃ©publique de GuinÃ©e Ã©quatoriale",
			"RepÃºblica da GuinÃ© Equatorial",
		],
		relevance: "0",
		region: "Africa",
		subregion: "Middle Africa",
		translations: {
			de: "Ã„quatorial-Guinea",
			es: "Guinea Ecuatorial",
			fr: "GuinÃ©e-Ã‰quatoriale",
			ja: "èµ¤é�“ã‚®ãƒ‹ã‚¢",
			it: "Guinea Equatoriale",
		},
		population: 1430000,
		latlng: [2, 10],
		demonym: "Equatorial Guinean",
		area: 28051,
		gini: null,
		timezones: ["UTC+01:00"],
		borders: ["CMR", "GAB"],
		nativeName: "Guinea Ecuatorial",
		currencies: ["XAF"],
		languages: ["es", "fr"],
		numericCode: "226",
	},
	{
		name: "Eritrea",
		topLevelDomain: [".er"],
		alpha2Code: "ER",
		alpha3Code: "ERI",
		callingCodes: ["291"],
		capital: "Asmara",
		altSpellings: [
			"ER",
			"State of Eritrea",
			"áˆƒáŒˆáˆ¨ áŠ¤áˆ­á‰µáˆ«",
			"Dawlat IritriyÃ¡",
			"Ê¾ErtrÄ�",
			"IritriyÄ�",
			"",
		],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Eritrea",
			es: "Eritrea",
			fr: "Ã‰rythrÃ©e",
			ja: "ã‚¨ãƒªãƒˆãƒªã‚¢",
			it: "Eritrea",
		},
		population: 5228000,
		latlng: [15, 39],
		demonym: "Eritrean",
		area: 117600,
		gini: null,
		timezones: ["UTC+03:00"],
		borders: ["DJI", "ETH", "SDN"],
		nativeName: "áŠ¤áˆ­á‰µáˆ«",
		currencies: ["ERN"],
		languages: ["ti", "ar", "en"],
		numericCode: "232",
	},
	{
		name: "Estonia",
		topLevelDomain: [".ee"],
		alpha2Code: "EE",
		alpha3Code: "EST",
		callingCodes: ["372"],
		capital: "Tallinn",
		altSpellings: ["EE", "Eesti", "Republic of Estonia", "Eesti Vabariik"],
		relevance: "0",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Estland",
			es: "Estonia",
			fr: "Estonie",
			ja: "ã‚¨ã‚¹ãƒˆãƒ‹ã‚¢",
			it: "Estonia",
		},
		population: 1313271,
		latlng: [59, 26],
		demonym: "Estonian",
		area: 45227,
		gini: 36,
		timezones: ["UTC+02:00"],
		borders: ["LVA", "RUS"],
		nativeName: "Eesti",
		currencies: ["EUR"],
		languages: ["et"],
		numericCode: "233",
	},
	{
		name: "Ethiopia",
		topLevelDomain: [".et"],
		alpha2Code: "ET",
		alpha3Code: "ETH",
		callingCodes: ["251"],
		capital: "Addis Ababa",
		altSpellings: [
			"ET",
			"Ê¾ÄªtyÅ�á¹—á¹—yÄ�",
			"Federal Democratic Republic of Ethiopia",
			"á‹¨áŠ¢á‰µá‹®áŒµá‹« á�Œá‹´áˆ«áˆ‹á‹Š á‹²áˆžáŠ­áˆ«áˆ²á‹«á‹Š áˆªá��á‰¥áˆŠáŠ­",
		],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Ã„thiopien",
			es: "EtiopÃ­a",
			fr: "Ã‰thiopie",
			ja: "ã‚¨ãƒ�ã‚ªãƒ”ã‚¢",
			it: "Etiopia",
		},
		population: 90076012,
		latlng: [8, 38],
		demonym: "Ethiopian",
		area: 1104300,
		gini: 29.8,
		timezones: ["UTC+03:00"],
		borders: ["DJI", "ERI", "KEN", "SOM", "SSD", "SDN"],
		nativeName: "áŠ¢á‰µá‹®áŒµá‹«",
		currencies: ["ETB"],
		languages: ["am"],
		numericCode: "231",
	},
	{
		name: "Falkland Islands",
		topLevelDomain: [".fk"],
		alpha2Code: "FK",
		alpha3Code: "FLK",
		callingCodes: ["500"],
		capital: "Stanley",
		altSpellings: ["FK", "Islas Malvinas"],
		relevance: "0.5",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "Falklandinseln",
			es: "Islas Malvinas",
			fr: "ÃŽles Malouines",
			ja: "ãƒ•ã‚©ãƒ¼ã‚¯ãƒ©ãƒ³ãƒ‰ï¼ˆãƒžãƒ«ãƒ“ãƒŠã‚¹ï¼‰è«¸å³¶",
			it: "Isole Falkland o Isole Malvine",
		},
		population: 3000,
		latlng: [-51.75, -59],
		demonym: "Falkland Islander",
		area: 12173,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Falkland Islands",
		currencies: ["FKP"],
		languages: ["en"],
		numericCode: "238",
	},
	{
		name: "Faroe Islands",
		topLevelDomain: [".fo"],
		alpha2Code: "FO",
		alpha3Code: "FRO",
		callingCodes: ["298"],
		capital: "TÃ³rshavn",
		altSpellings: ["FO", "FÃ¸royar", "FÃ¦rÃ¸erne"],
		relevance: "0.5",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "FÃ¤rÃ¶er-Inseln",
			es: "Islas Faroe",
			fr: "ÃŽles FÃ©roÃ©",
			ja: "ãƒ•ã‚§ãƒ­ãƒ¼è«¸å³¶",
			it: "Isole Far Oer",
		},
		population: 48846,
		latlng: [62, -7],
		demonym: "Faroese",
		area: 1393,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "FÃ¸royar",
		currencies: ["DKK"],
		languages: ["fo"],
		numericCode: "234",
	},
	{
		name: "Fiji",
		topLevelDomain: [".fj"],
		alpha2Code: "FJ",
		alpha3Code: "FJI",
		callingCodes: ["679"],
		capital: "Suva",
		altSpellings: ["FJ", "Viti", "Republic of Fiji", "Matanitu ko Viti", "FijÄ« Gaá¹‡arÄ�jya"],
		relevance: "0",
		region: "Oceania",
		subregion: "Melanesia",
		translations: {
			de: "Fidschi",
			es: "Fiyi",
			fr: "Fidji",
			ja: "ãƒ•ã‚£ã‚¸ãƒ¼",
			it: "Figi",
		},
		population: 859178,
		latlng: [-18, 175],
		demonym: "Fijian",
		area: 18272,
		gini: 42.8,
		timezones: ["UTC+12:00"],
		borders: [],
		nativeName: "Fiji",
		currencies: ["FJD"],
		languages: ["en", "fj", "hi", "ur"],
		numericCode: "242",
	},
	{
		name: "Finland",
		topLevelDomain: [".fi"],
		alpha2Code: "FI",
		alpha3Code: "FIN",
		callingCodes: ["358"],
		capital: "Helsinki",
		altSpellings: [
			"FI",
			"Suomi",
			"Republic of Finland",
			"Suomen tasavalta",
			"Republiken Finland",
		],
		relevance: "0.5",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Finnland",
			es: "Finlandia",
			fr: "Finlande",
			ja: "ãƒ•ã‚£ãƒ³ãƒ©ãƒ³ãƒ‰",
			it: "Finlandia",
		},
		population: 5485215,
		latlng: [64, 26],
		demonym: "Finnish",
		area: 338424,
		gini: 26.9,
		timezones: ["UTC+02:00"],
		borders: ["NOR", "SWE", "RUS"],
		nativeName: "Suomi",
		currencies: ["EUR"],
		languages: ["fi", "sv"],
		numericCode: "246",
	},
	{
		name: "France",
		topLevelDomain: [".fr"],
		alpha2Code: "FR",
		alpha3Code: "FRA",
		callingCodes: ["33"],
		capital: "Paris",
		altSpellings: ["FR", "French Republic", "RÃ©publique franÃ§aise"],
		relevance: "2.5",
		region: "Europe",
		subregion: "Western Europe",
		translations: {
			de: "Frankreich",
			es: "Francia",
			fr: "France",
			ja: "ãƒ•ãƒ©ãƒ³ã‚¹",
			it: "Francia",
		},
		population: 66186000,
		latlng: [46, 2],
		demonym: "French",
		area: 640679,
		gini: 32.7,
		timezones: [
			"UTCâˆ’10:00",
			"UTCâˆ’09:30",
			"UTCâˆ’09:00",
			"UTCâˆ’08:00",
			"UTCâˆ’04:00",
			"UTCâˆ’03:00",
			"UTC+01:00",
			"UTC+03:00",
			"UTC+04:00",
			"UTC+05:00",
			"UTC+11:00",
			"UTC+12:00",
		],
		borders: ["AND", "BEL", "DEU", "ITA", "LUX", "MCO", "ESP", "CHE"],
		nativeName: "France",
		currencies: ["EUR"],
		languages: ["fr"],
		numericCode: "250",
	},
	{
		name: "French Guiana",
		topLevelDomain: [".gf"],
		alpha2Code: "GF",
		alpha3Code: "GUF",
		callingCodes: ["594"],
		capital: "Cayenne",
		altSpellings: ["GF", "Guiana", "Guyane"],
		relevance: "0",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "FranzÃ¶sisch Guyana",
			es: "Guayana Francesa",
			fr: "Guayane",
			ja: "ãƒ•ãƒ©ãƒ³ã‚¹é ˜ã‚®ã‚¢ãƒŠ",
			it: "Guyana francese",
		},
		population: 239648,
		latlng: [4, -53],
		demonym: "",
		area: null,
		gini: null,
		timezones: null,
		borders: ["BRA", "SUR"],
		nativeName: "Guyane franÃ§aise",
		currencies: ["EUR"],
		languages: ["fr"],
		numericCode: "254",
	},
	{
		name: "French Polynesia",
		topLevelDomain: [".pf"],
		alpha2Code: "PF",
		alpha3Code: "PYF",
		callingCodes: ["689"],
		capital: "PapeetÄ“",
		altSpellings: ["PF", "PolynÃ©sie franÃ§aise", "French Polynesia", "PÅ�rÄ«netia FarÄ�ni"],
		relevance: "0",
		region: "Oceania",
		subregion: "Polynesia",
		translations: {
			de: "FranzÃ¶sisch-Polynesien",
			es: "Polinesia Francesa",
			fr: "PolynÃ©sie franÃ§aise",
			ja: "ãƒ•ãƒ©ãƒ³ã‚¹é ˜ãƒ�ãƒªãƒ�ã‚·ã‚¢",
			it: "Polinesia Francese",
		},
		population: 268270,
		latlng: [-15, -140],
		demonym: "French Polynesian",
		area: 4167,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "PolynÃ©sie franÃ§aise",
		currencies: ["XPF"],
		languages: ["fr"],
		numericCode: "258",
	},
	{
		name: "French Southern and Antarctic Lands",
		topLevelDomain: [".tf"],
		alpha2Code: "TF",
		alpha3Code: "ATF",
		callingCodes: [""],
		capital: "Port-aux-FranÃ§ais",
		altSpellings: ["TF"],
		relevance: "0",
		region: "",
		subregion: "",
		translations: {
			de: "FranzÃ¶sische SÃ¼d- und Antarktisgebiete",
			es: "Tierras Australes y AntÃ¡rticas Francesas",
			fr: "Terres australes et antarctiques franÃ§aises",
			ja: "ãƒ•ãƒ©ãƒ³ã‚¹é ˜å�—æ–¹ãƒ»å�—æ¥µåœ°åŸŸ",
			it: "Territori Francesi del Sud",
		},
		population: 140,
		latlng: [-49.25, 69.167],
		demonym: "French",
		area: 7747,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Territoire des Terres australes et antarctiques franÃ§aises",
		currencies: ["EUR"],
		languages: ["fr"],
		numericCode: "260",
	},
	{
		name: "Gabon",
		topLevelDomain: [".ga"],
		alpha2Code: "GA",
		alpha3Code: "GAB",
		callingCodes: ["241"],
		capital: "Libreville",
		altSpellings: ["GA", "Gabonese Republic", "RÃ©publique Gabonaise"],
		relevance: "0",
		region: "Africa",
		subregion: "Middle Africa",
		translations: {
			de: "Gabun",
			es: "GabÃ³n",
			fr: "Gabon",
			ja: "ã‚¬ãƒœãƒ³",
			it: "Gabon",
		},
		population: 1725000,
		latlng: [-1, 11.75],
		demonym: "Gabonese",
		area: 267668,
		gini: 41.5,
		timezones: ["UTC+01:00"],
		borders: ["CMR", "COG", "GNQ"],
		nativeName: "Gabon",
		currencies: ["XAF"],
		languages: ["fr"],
		numericCode: "266",
	},
	{
		name: "The Gambia",
		topLevelDomain: [".gm"],
		alpha2Code: "GM",
		alpha3Code: "GMB",
		callingCodes: ["220"],
		capital: "Banjul",
		altSpellings: ["GM", "Republic of the Gambia"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Gambia",
			es: "Gambia",
			fr: "Gambie",
			ja: "ã‚¬ãƒ³ãƒ“ã‚¢",
			it: "Gambia",
		},
		population: 1882450,
		latlng: [13.46666666, -16.56666666],
		demonym: "Gambian",
		area: 11295,
		gini: null,
		timezones: null,
		borders: ["SEN"],
		nativeName: "Gambia",
		currencies: ["GMD"],
		languages: ["en"],
		numericCode: "270",
	},
	{
		name: "Georgia",
		topLevelDomain: [".ge"],
		alpha2Code: "GE",
		alpha3Code: "GEO",
		callingCodes: ["995"],
		capital: "Tbilisi",
		altSpellings: ["GE", "Sakartvelo"],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Georgien",
			es: "Georgia",
			fr: "GÃ©orgie",
			ja: "ã‚°ãƒ«ã‚¸ã‚¢",
			it: "Georgia",
		},
		population: 3729500,
		latlng: [42, 43.5],
		demonym: "Georgian",
		area: 69700,
		gini: 41.3,
		timezones: null,
		borders: ["ARM", "AZE", "RUS", "TUR"],
		nativeName: "áƒ¡áƒ�áƒ¥áƒ�áƒ áƒ—áƒ•áƒ”áƒšáƒ�",
		currencies: ["GEL"],
		languages: ["ka"],
		numericCode: "268",
	},
	{
		name: "Germany",
		topLevelDomain: [".de"],
		alpha2Code: "DE",
		alpha3Code: "DEU",
		callingCodes: ["49"],
		capital: "Berlin",
		altSpellings: ["DE", "Federal Republic of Germany", "Bundesrepublik Deutschland"],
		relevance: "3",
		region: "Europe",
		subregion: "Western Europe",
		translations: {
			de: "Deutschland",
			es: "Alemania",
			fr: "Allemagne",
			ja: "ãƒ‰ã‚¤ãƒ„",
			it: "Germania",
		},
		population: 81083600,
		latlng: [51, 9],
		demonym: "German",
		area: 357114,
		gini: 28.3,
		timezones: ["UTC+01:00"],
		borders: ["AUT", "BEL", "CZE", "DNK", "FRA", "LUX", "NLD", "POL", "CHE"],
		nativeName: "Deutschland",
		currencies: ["EUR"],
		languages: ["de"],
		numericCode: "276",
	},
	{
		name: "Ghana",
		topLevelDomain: [".gh"],
		alpha2Code: "GH",
		alpha3Code: "GHA",
		callingCodes: ["233"],
		capital: "Accra",
		altSpellings: ["GH"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Ghana",
			es: "Ghana",
			fr: "Ghana",
			ja: "ã‚¬ãƒ¼ãƒŠ",
			it: "Ghana",
		},
		population: 27043093,
		latlng: [8, -2],
		demonym: "Ghanaian",
		area: 238533,
		gini: 42.8,
		timezones: ["UTC"],
		borders: ["BFA", "CIV", "TGO"],
		nativeName: "Ghana",
		currencies: ["GHS"],
		languages: ["en"],
		numericCode: "288",
	},
	{
		name: "Gibraltar",
		topLevelDomain: [".gi"],
		alpha2Code: "GI",
		alpha3Code: "GIB",
		callingCodes: ["350"],
		capital: "Gibraltar",
		altSpellings: ["GI"],
		relevance: "0.5",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Gibraltar",
			es: "Gibraltar",
			fr: "Gibraltar",
			ja: "ã‚¸ãƒ–ãƒ©ãƒ«ã‚¿ãƒ«",
			it: "Gibilterra",
		},
		population: 32734,
		latlng: [36.13333333, -5.35],
		demonym: "Gibraltar",
		area: 6,
		gini: null,
		timezones: null,
		borders: ["ESP"],
		nativeName: "Gibraltar",
		currencies: ["GIP"],
		languages: ["en"],
		numericCode: "292",
	},
	{
		name: "Greece",
		topLevelDomain: [".gr"],
		alpha2Code: "GR",
		alpha3Code: "GRC",
		callingCodes: ["30"],
		capital: "Athens",
		altSpellings: [
			"GR",
			"EllÃ¡da",
			"Hellenic Republic",
			"Î•Î»Î»Î·Î½Î¹ÎºÎ® Î”Î·Î¼Î¿ÎºÏ�Î±Ï„Î¯Î±",
		],
		relevance: "1.5",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Griechenland",
			es: "Grecia",
			fr: "GrÃ¨ce",
			ja: "ã‚®ãƒªã‚·ãƒ£",
			it: "Grecia",
		},
		population: 10846979,
		latlng: [39, 22],
		demonym: "Greek",
		area: 131990,
		gini: 34.3,
		timezones: ["UTC+02:00"],
		borders: ["ALB", "BGR", "TUR", "MKD"],
		nativeName: "Î•Î»Î»Î¬Î´Î±",
		currencies: ["EUR"],
		languages: ["el"],
		numericCode: "300",
	},
	{
		name: "Greenland",
		topLevelDomain: [".gl"],
		alpha2Code: "GL",
		alpha3Code: "GRL",
		callingCodes: ["299"],
		capital: "Nuuk",
		altSpellings: ["GL", "GrÃ¸nland"],
		relevance: "0.5",
		region: "Americas",
		subregion: "Northern America",
		translations: {
			de: "GrÃ¶nland",
			es: "Groenlandia",
			fr: "Groenland",
			ja: "ã‚°ãƒªãƒ¼ãƒ³ãƒ©ãƒ³ãƒ‰",
			it: "Groenlandia",
		},
		population: 56114,
		latlng: [72, -40],
		demonym: "Greenlandic",
		area: 2166086,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Kalaallit Nunaat",
		currencies: ["DKK"],
		languages: ["kl"],
		numericCode: "304",
	},
	{
		name: "Grenada",
		topLevelDomain: [".gd"],
		alpha2Code: "GD",
		alpha3Code: "GRD",
		callingCodes: ["1473"],
		capital: "St. George's",
		altSpellings: ["GD"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Grenada",
			es: "Grenada",
			fr: "Grenade",
			ja: "ã‚°ãƒ¬ãƒŠãƒ€",
			it: "Grenada",
		},
		population: 103328,
		latlng: [12.11666666, -61.66666666],
		demonym: "Grenadian",
		area: 344,
		gini: null,
		timezones: ["UTCâˆ’04:00"],
		borders: [],
		nativeName: "Grenada",
		currencies: ["XCD"],
		languages: ["en"],
		numericCode: "308",
	},
	{
		name: "Guadeloupe",
		topLevelDomain: [".gp"],
		alpha2Code: "GP",
		alpha3Code: "GLP",
		callingCodes: ["590"],
		capital: "Basse-Terre",
		altSpellings: ["GP", "Gwadloup"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Guadeloupe",
			es: "Guadalupe",
			fr: "Guadeloupe",
			ja: "ã‚°ã‚¢ãƒ‰ãƒ«ãƒ¼ãƒ—",
			it: "Guadeloupa",
		},
		population: 403750,
		latlng: [16.25, -61.583333],
		demonym: "Guadeloupian",
		area: null,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Guadeloupe",
		currencies: ["EUR"],
		languages: ["fr"],
		numericCode: "312",
	},
	{
		name: "Guam",
		topLevelDomain: [".gu"],
		alpha2Code: "GU",
		alpha3Code: "GUM",
		callingCodes: ["1671"],
		capital: "HagÃ¥tÃ±a",
		altSpellings: ["GU", "GuÃ¥hÃ¥n"],
		relevance: "0",
		region: "Oceania",
		subregion: "Micronesia",
		translations: {
			de: "Guam",
			es: "Guam",
			fr: "Guam",
			ja: "ã‚°ã‚¢ãƒ ",
			it: "Guam",
		},
		population: 159358,
		latlng: [13.46666666, 144.78333333],
		demonym: "Guamanian",
		area: 549,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Guam",
		currencies: ["USD"],
		languages: ["en", "ch", "es"],
		numericCode: "316",
	},
	{
		name: "Guatemala",
		topLevelDomain: [".gt"],
		alpha2Code: "GT",
		alpha3Code: "GTM",
		callingCodes: ["502"],
		capital: "Guatemala City",
		altSpellings: ["GT"],
		relevance: "0",
		region: "Americas",
		subregion: "Central America",
		translations: {
			de: "Guatemala",
			es: "Guatemala",
			fr: "Guatemala",
			ja: "ã‚°ã‚¢ãƒ†ãƒžãƒ©",
			it: "Guatemala",
		},
		population: 16176133,
		latlng: [15.5, -90.25],
		demonym: "Guatemalan",
		area: 108889,
		gini: 55.9,
		timezones: ["UTCâˆ’06:00"],
		borders: ["BLZ", "SLV", "HND", "MEX"],
		nativeName: "Guatemala",
		currencies: ["GTQ"],
		languages: ["es"],
		numericCode: "320",
	},
	{
		name: "Guernsey",
		topLevelDomain: [".gg"],
		alpha2Code: "GG",
		alpha3Code: "GGY",
		callingCodes: ["44"],
		capital: "St. Peter Port",
		altSpellings: ["GG", "Bailiwick of Guernsey", "Bailliage de Guernesey"],
		relevance: "0.5",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Guernsey",
			es: "Guernsey",
			fr: "Guernesey",
			ja: "ã‚¬ãƒ¼ãƒ³ã‚¸ãƒ¼",
			it: "Guernsey",
		},
		population: 65150,
		latlng: [49.46666666, -2.58333333],
		demonym: "Channel Islander",
		area: 78,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Guernsey",
		currencies: ["GBP"],
		languages: ["en", "fr"],
		numericCode: "831",
	},
	{
		name: "Guinea",
		topLevelDomain: [".gn"],
		alpha2Code: "GN",
		alpha3Code: "GIN",
		callingCodes: ["224"],
		capital: "Conakry",
		altSpellings: ["GN", "Republic of Guinea", "RÃ©publique de GuinÃ©e"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Guinea",
			es: "Guinea",
			fr: "GuinÃ©e",
			ja: "ã‚®ãƒ‹ã‚¢",
			it: "Guinea",
		},
		population: 10628972,
		latlng: [11, -10],
		demonym: "Guinean",
		area: 245857,
		gini: 39.4,
		timezones: ["UTC"],
		borders: ["CIV", "GNB", "LBR", "MLI", "SEN", "SLE"],
		nativeName: "GuinÃ©e",
		currencies: ["GNF"],
		languages: ["fr", "ff"],
		numericCode: "324",
	},
	{
		name: "Guinea-Bissau",
		topLevelDomain: [".gw"],
		alpha2Code: "GW",
		alpha3Code: "GNB",
		callingCodes: ["245"],
		capital: "Bissau",
		altSpellings: ["GW", "Republic of Guinea-Bissau", "RepÃºblica da GuinÃ©-Bissau"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Guinea-Bissau",
			es: "Guinea-BisÃ¡u",
			fr: "GuinÃ©e-Bissau",
			ja: "ã‚®ãƒ‹ã‚¢ãƒ“ã‚µã‚¦",
			it: "Guinea-Bissau",
		},
		population: 1844000,
		latlng: [12, -15],
		demonym: "Guinea-Bissauan",
		area: 36125,
		gini: 35.5,
		timezones: ["UTC"],
		borders: ["GIN", "SEN"],
		nativeName: "GuinÃ©-Bissau",
		currencies: ["XOF"],
		languages: ["pt"],
		numericCode: "624",
	},
	{
		name: "Guyana",
		topLevelDomain: [".gy"],
		alpha2Code: "GY",
		alpha3Code: "GUY",
		callingCodes: ["592"],
		capital: "Georgetown",
		altSpellings: ["GY", "Co-operative Republic of Guyana"],
		relevance: "0",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "Guyana",
			es: "Guyana",
			fr: "Guyane",
			ja: "ã‚¬ã‚¤ã‚¢ãƒŠ",
			it: "Guyana",
		},
		population: 746900,
		latlng: [5, -59],
		demonym: "Guyanese",
		area: 214969,
		gini: 44.5,
		timezones: ["UTCâˆ’04:00"],
		borders: ["BRA", "SUR", "VEN"],
		nativeName: "Guyana",
		currencies: ["GYD"],
		languages: ["en"],
		numericCode: "328",
	},
	{
		name: "Haiti",
		topLevelDomain: [".ht"],
		alpha2Code: "HT",
		alpha3Code: "HTI",
		callingCodes: ["509"],
		capital: "Port-au-Prince",
		altSpellings: ["HT", "Republic of Haiti", "RÃ©publique d'HaÃ¯ti", "Repiblik Ayiti"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Haiti",
			es: "Haiti",
			fr: "HaÃ¯ti",
			ja: "ãƒ�ã‚¤ãƒ�",
			it: "Haiti",
		},
		population: 10911819,
		latlng: [19, -72.41666666],
		demonym: "Haitian",
		area: 27750,
		gini: 59.2,
		timezones: ["UTCâˆ’05:00"],
		borders: ["DOM"],
		nativeName: "HaÃ¯ti",
		currencies: ["HTG", "USD"],
		languages: ["fr", "ht"],
		numericCode: "332",
	},
	{
		name: "Heard Island and McDonald Islands",
		topLevelDomain: [".hm", ".aq"],
		alpha2Code: "HM",
		alpha3Code: "HMD",
		callingCodes: [""],
		capital: "",
		altSpellings: ["HM"],
		relevance: "0",
		region: "",
		subregion: "",
		translations: {
			de: "Heard und die McDonaldinseln",
			es: "Islas Heard y McDonald",
			fr: "ÃŽles Heard-et-MacDonald",
			ja: "ãƒ�ãƒ¼ãƒ‰å³¶ã�¨ãƒžã‚¯ãƒ‰ãƒŠãƒ«ãƒ‰è«¸å³¶",
			it: "Isole Heard e McDonald",
		},
		population: 0,
		latlng: [-53.1, 72.51666666],
		demonym: "Heard and McDonald Islander",
		area: 412,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Heard Island and McDonald Islands",
		currencies: ["AUD"],
		languages: ["en"],
		numericCode: "334",
	},
	{
		name: "Holy See",
		topLevelDomain: [".va"],
		alpha2Code: "VA",
		alpha3Code: "VAT",
		callingCodes: ["379"],
		capital: "Rome",
		altSpellings: ["Sancta Sedes", "Vatican", "The Vatican"],
		relevance: null,
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Heiliger Stuhl",
			es: "Santa Sede",
			fr: "voir Saint",
			ja: "è�–åº§",
			it: "Santa Sede",
		},
		population: 451,
		latlng: [41.9, 12.45],
		demonym: "",
		area: 0.44,
		gini: null,
		timezones: ["UTC+01:00"],
		borders: ["ITA"],
		nativeName: "Sancta Sedes",
		currencies: ["EUR"],
		languages: ["la", "it", "fr", "de"],
		numericCode: null,
	},
	{
		name: "Honduras",
		topLevelDomain: [".hn"],
		alpha2Code: "HN",
		alpha3Code: "HND",
		callingCodes: ["504"],
		capital: "Tegucigalpa",
		altSpellings: ["HN", "Republic of Honduras", "RepÃºblica de Honduras"],
		relevance: "0",
		region: "Americas",
		subregion: "Central America",
		translations: {
			de: "Honduras",
			es: "Honduras",
			fr: "Honduras",
			ja: "ãƒ›ãƒ³ã‚¸ãƒ¥ãƒ©ã‚¹",
			it: "Honduras",
		},
		population: 8725111,
		latlng: [15, -86.5],
		demonym: "Honduran",
		area: 112492,
		gini: 57,
		timezones: ["UTC-06:00"],
		borders: ["GTM", "SLV", "NIC"],
		nativeName: "Honduras",
		currencies: ["HNL"],
		languages: ["es"],
		numericCode: "340",
	},
	{
		name: "Hong Kong",
		topLevelDomain: [".hk"],
		alpha2Code: "HK",
		alpha3Code: "HKG",
		callingCodes: ["852"],
		capital: "City of Victoria",
		altSpellings: ["HK", "é¦™æ¸¯"],
		relevance: "0",
		region: "Asia",
		subregion: "Eastern Asia",
		translations: {
			de: "Hong Kong",
			es: "Hong Kong",
			fr: "Hong Kong",
			ja: "é¦™æ¸¯",
			it: "Hong Kong",
		},
		population: 7298600,
		latlng: [22.25, 114.16666666],
		demonym: "Chinese",
		area: 1104,
		gini: 53.3,
		timezones: ["UTC+08:00"],
		borders: ["CHN"],
		nativeName: "é¦™æ¸¯",
		currencies: ["HKD"],
		languages: ["en", "zh"],
		numericCode: "344",
	},
	{
		name: "Hungary",
		topLevelDomain: [".hu"],
		alpha2Code: "HU",
		alpha3Code: "HUN",
		callingCodes: ["36"],
		capital: "Budapest",
		altSpellings: ["HU"],
		relevance: "0",
		region: "Europe",
		subregion: "Eastern Europe",
		translations: {
			de: "Ungarn",
			es: "HungrÃ­a",
			fr: "Hongrie",
			ja: "ãƒ�ãƒ³ã‚¬ãƒªãƒ¼",
			it: "Ungheria",
		},
		population: 9849000,
		latlng: [47, 20],
		demonym: "Hungarian",
		area: 93028,
		gini: 31.2,
		timezones: ["UTC+01:00"],
		borders: ["AUT", "HRV", "ROU", "SRB", "SVK", "SVN", "UKR"],
		nativeName: "MagyarorszÃ¡g",
		currencies: ["HUF"],
		languages: ["hu"],
		numericCode: "348",
	},
	{
		name: "Iceland",
		topLevelDomain: [".is"],
		alpha2Code: "IS",
		alpha3Code: "ISL",
		callingCodes: ["354"],
		capital: "ReykjavÃ­k",
		altSpellings: ["IS", "Island", "Republic of Iceland", "LÃ½Ã°veldiÃ° Ã�sland"],
		relevance: "0",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Island",
			es: "Islandia",
			fr: "Islande",
			ja: "ã‚¢ã‚¤ã‚¹ãƒ©ãƒ³ãƒ‰",
			it: "Islanda",
		},
		population: 330610,
		latlng: [65, -18],
		demonym: "Icelander",
		area: 103000,
		gini: null,
		timezones: ["UTC"],
		borders: [],
		nativeName: "Ã�sland",
		currencies: ["ISK"],
		languages: ["is"],
		numericCode: "352",
	},
	{
		name: "India",
		topLevelDomain: [".in"],
		alpha2Code: "IN",
		alpha3Code: "IND",
		callingCodes: ["91"],
		capital: "New Delhi",
		altSpellings: ["IN", "BhÄ�rat", "Republic of India", "Bharat Ganrajya"],
		relevance: "3",
		region: "Asia",
		subregion: "Southern Asia",
		translations: {
			de: "Indien",
			es: "India",
			fr: "Inde",
			ja: "ã‚¤ãƒ³ãƒ‰",
			it: "India",
		},
		population: 1275840000,
		latlng: [20, 77],
		demonym: "Indian",
		area: 3287590,
		gini: 33.4,
		timezones: ["UTC+05:30"],
		borders: ["AFG", "BGD", "BTN", "MMR", "CHN", "NPL", "PAK", "LKA"],
		nativeName: "à¤­à¤¾à¤°à¤¤",
		currencies: ["INR"],
		languages: ["hi", "en"],
		numericCode: "356",
	},
	{
		name: "Indonesia",
		topLevelDomain: [".id"],
		alpha2Code: "ID",
		alpha3Code: "IDN",
		callingCodes: ["62"],
		capital: "Jakarta",
		altSpellings: ["ID", "Republic of Indonesia", "Republik Indonesia"],
		relevance: "2",
		region: "Asia",
		subregion: "South-Eastern Asia",
		translations: {
			de: "Indonesien",
			es: "Indonesia",
			fr: "IndonÃ©sie",
			ja: "ã‚¤ãƒ³ãƒ‰ãƒ�ã‚·ã‚¢",
			it: "Indonesia",
		},
		population: 255461700,
		latlng: [-5, 120],
		demonym: "Indonesian",
		area: 1904569,
		gini: 34,
		timezones: ["UTC+07:00", "UTC+08:00", "UTC+09:00"],
		borders: ["TLS", "MYS", "PNG"],
		nativeName: "Indonesia",
		currencies: ["IDR"],
		languages: ["id"],
		numericCode: "360",
	},
	{
		name: "Ivory Coast",
		topLevelDomain: [".ci"],
		alpha2Code: "CI",
		alpha3Code: "CIV",
		callingCodes: ["225"],
		capital: "Yamoussoukro",
		altSpellings: [
			"CI",
			"Ivory Coast",
			"Republic of CÃ´te d'Ivoire",
			"RÃ©publique de CÃ´te d'Ivoire",
		],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "ElfenbeinkÃ¼ste",
			es: "Costa de Marfil",
			fr: "CÃ´te d'Ivoire",
			ja: "ã‚³ãƒ¼ãƒˆã‚¸ãƒœãƒ¯ãƒ¼ãƒ«",
			it: "Costa D'Avorio",
		},
		population: 22671331,
		latlng: [8, -5],
		demonym: "Ivorian",
		area: 322463,
		gini: 41.5,
		timezones: ["UTC"],
		borders: ["BFA", "GHA", "GIN", "LBR", "MLI"],
		nativeName: "CÃ´te d'Ivoire",
		currencies: ["XOF"],
		languages: ["fr"],
		numericCode: "384",
	},
	{
		name: "Iran",
		topLevelDomain: [".ir"],
		alpha2Code: "IR",
		alpha3Code: "IRN",
		callingCodes: ["98"],
		capital: "Tehran",
		altSpellings: ["IR", "Islamic Republic of Iran", "Jomhuri-ye EslÄ�mi-ye IrÄ�n"],
		relevance: "0",
		region: "Asia",
		subregion: "Southern Asia",
		translations: {
			de: "Iran",
			es: "Iran",
			fr: "Iran",
			ja: "ã‚¤ãƒ©ãƒ³ãƒ»ã‚¤ã‚¹ãƒ©ãƒ å…±å’Œå›½",
			it: null,
		},
		population: 78572500,
		latlng: [32, 53],
		demonym: "Iranian",
		area: 1648195,
		gini: 38.3,
		timezones: ["UTC+03:30"],
		borders: ["AFG", "ARM", "AZE", "IRQ", "PAK", "TUR", "TKM"],
		nativeName: "Ø§ÛŒØ±Ø§Ù†",
		currencies: ["IRR"],
		languages: ["fa"],
		numericCode: "364",
	},
	{
		name: "Iraq",
		topLevelDomain: [".iq"],
		alpha2Code: "IQ",
		alpha3Code: "IRQ",
		callingCodes: ["964"],
		capital: "Baghdad",
		altSpellings: ["IQ", "Republic of Iraq", "JumhÅ«riyyat al-â€˜IrÄ�q"],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Irak",
			es: "Irak",
			fr: "Irak",
			ja: "ã‚¤ãƒ©ã‚¯",
			it: "Iraq",
		},
		population: 36004552,
		latlng: [33, 44],
		demonym: "Iraqi",
		area: 438317,
		gini: 30.9,
		timezones: ["UTC+03:00"],
		borders: ["IRN", "JOR", "KWT", "SAU", "SYR", "TUR"],
		nativeName: "Ø§Ù„Ø¹Ø±Ø§Ù‚",
		currencies: ["IQD"],
		languages: ["ar", "ku"],
		numericCode: "368",
	},
	{
		name: "Republic of Ireland",
		topLevelDomain: [".ie"],
		alpha2Code: "IE",
		alpha3Code: "IRL",
		callingCodes: ["353"],
		capital: "Dublin",
		altSpellings: ["IE", "Ã‰ire", "Republic of Ireland", "Poblacht na hÃ‰ireann"],
		relevance: "1.2",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Irland",
			es: "Irlanda",
			fr: "Irlande",
			ja: "ã‚¢ã‚¤ãƒ«ãƒ©ãƒ³ãƒ‰",
			it: "Irlanda",
		},
		population: 6378000,
		latlng: [53, -8],
		demonym: "Irish",
		area: 70273,
		gini: 34.3,
		timezones: ["UTC"],
		borders: ["GBR"],
		nativeName: "Ã‰ire",
		currencies: ["EUR"],
		languages: ["ga", "en"],
		numericCode: "372",
	},
	{
		name: "Isle of Man",
		topLevelDomain: [".im"],
		alpha2Code: "IM",
		alpha3Code: "IMN",
		callingCodes: ["44"],
		capital: "Douglas",
		altSpellings: ["IM", "Ellan Vannin", "Mann", "Mannin"],
		relevance: "0.5",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Insel Man",
			es: "Isla de Man",
			fr: "ÃŽle de Man",
			ja: "ãƒžãƒ³å³¶",
			it: "Isola di Man",
		},
		population: 84497,
		latlng: [54.25, -4.5],
		demonym: "Manx",
		area: 572,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Isle of Man",
		currencies: ["GBP"],
		languages: ["en", "gv"],
		numericCode: "833",
	},
	{
		name: "Italy",
		topLevelDomain: [".it"],
		alpha2Code: "IT",
		alpha3Code: "ITA",
		callingCodes: ["39"],
		capital: "Rome",
		altSpellings: ["IT", "Italian Republic", "Repubblica italiana"],
		relevance: "2",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Italien",
			es: "Italia",
			fr: "Italie",
			ja: "ã‚¤ã‚¿ãƒªã‚¢",
			it: "Italia",
		},
		population: 60753794,
		latlng: [42.83333333, 12.83333333],
		demonym: "Italian",
		area: 301336,
		gini: 36,
		timezones: ["UTC+01:00"],
		borders: ["AUT", "FRA", "SMR", "SVN", "CHE", "VAT"],
		nativeName: "Italia",
		currencies: ["EUR"],
		languages: ["it"],
		numericCode: "380",
	},
	{
		name: "Jamaica",
		topLevelDomain: [".jm"],
		alpha2Code: "JM",
		alpha3Code: "JAM",
		callingCodes: ["1876"],
		capital: "Kingston",
		altSpellings: ["JM"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Jamaika",
			es: "Jamaica",
			fr: "JamaÃ¯que",
			ja: "ã‚¸ãƒ£ãƒžã‚¤ã‚«",
			it: "Giamaica",
		},
		population: 2717991,
		latlng: [18.25, -77.5],
		demonym: "Jamaican",
		area: 10991,
		gini: 45.5,
		timezones: ["UTCâˆ’05:00"],
		borders: [],
		nativeName: "Jamaica",
		currencies: ["JMD"],
		languages: ["en"],
		numericCode: "388",
	},
	{
		name: "Japan",
		topLevelDomain: [".jp"],
		alpha2Code: "JP",
		alpha3Code: "JPN",
		callingCodes: ["81"],
		capital: "Tokyo",
		altSpellings: ["JP", "Nippon", "Nihon"],
		relevance: "2.5",
		region: "Asia",
		subregion: "Eastern Asia",
		translations: {
			de: "Japan",
			es: "JapÃ³n",
			fr: "Japon",
			ja: "æ—¥æœ¬",
			it: "Giappone",
		},
		population: 126865000,
		latlng: [36, 138],
		demonym: "Japanese",
		area: 377930,
		gini: 38.1,
		timezones: ["UTC+09:00"],
		borders: [],
		nativeName: "æ—¥æœ¬",
		currencies: ["JPY"],
		languages: ["ja"],
		numericCode: "392",
	},
	{
		name: "Jersey",
		topLevelDomain: [".je"],
		alpha2Code: "JE",
		alpha3Code: "JEY",
		callingCodes: ["44"],
		capital: "Saint Helier",
		altSpellings: ["JE", "Bailiwick of Jersey", "Bailliage de Jersey", "Bailliage dÃ© JÃ¨rri"],
		relevance: "0.5",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Jersey",
			es: "Jersey",
			fr: "Jersey",
			ja: "ã‚¸ãƒ£ãƒ¼ã‚¸ãƒ¼",
			it: "Isola di Jersey",
		},
		population: 99000,
		latlng: [49.25, -2.16666666],
		demonym: "Channel Islander",
		area: 116,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Jersey",
		currencies: ["GBP"],
		languages: ["en", "fr"],
		numericCode: "832",
	},
	{
		name: "Jordan",
		topLevelDomain: [".jo"],
		alpha2Code: "JO",
		alpha3Code: "JOR",
		callingCodes: ["962"],
		capital: "Amman",
		altSpellings: [
			"JO",
			"Hashemite Kingdom of Jordan",
			"al-Mamlakah al-UrdunÄ«yah al-HÄ�shimÄ«yah",
		],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Jordanien",
			es: "Jordania",
			fr: "Jordanie",
			ja: "ãƒ¨ãƒ«ãƒ€ãƒ³",
			it: "Giordania",
		},
		population: 6767600,
		latlng: [31, 36],
		demonym: "Jordanian",
		area: 89342,
		gini: 35.4,
		timezones: ["UTC+03:00"],
		borders: ["IRQ", "ISR", "SAU", "SYR"],
		nativeName: "Ø§Ù„Ø£Ø±Ø¯Ù†",
		currencies: ["JOD"],
		languages: ["ar"],
		numericCode: "400",
	},
	{
		name: "Kazakhstan",
		topLevelDomain: [".kz", ".Ò›Ð°Ð·"],
		alpha2Code: "KZ",
		alpha3Code: "KAZ",
		callingCodes: ["76", "77"],
		capital: "Astana",
		altSpellings: [
			"KZ",
			"Qazaqstan",
			"ÐšÐ°Ð·Ð°Ñ…Ñ�Ñ‚Ð°Ð½",
			"Republic of Kazakhstan",
			"ÒšÐ°Ð·Ð°Ò›Ñ�Ñ‚Ð°Ð½ Ð ÐµÑ�Ð¿ÑƒÐ±Ð»Ð¸ÐºÐ°Ñ�Ñ‹",
			"Qazaqstan RespublÃ¯kasÄ±",
			"Ð ÐµÑ�Ð¿ÑƒÐ±Ð»Ð¸ÐºÐ° ÐšÐ°Ð·Ð°Ñ…Ñ�Ñ‚Ð°Ð½",
			"Respublika Kazakhstan",
		],
		relevance: "0",
		region: "Asia",
		subregion: "Central Asia",
		translations: {
			de: "Kasachstan",
			es: "KazajistÃ¡n",
			fr: "Kazakhstan",
			ja: "ã‚«ã‚¶ãƒ•ã‚¹ã‚¿ãƒ³",
			it: "Kazakistan",
		},
		population: 17541200,
		latlng: [48, 68],
		demonym: "Kazakhstani",
		area: 2724900,
		gini: 29,
		timezones: ["UTC+05:00", "UTC+06:00"],
		borders: ["CHN", "KGZ", "RUS", "TKM", "UZB"],
		nativeName: "ÒšÐ°Ð·Ð°Ò›Ñ�Ñ‚Ð°Ð½",
		currencies: ["KZT"],
		languages: ["kk", "ru"],
		numericCode: "398",
	},
	{
		name: "Kenya",
		topLevelDomain: [".ke"],
		alpha2Code: "KE",
		alpha3Code: "KEN",
		callingCodes: ["254"],
		capital: "Nairobi",
		altSpellings: ["KE", "Republic of Kenya", "Jamhuri ya Kenya"],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Kenia",
			es: "Kenia",
			fr: "Kenya",
			ja: "ã‚±ãƒ‹ã‚¢",
			it: "Kenya",
		},
		population: 46050000,
		latlng: [1, 38],
		demonym: "Kenyan",
		area: 580367,
		gini: 47.7,
		timezones: ["UTC+03:00"],
		borders: ["ETH", "SOM", "SSD", "TZA", "UGA"],
		nativeName: "Kenya",
		currencies: ["KES"],
		languages: ["en", "sw"],
		numericCode: "404",
	},
	{
		name: "Kiribati",
		topLevelDomain: [".ki"],
		alpha2Code: "KI",
		alpha3Code: "KIR",
		callingCodes: ["686"],
		capital: "South Tarawa",
		altSpellings: ["KI", "Republic of Kiribati", "Ribaberiki Kiribati"],
		relevance: "0",
		region: "Oceania",
		subregion: "Micronesia",
		translations: {
			de: "Kiribati",
			es: "Kiribati",
			fr: "Kiribati",
			ja: "ã‚­ãƒªãƒ�ã‚¹",
			it: "Kiribati",
		},
		population: 106461,
		latlng: [1.41666666, 173],
		demonym: "I-Kiribati",
		area: 811,
		gini: null,
		timezones: ["UTC+12:00", "UTC+13:00", "UTC+14:00"],
		borders: [],
		nativeName: "Kiribati",
		currencies: ["AUD"],
		languages: ["en"],
		numericCode: "296",
	},
	{
		name: "Kuwait",
		topLevelDomain: [".kw"],
		alpha2Code: "KW",
		alpha3Code: "KWT",
		callingCodes: ["965"],
		capital: "Kuwait City",
		altSpellings: ["KW", "State of Kuwait", "Dawlat al-Kuwait"],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Kuwait",
			es: "Kuwait",
			fr: "KoweÃ¯t",
			ja: "ã‚¯ã‚¦ã‚§ãƒ¼ãƒˆ",
			it: "Kuwait",
		},
		population: 3268431,
		latlng: [29.5, 45.75],
		demonym: "Kuwaiti",
		area: 17818,
		gini: null,
		timezones: ["UTC+03:00"],
		borders: ["IRN", "SAU"],
		nativeName: "Ø§Ù„ÙƒÙˆÙŠØª",
		currencies: ["KWD"],
		languages: ["ar"],
		numericCode: "414",
	},
	{
		name: "Kyrgyzstan",
		topLevelDomain: [".kg"],
		alpha2Code: "KG",
		alpha3Code: "KGZ",
		callingCodes: ["996"],
		capital: "Bishkek",
		altSpellings: [
			"KG",
			"ÐšÐ¸Ñ€Ð³Ð¸Ð·Ð¸Ñ�",
			"Kyrgyz Republic",
			"ÐšÑ‹Ñ€Ð³Ñ‹Ð· Ð ÐµÑ�Ð¿ÑƒÐ±Ð»Ð¸ÐºÐ°Ñ�Ñ‹",
			"Kyrgyz Respublikasy",
		],
		relevance: "0",
		region: "Asia",
		subregion: "Central Asia",
		translations: {
			de: "Kirgisistan",
			es: "KirguizistÃ¡n",
			fr: "Kirghizistan",
			ja: "ã‚­ãƒ«ã‚®ã‚¹",
			it: "Kirghizistan",
		},
		population: 5944400,
		latlng: [41, 75],
		demonym: "Kirghiz",
		area: 199951,
		gini: 36.2,
		timezones: ["UTC+06:00"],
		borders: ["CHN", "KAZ", "TJK", "UZB"],
		nativeName: "ÐšÑ‹Ñ€Ð³Ñ‹Ð·Ñ�Ñ‚Ð°Ð½",
		currencies: ["KGS"],
		languages: ["ky", "ru"],
		numericCode: "417",
	},
	{
		name: "Laos",
		topLevelDomain: [".la"],
		alpha2Code: "LA",
		alpha3Code: "LAO",
		callingCodes: ["856"],
		capital: "Vientiane",
		altSpellings: [
			"LA",
			"Lao",
			"Lao People's Democratic Republic",
			"Sathalanalat Paxathipatai Paxaxon Lao",
		],
		relevance: "0",
		region: "Asia",
		subregion: "South-Eastern Asia",
		translations: {
			de: "Laos",
			es: "Laos",
			fr: "Laos",
			ja: "ãƒ©ã‚ªã‚¹äººæ°‘æ°‘ä¸»å…±å’Œå›½",
			it: "Laos",
		},
		population: 6802000,
		latlng: [18, 105],
		demonym: "Laotian",
		area: 236800,
		gini: 36.7,
		timezones: ["UTC+07:00"],
		borders: ["MMR", "KHM", "CHN", "THA", "VNM"],
		nativeName: "àºªàº›àº›àº¥àº²àº§",
		currencies: ["LAK"],
		languages: ["lo"],
		numericCode: "418",
	},
	{
		name: "Latvia",
		topLevelDomain: [".lv"],
		alpha2Code: "LV",
		alpha3Code: "LVA",
		callingCodes: ["371"],
		capital: "Riga",
		altSpellings: ["LV", "Republic of Latvia", "Latvijas Republika"],
		relevance: "0",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Lettland",
			es: "Letonia",
			fr: "Lettonie",
			ja: "ãƒ©ãƒˆãƒ“ã‚¢",
			it: "Lettonia",
		},
		population: 1980700,
		latlng: [57, 25],
		demonym: "Latvian",
		area: 64559,
		gini: 36.6,
		timezones: ["UTC+02:00"],
		borders: ["BLR", "EST", "LTU", "RUS"],
		nativeName: "Latvija",
		currencies: ["EUR"],
		languages: ["lv"],
		numericCode: "428",
	},
	{
		name: "Lebanon",
		topLevelDomain: [".lb"],
		alpha2Code: "LB",
		alpha3Code: "LBN",
		callingCodes: ["961"],
		capital: "Beirut",
		altSpellings: ["LB", "Lebanese Republic", "Al-JumhÅ«rÄ«yah Al-LibnÄ�nÄ«yah"],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Libanon",
			es: "LÃ­bano",
			fr: "Liban",
			ja: "ãƒ¬ãƒ�ãƒŽãƒ³",
			it: "Libano",
		},
		population: 4104000,
		latlng: [33.83333333, 35.83333333],
		demonym: "Lebanese",
		area: 10452,
		gini: null,
		timezones: ["UTC+02:00"],
		borders: ["ISR", "SYR"],
		nativeName: "Ù„Ø¨Ù†Ø§Ù†",
		currencies: ["LBP"],
		languages: ["ar", "fr"],
		numericCode: "422",
	},
	{
		name: "Lesotho",
		topLevelDomain: [".ls"],
		alpha2Code: "LS",
		alpha3Code: "LSO",
		callingCodes: ["266"],
		capital: "Maseru",
		altSpellings: ["LS", "Kingdom of Lesotho", "Muso oa Lesotho"],
		relevance: "0",
		region: "Africa",
		subregion: "Southern Africa",
		translations: {
			de: "Lesotho",
			es: "Lesotho",
			fr: "Lesotho",
			ja: "ãƒ¬ã‚½ãƒˆ",
			it: "Lesotho",
		},
		population: 2135000,
		latlng: [-29.5, 28.5],
		demonym: "Mosotho",
		area: 30355,
		gini: 52.5,
		timezones: ["UTC+02:00"],
		borders: ["ZAF"],
		nativeName: "Lesotho",
		currencies: ["LSL", "ZAR"],
		languages: ["en", "st"],
		numericCode: "426",
	},
	{
		name: "Liberia",
		topLevelDomain: [".lr"],
		alpha2Code: "LR",
		alpha3Code: "LBR",
		callingCodes: ["231"],
		capital: "Monrovia",
		altSpellings: ["LR", "Republic of Liberia"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Liberia",
			es: "Liberia",
			fr: "Liberia",
			ja: "ãƒªãƒ™ãƒªã‚¢",
			it: "Liberia",
		},
		population: 4503000,
		latlng: [6.5, -9.5],
		demonym: "Liberian",
		area: 111369,
		gini: 38.2,
		timezones: ["UTC"],
		borders: ["GIN", "CIV", "SLE"],
		nativeName: "Liberia",
		currencies: ["LRD"],
		languages: ["en"],
		numericCode: "430",
	},
	{
		name: "Libya",
		topLevelDomain: [".ly"],
		alpha2Code: "LY",
		alpha3Code: "LBY",
		callingCodes: ["218"],
		capital: "Tripoli",
		altSpellings: ["LY", "State of Libya", "Dawlat Libya"],
		relevance: "0",
		region: "Africa",
		subregion: "Northern Africa",
		translations: {
			de: "Libyen",
			es: "Libia",
			fr: "Libye",
			ja: "ãƒªãƒ“ã‚¢",
			it: "Libia",
		},
		population: 6278000,
		latlng: [25, 17],
		demonym: "Libyan",
		area: 1759540,
		gini: null,
		timezones: ["UTC+01:00"],
		borders: ["DZA", "TCD", "EGY", "NER", "SDN", "TUN"],
		nativeName: "â€�Ù„ÙŠØ¨ÙŠØ§",
		currencies: ["LYD"],
		languages: ["ar"],
		numericCode: "434",
	},
	{
		name: "Liechtenstein",
		topLevelDomain: [".li"],
		alpha2Code: "LI",
		alpha3Code: "LIE",
		callingCodes: ["423"],
		capital: "Vaduz",
		altSpellings: ["LI", "Principality of Liechtenstein", "FÃ¼rstentum Liechtenstein"],
		relevance: "0",
		region: "Europe",
		subregion: "Western Europe",
		translations: {
			de: "Liechtenstein",
			es: "Liechtenstein",
			fr: "Liechtenstein",
			ja: "ãƒªãƒ’ãƒ†ãƒ³ã‚·ãƒ¥ã‚¿ã‚¤ãƒ³",
			it: "Liechtenstein",
		},
		population: 37370,
		latlng: [47.26666666, 9.53333333],
		demonym: "Liechtensteiner",
		area: 160,
		gini: null,
		timezones: ["UTC+01:00"],
		borders: ["AUT", "CHE"],
		nativeName: "Liechtenstein",
		currencies: ["CHF"],
		languages: ["de"],
		numericCode: "438",
	},
	{
		name: "Lithuania",
		topLevelDomain: [".lt"],
		alpha2Code: "LT",
		alpha3Code: "LTU",
		callingCodes: ["370"],
		capital: "Vilnius",
		altSpellings: ["LT", "Republic of Lithuania", "Lietuvos Respublika"],
		relevance: "0",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Litauen",
			es: "Lituania",
			fr: "Lituanie",
			ja: "ãƒªãƒˆã‚¢ãƒ‹ã‚¢",
			it: "Lituania",
		},
		population: 2900787,
		latlng: [56, 24],
		demonym: "Lithuanian",
		area: 65300,
		gini: 37.6,
		timezones: ["UTC+02:00"],
		borders: ["BLR", "LVA", "POL", "RUS"],
		nativeName: "Lietuva",
		currencies: ["EUR"],
		languages: ["lt"],
		numericCode: "440",
	},
	{
		name: "Luxembourg",
		topLevelDomain: [".lu"],
		alpha2Code: "LU",
		alpha3Code: "LUX",
		callingCodes: ["352"],
		capital: "Luxembourg",
		altSpellings: [
			"LU",
			"Grand Duchy of Luxembourg",
			"Grand-DuchÃ© de Luxembourg",
			"GroÃŸherzogtum Luxemburg",
			"Groussherzogtum LÃ«tzebuerg",
		],
		relevance: "0",
		region: "Europe",
		subregion: "Western Europe",
		translations: {
			de: "Luxemburg",
			es: "Luxemburgo",
			fr: "Luxembourg",
			ja: "ãƒ«ã‚¯ã‚»ãƒ³ãƒ–ãƒ«ã‚¯",
			it: "Lussemburgo",
		},
		population: 562958,
		latlng: [49.75, 6.16666666],
		demonym: "Luxembourger",
		area: 2586,
		gini: 30.8,
		timezones: ["UTC+01:00"],
		borders: ["BEL", "FRA", "DEU"],
		nativeName: "Luxembourg",
		currencies: ["EUR"],
		languages: ["fr", "de", "lb"],
		numericCode: "442",
	},
	{
		name: "Macau",
		topLevelDomain: [".mo"],
		alpha2Code: "MO",
		alpha3Code: "MAC",
		callingCodes: ["853"],
		capital: "",
		altSpellings: [
			"MO",
			"æ¾³é—¨",
			"Macao Special Administrative Region of the People's Republic of China",
			"ä¸­è�¯äººæ°‘å…±å’Œåœ‹æ¾³é–€ç‰¹åˆ¥è¡Œæ”¿å�€",
			"RegiÃ£o Administrativa Especial de Macau da RepÃºblica Popular da China",
		],
		relevance: "0",
		region: "Asia",
		subregion: "Eastern Asia",
		translations: {
			de: "Macao",
			es: "Macao",
			fr: "Macao",
			ja: "ãƒžã‚«ã‚ª",
			it: "Macao",
		},
		population: 642900,
		latlng: [22.16666666, 113.55],
		demonym: "Chinese",
		area: 30,
		gini: null,
		timezones: ["UTC+08:00"],
		borders: ["CHN"],
		nativeName: "æ¾³é–€",
		currencies: ["MOP"],
		languages: ["zh", "pt"],
		numericCode: "446",
	},
	{
		name: "Republic of Macedonia",
		topLevelDomain: [".mk"],
		alpha2Code: "MK",
		alpha3Code: "MKD",
		callingCodes: ["389"],
		capital: "Skopje",
		altSpellings: ["MK", "Republic of Macedonia", "Ð ÐµÐ¿ÑƒÐ±Ð»Ð¸ÐºÐ° ÐœÐ°ÐºÐµÐ´Ð¾Ð½Ð¸Ñ˜Ð°"],
		relevance: "0",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Mazedonien",
			es: "Macedonia",
			fr: "MacÃ©doine",
			ja: "ãƒžã‚±ãƒ‰ãƒ‹ã‚¢æ—§ãƒ¦ãƒ¼ã‚´ã‚¹ãƒ©ãƒ“ã‚¢å…±å’Œå›½",
			it: "Macedonia",
		},
		population: 2058539,
		latlng: [41.83333333, 22],
		demonym: "Macedonian",
		area: 25713,
		gini: 43.2,
		timezones: ["UTC+01:00"],
		borders: ["ALB", "BGR", "GRC", "KOS", "SRB"],
		nativeName: "ÐœÐ°ÐºÐµÐ´Ð¾Ð½Ð¸Ñ˜Ð°",
		currencies: ["MKD"],
		languages: ["mk"],
		numericCode: "807",
	},
	{
		name: "Madagascar",
		topLevelDomain: [".mg"],
		alpha2Code: "MG",
		alpha3Code: "MDG",
		callingCodes: ["261"],
		capital: "Antananarivo",
		altSpellings: [
			"MG",
			"Republic of Madagascar",
			"Repoblikan'i Madagasikara",
			"RÃ©publique de Madagascar",
		],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Madagaskar",
			es: "Madagascar",
			fr: "Madagascar",
			ja: "ãƒžãƒ€ã‚¬ã‚¹ã‚«ãƒ«",
			it: "Madagascar",
		},
		population: 22434363,
		latlng: [-20, 47],
		demonym: "Malagasy",
		area: 587041,
		gini: 44.1,
		timezones: ["UTC+03:00"],
		borders: [],
		nativeName: "Madagasikara",
		currencies: ["MGA"],
		languages: ["fr", "mg"],
		numericCode: "450",
	},
	{
		name: "Malawi",
		topLevelDomain: [".mw"],
		alpha2Code: "MW",
		alpha3Code: "MWI",
		callingCodes: ["265"],
		capital: "Lilongwe",
		altSpellings: ["MW", "Republic of Malawi"],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Malawi",
			es: "Malawi",
			fr: "Malawi",
			ja: "ãƒžãƒ©ã‚¦ã‚¤",
			it: "Malawi",
		},
		population: 16310431,
		latlng: [-13.5, 34],
		demonym: "Malawian",
		area: 118484,
		gini: 39,
		timezones: ["UTC+02:00"],
		borders: ["MOZ", "TZA", "ZMB"],
		nativeName: "Malawi",
		currencies: ["MWK"],
		languages: ["en", "ny"],
		numericCode: "454",
	},
	{
		name: "Malaysia",
		topLevelDomain: [".my"],
		alpha2Code: "MY",
		alpha3Code: "MYS",
		callingCodes: ["60"],
		capital: "Kuala Lumpur",
		altSpellings: ["MY"],
		relevance: "0",
		region: "Asia",
		subregion: "South-Eastern Asia",
		translations: {
			de: "Malaysia",
			es: "Malasia",
			fr: "Malaisie",
			ja: "ãƒžãƒ¬ãƒ¼ã‚·ã‚¢",
			it: "Malesia",
		},
		population: 30678800,
		latlng: [2.5, 112.5],
		demonym: "Malaysian",
		area: 330803,
		gini: 46.2,
		timezones: ["UTC+08:00"],
		borders: ["BRN", "IDN", "THA"],
		nativeName: "Malaysia",
		currencies: ["MYR"],
		languages: [],
		numericCode: "458",
	},
	{
		name: "Maldives",
		topLevelDomain: [".mv"],
		alpha2Code: "MV",
		alpha3Code: "MDV",
		callingCodes: ["960"],
		capital: "MalÃ©",
		altSpellings: [
			"MV",
			"Maldive Islands",
			"Republic of the Maldives",
			"Dhivehi Raajjeyge Jumhooriyya",
		],
		relevance: "0",
		region: "Asia",
		subregion: "Southern Asia",
		translations: {
			de: "Malediven",
			es: "Maldivas",
			fr: "Maldives",
			ja: "ãƒ¢ãƒ«ãƒ‡ã‚£ãƒ–",
			it: "Maldive",
		},
		population: 341256,
		latlng: [3.25, 73],
		demonym: "Maldivan",
		area: 300,
		gini: 37.4,
		timezones: ["UTC+05:00"],
		borders: [],
		nativeName: "Maldives",
		currencies: ["MVR"],
		languages: ["dv"],
		numericCode: "462",
	},
	{
		name: "Mali",
		topLevelDomain: [".ml"],
		alpha2Code: "ML",
		alpha3Code: "MLI",
		callingCodes: ["223"],
		capital: "Bamako",
		altSpellings: ["ML", "Republic of Mali", "RÃ©publique du Mali"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Mali",
			es: "Mali",
			fr: "Mali",
			ja: "ãƒžãƒª",
			it: "Mali",
		},
		population: 17600000,
		latlng: [17, -4],
		demonym: "Malian",
		area: 1240192,
		gini: 33,
		timezones: ["UTC"],
		borders: ["DZA", "BFA", "GIN", "CIV", "MRT", "NER", "SEN"],
		nativeName: "Mali",
		currencies: ["XOF"],
		languages: ["fr"],
		numericCode: "466",
	},
	{
		name: "Malta",
		topLevelDomain: [".mt"],
		alpha2Code: "MT",
		alpha3Code: "MLT",
		callingCodes: ["356"],
		capital: "Valletta",
		altSpellings: ["MT", "Republic of Malta", "Repubblika ta' Malta"],
		relevance: "0",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Malta",
			es: "Malta",
			fr: "Malte",
			ja: "ãƒžãƒ«ã‚¿",
			it: "Malta",
		},
		population: 445426,
		latlng: [35.83333333, 14.58333333],
		demonym: "Maltese",
		area: 316,
		gini: null,
		timezones: ["UTC+01:00"],
		borders: [],
		nativeName: "Malta",
		currencies: ["EUR"],
		languages: ["mt", "en"],
		numericCode: "470",
	},
	{
		name: "Marshall Islands",
		topLevelDomain: [".mh"],
		alpha2Code: "MH",
		alpha3Code: "MHL",
		callingCodes: ["692"],
		capital: "Majuro",
		altSpellings: ["MH", "Republic of the Marshall Islands", "AolepÄ�n AorÅ�kin MÌ§ajeÄ¼"],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Micronesia",
		translations: {
			de: "Marshallinseln",
			es: "Islas Marshall",
			fr: "ÃŽles Marshall",
			ja: "ãƒžãƒ¼ã‚·ãƒ£ãƒ«è«¸å³¶",
			it: "Isole Marshall",
		},
		population: 56086,
		latlng: [9, 168],
		demonym: "Marshallese",
		area: 181,
		gini: null,
		timezones: ["UTC+12:00"],
		borders: [],
		nativeName: "MÌ§ajeÄ¼",
		currencies: ["USD"],
		languages: ["en", "mh"],
		numericCode: "584",
	},
	{
		name: "Martinique",
		topLevelDomain: [".mq"],
		alpha2Code: "MQ",
		alpha3Code: "MTQ",
		callingCodes: ["596"],
		capital: "Fort-de-France",
		altSpellings: ["MQ"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Martinique",
			es: "Martinica",
			fr: "Martinique",
			ja: "ãƒžãƒ«ãƒ†ã‚£ãƒ‹ãƒ¼ã‚¯",
			it: "Martinica",
		},
		population: 381326,
		latlng: [14.666667, -61],
		demonym: "French",
		area: null,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Martinique",
		currencies: ["EUR"],
		languages: ["fr"],
		numericCode: "474",
	},
	{
		name: "Mauritania",
		topLevelDomain: [".mr"],
		alpha2Code: "MR",
		alpha3Code: "MRT",
		callingCodes: ["222"],
		capital: "Nouakchott",
		altSpellings: [
			"MR",
			"Islamic Republic of Mauritania",
			"al-JumhÅ«riyyah al-Ê¾IslÄ�miyyah al-MÅ«rÄ«tÄ�niyyah",
		],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Mauretanien",
			es: "Mauritania",
			fr: "Mauritanie",
			ja: "ãƒ¢ãƒ¼ãƒªã‚¿ãƒ‹ã‚¢",
			it: "Mauritania",
		},
		population: 3631775,
		latlng: [20, -12],
		demonym: "Mauritanian",
		area: 1030700,
		gini: 40.5,
		timezones: ["UTC"],
		borders: ["DZA", "MLI", "SEN", "ESH"],
		nativeName: "Ù…ÙˆØ±ÙŠØªØ§Ù†ÙŠØ§",
		currencies: ["MRO"],
		languages: ["ar"],
		numericCode: "478",
	},
	{
		name: "Mauritius",
		topLevelDomain: [".mu"],
		alpha2Code: "MU",
		alpha3Code: "MUS",
		callingCodes: ["230"],
		capital: "Port Louis",
		altSpellings: ["MU", "Republic of Mauritius", "RÃ©publique de Maurice"],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Mauritius",
			es: "Mauricio",
			fr: "ÃŽle Maurice",
			ja: "ãƒ¢ãƒ¼ãƒªã‚·ãƒ£ã‚¹",
			it: "Mauritius",
		},
		population: 1261208,
		latlng: [-20.28333333, 57.55],
		demonym: "Mauritian",
		area: 2040,
		gini: null,
		timezones: ["UTC+04:00"],
		borders: [],
		nativeName: "Maurice",
		currencies: ["MUR"],
		languages: ["en"],
		numericCode: "480",
	},
	{
		name: "Mayotte",
		topLevelDomain: [".yt"],
		alpha2Code: "YT",
		alpha3Code: "MYT",
		callingCodes: ["262"],
		capital: "Mamoudzou",
		altSpellings: ["YT", "Department of Mayotte", "DÃ©partement de Mayotte"],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Mayotte",
			es: "Mayotte",
			fr: "Mayotte",
			ja: "ãƒžãƒ¨ãƒƒãƒˆ",
			it: "Mayotte",
		},
		population: 212645,
		latlng: [-12.83333333, 45.16666666],
		demonym: "French",
		area: null,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Mayotte",
		currencies: ["EUR"],
		languages: ["fr"],
		numericCode: "175",
	},
	{
		name: "Mexico",
		topLevelDomain: [".mx"],
		alpha2Code: "MX",
		alpha3Code: "MEX",
		callingCodes: ["52"],
		capital: "Mexico City",
		altSpellings: ["MX", "Mexicanos", "United Mexican States", "Estados Unidos Mexicanos"],
		relevance: "1.5",
		region: "Americas",
		subregion: "Central America",
		translations: {
			de: "Mexiko",
			es: "MÃ©xico",
			fr: "Mexique",
			ja: "ãƒ¡ã‚­ã‚·ã‚³",
			it: "Messico",
		},
		population: 121740000,
		latlng: [23, -102],
		demonym: "Mexican",
		area: 1964375,
		gini: 47,
		timezones: ["UTCâˆ’08:00", "UTCâˆ’07:00", "UTCâˆ’06:00"],
		borders: ["BLZ", "GTM", "USA"],
		nativeName: "MÃ©xico",
		currencies: ["MXN"],
		languages: ["es"],
		numericCode: "484",
	},
	{
		name: "Federated States of Micronesia",
		topLevelDomain: [".fm"],
		alpha2Code: "FM",
		alpha3Code: "FSM",
		callingCodes: ["691"],
		capital: "Palikir",
		altSpellings: ["FM", "Federated States of Micronesia"],
		relevance: "0",
		region: "Oceania",
		subregion: "Micronesia",
		translations: {
			de: "Mikronesien",
			es: "Micronesia",
			fr: "MicronÃ©sie",
			ja: "ãƒŸã‚¯ãƒ­ãƒ�ã‚·ã‚¢é€£é‚¦",
			it: "Micronesia",
		},
		population: 101351,
		latlng: [6.91666666, 158.25],
		demonym: "Micronesian",
		area: 702,
		gini: null,
		timezones: ["UTC+10:00", "UTC+11"],
		borders: [],
		nativeName: "Micronesia",
		currencies: ["USD"],
		languages: ["en"],
		numericCode: "583",
	},
	{
		name: "Moldova",
		topLevelDomain: [".md"],
		alpha2Code: "MD",
		alpha3Code: "MDA",
		callingCodes: ["373"],
		capital: "ChiÈ™inÄƒu",
		altSpellings: ["MD", "Republic of Moldova", "Republica Moldova"],
		relevance: "0",
		region: "Europe",
		subregion: "Eastern Europe",
		translations: {
			de: "Moldawie",
			es: "Moldavia",
			fr: "Moldavie",
			ja: "ãƒ¢ãƒ«ãƒ‰ãƒ�å…±å’Œå›½",
			it: "Moldavia",
		},
		population: 3555200,
		latlng: [47, 29],
		demonym: "Moldovan",
		area: 33846,
		gini: 33,
		timezones: ["UTC+02:00"],
		borders: ["ROU", "UKR"],
		nativeName: "Moldova",
		currencies: ["MDL"],
		languages: ["ro"],
		numericCode: "498",
	},
	{
		name: "Monaco",
		topLevelDomain: [".mc"],
		alpha2Code: "MC",
		alpha3Code: "MCO",
		callingCodes: ["377"],
		capital: "Monaco",
		altSpellings: ["MC", "Principality of Monaco", "PrincipautÃ© de Monaco"],
		relevance: "0",
		region: "Europe",
		subregion: "Western Europe",
		translations: {
			de: "Monaco",
			es: "MÃ³naco",
			fr: "Monaco",
			ja: "ãƒ¢ãƒŠã‚³",
			it: "Principato di Monaco",
		},
		population: 37800,
		latlng: [43.73333333, 7.4],
		demonym: "Monegasque",
		area: 2.02,
		gini: null,
		timezones: ["UTC+01:00"],
		borders: ["FRA"],
		nativeName: "Monaco",
		currencies: ["EUR"],
		languages: ["fr"],
		numericCode: "492",
	},
	{
		name: "Mongolia",
		topLevelDomain: [".mn"],
		alpha2Code: "MN",
		alpha3Code: "MNG",
		callingCodes: ["976"],
		capital: "Ulan Bator",
		altSpellings: ["MN"],
		relevance: "0",
		region: "Asia",
		subregion: "Eastern Asia",
		translations: {
			de: "Mongolei",
			es: "Mongolia",
			fr: "Mongolie",
			ja: "ãƒ¢ãƒ³ã‚´ãƒ«",
			it: "Mongolia",
		},
		population: 3031099,
		latlng: [46, 105],
		demonym: "Mongolian",
		area: 1564110,
		gini: 36.5,
		timezones: ["UTC+07:00", "UTC+08:00"],
		borders: ["CHN", "RUS"],
		nativeName: "ÐœÐ¾Ð½Ð³Ð¾Ð» ÑƒÐ»Ñ�",
		currencies: ["MNT"],
		languages: ["mn"],
		numericCode: "496",
	},
	{
		name: "Montenegro",
		topLevelDomain: [".me"],
		alpha2Code: "ME",
		alpha3Code: "MNE",
		callingCodes: ["382"],
		capital: "Podgorica",
		altSpellings: ["ME", "Crna Gora"],
		relevance: "0",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Montenegro",
			es: "Montenegro",
			fr: "MontÃ©nÃ©gro",
			ja: "ãƒ¢ãƒ³ãƒ†ãƒ�ã‚°ãƒ­",
			it: "Montenegro",
		},
		population: 621207,
		latlng: [42.5, 19.3],
		demonym: "Montenegrin",
		area: 13812,
		gini: 30,
		timezones: ["UTC+01:00"],
		borders: ["ALB", "BIH", "HRV", "KOS", "SRB"],
		nativeName: "Ð¦Ñ€Ð½Ð° Ð“Ð¾Ñ€Ð°",
		currencies: ["EUR"],
		languages: ["sr", "bs", "sq", "hr"],
		numericCode: "499",
	},
	{
		name: "Montserrat",
		topLevelDomain: [".ms"],
		alpha2Code: "MS",
		alpha3Code: "MSR",
		callingCodes: ["1664"],
		capital: "Plymouth",
		altSpellings: ["MS"],
		relevance: "0.5",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Montserrat",
			es: "Montserrat",
			fr: "Montserrat",
			ja: "ãƒ¢ãƒ³ãƒˆã‚»ãƒ©ãƒˆ",
			it: "Montserrat",
		},
		population: 4922,
		latlng: [16.75, -62.2],
		demonym: "Montserratian",
		area: 102,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Montserrat",
		currencies: ["XCD"],
		languages: ["en"],
		numericCode: "500",
	},
	{
		name: "Morocco",
		topLevelDomain: [".ma"],
		alpha2Code: "MA",
		alpha3Code: "MAR",
		callingCodes: ["212"],
		capital: "Rabat",
		altSpellings: ["MA", "Kingdom of Morocco", "Al-Mamlakah al-MaÄ¡ribiyah"],
		relevance: "0",
		region: "Africa",
		subregion: "Northern Africa",
		translations: {
			de: "Marokko",
			es: "Marruecos",
			fr: "Maroc",
			ja: "ãƒ¢ãƒ­ãƒƒã‚³",
			it: "Marocco",
		},
		population: 33337529,
		latlng: [32, -5],
		demonym: "Moroccan",
		area: 446550,
		gini: 40.9,
		timezones: ["UTC"],
		borders: ["DZA", "ESH", "ESP"],
		nativeName: "Ø§Ù„Ù…ØºØ±Ø¨",
		currencies: ["MAD"],
		languages: ["ar"],
		numericCode: "504",
	},
	{
		name: "Mozambique",
		topLevelDomain: [".mz"],
		alpha2Code: "MZ",
		alpha3Code: "MOZ",
		callingCodes: ["258"],
		capital: "Maputo",
		altSpellings: ["MZ", "Republic of Mozambique", "RepÃºblica de MoÃ§ambique"],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Mosambik",
			es: "Mozambique",
			fr: "Mozambique",
			ja: "ãƒ¢ã‚¶ãƒ³ãƒ“ãƒ¼ã‚¯",
			it: "Mozambico",
		},
		population: 25727911,
		latlng: [-18.25, 35],
		demonym: "Mozambican",
		area: 801590,
		gini: 45.7,
		timezones: ["UTC+02:00"],
		borders: ["MWI", "ZAF", "SWZ", "TZA", "ZMB", "ZWE"],
		nativeName: "MoÃ§ambique",
		currencies: ["MZN"],
		languages: ["pt"],
		numericCode: "508",
	},
	{
		name: "Myanmar",
		topLevelDomain: [".mm"],
		alpha2Code: "MM",
		alpha3Code: "MMR",
		callingCodes: ["95"],
		capital: "Naypyidaw",
		altSpellings: [
			"MM",
			"Burma",
			"Republic of the Union of Myanmar",
			"Pyidaunzu ThanmÄƒda MyÄƒma Nainngandaw",
		],
		relevance: "0",
		region: "Asia",
		subregion: "South-Eastern Asia",
		translations: {
			de: "Myanmar",
			es: "Myanmar",
			fr: "Myanmar",
			ja: "ãƒŸãƒ£ãƒ³ãƒžãƒ¼",
			it: "Birmania",
		},
		population: 53897000,
		latlng: [22, 98],
		demonym: "Burmese",
		area: 676578,
		gini: null,
		timezones: ["UTC+06:30"],
		borders: ["BGD", "CHN", "IND", "LAO", "THA"],
		nativeName: "Myanma",
		currencies: ["MMK"],
		languages: ["my"],
		numericCode: "104",
	},
	{
		name: "Namibia",
		topLevelDomain: [".na"],
		alpha2Code: "NA",
		alpha3Code: "NAM",
		callingCodes: ["264"],
		capital: "Windhoek",
		altSpellings: ["NA", "NamibiÃ«", "Republic of Namibia"],
		relevance: "0",
		region: "Africa",
		subregion: "Southern Africa",
		translations: {
			de: "Namibia",
			es: "Namibia",
			fr: "Namibie",
			ja: "ãƒŠãƒŸãƒ“ã‚¢",
			it: "Namibia",
		},
		population: 2280700,
		latlng: [-22, 17],
		demonym: "Namibian",
		area: 825615,
		gini: 63.9,
		timezones: ["UTC+01:00"],
		borders: ["AGO", "BWA", "ZAF", "ZMB"],
		nativeName: "Namibia",
		currencies: ["NAD", "ZAR"],
		languages: ["en", "af"],
		numericCode: "516",
	},
	{
		name: "Nauru",
		topLevelDomain: [".nr"],
		alpha2Code: "NR",
		alpha3Code: "NRU",
		callingCodes: ["674"],
		capital: "Yaren",
		altSpellings: ["NR", "Naoero", "Pleasant Island", "Republic of Nauru", "Ripublik Naoero"],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Micronesia",
		translations: {
			de: "Nauru",
			es: "Nauru",
			fr: "Nauru",
			ja: "ãƒŠã‚¦ãƒ«",
			it: "Nauru",
		},
		population: 10084,
		latlng: [-0.53333333, 166.91666666],
		demonym: "Nauruan",
		area: 21,
		gini: null,
		timezones: ["UTC+12:00"],
		borders: [],
		nativeName: "Nauru",
		currencies: ["AUD"],
		languages: ["en", "na"],
		numericCode: "520",
	},
	{
		name: "Nepal",
		topLevelDomain: [".np"],
		alpha2Code: "NP",
		alpha3Code: "NPL",
		callingCodes: ["977"],
		capital: "Kathmandu",
		altSpellings: [
			"NP",
			"Federal Democratic Republic of Nepal",
			"LoktÄ�ntrik Ganatantra NepÄ�l",
		],
		relevance: "0",
		region: "Asia",
		subregion: "Southern Asia",
		translations: {
			de: "NÃ©pal",
			es: "Nepal",
			fr: "NÃ©pal",
			ja: "ãƒ�ãƒ‘ãƒ¼ãƒ«",
			it: "Nepal",
		},
		population: 28037904,
		latlng: [28, 84],
		demonym: "Nepalese",
		area: 147181,
		gini: 32.8,
		timezones: ["UTC+05:45"],
		borders: ["CHN", "IND"],
		nativeName: "à¤¨à¥‡à¤ªà¤¾à¤²",
		currencies: ["NPR"],
		languages: ["ne"],
		numericCode: "524",
	},
	{
		name: "Netherlands",
		topLevelDomain: [".nl"],
		alpha2Code: "NL",
		alpha3Code: "NLD",
		callingCodes: ["31"],
		capital: "Amsterdam",
		altSpellings: ["NL", "Holland", "Nederland"],
		relevance: "1.5",
		region: "Europe",
		subregion: "Western Europe",
		translations: {
			de: "Niederlande",
			es: "PaÃ­ses Bajos",
			fr: "Pays-Bas",
			ja: "ã‚ªãƒ©ãƒ³ãƒ€",
			it: "Paesi Bassi",
		},
		population: 16916000,
		latlng: [52.5, 5.75],
		demonym: "Dutch",
		area: 41850,
		gini: 30.9,
		timezones: null,
		borders: ["BEL", "DEU"],
		nativeName: "Nederland",
		currencies: ["EUR"],
		languages: ["nl"],
		numericCode: "528",
	},
	{
		name: "New Caledonia",
		topLevelDomain: [".nc"],
		alpha2Code: "NC",
		alpha3Code: "NCL",
		callingCodes: ["687"],
		capital: "NoumÃ©a",
		altSpellings: ["NC"],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Melanesia",
		translations: {
			de: "Neukaledonien",
			es: "Nueva Caledonia",
			fr: "Nouvelle-CalÃ©donie",
			ja: "ãƒ‹ãƒ¥ãƒ¼ã‚«ãƒ¬ãƒ‰ãƒ‹ã‚¢",
			it: "Nuova Caledonia",
		},
		population: 268767,
		latlng: [-21.5, 165.5],
		demonym: "New Caledonian",
		area: 18575,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Nouvelle-CalÃ©donie",
		currencies: ["XPF"],
		languages: ["fr"],
		numericCode: "540",
	},
	{
		name: "New Zealand",
		topLevelDomain: [".nz"],
		alpha2Code: "NZ",
		alpha3Code: "NZL",
		callingCodes: ["64"],
		capital: "Wellington",
		altSpellings: ["NZ", "Aotearoa"],
		relevance: "1.0",
		region: "Oceania",
		subregion: "Australia and New Zealand",
		translations: {
			de: "Neuseeland",
			es: "Nueva Zelanda",
			fr: "Nouvelle-ZÃ©lande",
			ja: "ãƒ‹ãƒ¥ãƒ¼ã‚¸ãƒ¼ãƒ©ãƒ³ãƒ‰",
			it: "Nuova Zelanda",
		},
		population: 4609440,
		latlng: [-41, 174],
		demonym: "New Zealander",
		area: 270467,
		gini: 36.2,
		timezones: ["UTCâˆ’11:00", "UTCâˆ’10:00", "UTC+12:00", "UTC+12:45", "UTC+13:00"],
		borders: [],
		nativeName: "New Zealand",
		currencies: ["NZD"],
		languages: ["en", "mi"],
		numericCode: "554",
	},
	{
		name: "Nicaragua",
		topLevelDomain: [".ni"],
		alpha2Code: "NI",
		alpha3Code: "NIC",
		callingCodes: ["505"],
		capital: "Managua",
		altSpellings: ["NI", "Republic of Nicaragua", "RepÃºblica de Nicaragua"],
		relevance: "0",
		region: "Americas",
		subregion: "Central America",
		translations: {
			de: "Nicaragua",
			es: "Nicaragua",
			fr: "Nicaragua",
			ja: "ãƒ‹ã‚«ãƒ©ã‚°ã‚¢",
			it: "Nicaragua",
		},
		population: 6198154,
		latlng: [13, -85],
		demonym: "Nicaraguan",
		area: 130373,
		gini: 40.5,
		timezones: ["UTCâˆ’06:00"],
		borders: ["CRI", "HND"],
		nativeName: "Nicaragua",
		currencies: ["NIO"],
		languages: ["es"],
		numericCode: "558",
	},
	{
		name: "Niger",
		topLevelDomain: [".ne"],
		alpha2Code: "NE",
		alpha3Code: "NER",
		callingCodes: ["227"],
		capital: "Niamey",
		altSpellings: ["NE", "Nijar", "Republic of Niger", "RÃ©publique du Niger"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Niger",
			es: "NÃ­ger",
			fr: "Niger",
			ja: "ãƒ‹ã‚¸ã‚§ãƒ¼ãƒ«",
			it: "Niger",
		},
		population: 19899000,
		latlng: [16, 8],
		demonym: "Nigerien",
		area: 1267000,
		gini: 34.6,
		timezones: ["UTC+01:00"],
		borders: ["DZA", "BEN", "BFA", "TCD", "LBY", "MLI", "NGA"],
		nativeName: "Niger",
		currencies: ["XOF"],
		languages: ["fr"],
		numericCode: "562",
	},
	{
		name: "Nigeria",
		topLevelDomain: [".ng"],
		alpha2Code: "NG",
		alpha3Code: "NGA",
		callingCodes: ["234"],
		capital: "Abuja",
		altSpellings: ["NG", "Nijeriya", "NaÃ­jÃ­rÃ­Ã ", "Federal Republic of Nigeria"],
		relevance: "1.5",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Nigeria",
			es: "Nigeria",
			fr: "NigÃ©ria",
			ja: "ãƒŠã‚¤ã‚¸ã‚§ãƒªã‚¢",
			it: "Nigeria",
		},
		population: 182202000,
		latlng: [10, 8],
		demonym: "Nigerian",
		area: 923768,
		gini: 48.8,
		timezones: ["UTC+01:00"],
		borders: ["BEN", "CMR", "TCD", "NER"],
		nativeName: "Nigeria",
		currencies: ["NGN"],
		languages: ["en"],
		numericCode: "566",
	},
	{
		name: "Niue",
		topLevelDomain: [".nu"],
		alpha2Code: "NU",
		alpha3Code: "NIU",
		callingCodes: ["683"],
		capital: "Alofi",
		altSpellings: ["NU"],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Polynesia",
		translations: {
			de: "Niue",
			es: "Niue",
			fr: "Niue",
			ja: "ãƒ‹ã‚¦ã‚¨",
			it: "Niue",
		},
		population: 1613,
		latlng: [-19.03333333, -169.86666666],
		demonym: "Niuean",
		area: 260,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "NiuÄ“",
		currencies: ["NZD"],
		languages: ["en"],
		numericCode: "570",
	},
	{
		name: "Norfolk Island",
		topLevelDomain: [".nf"],
		alpha2Code: "NF",
		alpha3Code: "NFK",
		callingCodes: ["672"],
		capital: "Kingston",
		altSpellings: ["NF", "Territory of Norfolk Island", "Teratri of Norf'k Ailen"],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Australia and New Zealand",
		translations: {
			de: "Norfolkinsel",
			es: "Isla de Norfolk",
			fr: "ÃŽle de Norfolk",
			ja: "ãƒŽãƒ¼ãƒ•ã‚©ãƒ¼ã‚¯å³¶",
			it: "Isola Norfolk",
		},
		population: 2302,
		latlng: [-29.03333333, 167.95],
		demonym: "Norfolk Islander",
		area: 36,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Norfolk Island",
		currencies: ["AUD"],
		languages: ["en"],
		numericCode: "574",
	},
	{
		name: "North Korea",
		topLevelDomain: [".kp"],
		alpha2Code: "KP",
		alpha3Code: "PRK",
		callingCodes: ["850"],
		capital: "Pyongyang",
		altSpellings: [
			"KP",
			"Democratic People's Republic of Korea",
			"ì¡°ì„ ë¯¼ì£¼ì£¼ì�˜ì�¸ë¯¼ê³µí™”êµ­",
			"ChosÅ�n MinjujuÅ­i Inmin Konghwaguk",
		],
		relevance: "0",
		region: "Asia",
		subregion: "Eastern Asia",
		translations: {
			de: "Nordkorea",
			es: "Corea del Norte",
			fr: "CorÃ©e du Nord",
			ja: "æœ�é®®æ°‘ä¸»ä¸»ç¾©äººæ°‘å…±å’Œå›½",
			it: "Corea del Nord",
		},
		population: 25155000,
		latlng: [40, 127],
		demonym: "North Korean",
		area: 120538,
		gini: null,
		timezones: ["UTC+09:00"],
		borders: ["CHN", "KOR", "RUS"],
		nativeName: "ë¶�í•œ",
		currencies: ["KPW"],
		languages: ["ko"],
		numericCode: "408",
	},
	{
		name: "Northern Mariana Islands",
		topLevelDomain: [".mp"],
		alpha2Code: "MP",
		alpha3Code: "MNP",
		callingCodes: ["1670"],
		capital: "Saipan",
		altSpellings: [
			"MP",
			"Commonwealth of the Northern Mariana Islands",
			"Sankattan Siha Na Islas MariÃ¥nas",
		],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Micronesia",
		translations: {
			de: "NÃ¶rdliche Marianen",
			es: "Islas Marianas del Norte",
			fr: "ÃŽles Mariannes du Nord",
			ja: "åŒ—ãƒžãƒªã‚¢ãƒŠè«¸å³¶",
			it: "Isole Marianne Settentrionali",
		},
		population: 53883,
		latlng: [15.2, 145.75],
		demonym: "American",
		area: 464,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Northern Mariana Islands",
		currencies: ["USD"],
		languages: ["en", "ch"],
		numericCode: "580",
	},
	{
		name: "Norway",
		topLevelDomain: [".no"],
		alpha2Code: "NO",
		alpha3Code: "NOR",
		callingCodes: ["47"],
		capital: "Oslo",
		altSpellings: [
			"NO",
			"Norge",
			"Noreg",
			"Kingdom of Norway",
			"Kongeriket Norge",
			"Kongeriket Noreg",
		],
		relevance: "1.5",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Norwegen",
			es: "Noruega",
			fr: "NorvÃ¨ge",
			ja: "ãƒŽãƒ«ã‚¦ã‚§ãƒ¼",
			it: "Norvegia",
		},
		population: 5176998,
		latlng: [62, 10],
		demonym: "Norwegian",
		area: 323802,
		gini: 25.8,
		timezones: ["UTC+01:00"],
		borders: ["FIN", "SWE", "RUS"],
		nativeName: "Norge",
		currencies: ["NOK"],
		languages: ["no", "nb", "nn"],
		numericCode: "578",
	},
	{
		name: "Oman",
		topLevelDomain: [".om"],
		alpha2Code: "OM",
		alpha3Code: "OMN",
		callingCodes: ["968"],
		capital: "Muscat",
		altSpellings: ["OM", "Sultanate of Oman", "Salá¹­anat Ê»UmÄ�n"],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Oman",
			es: "OmÃ¡n",
			fr: "Oman",
			ja: "ã‚ªãƒžãƒ¼ãƒ³",
			it: "oman",
		},
		population: 4185440,
		latlng: [21, 57],
		demonym: "Omani",
		area: 309500,
		gini: null,
		timezones: ["UTC+04:00"],
		borders: ["SAU", "ARE", "YEM"],
		nativeName: "Ø¹Ù…Ø§Ù†",
		currencies: ["OMR"],
		languages: ["ar"],
		numericCode: "512",
	},
	{
		name: "Pakistan",
		topLevelDomain: [".pk"],
		alpha2Code: "PK",
		alpha3Code: "PAK",
		callingCodes: ["92"],
		capital: "Islamabad",
		altSpellings: [
			"PK",
			"PÄ�kistÄ�n",
			"Islamic Republic of Pakistan",
			"IslÄ�mÄ« JumhÅ«riya'eh PÄ�kistÄ�n",
		],
		relevance: "2",
		region: "Asia",
		subregion: "Southern Asia",
		translations: {
			de: "Pakistan",
			es: "PakistÃ¡n",
			fr: "Pakistan",
			ja: "ãƒ‘ã‚­ã‚¹ã‚¿ãƒ³",
			it: "Pakistan",
		},
		population: 190668000,
		latlng: [30, 70],
		demonym: "Pakistani",
		area: 881912,
		gini: 30,
		timezones: ["UTC+05:00"],
		borders: ["AFG", "CHN", "IND", "IRN"],
		nativeName: "Pakistan",
		currencies: ["PKR"],
		languages: ["en", "ur"],
		numericCode: "586",
	},
	{
		name: "Palau",
		topLevelDomain: [".pw"],
		alpha2Code: "PW",
		alpha3Code: "PLW",
		callingCodes: ["680"],
		capital: "Ngerulmud",
		altSpellings: ["PW", "Republic of Palau", "Beluu er a Belau"],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Micronesia",
		translations: {
			de: "Palau",
			es: "Palau",
			fr: "Palaos",
			ja: "ãƒ‘ãƒ©ã‚ª",
			it: "Palau",
		},
		population: 20901,
		latlng: [7.5, 134.5],
		demonym: "Palauan",
		area: 459,
		gini: null,
		timezones: ["UTC+09:00"],
		borders: [],
		nativeName: "Palau",
		currencies: ["USD"],
		languages: ["en"],
		numericCode: "585",
	},
	{
		name: "Palestine",
		topLevelDomain: [".ps"],
		alpha2Code: "PS",
		alpha3Code: "PSE",
		callingCodes: ["970"],
		capital: "Ramallah",
		altSpellings: ["PS", "State of Palestine", "Dawlat Filasá¹­in"],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "PalÃ¤stina",
			es: "Palestina",
			fr: "Palestine",
			ja: "ãƒ‘ãƒ¬ã‚¹ãƒ�ãƒŠ",
			it: "Palestina",
		},
		population: 4682467,
		latlng: [31.9, 35.2],
		demonym: "Palestinian",
		area: null,
		gini: 35.5,
		timezones: null,
		borders: ["ISR", "EGY", "JOR"],
		nativeName: "Ù�Ù„Ø³Ø·ÙŠÙ†",
		currencies: ["ILS"],
		languages: ["ar"],
		numericCode: "275",
	},
	{
		name: "Panama",
		topLevelDomain: [".pa"],
		alpha2Code: "PA",
		alpha3Code: "PAN",
		callingCodes: ["507"],
		capital: "Panama City",
		altSpellings: ["PA", "Republic of Panama", "RepÃºblica de PanamÃ¡"],
		relevance: "0",
		region: "Americas",
		subregion: "Central America",
		translations: {
			de: "Panama",
			es: "PanamÃ¡",
			fr: "Panama",
			ja: "ãƒ‘ãƒŠãƒž",
			it: "Panama",
		},
		population: 3764166,
		latlng: [9, -80],
		demonym: "Panamanian",
		area: 75417,
		gini: 51.9,
		timezones: ["UTCâˆ’05:00"],
		borders: ["COL", "CRI"],
		nativeName: "PanamÃ¡",
		currencies: ["PAB", "USD"],
		languages: ["es"],
		numericCode: "591",
	},
	{
		name: "Papua New Guinea",
		topLevelDomain: [".pg"],
		alpha2Code: "PG",
		alpha3Code: "PNG",
		callingCodes: ["675"],
		capital: "Port Moresby",
		altSpellings: [
			"PG",
			"Independent State of Papua New Guinea",
			"Independen Stet bilong Papua Niugini",
		],
		relevance: "0",
		region: "Oceania",
		subregion: "Melanesia",
		translations: {
			de: "Papua-Neuguinea",
			es: "PapÃºa Nueva Guinea",
			fr: "Papouasie-Nouvelle-GuinÃ©e",
			ja: "ãƒ‘ãƒ—ã‚¢ãƒ‹ãƒ¥ãƒ¼ã‚®ãƒ‹ã‚¢",
			it: "Papua Nuova Guinea",
		},
		population: 7398500,
		latlng: [-6, 147],
		demonym: "Papua New Guinean",
		area: 462840,
		gini: 50.9,
		timezones: ["UTC+10:00"],
		borders: ["IDN"],
		nativeName: "Papua Niugini",
		currencies: ["PGK"],
		languages: ["en"],
		numericCode: "598",
	},
	{
		name: "Paraguay",
		topLevelDomain: [".py"],
		alpha2Code: "PY",
		alpha3Code: "PRY",
		callingCodes: ["595"],
		capital: "AsunciÃ³n",
		altSpellings: ["PY", "Republic of Paraguay", "RepÃºblica del Paraguay", "TetÃ£ ParaguÃ¡i"],
		relevance: "0",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "Paraguay",
			es: "Paraguay",
			fr: "Paraguay",
			ja: "ãƒ‘ãƒ©ã‚°ã‚¢ã‚¤",
			it: "Paraguay",
		},
		population: 7003406,
		latlng: [-23, -58],
		demonym: "Paraguayan",
		area: 406752,
		gini: 52.4,
		timezones: ["UTCâˆ’04:00"],
		borders: ["ARG", "BOL", "BRA"],
		nativeName: "Paraguay",
		currencies: ["PYG"],
		languages: ["es", "gn"],
		numericCode: "600",
	},
	{
		name: "Peru",
		topLevelDomain: [".pe"],
		alpha2Code: "PE",
		alpha3Code: "PER",
		callingCodes: ["51"],
		capital: "Lima",
		altSpellings: ["PE", "Republic of Peru", " RepÃºblica del PerÃº"],
		relevance: "0",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "Peru",
			es: "PerÃº",
			fr: "PÃ©rou",
			ja: "ãƒšãƒ«ãƒ¼",
			it: "PerÃ¹",
		},
		population: 31151643,
		latlng: [-10, -76],
		demonym: "Peruvian",
		area: 1285216,
		gini: 48.1,
		timezones: ["UTCâˆ’05:00"],
		borders: ["BOL", "BRA", "CHL", "COL", "ECU"],
		nativeName: "PerÃº",
		currencies: ["PEN"],
		languages: ["es"],
		numericCode: "604",
	},
	{
		name: "Philippines",
		topLevelDomain: [".ph"],
		alpha2Code: "PH",
		alpha3Code: "PHL",
		callingCodes: ["63"],
		capital: "Manila",
		altSpellings: ["PH", "Republic of the Philippines", "RepÃºblika ng Pilipinas"],
		relevance: "1.5",
		region: "Asia",
		subregion: "South-Eastern Asia",
		translations: {
			de: "Philippinen",
			es: "Filipinas",
			fr: "Philippines",
			ja: "ãƒ•ã‚£ãƒªãƒ”ãƒ³",
			it: "Filippine",
		},
		population: 101920000,
		latlng: [13, 122],
		demonym: "Filipino",
		area: 342353,
		gini: 43,
		timezones: ["UTC+08:00"],
		borders: [],
		nativeName: "Pilipinas",
		currencies: ["PHP"],
		languages: ["en"],
		numericCode: "608",
	},
	{
		name: "Pitcairn Islands",
		topLevelDomain: [".pn"],
		alpha2Code: "PN",
		alpha3Code: "PCN",
		callingCodes: ["64"],
		capital: "Adamstown",
		altSpellings: ["PN", "Pitcairn Henderson Ducie and Oeno Islands"],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Polynesia",
		translations: {
			de: "Pitcairn",
			es: "Islas Pitcairn",
			fr: "ÃŽles Pitcairn",
			ja: "ãƒ”ãƒˆã‚±ã‚¢ãƒ³",
			it: "Isole Pitcairn",
		},
		population: 56,
		latlng: [-25.06666666, -130.1],
		demonym: "Pitcairn Islander",
		area: 47,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Pitcairn Islands",
		currencies: ["NZD"],
		languages: ["en"],
		numericCode: "612",
	},
	{
		name: "Poland",
		topLevelDomain: [".pl"],
		alpha2Code: "PL",
		alpha3Code: "POL",
		callingCodes: ["48"],
		capital: "Warsaw",
		altSpellings: ["PL", "Republic of Poland", "Rzeczpospolita Polska"],
		relevance: "1.25",
		region: "Europe",
		subregion: "Eastern Europe",
		translations: {
			de: "Polen",
			es: "Polonia",
			fr: "Pologne",
			ja: "ãƒ�ãƒ¼ãƒ©ãƒ³ãƒ‰",
			it: "Polonia",
		},
		population: 38484000,
		latlng: [52, 20],
		demonym: "Polish",
		area: 312679,
		gini: 34.1,
		timezones: ["UTC+01:00"],
		borders: ["BLR", "CZE", "DEU", "LTU", "RUS", "SVK", "UKR"],
		nativeName: "Polska",
		currencies: ["PLN"],
		languages: ["pl"],
		numericCode: "616",
	},
	{
		name: "Portugal",
		topLevelDomain: [".pt"],
		alpha2Code: "PT",
		alpha3Code: "PRT",
		callingCodes: ["351"],
		capital: "Lisbon",
		altSpellings: ["PT", "Portuguesa", "Portuguese Republic", "RepÃºblica Portuguesa"],
		relevance: "1.5",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Portugal",
			es: "Portugal",
			fr: "Portugal",
			ja: "ãƒ�ãƒ«ãƒˆã‚¬ãƒ«",
			it: "Portogallo",
		},
		population: 10374822,
		latlng: [39.5, -8],
		demonym: "Portuguese",
		area: 92090,
		gini: 38.5,
		timezones: ["UTCâˆ’01:00", "UTC"],
		borders: ["ESP"],
		nativeName: "Portugal",
		currencies: ["EUR"],
		languages: ["pt"],
		numericCode: "620",
	},
	{
		name: "Puerto Rico",
		topLevelDomain: [".pr"],
		alpha2Code: "PR",
		alpha3Code: "PRI",
		callingCodes: ["1787", "1939"],
		capital: "San Juan",
		altSpellings: ["PR", "Commonwealth of Puerto Rico", "Estado Libre Asociado de Puerto Rico"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Puerto Rico",
			es: "Puerto Rico",
			fr: "Porto Rico",
			ja: "ãƒ—ã‚¨ãƒ«ãƒˆãƒªã‚³",
			it: "Porto Rico",
		},
		population: 3548397,
		latlng: [18.25, -66.5],
		demonym: "Puerto Rican",
		area: 8870,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Puerto Rico",
		currencies: ["USD"],
		languages: ["es", "en"],
		numericCode: "630",
	},
	{
		name: "Qatar",
		topLevelDomain: [".qa"],
		alpha2Code: "QA",
		alpha3Code: "QAT",
		callingCodes: ["974"],
		capital: "Doha",
		altSpellings: ["QA", "State of Qatar", "Dawlat Qaá¹­ar"],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Katar",
			es: "Catar",
			fr: "Qatar",
			ja: "ã‚«ã‚¿ãƒ¼ãƒ«",
			it: "Qatar",
		},
		population: 2120129,
		latlng: [25.5, 51.25],
		demonym: "Qatari",
		area: 11586,
		gini: 41.1,
		timezones: ["UTC+03:00"],
		borders: ["SAU"],
		nativeName: "Ù‚Ø·Ø±",
		currencies: ["QAR"],
		languages: ["ar"],
		numericCode: "634",
	},
	{
		name: "Republic of Kosovo",
		topLevelDomain: [""],
		alpha2Code: "XK",
		alpha3Code: "KOS",
		callingCodes: ["383"],
		capital: "Pristina",
		altSpellings: ["XK", "Ð ÐµÐ¿ÑƒÐ±Ð»Ð¸ÐºÐ° ÐšÐ¾Ñ�Ð¾Ð²Ð¾"],
		relevance: "0",
		region: "Europe",
		subregion: "Eastern Europe",
		translations: {
			de: null,
			es: "Kosovo",
			fr: null,
			ja: null,
			it: null,
		},
		population: 1733842,
		latlng: [42.666667, 21.166667],
		demonym: "Kosovar",
		area: 10908,
		gini: null,
		timezones: null,
		borders: ["ALB", "MKD", "MNE", "SRB"],
		nativeName: "Republika e KosovÃ«s",
		currencies: ["EUR"],
		languages: ["sq", "sr"],
		numericCode: "780",
	},
	{
		name: "RÃ©union",
		topLevelDomain: [".re"],
		alpha2Code: "RE",
		alpha3Code: "REU",
		callingCodes: ["262"],
		capital: "Saint-Denis",
		altSpellings: ["RE", "Reunion"],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "RÃ©union",
			es: "ReuniÃ³n",
			fr: "RÃ©union",
			ja: "ãƒ¬ãƒ¦ãƒ‹ã‚ªãƒ³",
			it: "Riunione",
		},
		population: 840974,
		latlng: [-21.15, 55.5],
		demonym: "French",
		area: null,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "La RÃ©union",
		currencies: ["EUR"],
		languages: ["fr"],
		numericCode: "638",
	},
	{
		name: "Romania",
		topLevelDomain: [".ro"],
		alpha2Code: "RO",
		alpha3Code: "ROU",
		callingCodes: ["40"],
		capital: "Bucharest",
		altSpellings: ["RO", "Rumania", "Roumania", "RomÃ¢nia"],
		relevance: "0",
		region: "Europe",
		subregion: "Eastern Europe",
		translations: {
			de: "RumÃ¤nien",
			es: "Rumania",
			fr: "Roumanie",
			ja: "ãƒ«ãƒ¼ãƒžãƒ‹ã‚¢",
			it: "Romania",
		},
		population: 19942642,
		latlng: [46, 25],
		demonym: "Romanian",
		area: 238391,
		gini: 30,
		timezones: ["UTC+02:00"],
		borders: ["BGR", "HUN", "MDA", "SRB", "UKR"],
		nativeName: "RomÃ¢nia",
		currencies: ["RON"],
		languages: ["ro"],
		numericCode: "642",
	},
	{
		name: "Russia",
		topLevelDomain: [".ru"],
		alpha2Code: "RU",
		alpha3Code: "RUS",
		callingCodes: ["7"],
		capital: "Moscow",
		altSpellings: [
			"RU",
			"Rossiya",
			"Russian Federation",
			"Ð Ð¾Ñ�Ñ�Ð¸Ð¹Ñ�ÐºÐ°Ñ� Ð¤ÐµÐ´ÐµÑ€Ð°Ñ†Ð¸Ñ�",
			"Rossiyskaya Federatsiya",
		],
		relevance: "2.5",
		region: "Europe",
		subregion: "Eastern Europe",
		translations: {
			de: "Russland",
			es: "Rusia",
			fr: "Russie",
			ja: "ãƒ­ã‚·ã‚¢é€£é‚¦",
			it: "Russia",
		},
		population: 146556330,
		latlng: [60, 100],
		demonym: "Russian",
		area: 17124442,
		gini: 40.1,
		timezones: [
			"UTC+03:00",
			"UTC+04:00",
			"UTC+06:00",
			"UTC+07:00",
			"UTC+08:00",
			"UTC+09:00",
			"UTC+10:00",
			"UTC+11:00",
			"UTC+12:00",
		],
		borders: [
			"AZE",
			"BLR",
			"CHN",
			"EST",
			"FIN",
			"GEO",
			"KAZ",
			"PRK",
			"LVA",
			"LTU",
			"MNG",
			"NOR",
			"POL",
			"UKR",
		],
		nativeName: "Ð Ð¾Ñ�Ñ�Ð¸Ñ�",
		currencies: ["RUB"],
		languages: ["ru"],
		numericCode: "643",
	},
	{
		name: "Rwanda",
		topLevelDomain: [".rw"],
		alpha2Code: "RW",
		alpha3Code: "RWA",
		callingCodes: ["250"],
		capital: "Kigali",
		altSpellings: [
			"RW",
			"Republic of Rwanda",
			"Repubulika y'u Rwanda",
			"RÃ©publique du Rwanda",
		],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Ruanda",
			es: "Ruanda",
			fr: "Rwanda",
			ja: "ãƒ«ãƒ¯ãƒ³ãƒ€",
			it: "Ruanda",
		},
		population: 11262564,
		latlng: [-2, 30],
		demonym: "Rwandan",
		area: 26338,
		gini: 50.8,
		timezones: ["UTC+02:00"],
		borders: ["BDI", "COD", "TZA", "UGA"],
		nativeName: "Rwanda",
		currencies: ["RWF"],
		languages: ["rw", "en", "fr"],
		numericCode: "646",
	},
	{
		name: "Saint BarthÃ©lemy",
		topLevelDomain: [".bl"],
		alpha2Code: "BL",
		alpha3Code: "BLM",
		callingCodes: ["590"],
		capital: "Gustavia",
		altSpellings: [
			"BL",
			"St. Barthelemy",
			"Collectivity of Saint BarthÃ©lemy",
			"CollectivitÃ© de Saint-BarthÃ©lemy",
		],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Saint-BarthÃ©lemy",
			es: "San BartolomÃ©",
			fr: "Saint-BarthÃ©lemy",
			ja: "ã‚µãƒ³ãƒ»ãƒ�ãƒ«ãƒ†ãƒ«ãƒŸãƒ¼",
			it: "Antille Francesi",
		},
		population: 9269,
		latlng: [18.5, -63.41666666],
		demonym: "Saint BarthÃ©lemy Islander",
		area: 21,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Saint-BarthÃ©lemy",
		currencies: ["EUR"],
		languages: ["fr"],
		numericCode: "652",
	},
	{
		name: "Saint Helena",
		topLevelDomain: [".sh"],
		alpha2Code: "SH",
		alpha3Code: "SHN",
		callingCodes: ["290"],
		capital: "Jamestown",
		altSpellings: ["SH"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Sankt Helena",
			es: "Santa Helena",
			fr: "Sainte-HÃ©lÃ¨ne",
			ja: "ã‚»ãƒ³ãƒˆãƒ˜ãƒ¬ãƒŠãƒ»ã‚¢ã‚»ãƒ³ã‚·ãƒ§ãƒ³ã�Šã‚ˆã�³ãƒˆãƒªã‚¹ã‚¿ãƒ³ãƒ€ã‚¯ãƒ¼ãƒ‹ãƒ£",
			it: "Sant'Elena",
		},
		population: 4255,
		latlng: [-15.95, -5.7],
		demonym: "Saint Helenian",
		area: null,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Saint Helena",
		currencies: ["SHP"],
		languages: ["en"],
		numericCode: "654",
	},
	{
		name: "Saint Kitts and Nevis",
		topLevelDomain: [".kn"],
		alpha2Code: "KN",
		alpha3Code: "KNA",
		callingCodes: ["1869"],
		capital: "Basseterre",
		altSpellings: ["KN", "Federation of Saint Christopher and Nevis"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "St. Kitts und Nevis",
			es: "San CristÃ³bal y Nieves",
			fr: "Saint-Christophe-et-NiÃ©vÃ¨s",
			ja: "ã‚»ãƒ³ãƒˆã‚¯ãƒªã‚¹ãƒˆãƒ•ã‚¡ãƒ¼ãƒ»ãƒ�ã‚¤ãƒ“ã‚¹",
			it: "Saint Kitts e Nevis",
		},
		population: 56000,
		latlng: [17.33333333, -62.75],
		demonym: "Kittian and Nevisian",
		area: 261,
		gini: null,
		timezones: ["UTCâˆ’04:00"],
		borders: [],
		nativeName: "Saint Kitts and Nevis",
		currencies: ["XCD"],
		languages: ["en"],
		numericCode: "659",
	},
	{
		name: "Saint Lucia",
		topLevelDomain: [".lc"],
		alpha2Code: "LC",
		alpha3Code: "LCA",
		callingCodes: ["1758"],
		capital: "Castries",
		altSpellings: ["LC"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Saint Lucia",
			es: "Santa LucÃ­a",
			fr: "Saint-Lucie",
			ja: "ã‚»ãƒ³ãƒˆãƒ«ã‚·ã‚¢",
			it: "Santa Lucia",
		},
		population: 185000,
		latlng: [13.88333333, -60.96666666],
		demonym: "Saint Lucian",
		area: 616,
		gini: 42.6,
		timezones: ["UTCâˆ’04:00"],
		borders: [],
		nativeName: "Saint Lucia",
		currencies: ["XCD"],
		languages: ["en"],
		numericCode: "662",
	},
	{
		name: "Saint Martin",
		topLevelDomain: [".mf", ".fr", ".gp"],
		alpha2Code: "MF",
		alpha3Code: "MAF",
		callingCodes: ["590"],
		capital: "Marigot",
		altSpellings: ["MF", "Collectivity of Saint Martin", "CollectivitÃ© de Saint-Martin"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Saint Martin",
			es: "Saint Martin",
			fr: "Saint-Martin",
			ja: "ã‚µãƒ³ãƒ»ãƒžãƒ«ã‚¿ãƒ³ï¼ˆãƒ•ãƒ©ãƒ³ã‚¹é ˜ï¼‰",
			it: "Saint Martin",
		},
		population: 36979,
		latlng: [18.08333333, -63.95],
		demonym: "Saint Martin Islander",
		area: 53,
		gini: null,
		timezones: null,
		borders: ["SXM", "NLD"],
		nativeName: "Saint-Martin",
		currencies: ["EUR"],
		languages: ["en", "fr", "nl"],
		numericCode: "663",
	},
	{
		name: "Saint Pierre and Miquelon",
		topLevelDomain: [".pm"],
		alpha2Code: "PM",
		alpha3Code: "SPM",
		callingCodes: ["508"],
		capital: "Saint-Pierre",
		altSpellings: ["PM", "CollectivitÃ© territoriale de Saint-Pierre-et-Miquelon"],
		relevance: "0",
		region: "Americas",
		subregion: "Northern America",
		translations: {
			de: "Saint-Pierre und Miquelon",
			es: "San Pedro y MiquelÃ³n",
			fr: "Saint-Pierre-et-Miquelon",
			ja: "ã‚µãƒ³ãƒ”ã‚¨ãƒ¼ãƒ«å³¶ãƒ»ãƒŸã‚¯ãƒ­ãƒ³å³¶",
			it: "Saint-Pierre e Miquelon",
		},
		population: 6069,
		latlng: [46.83333333, -56.33333333],
		demonym: "French",
		area: 242,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Saint-Pierre-et-Miquelon",
		currencies: ["EUR"],
		languages: ["fr"],
		numericCode: "666",
	},
	{
		name: "Saint Vincent and the Grenadines",
		topLevelDomain: [".vc"],
		alpha2Code: "VC",
		alpha3Code: "VCT",
		callingCodes: ["1784"],
		capital: "Kingstown",
		altSpellings: ["VC"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Saint Vincent und die Grenadinen",
			es: "San Vicente y Granadinas",
			fr: "Saint-Vincent-et-les-Grenadines",
			ja: "ã‚»ãƒ³ãƒˆãƒ“ãƒ³ã‚»ãƒ³ãƒˆã�Šã‚ˆã�³ã‚°ãƒ¬ãƒŠãƒ‡ã‚£ãƒ¼ãƒ³è«¸å³¶",
			it: "Saint Vincent e Grenadine",
		},
		population: 109991,
		latlng: [13.25, -61.2],
		demonym: "Saint Vincentian",
		area: 389,
		gini: null,
		timezones: ["UTCâˆ’04:00"],
		borders: [],
		nativeName: "Saint Vincent and the Grenadines",
		currencies: ["XCD"],
		languages: ["en"],
		numericCode: "670",
	},
	{
		name: "Samoa",
		topLevelDomain: [".ws"],
		alpha2Code: "WS",
		alpha3Code: "WSM",
		callingCodes: ["685"],
		capital: "Apia",
		altSpellings: ["WS", "Independent State of Samoa", "Malo SaÊ»oloto TutoÊ»atasi o SÄ�moa"],
		relevance: "0",
		region: "Oceania",
		subregion: "Polynesia",
		translations: {
			de: "Samoa",
			es: "Samoa",
			fr: "Samoa",
			ja: "ã‚µãƒ¢ã‚¢",
			it: "Samoa",
		},
		population: 187820,
		latlng: [-13.58333333, -172.33333333],
		demonym: "Samoan",
		area: 2842,
		gini: null,
		timezones: ["UTC+13:00"],
		borders: [],
		nativeName: "Samoa",
		currencies: ["WST"],
		languages: ["sm", "en"],
		numericCode: "882",
	},
	{
		name: "San Marino",
		topLevelDomain: [".sm"],
		alpha2Code: "SM",
		alpha3Code: "SMR",
		callingCodes: ["378"],
		capital: "City of San Marino",
		altSpellings: ["SM", "Republic of San Marino", "Repubblica di San Marino"],
		relevance: "0",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "San Marino",
			es: "San Marino",
			fr: "Saint-Marin",
			ja: "ã‚µãƒ³ãƒžãƒªãƒŽ",
			it: "San Marino",
		},
		population: 32831,
		latlng: [43.76666666, 12.41666666],
		demonym: "Sammarinese",
		area: 61,
		gini: null,
		timezones: ["UTC+01:00"],
		borders: ["ITA"],
		nativeName: "San Marino",
		currencies: ["EUR"],
		languages: ["it"],
		numericCode: "674",
	},
	{
		name: "SÃ£o TomÃ© and PrÃ­ncipe",
		topLevelDomain: [".st"],
		alpha2Code: "ST",
		alpha3Code: "STP",
		callingCodes: ["239"],
		capital: "SÃ£o TomÃ©",
		altSpellings: [
			"ST",
			"Democratic Republic of SÃ£o TomÃ© and PrÃ­ncipe",
			"RepÃºblica DemocrÃ¡tica de SÃ£o TomÃ© e PrÃ­ncipe",
		],
		relevance: "0",
		region: "Africa",
		subregion: "Middle Africa",
		translations: {
			de: "SÃ£o TomÃ© und PrÃ­ncipe",
			es: "Santo TomÃ© y PrÃ­ncipe",
			fr: "Sao TomÃ©-et-Principe",
			ja: "ã‚µãƒ³ãƒˆãƒ¡ãƒ»ãƒ—ãƒªãƒ³ã‚·ãƒš",
			it: "SÃ£o TomÃ© e PrÃ­ncipe",
		},
		population: 187356,
		latlng: [1, 7],
		demonym: "Sao Tomean",
		area: 964,
		gini: 50.8,
		timezones: ["UTC"],
		borders: [],
		nativeName: "SÃ£o TomÃ© e PrÃ­ncipe",
		currencies: ["STD"],
		languages: ["pt"],
		numericCode: "678",
	},
	{
		name: "Saudi Arabia",
		topLevelDomain: [".sa"],
		alpha2Code: "SA",
		alpha3Code: "SAU",
		callingCodes: ["966"],
		capital: "Riyadh",
		altSpellings: [
			"SA",
			"Kingdom of Saudi Arabia",
			"Al-Mamlakah al-â€˜Arabiyyah as-Suâ€˜Å«diyyah",
		],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Saudi-Arabien",
			es: "Arabia SaudÃ­",
			fr: "Arabie Saoudite",
			ja: "ã‚µã‚¦ã‚¸ã‚¢ãƒ©ãƒ“ã‚¢",
			it: "Arabia Saudita",
		},
		population: 31521418,
		latlng: [25, 45],
		demonym: "Saudi Arabian",
		area: 2149690,
		gini: null,
		timezones: ["UTC+03:00"],
		borders: ["IRQ", "JOR", "KWT", "OMN", "QAT", "ARE", "YEM"],
		nativeName: "Ø§Ù„Ø¹Ø±Ø¨ÙŠØ© Ø§Ù„Ø³Ø¹ÙˆØ¯ÙŠØ©",
		currencies: ["SAR"],
		languages: ["ar"],
		numericCode: "682",
	},
	{
		name: "Senegal",
		topLevelDomain: [".sn"],
		alpha2Code: "SN",
		alpha3Code: "SEN",
		callingCodes: ["221"],
		capital: "Dakar",
		altSpellings: ["SN", "Republic of Senegal", "RÃ©publique du SÃ©nÃ©gal"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Senegal",
			es: "Senegal",
			fr: "SÃ©nÃ©gal",
			ja: "ã‚»ãƒ�ã‚¬ãƒ«",
			it: "Senegal",
		},
		population: 13508715,
		latlng: [14, -14],
		demonym: "Senegalese",
		area: 196722,
		gini: 39.2,
		timezones: ["UTC"],
		borders: ["GMB", "GIN", "GNB", "MLI", "MRT"],
		nativeName: "SÃ©nÃ©gal",
		currencies: ["XOF"],
		languages: ["fr"],
		numericCode: "686",
	},
	{
		name: "Serbia",
		topLevelDomain: [".rs"],
		alpha2Code: "RS",
		alpha3Code: "SRB",
		callingCodes: ["381"],
		capital: "Belgrade",
		altSpellings: [
			"RS",
			"Srbija",
			"Republic of Serbia",
			"Ð ÐµÐ¿ÑƒÐ±Ð»Ð¸ÐºÐ° Ð¡Ñ€Ð±Ð¸Ñ˜Ð°",
			"Republika Srbija",
		],
		relevance: "0",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Serbien",
			es: "Serbia",
			fr: "Serbie",
			ja: "ã‚»ãƒ«ãƒ“ã‚¢",
			it: "Serbia",
		},
		population: 7114393,
		latlng: [44, 21],
		demonym: "Serbian",
		area: 88361,
		gini: 27.8,
		timezones: ["UTC+01:00"],
		borders: ["BIH", "BGR", "HRV", "HUN", "KOS", "MKD", "MNE", "ROU"],
		nativeName: "Ð¡Ñ€Ð±Ð¸Ñ˜Ð°",
		currencies: ["RSD"],
		languages: ["sr"],
		numericCode: "688",
	},
	{
		name: "Seychelles",
		topLevelDomain: [".sc"],
		alpha2Code: "SC",
		alpha3Code: "SYC",
		callingCodes: ["248"],
		capital: "Victoria",
		altSpellings: [
			"SC",
			"Republic of Seychelles",
			"Repiblik Sesel",
			"RÃ©publique des Seychelles",
		],
		relevance: "0.5",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Seychellen",
			es: "Seychelles",
			fr: "Seychelles",
			ja: "ã‚»ãƒ¼ã‚·ã‚§ãƒ«",
			it: "Seychelles",
		},
		population: 89949,
		latlng: [-4.58333333, 55.66666666],
		demonym: "Seychellois",
		area: 452,
		gini: 65.8,
		timezones: ["UTC+04:00"],
		borders: [],
		nativeName: "Seychelles",
		currencies: ["SCR"],
		languages: ["fr", "en"],
		numericCode: "690",
	},
	{
		name: "Sierra Leone",
		topLevelDomain: [".sl"],
		alpha2Code: "SL",
		alpha3Code: "SLE",
		callingCodes: ["232"],
		capital: "Freetown",
		altSpellings: ["SL", "Republic of Sierra Leone"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Sierra Leone",
			es: "Sierra Leone",
			fr: "Sierra Leone",
			ja: "ã‚·ã‚¨ãƒ©ãƒ¬ã‚ªãƒ�",
			it: "Sierra Leone",
		},
		population: 6453000,
		latlng: [8.5, -11.5],
		demonym: "Sierra Leonean",
		area: 71740,
		gini: 42.5,
		timezones: ["UTC"],
		borders: ["GIN", "LBR"],
		nativeName: "Sierra Leone",
		currencies: ["SLL"],
		languages: ["en"],
		numericCode: "694",
	},
	{
		name: "Singapore",
		topLevelDomain: [".sg"],
		alpha2Code: "SG",
		alpha3Code: "SGP",
		callingCodes: ["65"],
		capital: "Singapore",
		altSpellings: ["SG", "Singapura", "Republik Singapura", "æ–°åŠ å�¡å…±å’Œå›½"],
		relevance: "0",
		region: "Asia",
		subregion: "South-Eastern Asia",
		translations: {
			de: "Singapur",
			es: "Singapur",
			fr: "Singapour",
			ja: "ã‚·ãƒ³ã‚¬ãƒ�ãƒ¼ãƒ«",
			it: "Singapore",
		},
		population: 5469700,
		latlng: [1.36666666, 103.8],
		demonym: "Singaporean",
		area: 710,
		gini: 48.1,
		timezones: ["UTC+08:00"],
		borders: [],
		nativeName: "Singapore",
		currencies: ["SGD"],
		languages: ["en", "ms", "ta", "zh"],
		numericCode: "702",
	},
	{
		name: "Sint Maarten",
		topLevelDomain: [".sx"],
		alpha2Code: "SX",
		alpha3Code: "SXM",
		callingCodes: ["1721"],
		capital: "Philipsburg",
		altSpellings: ["SX"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: null,
			es: null,
			fr: null,
			ja: null,
			it: null,
		},
		population: 37429,
		latlng: [18.033333, -63.05],
		demonym: "Dutch",
		area: 34,
		gini: null,
		timezones: null,
		borders: ["MAF"],
		nativeName: "Sint Maarten",
		currencies: ["ANG"],
		languages: ["nl", "en"],
		numericCode: "534",
	},
	{
		name: "Slovakia",
		topLevelDomain: [".sk"],
		alpha2Code: "SK",
		alpha3Code: "SVK",
		callingCodes: ["421"],
		capital: "Bratislava",
		altSpellings: ["SK", "Slovak Republic", "SlovenskÃ¡ republika"],
		relevance: "0",
		region: "Europe",
		subregion: "Eastern Europe",
		translations: {
			de: "Slowakei",
			es: "RepÃºblica Eslovaca",
			fr: "Slovaquie",
			ja: "ã‚¹ãƒ­ãƒ�ã‚­ã‚¢",
			it: "Slovacchia",
		},
		population: 5421349,
		latlng: [48.66666666, 19.5],
		demonym: "Slovak",
		area: 49037,
		gini: 26,
		timezones: ["UTC+01:00"],
		borders: ["AUT", "CZE", "HUN", "POL", "UKR"],
		nativeName: "Slovensko",
		currencies: ["EUR"],
		languages: ["sk"],
		numericCode: "703",
	},
	{
		name: "Slovenia",
		topLevelDomain: [".si"],
		alpha2Code: "SI",
		alpha3Code: "SVN",
		callingCodes: ["386"],
		capital: "Ljubljana",
		altSpellings: ["SI", "Republic of Slovenia", "Republika Slovenija"],
		relevance: "0",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Slowenien",
			es: "Eslovenia",
			fr: "SlovÃ©nie",
			ja: "ã‚¹ãƒ­ãƒ™ãƒ‹ã‚¢",
			it: "Slovenia",
		},
		population: 2067683,
		latlng: [46.11666666, 14.81666666],
		demonym: "Slovene",
		area: 20273,
		gini: 31.2,
		timezones: ["UTC+01:00"],
		borders: ["AUT", "HRV", "ITA", "HUN"],
		nativeName: "Slovenija",
		currencies: ["EUR"],
		languages: ["sl"],
		numericCode: "705",
	},
	{
		name: "Solomon Islands",
		topLevelDomain: [".sb"],
		alpha2Code: "SB",
		alpha3Code: "SLB",
		callingCodes: ["677"],
		capital: "Honiara",
		altSpellings: ["SB"],
		relevance: "0",
		region: "Oceania",
		subregion: "Melanesia",
		translations: {
			de: "Salomonen",
			es: "Islas SalomÃ³n",
			fr: "ÃŽles Salomon",
			ja: "ã‚½ãƒ­ãƒ¢ãƒ³è«¸å³¶",
			it: "Isole Salomone",
		},
		population: 581344,
		latlng: [-8, 159],
		demonym: "Solomon Islander",
		area: 28896,
		gini: null,
		timezones: ["UTC+11:00"],
		borders: [],
		nativeName: "Solomon Islands",
		currencies: ["SBD"],
		languages: ["en"],
		numericCode: "090",
	},
	{
		name: "Somalia",
		topLevelDomain: [".so"],
		alpha2Code: "SO",
		alpha3Code: "SOM",
		callingCodes: ["252"],
		capital: "Mogadishu",
		altSpellings: [
			"SO",
			"aá¹£-á¹¢Å«mÄ�l",
			"Federal Republic of Somalia",
			"Jamhuuriyadda Federaalka Soomaaliya",
			"JumhÅ«riyyat aá¹£-á¹¢Å«mÄ�l al-FiderÄ�liyya",
		],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Somalia",
			es: "Somalia",
			fr: "Somalie",
			ja: "ã‚½ãƒžãƒªã‚¢",
			it: "Somalia",
		},
		population: 10787000,
		latlng: [10, 49],
		demonym: "Somali",
		area: 637657,
		gini: null,
		timezones: ["UTC+03:00"],
		borders: ["DJI", "ETH", "KEN"],
		nativeName: "Soomaaliya",
		currencies: ["SOS"],
		languages: ["so", "ar"],
		numericCode: "706",
	},
	{
		name: "South Africa",
		topLevelDomain: [".za"],
		alpha2Code: "ZA",
		alpha3Code: "ZAF",
		callingCodes: ["27"],
		capital: "Pretoria",
		altSpellings: ["ZA", "RSA", "Suid-Afrika", "Republic of South Africa"],
		relevance: "0",
		region: "Africa",
		subregion: "Southern Africa",
		translations: {
			de: "Republik SÃ¼dafrika",
			es: "RepÃºblica de SudÃ¡frica",
			fr: "Afrique du Sud",
			ja: "å�—ã‚¢ãƒ•ãƒªã‚«",
			it: "Sud Africa",
		},
		population: 54956900,
		latlng: [-29, 24],
		demonym: "South African",
		area: 1221037,
		gini: 63.1,
		timezones: ["UTC+02:00"],
		borders: ["BWA", "LSO", "MOZ", "NAM", "SWZ", "ZWE"],
		nativeName: "South Africa",
		currencies: ["ZAR"],
		languages: ["af", "en", "nr", "st", "ss", "tn", "ts", "ve", "xh", "zu"],
		numericCode: "710",
	},
	{
		name: "South Georgia",
		topLevelDomain: [".gs"],
		alpha2Code: "GS",
		alpha3Code: "SGS",
		callingCodes: ["500"],
		capital: "King Edward Point",
		altSpellings: ["GS", "South Georgia and the South Sandwich Islands"],
		relevance: "0",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "SÃ¼dgeorgien und die SÃ¼dlichen Sandwichinseln",
			es: "Islas Georgias del Sur y Sandwich del Sur",
			fr: "GÃ©orgie du Sud-et-les ÃŽles Sandwich du Sud",
			ja: "ã‚µã‚¦ã‚¹ã‚¸ãƒ§ãƒ¼ã‚¸ã‚¢ãƒ»ã‚µã‚¦ã‚¹ã‚µãƒ³ãƒ‰ã‚¦ã‚£ãƒƒãƒ�è«¸å³¶",
			it: "Georgia del Sud e Isole Sandwich Meridionali",
		},
		population: 30,
		latlng: [-54.5, -37],
		demonym: "South Georgia and the South Sandwich Islander",
		area: null,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "South Georgia",
		currencies: ["GBP"],
		languages: ["en"],
		numericCode: "239",
	},
	{
		name: "South Korea",
		topLevelDomain: [".kr"],
		alpha2Code: "KR",
		alpha3Code: "KOR",
		callingCodes: ["82"],
		capital: "Seoul",
		altSpellings: ["KR", "Republic of Korea"],
		relevance: "1.5",
		region: "Asia",
		subregion: "Eastern Asia",
		translations: {
			de: "SÃ¼dkorea",
			es: "Corea del Sur",
			fr: "CorÃ©e du Sud",
			ja: "å¤§éŸ“æ°‘å›½",
			it: "Corea del Sud",
		},
		population: 51448183,
		latlng: [37, 127.5],
		demonym: "South Korean",
		area: 100210,
		gini: 31.3,
		timezones: ["UTC+09:00"],
		borders: ["PRK"],
		nativeName: "ëŒ€í•œë¯¼êµ­",
		currencies: ["KRW"],
		languages: ["ko"],
		numericCode: "410",
	},
	{
		name: "South Sudan",
		topLevelDomain: [".ss"],
		alpha2Code: "SS",
		alpha3Code: "SSD",
		callingCodes: ["211"],
		capital: "Juba",
		altSpellings: ["SS"],
		relevance: "0",
		region: "Africa",
		subregion: "Middle Africa",
		translations: {
			de: "SÃ¼dsudan",
			es: "SudÃ¡n del Sur",
			fr: "Soudan du Sud",
			ja: "å�—ã‚¹ãƒ¼ãƒ€ãƒ³",
			it: "Sudan del sud",
		},
		population: 11892934,
		latlng: [7, 30],
		demonym: "South Sudanese",
		area: 619745,
		gini: 45.5,
		timezones: ["UTC+03:00"],
		borders: ["CAF", "COD", "ETH", "KEN", "SDN", "UGA"],
		nativeName: "South Sudan",
		currencies: ["SSP"],
		languages: ["en"],
		numericCode: "728",
	},
	{
		name: "Spain",
		topLevelDomain: [".es"],
		alpha2Code: "ES",
		alpha3Code: "ESP",
		callingCodes: ["34"],
		capital: "Madrid",
		altSpellings: ["ES", "Kingdom of Spain", "Reino de EspaÃ±a"],
		relevance: "2",
		region: "Europe",
		subregion: "Southern Europe",
		translations: {
			de: "Spanien",
			es: "EspaÃ±a",
			fr: "Espagne",
			ja: "ã‚¹ãƒšã‚¤ãƒ³",
			it: "Spagna",
		},
		population: 46439864,
		latlng: [40, -4],
		demonym: "Spanish",
		area: 505992,
		gini: 34.7,
		timezones: ["UTC", "UTC+01:00"],
		borders: ["AND", "FRA", "GIB", "PRT", "MAR"],
		nativeName: "EspaÃ±a",
		currencies: ["EUR"],
		languages: ["es"],
		numericCode: "724",
	},
	{
		name: "Sri Lanka",
		topLevelDomain: [".lk"],
		alpha2Code: "LK",
		alpha3Code: "LKA",
		callingCodes: ["94"],
		capital: "Colombo",
		altSpellings: ["LK", "ilaá¹…kai", "Democratic Socialist Republic of Sri Lanka"],
		relevance: "0",
		region: "Asia",
		subregion: "Southern Asia",
		translations: {
			de: "Sri Lanka",
			es: "Sri Lanka",
			fr: "Sri Lanka",
			ja: "ã‚¹ãƒªãƒ©ãƒ³ã‚«",
			it: "Sri Lanka",
		},
		population: 20675000,
		latlng: [7, 81],
		demonym: "Sri Lankan",
		area: 65610,
		gini: 40.3,
		timezones: ["UTC+05:30"],
		borders: ["IND"],
		nativeName: "Å›rÄ« laá¹ƒkÄ�va",
		currencies: ["LKR"],
		languages: ["si", "ta"],
		numericCode: "144",
	},
	{
		name: "Sudan",
		topLevelDomain: [".sd"],
		alpha2Code: "SD",
		alpha3Code: "SDN",
		callingCodes: ["249"],
		capital: "Khartoum",
		altSpellings: ["SD", "Republic of the Sudan", "JumhÅ«rÄ«yat as-SÅ«dÄ�n"],
		relevance: "0",
		region: "Africa",
		subregion: "Northern Africa",
		translations: {
			de: "Sudan",
			es: "SudÃ¡n",
			fr: "Soudan",
			ja: "ã‚¹ãƒ¼ãƒ€ãƒ³",
			it: "Sudan",
		},
		population: 38435252,
		latlng: [15, 30],
		demonym: "Sudanese",
		area: 1886068,
		gini: 35.3,
		timezones: ["UTC+03:00"],
		borders: ["CAF", "TCD", "EGY", "ERI", "ETH", "LBY", "SSD"],
		nativeName: "Ø§Ù„Ø³ÙˆØ¯Ø§Ù†",
		currencies: ["SDG"],
		languages: ["ar", "en"],
		numericCode: "729",
	},
	{
		name: "Suriname",
		topLevelDomain: [".sr"],
		alpha2Code: "SR",
		alpha3Code: "SUR",
		callingCodes: ["597"],
		capital: "Paramaribo",
		altSpellings: ["SR", "Sarnam", "Sranangron", "Republic of Suriname", "Republiek Suriname"],
		relevance: "0",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "Suriname",
			es: "Surinam",
			fr: "Surinam",
			ja: "ã‚¹ãƒªãƒŠãƒ ",
			it: "Suriname",
		},
		population: 534189,
		latlng: [4, -56],
		demonym: "Surinamer",
		area: 163820,
		gini: 52.9,
		timezones: ["UTCâˆ’03:00"],
		borders: ["BRA", "GUF", "FRA", "GUY"],
		nativeName: "Suriname",
		currencies: ["SRD"],
		languages: ["nl"],
		numericCode: "740",
	},
	{
		name: "Svalbard and Jan Mayen",
		topLevelDomain: [".sj"],
		alpha2Code: "SJ",
		alpha3Code: "SJM",
		callingCodes: ["4779"],
		capital: "Longyearbyen",
		altSpellings: ["SJ", "Svalbard and Jan Mayen Islands"],
		relevance: "0.5",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Svalbard und Jan Mayen",
			es: "Islas Svalbard y Jan Mayen",
			fr: "Svalbard et Jan Mayen",
			ja: "ã‚¹ãƒ´ã‚¡ãƒ¼ãƒ«ãƒ�ãƒ«è«¸å³¶ã�Šã‚ˆã�³ãƒ¤ãƒ³ãƒžã‚¤ã‚¨ãƒ³å³¶",
			it: "Svalbard e Jan Mayen",
		},
		population: 2562,
		latlng: [78, 20],
		demonym: "Norwegian",
		area: null,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Svalbard og Jan Mayen",
		currencies: ["NOK"],
		languages: ["no"],
		numericCode: "744",
	},
	{
		name: "Swaziland",
		topLevelDomain: [".sz"],
		alpha2Code: "SZ",
		alpha3Code: "SWZ",
		callingCodes: ["268"],
		capital: "Lobamba",
		altSpellings: [
			"SZ",
			"weSwatini",
			"Swatini",
			"Ngwane",
			"Kingdom of Swaziland",
			"Umbuso waseSwatini",
		],
		relevance: "0",
		region: "Africa",
		subregion: "Southern Africa",
		translations: {
			de: "Swasiland",
			es: "Suazilandia",
			fr: "Swaziland",
			ja: "ã‚¹ãƒ¯ã‚¸ãƒ©ãƒ³ãƒ‰",
			it: "Swaziland",
		},
		population: 1119375,
		latlng: [-26.5, 31.5],
		demonym: "Swazi",
		area: 17364,
		gini: 51.5,
		timezones: ["UTC+02:00"],
		borders: ["MOZ", "ZAF"],
		nativeName: "Swaziland",
		currencies: ["SZL"],
		languages: ["en", "ss"],
		numericCode: "748",
	},
	{
		name: "Sweden",
		topLevelDomain: [".se"],
		alpha2Code: "SE",
		alpha3Code: "SWE",
		callingCodes: ["46"],
		capital: "Stockholm",
		altSpellings: ["SE", "Kingdom of Sweden", "Konungariket Sverige"],
		relevance: "1.5",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Schweden",
			es: "Suecia",
			fr: "SuÃ¨de",
			ja: "ã‚¹ã‚¦ã‚§ãƒ¼ãƒ‡ãƒ³",
			it: "Svezia",
		},
		population: 9793172,
		latlng: [62, 15],
		demonym: "Swedish",
		area: 450295,
		gini: 25,
		timezones: ["UTC+01:00"],
		borders: ["FIN", "NOR"],
		nativeName: "Sverige",
		currencies: ["SEK"],
		languages: ["sv"],
		numericCode: "752",
	},
	{
		name: "Switzerland",
		topLevelDomain: [".ch"],
		alpha2Code: "CH",
		alpha3Code: "CHE",
		callingCodes: ["41"],
		capital: "Bern",
		altSpellings: ["CH", "Swiss Confederation", "Schweiz", "Suisse", "Svizzera", "Svizra"],
		relevance: "1.5",
		region: "Europe",
		subregion: "Western Europe",
		translations: {
			de: "Schweiz",
			es: "Suiza",
			fr: "Suisse",
			ja: "ã‚¹ã‚¤ã‚¹",
			it: "Svizzera",
		},
		population: 8256000,
		latlng: [47, 8],
		demonym: "Swiss",
		area: 41284,
		gini: 33.7,
		timezones: ["UTC+01:00"],
		borders: ["AUT", "FRA", "ITA", "LIE", "DEU"],
		nativeName: "Schweiz",
		currencies: ["CHE", "CHF", "CHW"],
		languages: ["de", "fr", "it"],
		numericCode: "756",
	},
	{
		name: "Syria",
		topLevelDomain: [".sy"],
		alpha2Code: "SY",
		alpha3Code: "SYR",
		callingCodes: ["963"],
		capital: "Damascus",
		altSpellings: ["SY", "Syrian Arab Republic", "Al-JumhÅ«rÄ«yah Al-Ê»ArabÄ«yah As-SÅ«rÄ«yah"],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Syrien",
			es: "Siria",
			fr: "Syrie",
			ja: "ã‚·ãƒªã‚¢ãƒ»ã‚¢ãƒ©ãƒ–å…±å’Œå›½",
			it: "Siria",
		},
		population: 23339517,
		latlng: [35, 38],
		demonym: "Syrian",
		area: 185180,
		gini: 35.8,
		timezones: ["UTC+02:00"],
		borders: ["IRQ", "ISR", "JOR", "LBN", "TUR"],
		nativeName: "Ø³ÙˆØ±ÙŠØ§",
		currencies: ["SYP"],
		languages: ["ar"],
		numericCode: "760",
	},
	{
		name: "Taiwan",
		topLevelDomain: [".tw"],
		alpha2Code: "TW",
		alpha3Code: "TWN",
		callingCodes: ["886"],
		capital: "Taipei",
		altSpellings: [
			"TW",
			"TÃ¡iwÄ�n",
			"Republic of China",
			"ä¸­è�¯æ°‘åœ‹",
			"ZhÅ�nghuÃ¡ MÃ­nguÃ³",
		],
		relevance: "0",
		region: "Asia",
		subregion: "Eastern Asia",
		translations: {
			de: "Taiwan",
			es: "TaiwÃ¡n",
			fr: "TaÃ¯wan",
			ja: "å�°æ¹¾ï¼ˆå�°æ¹¾çœ�/ä¸­è�¯æ°‘å›½ï¼‰",
			it: "Taiwan",
		},
		population: 23461708,
		latlng: [23.5, 121],
		demonym: "Taiwanese",
		area: 36193,
		gini: null,
		timezones: ["UTC+08:00"],
		borders: [],
		nativeName: "è‡ºç�£",
		currencies: ["TWD"],
		languages: ["zh"],
		numericCode: "158",
	},
	{
		name: "Tajikistan",
		topLevelDomain: [".tj"],
		alpha2Code: "TJ",
		alpha3Code: "TJK",
		callingCodes: ["992"],
		capital: "Dushanbe",
		altSpellings: [
			"TJ",
			"ToÃ§ikiston",
			"Republic of Tajikistan",
			"Ò¶ÑƒÐ¼Ò³ÑƒÑ€Ð¸Ð¸ Ð¢Ð¾Ò·Ð¸ÐºÐ¸Ñ�Ñ‚Ð¾Ð½",
			"Ã‡umhuriyi ToÃ§ikiston",
		],
		relevance: "0",
		region: "Asia",
		subregion: "Central Asia",
		translations: {
			de: "Tadschikistan",
			es: "TayikistÃ¡n",
			fr: "Tadjikistan",
			ja: "ã‚¿ã‚¸ã‚­ã‚¹ã‚¿ãƒ³",
			it: "Tagikistan",
		},
		population: 8354000,
		latlng: [39, 71],
		demonym: "Tadzhik",
		area: 143100,
		gini: 30.8,
		timezones: ["UTC+05:00"],
		borders: ["AFG", "CHN", "KGZ", "UZB"],
		nativeName: "Ð¢Ð¾Ò·Ð¸ÐºÐ¸Ñ�Ñ‚Ð¾Ð½",
		currencies: ["TJS"],
		languages: ["tg", "ru"],
		numericCode: "762",
	},
	{
		name: "Tanzania",
		topLevelDomain: [".tz"],
		alpha2Code: "TZ",
		alpha3Code: "TZA",
		callingCodes: ["255"],
		capital: "Dodoma",
		altSpellings: ["TZ", "United Republic of Tanzania", "Jamhuri ya Muungano wa Tanzania"],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Tansania",
			es: "Tanzania",
			fr: "Tanzanie",
			ja: "ã‚¿ãƒ³ã‚¶ãƒ‹ã‚¢",
			it: "Tanzania",
		},
		population: 53470000,
		latlng: [-6, 35],
		demonym: "Tanzanian",
		area: 945087,
		gini: 37.6,
		timezones: ["UTC+03:00"],
		borders: ["BDI", "COD", "KEN", "MWI", "MOZ", "RWA", "UGA", "ZMB"],
		nativeName: "Tanzania",
		currencies: ["TZS"],
		languages: ["sw", "en"],
		numericCode: "834",
	},
	{
		name: "Thailand",
		topLevelDomain: [".th"],
		alpha2Code: "TH",
		alpha3Code: "THA",
		callingCodes: ["66"],
		capital: "Bangkok",
		altSpellings: [
			"TH",
			"Prathet",
			"Thai",
			"Kingdom of Thailand",
			"à¸£à¸²à¸Šà¸­à¸²à¸“à¸²à¸ˆà¸±à¸�à¸£à¹„à¸—à¸¢",
			"Ratcha Anachak Thai",
		],
		relevance: "0",
		region: "Asia",
		subregion: "South-Eastern Asia",
		translations: {
			de: "Thailand",
			es: "Tailandia",
			fr: "ThaÃ¯lande",
			ja: "ã‚¿ã‚¤",
			it: "Tailandia",
		},
		population: 65104000,
		latlng: [15, 100],
		demonym: "Thai",
		area: 513120,
		gini: 40,
		timezones: ["UTC+07:00"],
		borders: ["MMR", "KHM", "LAO", "MYS"],
		nativeName: "à¸›à¸£à¸°à¹€à¸—à¸¨à¹„à¸—à¸¢",
		currencies: ["THB"],
		languages: ["th"],
		numericCode: "764",
	},
	{
		name: "East Timor",
		topLevelDomain: [".tl"],
		alpha2Code: "TL",
		alpha3Code: "TLS",
		callingCodes: ["670"],
		capital: "Dili",
		altSpellings: [
			"TL",
			"East Timor",
			"Democratic Republic of Timor-Leste",
			"RepÃºblica DemocrÃ¡tica de Timor-Leste",
			"RepÃºblika DemokrÃ¡tika TimÃ³r-Leste",
		],
		relevance: "0",
		region: "Asia",
		subregion: "South-Eastern Asia",
		translations: {
			de: "Timor-Leste",
			es: "Timor Oriental",
			fr: "Timor oriental",
			ja: "æ�±ãƒ†ã‚£ãƒ¢ãƒ¼ãƒ«",
			it: "Timor Est",
		},
		population: 1212107,
		latlng: [-8.83333333, 125.91666666],
		demonym: "East Timorese",
		area: 14874,
		gini: 31.9,
		timezones: ["UTC+09:00"],
		borders: ["IDN"],
		nativeName: "Timor-Leste",
		currencies: ["USD"],
		languages: ["pt"],
		numericCode: "626",
	},
	{
		name: "Togo",
		topLevelDomain: [".tg"],
		alpha2Code: "TG",
		alpha3Code: "TGO",
		callingCodes: ["228"],
		capital: "LomÃ©",
		altSpellings: ["TG", "Togolese", "Togolese Republic", "RÃ©publique Togolaise"],
		relevance: "0",
		region: "Africa",
		subregion: "Western Africa",
		translations: {
			de: "Togo",
			es: "Togo",
			fr: "Togo",
			ja: "ãƒˆãƒ¼ã‚´",
			it: "Togo",
		},
		population: 7305000,
		latlng: [8, 1.16666666],
		demonym: "Togolese",
		area: 56785,
		gini: 34.4,
		timezones: ["UTC"],
		borders: ["BEN", "BFA", "GHA"],
		nativeName: "Togo",
		currencies: ["XOF"],
		languages: ["fr"],
		numericCode: "768",
	},
	{
		name: "Tokelau",
		topLevelDomain: [".tk"],
		alpha2Code: "TK",
		alpha3Code: "TKL",
		callingCodes: ["690"],
		capital: "Fakaofo",
		altSpellings: ["TK"],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Polynesia",
		translations: {
			de: "Tokelau",
			es: "Islas Tokelau",
			fr: "Tokelau",
			ja: "ãƒˆã‚±ãƒ©ã‚¦",
			it: "Isole Tokelau",
		},
		population: 1411,
		latlng: [-9, -172],
		demonym: "Tokelauan",
		area: 12,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Tokelau",
		currencies: ["NZD"],
		languages: ["en"],
		numericCode: "772",
	},
	{
		name: "Tonga",
		topLevelDomain: [".to"],
		alpha2Code: "TO",
		alpha3Code: "TON",
		callingCodes: ["676"],
		capital: "Nuku'alofa",
		altSpellings: ["TO"],
		relevance: "0",
		region: "Oceania",
		subregion: "Polynesia",
		translations: {
			de: "Tonga",
			es: "Tonga",
			fr: "Tonga",
			ja: "ãƒˆãƒ³ã‚¬",
			it: "Tonga",
		},
		population: 103252,
		latlng: [-20, -175],
		demonym: "Tongan",
		area: 747,
		gini: null,
		timezones: ["UTC+13:00"],
		borders: [],
		nativeName: "Tonga",
		currencies: ["TOP"],
		languages: ["en", "to"],
		numericCode: "776",
	},
	{
		name: "Trinidad and Tobago",
		topLevelDomain: [".tt"],
		alpha2Code: "TT",
		alpha3Code: "TTO",
		callingCodes: ["1868"],
		capital: "Port of Spain",
		altSpellings: ["TT", "Republic of Trinidad and Tobago"],
		relevance: "0",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Trinidad und Tobago",
			es: "Trinidad y Tobago",
			fr: "TrinitÃ© et Tobago",
			ja: "ãƒˆãƒªãƒ‹ãƒ€ãƒ¼ãƒ‰ãƒ»ãƒˆãƒ�ã‚´",
			it: "Trinidad e Tobago",
		},
		population: 1340557,
		latlng: [11, -61],
		demonym: "Trinidadian",
		area: 5130,
		gini: 40.3,
		timezones: ["UTCâˆ’04:00"],
		borders: [],
		nativeName: "Trinidad and Tobago",
		currencies: ["TTD"],
		languages: ["en"],
		numericCode: "780",
	},
	{
		name: "Tunisia",
		topLevelDomain: [".tn"],
		alpha2Code: "TN",
		alpha3Code: "TUN",
		callingCodes: ["216"],
		capital: "Tunis",
		altSpellings: ["TN", "Republic of Tunisia", "al-JumhÅ«riyyah at-TÅ«nisiyyah"],
		relevance: "0",
		region: "Africa",
		subregion: "Northern Africa",
		translations: {
			de: "Tunesien",
			es: "TÃºnez",
			fr: "Tunisie",
			ja: "ãƒ�ãƒ¥ãƒ‹ã‚¸ã‚¢",
			it: "Tunisia",
		},
		population: 10982754,
		latlng: [34, 9],
		demonym: "Tunisian",
		area: 163610,
		gini: 41.4,
		timezones: ["UTC+01:00"],
		borders: ["DZA", "LBY"],
		nativeName: "ØªÙˆÙ†Ø³",
		currencies: ["TND"],
		languages: ["ar"],
		numericCode: "788",
	},
	{
		name: "Turkey",
		topLevelDomain: [".tr"],
		alpha2Code: "TR",
		alpha3Code: "TUR",
		callingCodes: ["90"],
		capital: "Ankara",
		altSpellings: ["TR", "Turkiye", "Republic of Turkey", "TÃ¼rkiye Cumhuriyeti"],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "TÃ¼rkei",
			es: "TurquÃ­a",
			fr: "Turquie",
			ja: "ãƒˆãƒ«ã‚³",
			it: "Turchia",
		},
		population: 77695904,
		latlng: [39, 35],
		demonym: "Turkish",
		area: 783562,
		gini: 39,
		timezones: ["UTC+02:00"],
		borders: ["ARM", "AZE", "BGR", "GEO", "GRC", "IRN", "IRQ", "SYR"],
		nativeName: "TÃ¼rkiye",
		currencies: ["TRY"],
		languages: ["tr"],
		numericCode: "792",
	},
	{
		name: "Turkmenistan",
		topLevelDomain: [".tm"],
		alpha2Code: "TM",
		alpha3Code: "TKM",
		callingCodes: ["993"],
		capital: "Ashgabat",
		altSpellings: ["TM"],
		relevance: "0",
		region: "Asia",
		subregion: "Central Asia",
		translations: {
			de: "Turkmenistan",
			es: "TurkmenistÃ¡n",
			fr: "TurkmÃ©nistan",
			ja: "ãƒˆãƒ«ã‚¯ãƒ¡ãƒ‹ã‚¹ã‚¿ãƒ³",
			it: "Turkmenistan",
		},
		population: 4751120,
		latlng: [40, 60],
		demonym: "Turkmen",
		area: 488100,
		gini: 40.8,
		timezones: ["UTC+05:00"],
		borders: ["AFG", "IRN", "KAZ", "UZB"],
		nativeName: "TÃ¼rkmenistan",
		currencies: ["TMT"],
		languages: ["tk", "ru"],
		numericCode: "795",
	},
	{
		name: "Turks and Caicos Islands",
		topLevelDomain: [".tc"],
		alpha2Code: "TC",
		alpha3Code: "TCA",
		callingCodes: ["1649"],
		capital: "Cockburn Town",
		altSpellings: ["TC"],
		relevance: "0.5",
		region: "Americas",
		subregion: "Caribbean",
		translations: {
			de: "Turks- und Caicosinseln",
			es: "Islas Turks y Caicos",
			fr: "ÃŽles Turques-et-CaÃ¯ques",
			ja: "ã‚¿ãƒ¼ã‚¯ã‚¹ãƒ»ã‚«ã‚¤ã‚³ã‚¹è«¸å³¶",
			it: "Isole Turks e Caicos",
		},
		population: 31458,
		latlng: [21.75, -71.58333333],
		demonym: "Turks and Caicos Islander",
		area: 948,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Turks and Caicos Islands",
		currencies: ["USD"],
		languages: ["en"],
		numericCode: "796",
	},
	{
		name: "Tuvalu",
		topLevelDomain: [".tv"],
		alpha2Code: "TV",
		alpha3Code: "TUV",
		callingCodes: ["688"],
		capital: "Funafuti",
		altSpellings: ["TV"],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Polynesia",
		translations: {
			de: "Tuvalu",
			es: "Tuvalu",
			fr: "Tuvalu",
			ja: "ãƒ„ãƒ�ãƒ«",
			it: "Tuvalu",
		},
		population: 10640,
		latlng: [-8, 178],
		demonym: "Tuvaluan",
		area: 26,
		gini: null,
		timezones: ["UTC+12:00"],
		borders: [],
		nativeName: "Tuvalu",
		currencies: ["AUD"],
		languages: ["en"],
		numericCode: "798",
	},
	{
		name: "Uganda",
		topLevelDomain: [".ug"],
		alpha2Code: "UG",
		alpha3Code: "UGA",
		callingCodes: ["256"],
		capital: "Kampala",
		altSpellings: ["UG", "Republic of Uganda", "Jamhuri ya Uganda"],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Uganda",
			es: "Uganda",
			fr: "Uganda",
			ja: "ã‚¦ã‚¬ãƒ³ãƒ€",
			it: "Uganda",
		},
		population: 34856813,
		latlng: [1, 32],
		demonym: "Ugandan",
		area: 241550,
		gini: 44.3,
		timezones: ["UTC+03:00"],
		borders: ["COD", "KEN", "RWA", "SSD", "TZA"],
		nativeName: "Uganda",
		currencies: ["UGX"],
		languages: ["en", "sw"],
		numericCode: "800",
	},
	{
		name: "Ukraine",
		topLevelDomain: [".ua"],
		alpha2Code: "UA",
		alpha3Code: "UKR",
		callingCodes: ["380"],
		capital: "Kiev",
		altSpellings: ["UA", "Ukrayina"],
		relevance: "0",
		region: "Europe",
		subregion: "Eastern Europe",
		translations: {
			de: "Ukraine",
			es: "Ucrania",
			fr: "Ukraine",
			ja: "ã‚¦ã‚¯ãƒ©ã‚¤ãƒŠ",
			it: "Ucraina",
		},
		population: 42836922,
		latlng: [49, 32],
		demonym: "Ukrainian",
		area: 603700,
		gini: 26.4,
		timezones: ["UTC+02:00"],
		borders: ["BLR", "HUN", "MDA", "POL", "ROU", "RUS", "SVK"],
		nativeName: "Ð£ÐºÑ€Ð°Ñ—Ð½Ð°",
		currencies: ["UAH"],
		languages: ["uk"],
		numericCode: "804",
	},
	{
		name: "United Arab Emirates",
		topLevelDomain: [".ae"],
		alpha2Code: "AE",
		alpha3Code: "ARE",
		callingCodes: ["971"],
		capital: "Abu Dhabi",
		altSpellings: ["AE", "UAE"],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Vereinigte Arabische Emirate",
			es: "Emiratos Ã�rabes Unidos",
			fr: "Ã‰mirats arabes unis",
			ja: "ã‚¢ãƒ©ãƒ–é¦–é•·å›½é€£é‚¦",
			it: "Emirati Arabi Uniti",
		},
		population: 9157000,
		latlng: [24, 54],
		demonym: "Emirati",
		area: 83600,
		gini: null,
		timezones: ["UTC+04"],
		borders: ["OMN", "SAU"],
		nativeName: "Ø¯ÙˆÙ„Ø© Ø§Ù„Ø¥Ù…Ø§Ø±Ø§Øª Ø§Ù„Ø¹Ø±Ø¨ÙŠØ© Ø§Ù„Ù…ØªØ­Ø¯Ø©",
		currencies: ["AED"],
		languages: ["ar"],
		numericCode: "784",
	},
	{
		name: "United Kingdom",
		topLevelDomain: [".uk"],
		alpha2Code: "GB",
		alpha3Code: "GBR",
		callingCodes: ["44"],
		capital: "London",
		altSpellings: ["GB", "UK", "Great Britain"],
		relevance: "2.5",
		region: "Europe",
		subregion: "Northern Europe",
		translations: {
			de: "Vereinigtes KÃ¶nigreich",
			es: "Reino Unido",
			fr: "Royaume-Uni",
			ja: "ã‚¤ã‚®ãƒªã‚¹",
			it: "Regno Unito",
		},
		population: 64800000,
		latlng: [54, -2],
		demonym: "British",
		area: 242900,
		gini: 34,
		timezones: [
			"UTCâˆ’08:00",
			"UTCâˆ’05:00",
			"UTCâˆ’04:00",
			"UTCâˆ’03:00",
			"UTCâˆ’02:00",
			"UTC",
			"UTC+01:00",
			"UTC+02:00",
			"UTC+06:00",
		],
		borders: ["IRL"],
		nativeName: "United Kingdom",
		currencies: ["GBP"],
		languages: ["en"],
		numericCode: "826",
	},
	{
		name: "United States",
		topLevelDomain: [".us"],
		alpha2Code: "US",
		alpha3Code: "USA",
		callingCodes: ["1"],
		capital: "Washington, D.C.",
		altSpellings: ["US", "USA", "United States of America"],
		relevance: "3.5",
		region: "Americas",
		subregion: "Northern America",
		translations: {
			de: "Vereinigte Staaten von Amerika",
			es: "Estados Unidos",
			fr: "Ã‰tats-Unis",
			ja: "ã‚¢ãƒ¡ãƒªã‚«å�ˆè¡†å›½",
			it: "Stati Uniti D'America",
		},
		population: 321645000,
		latlng: [38, -97],
		demonym: "American",
		area: 9629091,
		gini: 48,
		timezones: [
			"UTCâˆ’12:00",
			"UTCâˆ’11:00",
			"UTCâˆ’10:00",
			"UTCâˆ’09:00",
			"UTCâˆ’08:00",
			"UTCâˆ’07:00",
			"UTCâˆ’06:00",
			"UTCâˆ’05:00",
			"UTCâˆ’04:00",
			"UTC+10:00",
			"UTC+12:00",
		],
		borders: ["CAN", "MEX"],
		nativeName: "United States",
		currencies: ["USD", "USN", "USS"],
		languages: ["en"],
		numericCode: "840",
	},
	{
		name: "Uruguay",
		topLevelDomain: [".uy"],
		alpha2Code: "UY",
		alpha3Code: "URY",
		callingCodes: ["598"],
		capital: "Montevideo",
		altSpellings: ["UY", "Oriental Republic of Uruguay", "RepÃºblica Oriental del Uruguay"],
		relevance: "0",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "Uruguay",
			es: "Uruguay",
			fr: "Uruguay",
			ja: "ã‚¦ãƒ«ã‚°ã‚¢ã‚¤",
			it: "Uruguay",
		},
		population: 3415866,
		latlng: [-33, -56],
		demonym: "Uruguayan",
		area: 181034,
		gini: 39.7,
		timezones: ["UTCâˆ’03:00"],
		borders: ["ARG", "BRA"],
		nativeName: "Uruguay",
		currencies: ["UYI", "UYU"],
		languages: ["es"],
		numericCode: "858",
	},
	{
		name: "Uzbekistan",
		topLevelDomain: [".uz"],
		alpha2Code: "UZ",
		alpha3Code: "UZB",
		callingCodes: ["998"],
		capital: "Tashkent",
		altSpellings: [
			"UZ",
			"Republic of Uzbekistan",
			"Oâ€˜zbekiston Respublikasi",
			"ÐŽÐ·Ð±ÐµÐºÐ¸Ñ�Ñ‚Ð¾Ð½ Ð ÐµÑ�Ð¿ÑƒÐ±Ð»Ð¸ÐºÐ°Ñ�Ð¸",
		],
		relevance: "0",
		region: "Asia",
		subregion: "Central Asia",
		translations: {
			de: "Usbekistan",
			es: "UzbekistÃ¡n",
			fr: "OuzbÃ©kistan",
			ja: "ã‚¦ã‚ºãƒ™ã‚­ã‚¹ã‚¿ãƒ³",
			it: "Uzbekistan",
		},
		population: 31022500,
		latlng: [41, 64],
		demonym: "Uzbekistani",
		area: 447400,
		gini: 36.7,
		timezones: ["UTC+05:00"],
		borders: ["AFG", "KAZ", "KGZ", "TJK", "TKM"],
		nativeName: "Oâ€˜zbekiston",
		currencies: ["UZS"],
		languages: ["uz", "ru"],
		numericCode: "860",
	},
	{
		name: "Vanuatu",
		topLevelDomain: [".vu"],
		alpha2Code: "VU",
		alpha3Code: "VUT",
		callingCodes: ["678"],
		capital: "Port Vila",
		altSpellings: [
			"VU",
			"Republic of Vanuatu",
			"Ripablik blong Vanuatu",
			"RÃ©publique de Vanuatu",
		],
		relevance: "0",
		region: "Oceania",
		subregion: "Melanesia",
		translations: {
			de: "Vanuatu",
			es: "Vanuatu",
			fr: "Vanuatu",
			ja: "ãƒ�ãƒŒã‚¢ãƒ„",
			it: "Vanuatu",
		},
		population: 264652,
		latlng: [-16, 167],
		demonym: "Ni-Vanuatu",
		area: 12189,
		gini: null,
		timezones: ["UTC+11:00"],
		borders: [],
		nativeName: "Vanuatu",
		currencies: ["VUV"],
		languages: ["bi", "en", "fr"],
		numericCode: "548",
	},
	{
		name: "Venezuela",
		topLevelDomain: [".ve"],
		alpha2Code: "VE",
		alpha3Code: "VEN",
		callingCodes: ["58"],
		capital: "Caracas",
		altSpellings: [
			"VE",
			"Bolivarian Republic of Venezuela",
			"RepÃºblica Bolivariana de Venezuela",
		],
		relevance: "0",
		region: "Americas",
		subregion: "South America",
		translations: {
			de: "Venezuela",
			es: "Venezuela",
			fr: "Venezuela",
			ja: "ãƒ™ãƒ�ã‚ºã‚¨ãƒ©ãƒ»ãƒœãƒªãƒ�ãƒ«å…±å’Œå›½",
			it: "Venezuela",
		},
		population: 30620404,
		latlng: [8, -66],
		demonym: "Venezuelan",
		area: 916445,
		gini: 44.8,
		timezones: ["UTCâˆ’04:30"],
		borders: ["BRA", "COL", "GUY"],
		nativeName: "Venezuela",
		currencies: ["VEF"],
		languages: ["es"],
		numericCode: "862",
	},
	{
		name: "Vietnam",
		topLevelDomain: [".vn"],
		alpha2Code: "VN",
		alpha3Code: "VNM",
		callingCodes: ["84"],
		capital: "Hanoi",
		altSpellings: [
			"VN",
			"Socialist Republic of Vietnam",
			"Cá»™ng hÃ²a XÃ£ há»™i chá»§ nghÄ©a Viá»‡t Nam",
		],
		relevance: "1.5",
		region: "Asia",
		subregion: "South-Eastern Asia",
		translations: {
			de: "Vietnam",
			es: "Vietnam",
			fr: "ViÃªt Nam",
			ja: "ãƒ™ãƒˆãƒŠãƒ ",
			it: "Vietnam",
		},
		population: 91812000,
		latlng: [16.16666666, 107.83333333],
		demonym: "Vietnamese",
		area: 331212,
		gini: 35.6,
		timezones: ["UTC+07:00"],
		borders: ["KHM", "CHN", "LAO"],
		nativeName: "Viá»‡t Nam",
		currencies: ["VND"],
		languages: ["vi"],
		numericCode: "704",
	},
	{
		name: "Wallis and Futuna",
		topLevelDomain: [".wf"],
		alpha2Code: "WF",
		alpha3Code: "WLF",
		callingCodes: ["681"],
		capital: "Mata-Utu",
		altSpellings: [
			"WF",
			"Territory of the Wallis and Futuna Islands",
			"Territoire des Ã®les Wallis et Futuna",
		],
		relevance: "0.5",
		region: "Oceania",
		subregion: "Polynesia",
		translations: {
			de: "Wallis und Futuna",
			es: "Wallis y Futuna",
			fr: "Wallis-et-Futuna",
			ja: "ã‚¦ã‚©ãƒªã‚¹ãƒ»ãƒ•ãƒ„ãƒŠ",
			it: "Wallis e Futuna",
		},
		population: 13135,
		latlng: [-13.3, -176.2],
		demonym: "Wallis and Futuna Islander",
		area: 142,
		gini: null,
		timezones: null,
		borders: [],
		nativeName: "Wallis et Futuna",
		currencies: ["XPF"],
		languages: ["fr"],
		numericCode: "876",
	},
	{
		name: "Western Sahara",
		topLevelDomain: [".eh"],
		alpha2Code: "EH",
		alpha3Code: "ESH",
		callingCodes: ["212"],
		capital: "El AaiÃºn",
		altSpellings: ["EH", "Taneáº“roft Tutrimt"],
		relevance: "0",
		region: "Africa",
		subregion: "Northern Africa",
		translations: {
			de: "Westsahara",
			es: "Sahara Occidental",
			fr: "Sahara Occidental",
			ja: "è¥¿ã‚µãƒ�ãƒ©",
			it: "Sahara Occidentale",
		},
		population: 510713,
		latlng: [24.5, -13],
		demonym: "Sahrawi",
		area: 266000,
		gini: null,
		timezones: null,
		borders: ["DZA", "MRT", "MAR"],
		nativeName: "Ø§Ù„ØµØ­Ø±Ø§Ø¡ Ø§Ù„ØºØ±Ø¨ÙŠØ©",
		currencies: ["MAD", "DZD", "MRO"],
		languages: ["es"],
		numericCode: "732",
	},
	{
		name: "Yemen",
		topLevelDomain: [".ye"],
		alpha2Code: "YE",
		alpha3Code: "YEM",
		callingCodes: ["967"],
		capital: "Sana'a",
		altSpellings: ["YE", "Yemeni Republic", "al-JumhÅ«riyyah al-Yamaniyyah"],
		relevance: "0",
		region: "Asia",
		subregion: "Western Asia",
		translations: {
			de: "Jemen",
			es: "Yemen",
			fr: "YÃ©men",
			ja: "ã‚¤ã‚¨ãƒ¡ãƒ³",
			it: "Yemen",
		},
		population: 25956000,
		latlng: [15, 48],
		demonym: "Yemeni",
		area: 527968,
		gini: 37.7,
		timezones: ["UTC+03:00"],
		borders: ["OMN", "SAU"],
		nativeName: "Ø§Ù„ÙŠÙŽÙ…ÙŽÙ†",
		currencies: ["YER"],
		languages: ["ar"],
		numericCode: "887",
	},
	{
		name: "Zambia",
		topLevelDomain: [".zm"],
		alpha2Code: "ZM",
		alpha3Code: "ZMB",
		callingCodes: ["260"],
		capital: "Lusaka",
		altSpellings: ["ZM", "Republic of Zambia"],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Sambia",
			es: "Zambia",
			fr: "Zambie",
			ja: "ã‚¶ãƒ³ãƒ“ã‚¢",
			it: "Zambia",
		},
		population: 15473905,
		latlng: [-15, 30],
		demonym: "Zambian",
		area: 752612,
		gini: 54.6,
		timezones: ["UTC+02:00"],
		borders: ["AGO", "BWA", "COD", "MWI", "MOZ", "NAM", "TZA", "ZWE"],
		nativeName: "Zambia",
		currencies: ["ZMK"],
		languages: ["en"],
		numericCode: "894",
	},
	{
		name: "Zimbabwe",
		topLevelDomain: [".zw"],
		alpha2Code: "ZW",
		alpha3Code: "ZWE",
		callingCodes: ["263"],
		capital: "Harare",
		altSpellings: ["ZW", "Republic of Zimbabwe"],
		relevance: "0",
		region: "Africa",
		subregion: "Eastern Africa",
		translations: {
			de: "Simbabwe",
			es: "Zimbabue",
			fr: "Zimbabwe",
			ja: "ã‚¸ãƒ³ãƒ�ãƒ–ã‚¨",
			it: "Zimbabwe",
		},
		population: 13061239,
		latlng: [-20, 30],
		demonym: "Zimbabwean",
		area: 390757,
		gini: null,
		timezones: ["UTC+02:00"],
		borders: ["BWA", "MOZ", "ZAF", "ZMB"],
		nativeName: "Zimbabwe",
		currencies: ["USD"],
		languages: ["en", "sn", "nd"],
		numericCode: "716",
	},
];
