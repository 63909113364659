import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { ACCOUNT_HISTORY_B2B_URL } from "./urls_b2b";
import { UrlBuilder } from "./urls_b2c";

@Injectable()
export class AccountHistoryService {
	fromDate;
	toDate;
	accountID;

	constructor(private baseService: BaseService) {}

	getAccountHistory(agencyID, from, to, accountId) {
		const urlBuilder = new UrlBuilder(ACCOUNT_HISTORY_B2B_URL + agencyID)
			.addParam("accountId", accountId)
			.addParam("from", from)
			.addParam("to", to);

		return this.baseService.tokenGetRequest(urlBuilder.getUrl());
	}
}
