import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Subject } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class InvoiceService {
	readonly URL: string = environment.serverURL + "/api/b2b/invoice";
	private headers: HttpHeaders;
	private token: string;

	private invoicesData;
	private selectedInvoice;

	public dataSubscription: Subject<any> = new Subject<any>();
	public selectedInvoiceSubscription: Subject<any> = new Subject<any>();

	constructor(private baseService: BaseService) {
		this.token = this.baseService.getToken();
		this.headers = this.baseService.createRequestHeaders(this.token);
	}

	getInvoiceByNumber(id: number) {
		function getOne() {
			const data: any[] = this.invoicesData.invoices.data;

			const invoice = data.find((value) => {
				return value.invoice_number == id;
			});

			this.readInvoice(invoice.id);
			this.selectedInvoice = invoice;
			this.selectedInvoiceSubscription.next(invoice);
		}

		if (!this.invoicesData) {
			this.dataSubscription.subscribe((_) => getOne.call(this));

			this.getAll(1, 999);

			return;
		}

		getOne.call(this);
	}

	getTotalUnread() {
		let url = this.URL + "/unread";
		return this.baseService.getRequestCode(url, this.headers);
	}

	getAll(page: number, perPage: number) {
		let url = this.URL + "?page=" + page + "&per_page=" + perPage;
		this.baseService.getRequestCode(url, this.headers).subscribe((data) => {
			this.invoicesData = data;
			this.dataSubscription.next(this.invoicesData);
		});
	}

	readInvoice(id: number) {
		const payload = { invoice_id: id };
		this.baseService.putRequestCode(this.URL, this.headers, payload).subscribe((_) => {});
	}
}
