import {
    Component,
    OnChanges,
    OnInit,
    SimpleChanges,
} from "@angular/core";
import { ShareVars } from "../../../services/share.vars";
import { TaskService } from "@app/shared/services/task.service";


const notAllowedPages = [
    "hotel-search/guest", "guest"
]

@Component({
    selector: "task-activity",
    templateUrl: "./task-activity.component.html",
    styleUrls: ["./task-activity.component.scss"],
})
export class TaskActivityComponent implements OnInit, OnChanges {
    shareVarsRef = ShareVars;
    modalWidth = "95";
    tasks;
    selectedTask: any;
    taskModal = false;

    constructor(public taskService: TaskService) {}

    ngOnInit() {
        this.taskService.tasks.asObservable().subscribe((data) => {
            this.tasks = data;
        });
        this.taskService.selectedTask.asObservable().subscribe((data) => {
            this.selectedTask = data;
        });
        this.taskService.taskActivityModal.asObservable().subscribe((data) => {
            this.taskModal = data;
			const body = document.getElementsByTagName("body")[0]
			if (data && this.tasks.length) {
				body.classList.add('modal-open')
                return
			}
			body.classList.remove('modal-open')
        });

        window.addEventListener("keydown", (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                e.preventDefault();
                this.taskService.closeTaskModal();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log("Activity changes:", changes);
    }

    closeModal() {
        this.taskService.removeTask(this.taskService.getSelectedTask());
        this.taskService.closeTaskModal();
    }

    minimizeModal() {
        this.taskService.closeTaskModal();
    }

    showActivity(task: any) {
        this.taskService.setSelectedTask(task);
        this.taskService.openTaskModal();
    }

    isPageAllowed() {
        const url = window.location.href
        let result = true
        notAllowedPages.forEach((p) => {
            if (url.includes(p)) {
                result = false
            }
        })
        return result
    }
}
