import {
	Component,
	OnInit,
	Output,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	EventEmitter,
} from "@angular/core";
import { AIRLINE_LOGO_URL, STATIC_FILES_URL } from "@app/shared/services/urls_b2c";
import { BaseService } from "../../services/base.service";
import { TranslateService } from "@ngx-translate/core";
import { CharterSearchService } from "@app/shared/services/charter-search.service";

@Component({
	selector: "app-charter-passenger-review",
	templateUrl: "./charter-passenger-review.component.html",
	styleUrls: ["./charter-passenger-review.component.scss"],
})
export class CharterPassengerReviewComponent implements OnInit, OnChanges, OnDestroy {
	@Input() holder: any = {};
	@Input() adults: any = [];
	@Input() infants: any = [];
	@Input() children: any = [];
	@Output() modalEvent = new EventEmitter();

	modalElement;

	constructor(
		private baseService: BaseService,
		private translateService: TranslateService,
		public charterService: CharterSearchService
	) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges): void {}

	ngOnDestroy() {}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}
}
